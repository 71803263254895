import {PropositionEdit} from "components/front/PMS/Proposition/Edit/PropositionEdit";
import React from "react"

export const PropositionEditRoute = (props: any) => {

  const {match} = props;
  const {params} = match || {}

  return <div>
    <PropositionEdit id={params?.id} />
  </div>
}