import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${PaymentFragment}

  mutation paymentSetDeleted($id: String!) {
    payment: paymentSetDeleted(id: $id) {
      ...PaymentFragment
    }
  }

`