import { useQuery } from "@apollo/react-hooks"
import moment from "moment"
import { DateFormat, DateInput, SelectInput, Tooltip } from "@zipou/front_tools"
import postStayConfigGetGql from "graphql/PMS/Stay/Post/Config/query/postStayConfigGet.gql"
import { PostStayAnswerStatsFilterInput, PostStayConfig, PostStayGroupItemTypeEnum, StatsDimensionType, UserRoleEnum } from "model"
import React, { useContext, useState } from "react"
import { PostStayAnswerStatsDisplay } from "../Answer/Stats/Display/PostStayAnswerStats"
import { StatsFilterWeekly } from "./Filters/StatsFiltersWeekly"
import { StatsFilterMonthly } from "./Filters/StatsFiltersMonthly"
import { userContext } from "context/User"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"

type PostStayDashboardProps = {
  hotelId: string,
}

export const PostStayDashboard = ({ hotelId }: PostStayDashboardProps) => {

  const user = useContext(userContext)
  const isDebug = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  const [filters, updateFilters] = useState<PostStayAnswerStatsFilterInput>({
    dimensionValue: "",
    dimensionType: StatsDimensionType.WEEKLY,
  })

  const { data } = useQuery<{ postStayConfig: PostStayConfig }>(postStayConfigGetGql, {
    variables: {
      hotelId,
    }
  })

  const postStayConfig = data?.postStayConfig
  const hotel = data?.postStayConfig?.Hotel


  const isDaily = filters.dimensionType === StatsDimensionType.DAILY
  const isWeekly = filters.dimensionType === StatsDimensionType.WEEKLY
  const isMonthly = filters.dimensionType === StatsDimensionType.MONTHLY

  const hasDimensionValue = !!filters?.dimensionValue

  return <>
    <h1>Dashboard</h1>
    <div className="card">
      <div className="card-header" style={{ backgroundColor: `#${hotel?.themeColor}` }}>
        <HotelBadge hotel={hotel} />
      </div>
      <div className="card-body">
        <div>
          <SelectInput label="Type" errors={{}} id="dimensionType" value={filters?.dimensionType} onChange={(v) => updateFilters({
            ...filters,
            dimensionValue: "",
            dimensionType: v as StatsDimensionType,
          })} choiceList={[
            // { id: StatsDimensionType.DAILY, label: "JOUR" },
            { id: StatsDimensionType.WEEKLY, label: "SEMAINE" },
            { id: StatsDimensionType.MONTHLY, label: "MOIS" },
            // { id: StatsDimensionType.YEARLY, label: "ANNEE" }
          ]} />
          {isDaily && <DateInput label="Date" value={new Date(filters?.dimensionValue).getTime()} onChange={(_, d) =>
            updateFilters({
              ...filters,
              dimensionValue: `${moment(d).format("YYYY-MM-DD")}`
            })
          } />}
          {isWeekly && <div className="">
            <StatsFilterWeekly hotelId={hotelId} dimensionValue={filters.dimensionValue} onChange={(v) => updateFilters({
              ...filters,
              dimensionValue: v

            })} />
          </div>}
          {isMonthly && <div className="">
            <StatsFilterMonthly hotelId={hotelId} dimensionValue={filters.dimensionValue} onChange={(v) => updateFilters({
              ...filters,
              dimensionValue: v
            })} />
          </div>}
          {/* {isDebug && <div className="input-group">
            <span className="input-group-text">Dimension Value</span>
            <div className="form-control">
              <span className="badge badge-dark">{filters.dimensionValue}</span>
            </div>

          </div>} */}
        </div>
        {!hasDimensionValue && <div className="alert alert-info">Aucune donnée</div>}
        {hasDimensionValue && postStayConfig?.PostStayGroup?.map((group, index) => {
          return <div className="card mt-2" key={`group_id_${group?.id}_${index}`}>
            <div className="card-header">
              <h2 style={{ margin: 0 }}>{group?.labelFr}</h2>
            </div>
            <div className="card-body">
              <table className="table table-striped table-bordered">
                <tbody>
                  {group?.PostStayGroupItem?.map((item, idxItem) => {

                    if (item?.Question && item.type === PostStayGroupItemTypeEnum.TYPE_QUESTION) {
                      const question = item?.Question
                      return <tr key={`item_${item?.id}`}>
                        <td style={{ verticalAlign: "middle" }}>
                          <span>
                            {question?.labelFr || question?.id}
                            {isDebug && <><br /><span className="badge badge-secondary badge-sm">{question?.id}</span></>}
                          </span>

                        </td>
                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                          <PostStayAnswerStatsDisplay questionId={question?.id} filters={filters} />
                        </td>
                      </tr>
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        })}
      </div>
    </div>
  </>
}