
import { TextInput } from 'components/common/Form/TextInput'
import { UserConfig } from 'model'
import React from 'react'

type UserConfigFormProps = {
  errors?: any,
  config: Partial<UserConfig>,
  onChange: (config: Partial<UserConfig>) => void,
}

export const UserConfigForm = (props: UserConfigFormProps) => {

  const config = props?.config
  const errors = props?.errors

  const onChange = (config: Partial<UserConfig>) => {
    props?.onChange(config)
  }

  return <div>
    <TextInput errors={errors} value={config?.name} label="name" onChange={(_, v) => {
      onChange({
        ...config,
        name: v,
      })
    }} />
    <TextInput errors={errors} value={config?.datas?.title} label="title" onChange={(_, v) => {
      onChange({
        ...config,
        datas: {
          ...config?.datas,
          title: v
        }
      })
    }} />
    <TextInput errors={errors} value={config?.datas?.logoUrl} label="logoUrl" onChange={(_, v) => {
      onChange({
        ...config,
        datas: {
          ...config?.datas,
          logoUrl: v
        }
      })
    }} />
    <TextInput errors={errors} value={config?.datas?.fontFamily} label="Font Family" onChange={(_, v) => {
      onChange({
        ...config,
        datas: {
          ...config?.datas,
          fontFamily: v
        }
      })
    }} />
    <TextInput errors={errors} value={config?.datas?.themeMainColor} label="themeMainColor" onChange={(_, v) => {
      onChange({
        ...config,
        datas: {
          ...config?.datas,
          themeMainColor: v
        }
      })
    }} />
  </div>
}
