import { useQuery } from '@apollo/react-hooks'
import { Pagination } from 'components/common/Navigation/Pagination/Pagination'
import { HotelBadge } from 'components/front/PMS/Hotel/Badge/HotelBadge'
import { userContext } from 'context/User'
import contactDedupListGql from 'graphql/PMS/Contact/Dedup/query/contactDedupList.gql'
import { ContactDedup } from 'model'
import React, { useContext, useState } from 'react'
import { ContactDetails } from '../../Details/ContactDetails'
import { ContactDedupStatus } from '../Status/ContactDedupStatus'

type ContactDedupListProps = {

}

export const ContactDedupList = ({ }: ContactDedupListProps) => {

  const limit = 5

  const user = useContext(userContext)

  const [filters] = useState({
    hotelIdsList: user?.hotels,
    orderField: "status",
    orderDirection: "ASC",
    status: ["STATUS_TODO"]
  })

  const { data, refetch } = useQuery(contactDedupListGql, {
    variables: {
      input: filters,
      cursor: {
        page: 1,
        limit,
      }
    }
  })

  const contactDedupList = data?.list?.nodes
  const hasDedups = contactDedupList?.length > 0

  return <div>
    <div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th className="bg-dark text-white">Hotel</th>
            <th className="bg-dark text-white">Contact 1</th>
            <th className="bg-dark text-white">Contact 2</th>
            <th className="bg-dark text-white">Status</th>
            <th className="bg-dark text-white">Action</th>
          </tr>
        </thead>
        {hasDedups && <tbody>
          {contactDedupList?.map((contactDedup: ContactDedup) => {
            return <tr>
              <td className="align-middle">
                <HotelBadge hotel={contactDedup?.Hotel} />
              </td>
              <td className="align-middle">
                <ContactDetails contact={contactDedup?.ContactLeft} />
              </td>
              <td className="align-middle">
                <ContactDetails contact={contactDedup?.ContactRight} />
              </td>
              <td className="align-middle">
                <ContactDedupStatus status={contactDedup?.status} />
              </td>
              <td className="align-middle">
                <button className="btn-dark btn btn-sm">
                  <span className="icon-edit"></span>
                </button>
              </td>
            </tr>
          })}
        </tbody>}
        {!hasDedups && <tbody>
          <tr>
            <td colSpan={3}>
              <div className='alert alert-info'>Rien à afficher</div>
            </td>
          </tr>
        </tbody>}
      </table>

    </div>
    <div className="pagination-container">
      <Pagination cursor={data?.list?.cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit,
          }
        })
      }} />
    </div>
  </div>

}