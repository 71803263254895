import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import PropositionListFragment from "../fragment/PropositionListFragment";

export default gql`

  ${PropositionListFragment}
  ${CursorFragment}

  query propositionList($cursor: CursorInput!, $input: PropositionListInput! ) {
    list: propositionList(cursor: $cursor, input: $input) {
      cursor {
        ...CursorFragment
      }
      nodes {
        ...PropositionListFragment
      }
      input
    }
  }

`