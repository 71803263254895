import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${PaymentFragment}

  mutation paymentRefund($id: String!, $amount: Float!) {
    payment: paymentRefund(id: $id, amount: $amount) {
      ...PaymentFragment
    }
  }

`