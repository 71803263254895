
import React from "react"
import BootstrapPagination from "react-bootstrap/Pagination"

type PaginationProps = {
  cursor: any,
  onChange: (page: number) => void
}

export const Pagination = (props: PaginationProps) => {

  const {cursor, onChange} = props
  if (!cursor) return <div></div>

  let {page, totalPages} = cursor

  if (totalPages === 1) return <div></div>

  let options = []

  for (let i = 1; i <= totalPages; i++) {
    options.push(<option value={i} key={`option_${i}`}>Page {i}</option>)
  }

  return (totalPages > 1) && <BootstrapPagination>
    <BootstrapPagination.Prev disabled={page === 1} onClick={() => onChange(page - 1)} key={'pagination_prev'} />
    <div className="">
      <select className="form-control" value={page} onChange={(e: any) => onChange(Number(e.target.value))}>
        {options}
      </select>
    </div>
    <BootstrapPagination.Next disabled={page === totalPages} onClick={() => onChange(page + 1)} key={'pagination_next'} />
  </BootstrapPagination>
}