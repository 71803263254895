import gql from "graphql-tag";
import PostStayAnswerStatsFragmentGql from "../fragment/PostStayAnswerStatsFragment.gql";

export default gql`

${PostStayAnswerStatsFragmentGql}

query postStayAnswerStatsGetByQuestionIdWithFilters($questionId: String!, $input: PostStayAnswerStatsFilterInput!) {
  postStayAnswerStats: postStayAnswerStatsGetByQuestionIdWithFilters(questionId: $questionId, input: $input) {
    ...PostStayAnswerStatsFragment
  }
}

`