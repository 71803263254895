import { useQuery } from "@apollo/react-hooks"
import { IconSearch } from "@zipou/front_tools"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import { URL_NETWORK_CONFIG_HOTELID } from "constant/url"
import { userContext } from "context/User"
import hotelGet from "graphql/PMS/Hotel/query/hotelGet"
import { Hotel } from "model"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { NetworkModuleHotelDisplay } from "./NetworkModuleHotelDisplay"

type NetworkModuleDisplayProps = {

}

export const NetworkModuleDisplay = ({ }: NetworkModuleDisplayProps) => {

  const user = useContext(userContext)
  const history = useHistory()

  const [hotelFocus, updateHotelFocus] = useState<Hotel>(null)
  const hotelList = user?.Hotel
  const hasMultiHotel = hotelList?.length > 1

  useEffect(() => {
    if (!hotelFocus && !hasMultiHotel) {
      updateHotelFocus(user?.Hotel[0])
    }
  }, [user])


  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: hotelFocus?.id,
    },
    skip: !hotelFocus,
  })
  const hotel = data?.hotel
  const hasNetworkConfig = hotel?.NetworkConfig?.hostingConfigId && hotel?.NetworkConfig?.token

  return <>
    {(hasMultiHotel) && <div>
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            <td className="bg-dark text-white">Hotel</td>
            <td className="bg-dark text-white">Actions</td>
          </tr>
        </thead>
        <tbody>
          {hotelList?.map((hotel, index) => {
            return <tr className={`hotel_${hotel?.id}_${index}`}>
              <td><HotelBadge hotel={hotel} /></td>
              <td>
                <button className="btn btn-sm btn-dark" onClick={() => history.push(URL_NETWORK_CONFIG_HOTELID.replace(":hotelId", hotel.id))}>
                  <IconSearch />
                </button>
              </td>
            </tr>
          })}

        </tbody>
      </table>
    </div>
    }
    {(!hasMultiHotel && hasNetworkConfig) && <NetworkModuleHotelDisplay hotelId={hotel?.id || ""} />}
    {(!hasNetworkConfig && !hasMultiHotel) && <div className="alert alert-warning">
      Aucune Configuration Réseau
    </div>}
  </>
}