import gql from "graphql-tag";
import HotelFragment from "../fragment/HotelFragment";

export default gql`

  ${HotelFragment}

  subscription hotelUpdate {
    hotel: hotelUpdate {
      ...HotelFragment
    }
  }

`