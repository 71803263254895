import gql from "graphql-tag";
import ContactFragment from "../fragment/ContactFragment";
// import ContactSearchIndivFragment from "../fragment/ContactSearchIndivFragment";

export default gql`

  ${ContactFragment}

  query contactGet($id: String!) {
    contact: contactGet(id: $id) {
      ...ContactFragment
    }
  }
`