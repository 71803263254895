import { TextInput } from "@zipou/front_tools"
import { GoogleConfigInput } from "model"
import React from "react"

type GoogleConfigFormType = {
  googleConfig: GoogleConfigInput,
  errors: any,
  onChange: (googleConfig: GoogleConfigInput) => void,
}


export const GoogleConfigForm = ({ googleConfig, errors, onChange }: GoogleConfigFormType) => {
  return <div>
    <TextInput id="placeId" errors={errors} value={googleConfig?.placeId || ""} label="placeId" onChange={v => {
      onChange({
        ...googleConfig,
        placeId: v
      })
    }} />
  </div>
}