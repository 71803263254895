import gql from "graphql-tag";
// import ReservationFragment from "graphql/PMS/Reservation/fragment/ReservationFragment";
// import CustomerHistoryFragment from "../History/fragment/CustomerHistoryFragment";
// import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";


export default gql`

  fragment CustomerCTIFragment on Customer {
    id
    #phoneNumbers
    mainEmail
    firstName
    lastName
    origin
    # Reservation {
    #   ...ReservationFragment
    # }
    # Contact {
    #   ...ContactFragment
    # }
  }

`