import React from 'react'
import { Notification, NotificationStatusEnum, NotificationTypeEnum } from "model"
import { URL_BACKOFFICE_BILLING_TOKEN_DISPLAY, URL_BACKOFFICE_PAYMENT_EDIT, URL_PHONE_CALL_EDIT, URL_PLANNING_EVENT_EDIT, URL_POST_STAY_DISPLAY_ID, URL_PRE_STAY_EDIT, URL_PROPOSITION_EDIT } from 'constant/url'
import { useMutation } from '@apollo/react-hooks'
import notificationSetReadGql from 'graphql/APP/Notification/mutation/notificationSetRead.gql'
import { DateTimeFormat, IconEdit, IconSearch } from '@zipou/front_tools'
// import {DateTimeFormat} from 'components/common/Format/DateTimeFormat'

type NotificationDisplayProps = {
  notification: Notification,
  onClick: (notif: Notification, target: string) => void,
}

export const NotificationDisplay = (props: NotificationDisplayProps) => {

  const notification = props?.notification
  const isRead = notification.status === NotificationStatusEnum.STATUS_READ

  const [mutate] = useMutation(notificationSetReadGql)

  const onSetRead = (isRead: boolean) => {
    mutate({
      variables: {
        id: notification?.id,
        isRead,
      }
    })
  }

  const onClick = (target: string) => {
    props?.onClick(notification, target)

  }

  const renderContent = (notification: Notification) => {
    switch (notification?.type) {
      case NotificationTypeEnum.TYPE_SERVICE_EVENT_MANAGER_NEW: {
        return <span>Evénement à traiter</span>
      }
      case NotificationTypeEnum.TYPE_PRESTAY_UPDATED: {
        return <span>Pre-Stay mis à jour</span>
      }
      case NotificationTypeEnum.TYPE_PHONE_CALL_MISSED: {
        return <span>Appel Manqué</span>
      }
      case NotificationTypeEnum.TYPE_PROPOSITION_ACCEPTED: {
        return <span>Proposition Acceptée</span>
      }
      case NotificationTypeEnum.TYPE_PAYMENT_DONE: {
        return <span>Paiement Validé</span>
      }
      case NotificationTypeEnum.TYPE_TOKEN_DONE: {
        return <span>Empreinte Validée</span>
      }
      case NotificationTypeEnum.TYPE_POSTSTAY_ALERT: {
        return <span>Alert PostStay</span>
      }
    }
  }

  const getAction = (notification: Notification) => {
    switch (notification?.type) {
      case NotificationTypeEnum.TYPE_SERVICE_EVENT_MANAGER_NEW: {
        return () => onClick(URL_PLANNING_EVENT_EDIT.replace(':id', notification?.source?.id))
      }
      case NotificationTypeEnum.TYPE_PRESTAY_UPDATED: {
        return () => onClick(URL_PRE_STAY_EDIT.replace(':id', notification?.source?.id))
      }
      case NotificationTypeEnum.TYPE_PHONE_CALL_MISSED: {
        return () => onClick(URL_PHONE_CALL_EDIT.replace(':id', notification?.source?.id))
      }
      case NotificationTypeEnum.TYPE_PROPOSITION_ACCEPTED: {
        return () => onClick(URL_PROPOSITION_EDIT.replace(':id', notification?.source?.id))
      }
      case NotificationTypeEnum.TYPE_PAYMENT_DONE: {
        return () => onClick(URL_BACKOFFICE_PAYMENT_EDIT.replace(':paymentId', notification?.source?.id))
      }
      case NotificationTypeEnum.TYPE_POSTSTAY_ALERT: {
        return () => onClick(URL_POST_STAY_DISPLAY_ID.replace(':id', notification?.source?.id))
      }
      case NotificationTypeEnum.TYPE_TOKEN_DONE: {
        return () => onClick(URL_BACKOFFICE_BILLING_TOKEN_DISPLAY.replace(':id', notification?.source?.id))
      }
    }
  }


  return <div className="card" style={{ borderRadius: 5 }}>
    <div className={notification.status === NotificationStatusEnum.STATUS_UNREAD ? "card-header bg-primary text-white " : "card-header bg-custom-light text-white"}>
      <DateTimeFormat value={notification?.date} />
    </div>
    <div className={notification.status === NotificationStatusEnum.STATUS_UNREAD ? "card-body " : "card-body bg-custom-light text-white"}>
      {renderContent(notification)}
    </div>
    <div className={notification.status === NotificationStatusEnum.STATUS_UNREAD ? "card-footer " : "card-footer bg-custom-light text-white"}>
      <button className="btn btn-sm btn-dark ml-1" onClick={getAction(notification)}>
        <IconSearch />Voir
      </button>
      {!isRead && <button className="btn btn-sm btn-warning ml-1" onClick={() => onSetRead(true)}>
        <IconEdit />
        Marquer lue
      </button>}
      {isRead && <button className="btn btn-sm btn-dark ml-1" onClick={() => onSetRead(false)}>
        <IconEdit />
        Marquer non lue
      </button>}
    </div>
  </div>

}
