import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";
import ReservationGroupLinkFragment from "../../GroupLink/fragment/ReservationGroupLinkFragment";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";

export default gql`

${ReservationGroupLinkFragment}
${HotelDisplayFragment}
${ContactListFragment}

fragment ReservationGroupListFragment on ReservationGroup {
  id
  wholeDates
  Contact {
    ...ContactListFragment
  }
  Hotel {
    ...HotelDisplayFragment
  }
  ReservationGroupLink {
    ...ReservationGroupLinkFragment
  }
}

`