import React from "react"
import { useTranslation } from "react-i18next"
import { PropositionRowTotal } from "./Total/PropositionRowTotal"
import "./style.scss"
import { PropositionRow, PropositionRowRoom, ReservationOptionQuantityTypeEnum, RoomType } from "model";
import { RoomTypeDisplay } from "components/front/PMS/Room/Type/Display/RoomTypeDisplay";
import { DateFormat } from "components/common/Format/DateFormat";


type PropositionRowDisplayProps = {
  showDates?: boolean,
  propositionRow: PropositionRow,
  loading: boolean,
  onFocus: (row: any) => void,
  onSelect: (row: any) => void,
}


export const PropositionRowDisplay = (props: PropositionRowDisplayProps) => {

  const { propositionRow, loading } = props
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'proposition' });
  const isFrench = i18n.language === "fr";

  const rowHasSameDates = propositionRow?.hasSameDates


  const renderRoomType = (roomType: RoomType) => {
    return <div className="" key={`roomType_${roomType?.id}`}>
      <RoomTypeDisplay roomType={roomType} />
    </div>
  }


  return <div className="proposition-row-display-container">
    <div>
      <div className="">
        {props?.showDates && <table className="table table-stripped">
          <tbody>
            <tr>
              <th>{t("Arrivée")}</th>
              <td><DateFormat value={propositionRow?.arrivalDate && new Date(propositionRow?.arrivalDate)} /></td>
            </tr>
            <tr>
              <th>{t("Départ")}</th>
              <td><DateFormat value={propositionRow?.departureDate && new Date(propositionRow?.departureDate)} /></td>
            </tr>
            <tr>
              <th>{t("Nombre de nuits")}</th>
              <td>{propositionRow?.nbNights}</td>
            </tr>
          </tbody>
        </table>}
        <div className="" style={{ padding: 5 }}>
          <div className="row">
            {propositionRow?.roomList?.map((rowRoom: PropositionRowRoom, index: number) => {
              const { itemList, roomIndex, hasDelogement, discount, isUpgrade, DefaultRateType } = rowRoom;
              const hasOptions = rowRoom?.Option?.length > 0
              const hasDiscount = !!discount

              const typeList: RoomType[] = Object.values(itemList?.reduce((acc: any, item: any) => {
                const roomType = item?.Room?.RoomType
                return {
                  ...acc,
                  [roomType?.type]: {
                    ...roomType,
                    roomIndexList: [
                      ...((acc[roomType?.type] && acc[roomType?.type]?.roomIndexList) || []),
                      Number(roomIndex) + 1,
                    ],
                  },
                }
              }, {}))

              const priceRateList = itemList.reduce((acc, roomNight, nightIndex: number) => {
                const priceRate = roomNight?.PriceRate

                if (nightIndex === 0) return [{
                  priceRateType: priceRate?.PriceRateType,
                  nights: [1],
                }]

                //If previous is same type, add the night to the array

                const lastElementIndex = acc?.length - 1

                if (acc[lastElementIndex]?.priceRateType?.id === priceRate?.PriceRateType?.id) {
                  acc[lastElementIndex]?.nights.push(nightIndex + 1)
                  return acc
                } else {
                  //If not, adding the type to root array
                  return [...acc, {
                    priceRateType: priceRate?.PriceRateType,
                    nights: [nightIndex + 1],
                  }]
                }
              }, [])

              const hasManyPriceRates = priceRateList?.length > 1


              const nbRooms = propositionRow?.roomList?.length
              const sm = nbRooms > 1 ? 6 : 12
              const md = (nbRooms > 1) ? (nbRooms > 2) ? 4 : 6 : 12
              const lg = (nbRooms > 1) ? (nbRooms > 2) ? 4 : 6 : 12

              const priceRateDesc = isFrench ? DefaultRateType?.descFr : DefaultRateType?.descEn
              const hasDesc = !!priceRateDesc

              return <div className={`col-12 col-sm-${sm} col-md-${md} col-lg-${lg}`} key={`room_${index}`}>
                <div className="card mt-2" style={{ borderColor: "var(--theme-color)" }}>
                  <div className="card-header text-white" style={{ backgroundColor: "var(--theme-color)" }}>
                    {t('Chambre {{id}}', { id: index + 1 })}
                  </div>
                  <div className="card-body">
                    {hasDelogement && <div className="alert alert-warning">{t("Delogement Warning")}</div>}
                    <div className="card-decks">
                      {typeList?.map((roomType: RoomType) => {
                        return renderRoomType(roomType)
                      })}
                    </div>

                    <div>
                      <div className="border-top border-dark p-1 mt-2"></div>
                    </div>
                    <div className="">
                      <div className="">
                        {isUpgrade && <div className="alert alert-secondary">
                          {t("tarif.surclassement")}
                        </div>}
                        {hasDiscount && <div className="alert alert-primary">
                          {t("tarif.preferentiel")}
                        </div>}
                        {!hasManyPriceRates && <div style={{ padding: 10 }}>
                          <span className="text-dark">{t("Package")}: <span className="text-dark" style={{ textTransform: "capitalize", fontWeight: "bolder", color: "" }}>{isFrench ? DefaultRateType?.labelFr : DefaultRateType?.labelEn}</span></span>
                          {hasDesc && <p className="pt-2 text-dark"> {priceRateDesc}</p>}
                        </div>}
                        {hasManyPriceRates && <div style={{ padding: 10 }} className="card">
                          <div className="card-header">{t("Package")}</div>
                          <div className="card-body">
                            <table className="table">
                              <tbody>
                                {priceRateList?.map(priceRate => {
                                  return <tr>
                                    <td>Nuit {priceRate?.nights?.join(",")}</td>
                                    <td>{isFrench ? priceRate?.priceRateType?.labelFr : priceRate?.priceRateType?.labelEn}</td>
                                  </tr>
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>}
                        {!rowHasSameDates && <p className="text-dark">
                          {t("stayFromTo", {
                            arrival: new Date(rowRoom?.StayDates.arrivalDate)?.toLocaleDateString('fr-FR', { timeZone: "Europe/Paris" }),
                            departure: new Date(new Date(rowRoom?.StayDates.departureDate).getTime() + (1 * 24 * 3600 * 1000))?.toLocaleDateString('fr-FR', { timeZone: "Europe/Paris" }),
                          })}
                        </p>
                        }

                      </div>
                    </div>

                    {hasOptions && <div className="card" style={{ marginTop: 10 }}>
                      <div className="card-header bg-secondary text-white">Options</div>
                      <div className="card-body">
                        <table className="table table-bordered">
                          <tbody>
                            {rowRoom?.Option?.map((rowOption, index: number) => {
                              const { Option, quantity, discount, quantityType } = rowOption

                              const nbNights = rowRoom?.itemList?.length

                              const hasDiscount = !!discount && discount.percent !== ""

                              const realQuantity = quantityType === ReservationOptionQuantityTypeEnum.TYPE_PER_NIGHT ? quantity * nbNights : quantity

                              const optionAmount = Option?.amountIncVat / 100
                              const discountValue = hasDiscount ? discount?.percent === "forced" ? discount.forced : Number(optionAmount * Number(discount.percent) / 100) : 0
                              const totalOptions = (optionAmount) * realQuantity
                              const totalOptionsWithDiscount = (optionAmount - discountValue) * realQuantity

                              return <tr key={`${Option?.labelFr}_${index}`}>
                                <td>{isFrench ? Option?.labelFr : Option?.labelEn}</td>
                                <td>x{quantity} {quantityType === ReservationOptionQuantityTypeEnum.TYPE_PER_NIGHT ? t("perNight") : ""}</td>
                                <td>{hasDiscount ? <span><span style={{ textDecoration: "line-through" }}>{totalOptions} €</span> {totalOptionsWithDiscount === 0 ? `Offert` : `${totalOptionsWithDiscount} €`}</span> : <span>{totalOptions} €</span>}</td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            })}
          </div>
          <div className="pt-2">
            {propositionRow?.comment && <div className="alert alert-secondary">
              {propositionRow?.comment}
            </div>}
            <div style={{ padding: 0 }}>
              <PropositionRowTotal row={propositionRow} onFocus={() => props?.onFocus(propositionRow)} />
            </div>
          </div>
          <div style={{ padding: 20 }}>
            <button disabled={loading} className="btn btn-large btn-dark" onClick={() => props?.onSelect(propositionRow?.id)}>{t("Valider cette proposition")}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

}