import gql from "graphql-tag";

export default gql`

  fragment PaymentListFragment on Payment {
    id
    status
    hotelId
    contactId
    publicId
    publicSalt
    email
    mobilePhoneNumber
    amount
    notes
    isError
    lastError
    publicUrl
    publicPath
    stripeClientSecret
    captureType
    captureStatus
    captureValue
    creationDate
    captureDate
    provider
    hasValidEmail
    sendStatus


  }

`