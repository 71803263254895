import gql from "graphql-tag";
import UserFragment from "../fragment/UserFragment";

export default gql`


  ${UserFragment}

  query userGet($id: String!) {
    user: userGet(id: $id) {
      ...UserFragment
    }
  }
`