import gql from "graphql-tag";
import PhoneAgentConfigFragmentGql from "../fragment/PhoneAgentConfigFragment.gql";

export default gql`

  ${PhoneAgentConfigFragmentGql}

  query phoneAgentConfigGet {
    phoneAgentConfig: phoneAgentConfigGet {
      ...PhoneAgentConfigFragment
    }
  }

`