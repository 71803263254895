import { useQuery } from "@apollo/react-hooks"
import { PhoneDirectoryModule } from "@zipou/phone_front"
import { userContext } from "context/User"
import hotelGet from "graphql/PMS/Hotel/query/hotelGet"
import { Hotel } from "model"
import React, { useContext } from "react"

type PhoneDirectoryProps = {
  hotelId: string,
}

export const PhoneDirectory = ({ hotelId }: PhoneDirectoryProps) => {

  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId
  })

  const user = useContext(userContext)

  const peerName = user?.PhoneAgentConfig?.accountName?.replace("PJSIP/", "")?.replace("SIP/", "")
  const hotel = data?.hotel
  const hasCreds = hotel?.PhoneConfig?.phoneConfigId && hotel?.PhoneConfig?.token

  return <div>
    {hasCreds && <PhoneDirectoryModule
      phoneConfigId={hotel?.PhoneConfig?.phoneConfigId}
      token={hotel?.PhoneConfig?.token}
      peerName={peerName}
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
    />
    }
    {!hasCreds && <div className="alert alert-danger">Aucune configuration trouvée</div>}
  </div>
}