import gql from "graphql-tag";
import ReservationFragment from "../fragment/ReservationFragment";

export default gql`

  ${ReservationFragment}

  mutation reservationSyncById($reservationId: String!) {
    event: reservationSyncById(reservationId: $reservationId) {
      ...ReservationFragment
    }
  }

`