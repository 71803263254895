import gql from "graphql-tag";

export default gql`

  fragment ServiceConfirmationFragment on Service {
    id
    name
    type
    pictoUrl
    labelFr
    labelEn
  }

`