import { TextInput } from "@zipou/front_tools"
import { PhoneSipConfigInput } from "model"
import React from "react"

type PhoneSipConfigFormProps = {
  phoneSipConfig: PhoneSipConfigInput,
  onChange: (phoneSipConfig: PhoneSipConfigInput) => void,
  errors: any,
}


export const PhoneSipConfigForm = ({ phoneSipConfig, onChange, errors }: PhoneSipConfigFormProps) => {

  return <>

    <TextInput label="Serveur" value={phoneSipConfig?.server || ""} id={"server"} errors={errors} onChange={(v) => onChange({
      ...phoneSipConfig,
      server: v
    })} />

    <TextInput label="User" value={phoneSipConfig?.user || ""} id={"user"} errors={errors} onChange={(v) => onChange({
      ...phoneSipConfig,
      user: v
    })} />

    <TextInput label="Mot de passe" value={phoneSipConfig?.password || ""} id={"password"} errors={errors} onChange={(v) => onChange({
      ...phoneSipConfig,
      password: v
    })} />

  </>

}