import React, {useState} from "react"


import {UserConfig} from "model"
import {UserConfigForm} from "../Form/UserConfigForm"
import {useMutation, useQuery} from "@apollo/react-hooks"
import {formatErrorResponseForJoi} from "helpers/Apollo"
import configUpdateGql from "graphql/APP/User/Config/mutation/userConfigUpdate.gql"
import configGetGql from "graphql/APP/User/Config/query/userConfigGet.gql"
import configListGql from "graphql/APP/User/Config/query/userConfigList.gql"

type UserConfigEditProps = {
  id: string,
  onDone?: () => void,
}

export const UserConfigEdit = (props: UserConfigEditProps) => {

  const [config, updateConfig] = useState<Partial<UserConfig>>()
  const [errors, updateErrors] = useState<any>()


  useQuery(configGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
    onCompleted:(response) => {
      updateConfig(response?.config)
    }
  })

  const [mutate] = useMutation(configUpdateGql)

  const onCancel = () => {
    props?.onDone()
  }

  const onSave = () => {
    const {__typename, id, datas, ...input} = config
    mutate({
      variables: {
        id: props?.id,
        input: {
          ...input,
          datas: {
            title: datas?.title,
            logoUrl: datas?.logoUrl,
            fontFamily: datas?.fontFamily,
            themeMainColor: datas?.themeMainColor,
          }
        },
      },
      refetchQueries: [{
        query: configListGql
      }]
    })
    .then(() => {
      props?.onDone()
    })
    .catch(e => {
      updateErrors(formatErrorResponseForJoi(e))
    })
  }

return <div>
    <UserConfigForm config={config} onChange={config => updateConfig(config)} errors={errors} />
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => onSave() }>Envoyer</button>
      {props?.onDone && <button className="btn btn-sm btn-danger" style={{marginLeft: 5}} onClick={() => onCancel()}>Annuler</button>}
    </div>
  </div>

}