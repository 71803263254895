import { PriceRateType, PriceRateTypeInput } from "model"
import React from "react"
import { PriceRateTypeForm } from "../Form/PriceRateTypeForm"

type PriceRateTypeEditProps = {
  hotelId: string,
  priceRateType: PriceRateTypeInput,
  onChange: (priceRateType: PriceRateTypeInput) => void,
  errors: any,
}


export const PriceRateTypeEdit = ({ priceRateType, hotelId, onChange, errors }: PriceRateTypeEditProps) => {

  return <div>
    <PriceRateTypeForm hotelId={hotelId} open={true} priceRateType={priceRateType} onChange={onChange} errors={errors} />
  </div>

}