import { useMutation, useQuery } from '@apollo/react-hooks'
import reservationGroupUpdate from 'graphql/PMS/Reservation/Group/mutation/reservationGroupUpdate'
import reservationGroupGetGql from 'graphql/PMS/Reservation/Group/query/reservationGroupGet.gql'
import { formatErrorResponseForJoi } from 'helpers/Apollo'
import { ReservationGroupLink } from 'model'
import React, { useState } from 'react'
import { ReservationGroupForm } from '../Form/ReservationGroupForm'
import reservationGroupDelete from 'graphql/PMS/Reservation/Group/mutation/reservationGroupDelete'
// import { userContext } from 'context/User'
import { TextInput } from '@zipou/front_tools'

type ReservationGroupEditProps = {
  id: string,
  onDone?: () => void
}

export const ReservationGroupEdit = (props: ReservationGroupEditProps) => {

  const [reservationGroup, updateReservationGroup] = useState<any>()
  const [isUpdated, updateIsUpdated] = useState<any>()
  const [errors, updateErrors] = useState<any>()
  const canbeDeleted = true

  // const user = useContext(userContext)
  // const hasDebugRole = user.roles?.includes(UserRoleEnum.ROLE_DEBUG)


  const [mutate] = useMutation(reservationGroupUpdate)

  const [mutateDelete] = useMutation(reservationGroupDelete)


  const { data } = useQuery(reservationGroupGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
    onCompleted: (data) => {
      updateReservationGroup(data?.reservationGroup)
    }
  })

  const onDelete = () => {

    mutateDelete({
      variables: {
        id: props?.id,
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
  }


  const onSave = () => {
    const { __typename, id, Hotel, ReservationGroupLink, ...input } = reservationGroup
    mutate({
      variables: {
        input: {
          ...input,
          ReservationGroupLink: ReservationGroupLink?.map((rgl: ReservationGroupLink) => ({
            id: rgl?.id,
            groupId: rgl.groupId,
            reservationId: rgl.reservationId,
          }))
        },
        id: props?.id
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const onReset = () => {
    updateIsUpdated(false)
    updateErrors(null)
    updateReservationGroup(data?.reservationGroup)
  }

  const onChange = (group: any) => {

    updateIsUpdated(true)
    updateReservationGroup(group)
  }


  return <div>

    <TextInput disabled value={reservationGroup?.id} label={'Identifiant'} onChange={() => { }} errors={undefined} />

    <ReservationGroupForm errors={errors} reservationGroup={reservationGroup} onChange={onChange} />
    <div>
      <button className='btn btn-sm btn-dark ml-2' onClick={() => onSave()}>Enregistrer</button>
      {isUpdated && <button className='btn btn-sm btn-warning ml-2' onClick={() => onReset()}>Annuler</button>}
      {canbeDeleted && <button className='btn btn-sm btn-danger ml-2' onClick={() => onDelete()}>Supprimer le groupe</button>}
    </div>

  </div>

}