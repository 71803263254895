import gql from "graphql-tag";

export default gql`

fragment FileEncryptedFragment on FileEncrypted {
  id
  name
  url
}

`