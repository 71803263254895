import gql from "graphql-tag";
import ReservationGroupEditFragment from "../fragment/ReservationGroupEditFragment";

export default gql`

  ${ReservationGroupEditFragment}

  mutation reservationGroupAdd($input: ReservationGroupEditInput!) {
    reservationGroup: reservationGroupAdd(input: $input) {
      ...ReservationGroupEditFragment
    }
  }

`