import gql from "graphql-tag";
import DeviceFragmentGql from "../fragment/DeviceFragment.gql";

export default gql`

${DeviceFragmentGql}

query deviceGetByIdAndToken($id: String!, $token: String!) {
  device: deviceGetByIdAndToken(id: $id, token: $token) {
    ...DeviceFragment
  }
}

`