import gql from "graphql-tag";

export default gql`
  fragment ContactPublicFragment on Contact {
    id
    lastName
    firstName
    companyName
    isFrench
    type
    countryCode
    greetingsFormatted
    forceIsFrench
    email
    address1
    address2
    address3
    zipcode
    city
    country
    phone1
    isPhone1Valid
    isEmailValid
  }
`