import { TextInput } from "@zipou/front_tools"
import { TripAdvisorConfigInput } from "model"
import React from "react"

type TripAdvisorConfigFormType = {
  tripAdvisorConfig: TripAdvisorConfigInput,
  errors: any,
  onChange: (TripAdvisorConfig: TripAdvisorConfigInput) => void,
}


export const TripAdvisorConfigForm = ({ tripAdvisorConfig, errors, onChange }: TripAdvisorConfigFormType) => {
  return <div>
    <TextInput id="reviewUrl" errors={errors} value={tripAdvisorConfig?.reviewUrl || ""} label="reviewUrl" onChange={v => {
      onChange({
        ...tripAdvisorConfig,
        reviewUrl: v
      })
    }} />
  </div>
}