import { useQuery } from "@apollo/react-hooks"
import { IconLoading } from "@zipou/front_tools"
import terminalGetGql from "graphql/BILLING/Terminal/query/terminalGet.gql"
import { Terminal, TerminalStatusEnum } from "model"
import React from "react"

type TerminalStatusProps = {
  terminalId: string,
}

export const TerminalStatus = ({ terminalId }: TerminalStatusProps) => {

  const { data } = useQuery<{ terminal: Terminal }>(terminalGetGql, {
    variables: {
      id: terminalId
    }
  })

  const terminal = data?.terminal

  switch (terminal?.status) {

    case TerminalStatusEnum.STATUS_IDLE: {
      return <span className="badge badge-sm btn-success">EN ATTENTE</span>
    }

    case TerminalStatusEnum.STATUS_PROCESSING: {
      return <span className="badge badge-sm badge-warning"><IconLoading /> <span className="ml-1">EN COURS</span></span>
    }

    default: {
      return <span className="badge badge-sm badge-dark">INCONNU</span>
    }
  }


}