import React, {useState, useEffect} from "react"

type DateInputProps = {
  value: number,
  label?: string,
  isError?: boolean,
  onChange: (value: number, date: Date) => void
}

export const DateInput = (props: DateInputProps) => {

  // const [isValid, updateIsValid] = useState(true)

  const stampValue = props?.value
  const dateValue = (stampValue && new Date(stampValue)) || null
  const displayValue = dateValue?.toISOString().substring(0, 10)

  const [fieldValue, updateFieldValue] = useState(displayValue)

  useEffect(() => {
    // if (!fieldValue) {
      const dateValue = (props?.value && new Date(props?.value )) || null
      updateFieldValue(dateValue?.toISOString().substring(0, 10))
    // }
  }, [props?.value, fieldValue])


  const onChange = (targetFieldValue: string) => {
    updateFieldValue(targetFieldValue)
    const parsedDate = Date.parse(targetFieldValue)
    const dateValue = parsedDate && new Date(parsedDate)
    if (dateValue) {
      // updateIsValid(true)
      const stampValue = Math.round(dateValue?.getTime())
      props?.onChange(stampValue, dateValue)
    // } else {
    //   updateIsValid(false)
    }
  }

  return <div className="input-group">
    {props?.label && <span className="input-group-text">{props?.label}</span>}
    <input type="date" className="form-control" placeholder={props?.label} value={fieldValue || ""} onChange={(e: any) => onChange(e.target.value)} />
    {props?.isError && <span className="input-group-text"><span className="icon-warning-sign" /></span>}
  </div>
}