import gql from "graphql-tag";

export default gql`

  fragment TerminalListFragment on Terminal {
    id
    name
    type
    location
    status
  }

`