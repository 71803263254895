import { PostStayConfigInput, PostStayGroupInput, Template } from 'model'
import React, { useState } from 'react'
import { PostStayGroupForm } from '../../Group/Form/PostStayGroupForm'
import { BooleanInput, Collapse, IconArrowDown, IconArrowUp, IconEdit, IconTrash, Modal, NumberInput, SelectInput } from '@zipou/front_tools'
import templateListQuery from "graphql/APP/Template/query/templateList"
import { useQuery } from '@apollo/react-hooks'
import { JSONImportForm } from 'components/front/APP/Template/Form/JSONImportForm'
import { ClickAndCopy } from 'components/common/Form/ClickAndCopy'

type PostStayConfigFormProps = {
  errors: any,
  hotelId: string,
  postStayConfig: PostStayConfigInput,
  onChange: (postStayConfig: PostStayConfigInput) => void,
}

export const PostStayConfigForm = ({ hotelId, postStayConfig, onChange, errors }: PostStayConfigFormProps) => {


  const { data } = useQuery<{ list: Template[] }>(templateListQuery, {
    variables: {
      hotelId,
    }
  })


  const [focusPostStayGroup, updateFocusPostStayGroup] = useState<PostStayGroupInput>()
  const [focusExport, updateFocusExport] = useState<boolean>()
  const [focusImport, updateFocusImport] = useState<boolean>()

  const postStayGroupList = postStayConfig?.PostStayGroup

  const onMove = (index: number, up: boolean) => {

    //Interverti juste les deux elements dans l'array et update le state
    const elementToMove = postStayGroupList[index]
    const backup = postStayGroupList[index - (1 * (up ? 1 : -1))]
    postStayGroupList[index - (1 * (up ? 1 : -1))] = elementToMove
    postStayGroupList[index] = backup

    onChange({
      ...postStayConfig,
      PostStayGroup: postStayGroupList
    })

  }

  const templateList = data?.list

  const filterPostGroup = (postGroupList: PostStayGroupInput[]): PostStayGroupInput[] => {
    return postGroupList?.map(postGroup => {
      const { id, ...rest } = postGroup
      return {
        ...rest,
        PostStayGroupItem: rest?.PostStayGroupItem?.map(item => {
          const { id: itemId, ...restItem } = item
          const { id: questionId, ...restQuestion } = item?.Question || {}
          const { id: separatorId, ...restSeparator } = item?.Separator || {}
          return {
            ...restItem,
            Question: restQuestion,
            Separator: restSeparator,
          }
        })
      }

    })
  }

  return <div className='mb-4'>
    {focusImport && <Modal title='Importer' display onClose={() => updateFocusImport(false)}>
      <JSONImportForm onChange={(value) => {
        console.log("VALUE", value)
        onChange({
          ...postStayConfig,
          PostStayGroup: value,
        })
      }} />
    </Modal>}
    {focusExport && <Modal title='Exporter' display onClose={() => updateFocusExport(false)}>
      <div className="input-group">
        <span className="input-group-text">Exporter</span>
        <textarea className="form-control" disabled>
          {postStayConfig?.PostStayGroup && JSON.stringify(filterPostGroup(postStayConfig?.PostStayGroup))}
        </textarea>
        <ClickAndCopy value={postStayConfig?.PostStayGroup && JSON.stringify(filterPostGroup(postStayConfig?.PostStayGroup))} />
      </div>
    </Modal>}
    <BooleanInput id={"postStayActive"} value={postStayConfig?.postStayActive} label='Post Stay Actif ?' errors={errors} onChange={(v) => {
      onChange({
        ...postStayConfig,
        postStayActive: v,
      })
    }} />


    <NumberInput id={"sendHour"} value={`${postStayConfig?.sendHour}` || ""} label="Heure d'envoi" errors={errors} onChange={(e, v) => {
      onChange({
        ...postStayConfig,
        sendHour: v,
      })
    }} />


    <NumberInput id={"alertThreshold"} value={`${postStayConfig?.alertThreshold}` || ""} label="Seuil d'Alerte" errors={errors} onChange={(e, v) => {
      onChange({
        ...postStayConfig,
        alertThreshold: v,
      })
    }} />

    <BooleanInput id={"demoMode"} value={postStayConfig?.demoMode} label='demoMode ?' errors={errors} onChange={(v) => {
      onChange({
        ...postStayConfig,
        demoMode: v,
      })
    }} />

    <div className="input-group">
      <span className='input-group-text'>Copies des envois</span>
      <div className="form-control" style={{ height: "100%" }}>
        {postStayConfig?.dest?.map((dest: string, index: number) => {
          return <div className="input-group" key={`postStayConfig_dest_${index}`}>
            <span className="input-group-text">Email</span>
            <input className="form-control" type="text" value={dest} onChange={(e: any) => {
              onChange({
                ...postStayConfig,
                dest: postStayConfig.dest?.map((el: any, id: number) => (id === index) ? e.target.value : el)
              })
            }} />
            <span className="input-group-text">
              <span className="icon-close" onClick={() => {
                onChange({
                  ...postStayConfig,
                  dest: postStayConfig?.dest?.filter((el: any, id: number) => id !== index)
                })
              }}></span>
            </span>

          </div>
        })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...postStayConfig,
              dest: [
                ...(postStayConfig?.dest ? postStayConfig?.dest : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>

    <div className="input-group">
      <span className='input-group-text'>Canaux</span>
      <div className="form-control" style={{ height: "100%" }}>
        <input type="checkbox" id="allowNull" checked={postStayConfig?.channel?.includes(null)} onChange={e => {
          onChange({
            ...postStayConfig,
            channel: e.target.checked ? [...(postStayConfig?.channel || []), null] : postStayConfig?.channel?.filter((el: any) => el !== null)
          })
        }} /><label htmlFor="allowNull">Autoriser un channel Vide</label>
        {postStayConfig?.channel
          ?.filter((el: any) => el !== null)
          ?.map((dest: string, index: number) => {
            return <div className="input-group" key={`confDest_${index}`}>
              {/* <span className="input-group-text">Channel</span> */}
              <input className="form-control" type="text" value={dest} onChange={(e: any) => {
                onChange({
                  ...postStayConfig,
                  channel: postStayConfig?.channel?.map((el: any, id: number) => (id === index) ? e.target.value : el)
                })
              }} />
              <span className="input-group-text">
                <span className="icon-close" onClick={() => {
                  onChange({
                    ...postStayConfig,
                    channel: postStayConfig?.channel?.filter((el: any, id: number) => id !== index)
                  })
                }}></span>
              </span>

            </div>
          })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...postStayConfig,
              channel: [
                ...(postStayConfig?.channel ? postStayConfig?.channel : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>


    <NumberInput id={"nbDaysAfterDeparture"} value={`${postStayConfig?.nbDaysAfterDeparture}` || ""} label='Nombre de Jours après Départ' errors={errors} onChange={(e, v) => {
      onChange({
        ...postStayConfig,
        nbDaysAfterDeparture: Number(v),
      })
    }} />


    <SelectInput id={"templateId"} value={postStayConfig?.templateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template' errors={errors} onChange={v => {
      onChange({
        ...postStayConfig,
        templateId: v,
      })
    }} />

    <SelectInput id={"thanksTemplateId"} value={postStayConfig?.thanksTemplateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template de Remerciement' errors={errors} onChange={v => {
      onChange({
        ...postStayConfig,
        thanksTemplateId: v,
      })
    }} />


    <h1>Groupes de Question</h1>
    <Modal display={!!focusPostStayGroup} title={postStayGroupList?.find(el => el.id === focusPostStayGroup?.id)?.labelFr || ""} size='xl' onClose={() => updateFocusPostStayGroup(undefined)}>
      <PostStayGroupForm postStayGroup={postStayGroupList?.find(el => el.id === focusPostStayGroup?.id)} errors={errors && errors?.PostStayGroup} onChange={(postStayGroup) => {
        onChange({
          ...postStayConfig,
          PostStayGroup: postStayGroupList?.map((psg, idx) => {
            return (psg?.id === postStayGroup?.id) ? postStayGroup : psg
          })
        })
      }} />

    </Modal>
    <div className='mb-4'>
      <table className='table table-striped table-bordered'>
        <tbody>
          {postStayGroupList?.map((postStayGroup, index) => {
            const canMoveUp = index > 0
            const canMoveDown = index < postStayGroupList?.length - 1

            return <tr key={`poststayGroup_${postStayGroup?.id}_${index}`}>
              <td>#{index + 1}</td>
              <td>{postStayGroup.labelFr}</td>
              <td>
                <button className='btn btn-sm btn-warning ml-1' onClick={() => updateFocusPostStayGroup(postStayGroup)}>
                  <IconEdit />
                </button>
                {canMoveUp && <button className='btn btn-dark btn-sm ml-1' onClick={() => onMove(index, true)}>
                  <IconArrowUp />
                </button>}
                {canMoveDown && <button className='btn btn-dark btn-sm ml-1' onClick={() => onMove(index, false)}>
                  <IconArrowDown />
                </button>}
                <button className='btn btn-danger btn-sm ml-4' onClick={() => {
                  onChange({
                    ...postStayConfig,
                    PostStayGroup: postStayGroupList?.filter((psg, idx) => {
                      return idx !== index
                    })
                  })
                }}>
                  <IconTrash />
                </button>
              </td>
            </tr>

          })}
        </tbody>
      </table>
      <div>
        <button className='btn btn-sm btn-dark' onClick={() => {
          onChange({
            ...postStayConfig,
            PostStayGroup: [
              ...(postStayGroupList || []),
              {},
            ]
          })
        }}>Ajouter un groupe de question</button>

        <button className='btn btn-warning btn-sm ml-1' onClick={() => updateFocusExport(true)}>Exporter les questions</button>
        <button className='btn btn-primary btn-sm ml-1' onClick={() => updateFocusImport(true)}>Importer les questions</button>
      </div>
    </div>

  </div>


}