import { useQuery } from "@apollo/react-hooks"
import fileGetGql from "graphql/APP/File/query/fileGet.gql"
import { File } from "model"
import React from "react"
type FileDisplayProps = {
  id: string,
  displayPreview?: boolean,
}

export const FileDisplay = ({ displayPreview, id }: FileDisplayProps) => {

  const { data } = useQuery<{ file: File }>(fileGetGql, {
    variables: {
      id: id,
    },
    skip: !id,
  })

  const renderDisplayPreview = () => {
    switch (file?.extension) {

      case "jpg":
      case "png":
      case "jpeg": {
        return <img alt="room_description" src={file?.url} className="img-responsive" style={{ width: "100%" }} />
      }

      default:
        return <></>
    }
  }


  const file = data?.file

  return <div className="card">
    <div className="card-header">{file?.name}</div>
    <div className="card-body">
      <table className="table table-bordered table-stripped">
        <tbody>
          <tr>
            <th>Url</th>
            <td>{file?.url}</td>
          </tr>
          {displayPreview && <tr>
            <td colSpan={2}>{renderDisplayPreview()}</td>
          </tr>}
        </tbody>
      </table>
    </div>


  </div>

}