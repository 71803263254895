import gql from "graphql-tag";
import PhoneConfigFragmentGql from "../fragment/PhoneConfigFragment.gql";

export default gql`

  ${PhoneConfigFragmentGql}

  query phoneConfigGetByHotelId($hotelId: String!) {
    phoneConfig: phoneConfigGetByHotelId(hotelId: $hotelId) {
      ...PhoneConfigFragment
    }
  }

`