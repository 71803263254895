import { BooleanInput } from 'components/common/Form/BooleanInput';
import { PriceInput } from 'components/common/Form/PriceInput';
import { TextInput } from 'components/common/Form/TextInput';
import React, { useState } from 'react'

import "./style.scss"
import { useQuery } from '@apollo/react-hooks';
import seasonalityListGql from 'graphql/PMS/Seasonality/query/seasonalityList.gql';
import { Option, OptionInput, Seasonality } from 'model';
import { FileInput } from 'components/front/APP/File/Input/FileInput';

type OptionFormProps = {
  open: boolean,
  option: OptionInput,
  errors: any,
  hotelId: string,
  onChange: (data: any) => void,
}

export const OptionForm = (props: OptionFormProps) => {

  const { option, errors } = props;
  const [open, updateOpen] = useState(props?.open)


  const { data: dataSeasonality } = useQuery<{ list: Seasonality[] }>(seasonalityListGql, {
    variables: {
      hotelId: props?.hotelId,
    }
  })

  const seasonalityList = dataSeasonality?.list
  const hasSeasonality = seasonalityList?.length > 0


  const onChange = (value: any) => {
    props?.onChange(value)
  }

  const hasErrors = errors && Object.keys(errors).length > 0

  return <div className="card option-container">
    <div className='card-header' onClick={() => updateOpen(!open)}>
      {hasErrors && <span className="icon-warning-sign"> </span>}
      {option?.labelFr}
    </div>
    <div className={open ? "collapse-container-option collapse-visible-option" : "collapse-container-option"}>
      <div className="card-body">
        <TextInput errors={props?.errors} value={option?.pmsId || ""} label="Code PMS" id={"pmsId"} onChange={(e: any) => {
          onChange({
            ...option,
            pmsId: e.target.value,
          })
        }} />

        <TextInput errors={props?.errors} value={option?.labelFr || ""} label="Label Français" id={"labelFr"} onChange={(e: any) => {
          onChange({
            ...option,
            labelFr: e.target.value,
          })
        }} rightLabel={<span className='badge badge-warning'>Visible par les clients</span>} />

        <TextInput errors={props?.errors} value={option?.descFr || ""} label="Description Français" id={"descFr"} onChange={(e: any) => {
          onChange({
            ...option,
            descFr: e.target.value,
          })
        }} rightLabel={<span className='badge badge-warning'>Visible par les clients. {option?.descFr?.length || 0}/100 car max.</span>} />

        <TextInput errors={props?.errors} value={option?.labelEn || ""} label="Label Anglais" id={"labelEn"} onChange={(e: any) => {
          onChange({
            ...option,
            labelEn: e.target.value,
          })
        }} rightLabel={<span className='badge badge-warning'>Visible par le client</span>} />

        <TextInput errors={props?.errors} value={option?.descEn || ""} label="Description Anglais" id={"descEn"} onChange={(e: any) => {
          onChange({
            ...option,
            descEn: e.target.value,
          })
        }} rightLabel={<span className='badge badge-warning'>Visible par les clients. {option?.descEn?.length || 0}/100 car max.</span>} />


        <PriceInput label={"Montant AVEC TVA"} value={option?.amountIncVat} disabled={false} isError={!!props?.errors?.amountIncVat} onChange={(value: string) => {
          onChange({
            ...option,
            amountIncVat: value,
          })
        }} />
        <PriceInput label={"Montant SANS TVA"} value={option?.amountExcVat} disabled={false} isError={!!props?.errors?.amountExcVat} onChange={(value: string) => {
          onChange({
            ...option,
            amountExcVat: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isBreakfast} label="Option Petit Déjeuner ?" id={"isBreakfast"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isBreakfast: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isSpa} label="Option Spa ?" id={"isSpa"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isSpa: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isRestaurant} label="Option Restaurant ?" id={"isRestaurant"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isRestaurant: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isRestaurant2} label="Option Restaurant2 ?" id={"isRestaurant2"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isRestaurant2: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.canBeDisplayedInConfirmation} label="Afficher dans les Confirmations ?" id={"canBeDisplayedInConfirmation"} onChange={(value: boolean) => {
          onChange({
            ...option,
            canBeDisplayedInConfirmation: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.canBeSelectedInProposition} label="Selectionable dans les Propositions ?" id={"canBeSelectedInProposition"} onChange={(value: boolean) => {
          onChange({
            ...option,
            canBeSelectedInProposition: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.canBeSelectedInPreStay} label="Selectionable dans les Pré Stay ?" id={"canBeSelectedInPreStay"} onChange={(value: boolean) => {
          onChange({
            ...option,
            canBeSelectedInPreStay: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isPerNight} label="Par Nuit ?" id={"isPerNight"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isPerNight: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isPerAdult} label="Par Adulte ?" id={"isPerAdult"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isPerAdult: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isPerChildren} label="Par Enfant ?" id={"isPerChildren"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isPerChildren: value,
          })
        }} />
        <BooleanInput errors={props?.errors} value={option?.isPerSpecial} label="Par Special ?" id={"isPerSpecial"} onChange={(value: boolean) => {
          onChange({
            ...option,
            isPerSpecial: value,
          })
        }} />

        <FileInput label="Picto" id={option?.fileId} onAdd={file => {
          onChange({
            ...option,
            fileId: file?.id
          })

        }} />

        {hasSeasonality && <div className='input-group'>
          <span className="input-group-text">Afficher dans les saisonalités</span>
          <div className='form-control' style={{ height: "100%" }}>
            <div className='d-flex flex-row justify-content-center align-items-center'>
              {seasonalityList?.map(seasonality => {

                //Checked if not exclude
                const isChecked = !option?.seasonalityExclude?.find(el => el === seasonality?.id)

                return <div key={`season_${seasonality?.id}`} className='ml-2'>
                  <input type="checkbox" id={`season_${seasonality?.id}`} checked={isChecked} onChange={(e) => {
                    const isChecked = e.target.checked
                    onChange({
                      ...option,
                      seasonalityExclude: isChecked
                        //Reverse checked means not excluded  
                        ? option?.seasonalityExclude?.filter((el) => el !== seasonality.id)
                        //Reverse not checked means EXCLUDED
                        : [...(option?.seasonalityExclude || []), seasonality?.id]
                    })
                  }}></input>
                  <label htmlFor={`season_${seasonality?.id}`}>{seasonality.name}</label>
                </div>
              })}
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>

}