import gql from "graphql-tag";
import CheckinFragmentGql from "../fragment/CheckinFragment.gql";

export default gql`

  ${CheckinFragmentGql}

  query checkinGet($id: String!) {
    checkin: checkinGet(id: $id) {
      ...CheckinFragment
    }
  }
`