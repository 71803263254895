import gql from "graphql-tag";
import PropositionFragment from "../fragment/PropositionFragment";

export default gql`

  ${PropositionFragment}

  mutation propositionPush($id: String!) {
    proposition: propositionPushById(id: $id) {
      ...PropositionFragment
    }
  }

`