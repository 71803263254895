import gql from "graphql-tag";

export default gql`
  
fragment NewsEditFragment on News {
  id
  date
  title
  level
  contentJSON
  contentHTML
}

`