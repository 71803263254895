import React from "react"

type ConfirmationStatusProps = {
  status: string,
}

export const ConfirmationStatus = (props: ConfirmationStatusProps) => {
  switch (props?.status) {

    case "CONFIRMATION_STATUS_TO_ANSWER": {
      return <span className="badge badge-secondary">A Traiter</span>
    }

    case "CONFIRMATION_STATUS_ERROR": {
      return <span className="badge badge-danger">En Erreur</span>
    }

    case "CONFIRMATION_STATUS_DELETED": {
      return <span className="badge badge-danger">Supprimé</span>
    }

    case "CONFIRMATION_STATUS_TO_SEND": {
      return <span className="badge badge-dark">En attente d'envoi</span>
    }

    case "CONFIRMATION_STATUS_TO_VALID": {
      return <span className="badge badge-warning">A Vérifier</span>
    }

    case "CONFIRMATION_STATUS_SENT": {
      return <span className="badge badge-primary">Envoyée</span>
    }

    case "CONFIRMATION_STATUS_DELIVERED": {
      return <span className="badge badge-success">Délivrée</span>
    }

    case "CONFIRMATION_STATUS_READ": {
      return <span className="badge badge-success">Lu</span>
    }

    default: {
      return <span className="badge badge-info">{props?.status}</span>
    }
  }
}