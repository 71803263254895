import gql from "graphql-tag";

export default gql`

  fragment ConfirmationQuestionFragment on ConfirmationQuestion {
    id
    varId
    answerType
    labelFR
  }

`