import gql from "graphql-tag";
import ReservationSearchFragment from "../fragment/ReservationSearchFragment";

export default gql`

${ReservationSearchFragment}

query reservationSearchByPmsId($pmsId: String!, $hotelId: String!) {
  list: reservationSearchByPmsId(pmsId: $pmsId, hotelId: $hotelId) {
    ...ReservationSearchFragment
  }
}

`