import { useQuery } from "@apollo/react-hooks"
import { CameraListModule } from "@zipou/video_front"
import hotelGet from "graphql/PMS/Hotel/query/hotelGet"
import { Hotel } from "model"
import React from "react"

type VideoDisplayProps = {
  hotelId: string,
}

export const VideoDisplay = ({ hotelId }: VideoDisplayProps) => {

  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId
  })


  const hotel = data?.hotel
  const id = hotel?.VideoConfig?.videoConfigId
  const token = hotel?.VideoConfig?.token
  const hasCreds = id && token

  return <div>
    {hasCreds && <CameraListModule
      videoConfigId={id}
      token={token}
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
    />}
    {!hasCreds && <div className="alert alert-danger">Aucune configuration trouvée</div>}
  </div>
}