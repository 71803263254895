import gql from "graphql-tag";
import CustomerSearchFragment from "../fragment/CustomerSearchFragment";

export default gql`

${CustomerSearchFragment}

query customerSearchByText($text: String!) {
  list: customerSearchByText(text: $text) {
    ...CustomerSearchFragment
  }
}
`