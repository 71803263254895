import gql from "graphql-tag";

export default gql`
  fragment HotelDisplayFragment on Hotel {
    id
    name
    themeColor
    logoUrl
    PhoneConfig {
      id
    }
    BillingConfig {
      id
    }
  }
`