import {ReservationGroupList} from 'components/front/PMS/Reservation/Group/List/ReservationGroupList'
import React from 'react'

type ReservationGroupListRouteProps = {

}

export const ReservationGroupListRoute = (props: ReservationGroupListRouteProps) => {

return <div>
  <ReservationGroupList />
</div>

}