import { PriceRateType } from "model";
import React from "react"
import { useTranslation } from "react-i18next";

type PriceRateTypePublicDisplayProps = {
  isFrench: boolean,
  priceRateType: PriceRateType,
  children?: (hasExtra: boolean, component: any) => any,
}

export const PriceRateTypePublicDisplay = ({ isFrench, priceRateType, children }: PriceRateTypePublicDisplayProps) => {

  const { t } = useTranslation('translation', { keyPrefix: 'confirmation' });

  const hasBreakfast = priceRateType?.isBreakfastIncluded
  const hasSpa = priceRateType?.hasSpa
  const hasFood = priceRateType?.hasFood
  const hasDrink = priceRateType?.hasDrink

  const hasDesc = isFrench ? priceRateType.descFr : priceRateType.descEn

  const hasExtra = hasBreakfast || hasSpa || hasFood || hasDrink || !!hasDesc

  const text = `${(hasBreakfast ? `${t("hasBreakfast")}, ` : ``) || ``}${((hasFood && !hasDrink) ? `${t("hasFood")}, ` : ``) || ``}${((hasDrink && !hasFood) ? `${t("hasDrink")}, ` : ``) || ``}${((hasDrink && hasFood) ? `${t("hasFoodAndDrinks")}, ` : ``) || ``}${hasSpa ? `${t("hasSpa")}, ` : ''}`.replace(/, $/, "")

  return children(hasExtra, <>
    {!!text && <span>{text}</span>}
    {!!hasDesc && <p>{hasDesc}</p>}
  </>) || <></>

}

