import gql from "graphql-tag";
import PropositionPublicFragment from "../fragment/PropositionPublicFragment";

export default gql`

${PropositionPublicFragment}

query propositionGetByIdAndPublicId($id: String!, $publicId: String!) {
  proposition: propositionGetByIdAndPublicId(id: $id, publicId: $publicId) {
    ...PropositionPublicFragment
  }
}

`