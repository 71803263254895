import gql from "graphql-tag";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import ReservationPublicFragment from "graphql/PMS/Reservation/fragment/ReservationPublicFragment";
import PostStayConfigFragment from "../Config/fragment/PostStayConfigFragment";

export default gql`

  ${ReservationPublicFragment}
  ${HotelPublicFragment}
  ${PostStayConfigFragment}

  fragment PostStayPublicFragment on PostStay {
    id
    publicId
    alertTrigger
    AnswerList
    PostStayConfig {
      ...PostStayConfigFragment
    }
    Reservation {
      ...ReservationPublicFragment
    }
    Hotel {
      ...HotelPublicFragment
    }
  }

`