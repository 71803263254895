import React from "react"

type DiscountInputProps = {
  label: string,
  discount: any,
  onChange: (d: any) => void,
}

export const DiscountInput = (props: DiscountInputProps) => {

  const discount= props?.discount
  const {percent, forced} = discount || {};

  const onChange = (d: any) => {
    props?.onChange(d)
  }


  return <div className="input-group">
    <span className="input-group-text">{props?.label}</span>
    <select value={percent || ""} className="form-control" placeholder={props?.label}onChange={(e: any) => {
      onChange({
        ...discount,
        percent: e.target.value,
      })
    }}>
      <option value="">Aucune</option>
      <option value="5">5%</option>
      <option value="10">10%</option>
      <option value="15">15%</option>
      <option value="20">20%</option>
      <option value="25">25%</option>
      <option value="100">Offert</option>
      <option value="forced">Forcé</option>
    </select>
    {percent === "forced" && <input type="text" value={forced || ""} disabled={percent !== "forced"} className="form-control" placeholder="Montant" onChange={(e: any) => {
      onChange({
        ...discount,
        forced: Number(e.target.value),
      })
    }} />}
  </div>
}