import gql from "graphql-tag";

export default gql`

  fragment ConfirmationConfigFragment on ConfirmationConfig {
    id
    hotelId
    dest
    channel
    type
    dataTypeActive
    demoMode
    templateId
  }

`