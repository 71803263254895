import gql from "graphql-tag";
import ReservationListFragment from "../../fragment/ReservationListFragment";

export default gql`

  ${ReservationListFragment}

  fragment ReservationGroupLinkEditFragment on ReservationGroupLink {
    id
    groupId
    reservationId
    Reservation {
      ...ReservationListFragment
    }
  }

`