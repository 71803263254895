import React from "react"

type DateTimeFormatProps = {
  value: Date,
  displaySeconds?: boolean,
  isFrench?: boolean,
  showUTC?: boolean,
}
export const DateTimeFormat = (props: DateTimeFormatProps) => {

  // const isFrench = !(props?.isFrench === false)

  const value = props?.value ? new Date(props?.value) : null


  return <span>{value?.toLocaleDateString('fr-FR', {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: props?.showUTC ? "UTC": "Europe/Paris",
    ...(props?.displaySeconds ? {second: "2-digit"} : {})

  })}</span>
}