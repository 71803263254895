import gql from "graphql-tag";
import ServicePreStayFragment from "graphql/PMS/Service/fragment/ServicePreStayFragment";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";
import ReservationPublicFragment from "graphql/PMS/Reservation/fragment/ReservationPublicFragment";

export default gql`

  ${OptionFragmentGql}
  ${ReservationPublicFragment}
  ${HotelPublicFragment}
  ${ServicePreStayFragment}
  ${OptionFragmentGql}

  fragment PreStayFragment on PreStay {
    id
    publicId
    answerStatus
    preStayContent {
      arrival {
        arrivalMinutes
        arrivalHours
        charging
        parking
        transfert
        comment
      }
      checkin {
        name
        phone
        mail
        idNumber
        expiration
        issue
        address
        zip
        city
        country
      }
      optionsSelected {
        quantity
        optionId
        Option {
          ...OptionFragment
        }
      }
      comment
    }
    optionList {
      ...OptionFragment
    }
    serviceList {
      ...ServicePreStayFragment
    }
    Reservation {
      ...ReservationPublicFragment
    }
    Hotel {
      ...HotelPublicFragment
    }
  }

`