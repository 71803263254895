import gql from "graphql-tag";
import TokenFragment from "../fragment/TokenFragment";


export default gql`

  ${TokenFragment}

 subscription tokenUpdate($id: String!) {
   token: tokenUpdate(id: $id) {
     ...TokenFragment
   }
 }
`