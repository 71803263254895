import {useMutation} from "@apollo/react-hooks"
import {URL_PLANNING_LIST} from "constant/url"
import planningAddGql from "graphql/PLANNING/Planning/mutation/planningAdd.gql"
import planningListGql from "graphql/PLANNING/Planning/query/planningList.gql"
import {formatErrorResponseForJoi} from "helpers/Apollo"
import {Planning} from "model"
import React, {useState} from "react"
import {useHistory} from "react-router-dom"
import {PlanningForm} from "../Form/PlanningForm"

type PlanningAddProps = {
  onDone?: () => void,
}

export const PlanningAdd = (props: PlanningAddProps) => {

  const history = useHistory()
  const [mutate] = useMutation(planningAddGql, {
    refetchQueries: [{
      query: planningListGql
    }]
  })
  const [errors, updateErrors] = useState<any>(null)
  const [planning, updatePlanning] = useState<Partial<Planning>>({})

  const onSubmit = async () => {
    updateErrors(null)
    const {id, __typename, ...input} = planning
    return mutate({
      variables: {
        input,
      }
    })
      .then(() => {
        props?.onDone ? props?.onDone() : history.push(URL_PLANNING_LIST)
      })
      .catch((e: any) => {
        const errors = formatErrorResponseForJoi(e)
        updateErrors(errors)
      })
  }


  return <div>
    <div className="card">
      <PlanningForm errors={errors} planning={planning} onChange={(planning) => updatePlanning(planning)} />
      <div className="card-footer">
        <div className="actions">
          <button type="submit" className="btn btn-info" onClick={() => onSubmit()}>Enregistrer</button>
        </div>
      </div>
    </div>
  </div>
}
