import React, {useState} from "react"

import {useQuery} from "@apollo/client"
import propositionGetGql from "graphql/PMS/Proposition/query/propositionGet.gql"
import {CustomerDetails} from "components/front/CRM/Customer/Details/CustomerDetails"
import { Modal } from "@zipou/front_tools"

// import {ReservationFullDetails} from "../Details/ReservationFullDetails"

type PropositionDetailsModalProps = {
  propositionId: string,
}

export const PropositionDetailsModal = (props: PropositionDetailsModalProps) => {

  const [showDetails, updateShowDetails] = useState<boolean>(false)
  const {data} = useQuery(propositionGetGql, {
    variables: {
      id: props?.propositionId,
    },
    skip: !props?.propositionId
  })

  const proposition = data?.proposition
  // console.log('PROPOSTION', proposition)

  return <div className="">
    <Modal display={showDetails} onClose={() => updateShowDetails(false)}>
      {/* <PropositionEdit id={proposition?.id} /> */}
      <CustomerDetails customerId={proposition?.Contact?.Customer?.id} />
      {/* <CustomerDetails customerId={"03d48cac-b41e-4f91-a63c-48f1a8742ee5"} /> */}
    </Modal>
    <button className="btn btn-dark" onClick={() => updateShowDetails(!showDetails)}>Voir la proposition</button>


  </div>
}