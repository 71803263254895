import { useQuery } from "@apollo/react-hooks";
import { DateTimeFormat } from "components/common/Format/DateTimeFormat";
import { Modal } from "components/common/Navigation/Modal/Modal";
import { EventAddByPreStay } from "components/front/PLANNING/Event/Add/EventAddByPreStay";
import preStayListEventGql from "graphql/PMS/Stay/Pre/query/preStayListEvent.gql";
import { Hotel, Service, Reservation, PreStay, Event } from "model"
import React, { useState } from "react"
import { useTranslation } from "react-i18next";


type ServiceAnswerProps = {
  hotel: Hotel,
  preStay: PreStay,
  reservation: Reservation,
  serviceList: Service[],
  onChange: (serviceIdList: string[]) => void,
}


export const ServiceAnswer = (props: ServiceAnswerProps) => {

  const [serviceFocus, updateServiceFocus] = useState<Service>()
  const [messageFocus, updateMessageFocus] = useState<boolean>(false)


  const { t, i18n } = useTranslation('translation', { keyPrefix: 'prestay' });
  const isFrench = i18n.language === "fr"

  const serviceList = props?.serviceList
  const preStay = props?.preStay
  const themeColor = `#${preStay?.Hotel?.themeColor}`

  const reservation = preStay?.Reservation
  const nbNights = reservation?.nbNight

  const hasServiceToPropose = serviceList?.length > 0

  const { data, refetch } = useQuery(preStayListEventGql, {
    variables: {
      publicId: preStay?.publicId,
      id: preStay?.id,
    }
  })

  const eventList: Event[] = data?.list

  return <div>
    {messageFocus && <Modal display={true} onClose={() => updateMessageFocus(null)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <h2>{t("service_thanks")}</h2>
    </Modal>}
    {serviceFocus && <Modal display={true} onClose={() => updateServiceFocus(null)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <EventAddByPreStay preStay={preStay} startDate={props?.reservation?.arrivalDate && new Date(props?.reservation?.arrivalDate)} service={serviceFocus} onDone={(eventId) => {
        refetch()
        updateServiceFocus(null)
        updateMessageFocus(true)
      }} />
    </Modal>}
    {hasServiceToPropose && <div className="">
      <h1>{t("service.title")}</h1>
      <h2>{t("service.desc")}</h2>
      <div className="pt-3">
        {serviceList?.map(service => {

          const eventListForService: Event[] = eventList?.filter(e => e?.serviceId === service?.id)
          const nbEvents = eventListForService?.length
          const hasEvents = nbEvents > 0

          const active = nbEvents < nbNights || true

          return active ? <div className="row p-2" key={`service_${service?.id}`}>

            <div className="d-block d-sm-none col-12 col-md-4 justify-content-center justify-content-sm-start ">
              <img src={service.pictoUrl} className="img-responsive" alt="picto" style={{ maxHeight: 150 }}></img>
            </div>

            <div className="col-12 col-md-8 d-flex">
              <div className="d-flex flex-column justify-content-start w-100 text-right align-items-end">
                <span className='font-weight-bold' style={{ fontSize: "1.1rem" }}>{isFrench ? service?.labelFr : service?.labelEn}</span>
                <span className='pt-1' style={{ color: "#333" }}>{isFrench ? service?.descFr : service?.descEn}</span>
                <div className='pt-1' >
                  <button className={"btn btn-dark px-4"} style={{ marginLeft: 5 }} onClick={() => {
                    updateServiceFocus(service)
                  }}>{t("service.book")}</button>
                </div>
                {hasEvents && <div className='pt-3' >
                  {eventListForService?.map(e => {
                    return <div className="font-weight-bold" key={`event_${e?.id}`}>{t("reservation")} - <DateTimeFormat value={new Date(e?.utcDate)} /> - {t(e.status)} </div>
                  })}
                </div>}
              </div>
            </div>

            <div className="d-none d-sm-block col-12 col-md-4 justify-content-center justify-content-sm-start ">
              <img src={service.pictoUrl} className="img-responsive" alt="picto" width={"100%"}></img>
            </div>
          </div> : <div />
        })}
      </div>
    </div>}
  </div>
}