import { ChargeInput } from "model"
import React from "react"
import { useState } from "react"
import { ChargeForm } from "../Form/ChargeForm"
import { useMutation } from "@apollo/react-hooks"
import chargeAddGql from "graphql/BILLING/Charge/mutation/chargeAdd.gql"
import { IconLoading } from "@zipou/front_tools"

export type ChargeAddProps = {
  tokenId: string,
  onDone: () => void,
}

export const ChargeAdd = ({ tokenId, onDone }: ChargeAddProps) => {

  const [charge, updateCharge] = useState<ChargeInput>()
  const [errors, updateErrors] = useState<any>()

  const [mutate, { loading }] = useMutation(chargeAddGql)

  const onAdd = () => {
    mutate({
      variables: {
        tokenId,
        input: charge,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(e.message)
        console.log("ERROR", e)
      })
  }

  return <div>
    {errors && <div className="alert alert-danger">
      {errors}
    </div>}
    <ChargeForm errors={errors} charge={charge} onChange={updateCharge} />
    <div>
      <button disabled={loading} className="btn btn-sm btn-dark" onClick={() => onAdd()}>
        {loading && <IconLoading />}
        Enregistrer
      </button>
    </div>
  </div>

}