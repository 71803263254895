import gql from "graphql-tag";

export default gql`

fragment SeasonalityFragment on Seasonality {

    id
    endDay
    startDay
    endMonth
    startMonth
    name
}
`