import gql from "graphql-tag";
import PostStayPublicFragment from "../fragment/PostStayPublicFragment";

export default gql`

  ${PostStayPublicFragment}

  mutation postStayAnswerByIdAndPublicId($id: String!, $publicId: String!, $input: PostStayAnswerInput!) {
    postStay: postStayAnswerByIdAndPublicId(id: $id, publicId: $publicId, input: $input) {
      ...PostStayPublicFragment
    }
  }

`