import React, {useEffect, useRef, useState} from "react"

type PriceProps = {
  value: number,
  disabled?: boolean,
  label?: string,
  isError?: boolean,
  onChange: (value: any) => void
}

export const PriceInput = (props: PriceProps) => {

  const defaultLeft = Number(`${props?.value}`.substring(0, `${props?.value}`.length - 2))
  const defaultRight = Number(`${props?.value}`.substring(`${props?.value}`.length - 2))

  const rightRef = useRef()

  const [leftValue, updateLeftValue] = useState(defaultLeft || "");
  const [rightValue, updateRightValue] = useState(defaultRight || "");

  useEffect(() => {
    if (props?.value) {
      if (Number(rightValue) > 9 || rightValue === "") {
        const defaultLeft = Number(`${props?.value}`.substring(0, `${props?.value}`.length - 2))
        updateLeftValue(defaultLeft)
        const defaultRight = Number(`${props?.value}`.substring(`${props?.value}`.length - 2)) || ""
        updateRightValue(defaultRight)
      }
    }
  }, [props?.value])


  const handleKeyDown = (e: any) => {
    if (e.keyCode === 110 || e.keyCode === 188 || e.keyCode === 190) {
      e.preventDefault()
      //@ts-ignore
      rightRef?.current.focus()
    }
  }

  const updateValue = (leftValue: string, rightValue: string) => {
    const value = Number(`${leftValue}${rightValue || '00'}`)
    props?.onChange(value)
  }


  return <div className="input-group">
    {props?.label && <span className="input-group-text">{props?.label}</span>}
    <input type="number" disabled={props?.disabled} name="left" step="1" className="form-control" placeholder="0" value={leftValue} onKeyDown={handleKeyDown} onChange={(e: any) => {
      updateLeftValue(e.target.value)
      // props?.onChange(Number(`${e.target.value}${rightValue}`))
      updateValue(e.target.value, `${rightValue}`)
    }} />
    <span className="input-group-text">€</span>
    <input ref={rightRef} disabled={props?.disabled} type="number" name="right" step="1" min="0" max="99" className="form-control" placeholder="00" value={rightValue} onChange={(e: any) => {
      updateRightValue(e.target.value)
      // props?.onChange(Number(`${leftValue}${e.target.value}`))
      updateValue(`${leftValue}`, e.target.value)
    }} />
    <span className="input-group-text">cents</span>
    {props?.isError && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
  </div>
}