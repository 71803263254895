import gql from "graphql-tag";
import PlanningFragment from "../fragment/PlanningFragment.gql";

export default gql`

  ${PlanningFragment}

  mutation planningAdd($input: PlanningInput!) {
    planning: planningAdd(input: $input) {
      ...PlanningFragment
    }
  }

`