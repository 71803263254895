import React, {useEffect, useState} from "react"
import {useInterval} from 'usehooks-ts'

import './style.scss'

type TemporisationButtonProps = {
  onClick: () => void,
  children: (onClick: () => void, spinnerComponent: any) => any,
  seconds: number,
}

export const TemporisationButton = (props: TemporisationButtonProps) => {

  const {onClick, children} = props
  const [tempoActive, updateTempoActive] = useState(false)
  const [seconds, updateSeconds] = useState(props?.seconds)

  useInterval(
    () => {
      updateSeconds(seconds - 1)
    },
    tempoActive ? 1000 : null,
  )

  useEffect(() => {
    if (tempoActive && seconds === 0) {
      updateTempoActive(false);
      updateSeconds(props?.seconds);
      onClick && onClick()
    }
  }, [tempoActive, seconds])

  const handleAction = () => {
    if (tempoActive) {
      updateSeconds(props?.seconds)
      updateTempoActive(!tempoActive)
    } else {
      updateTempoActive(!tempoActive)
    }
  }

  return <>
    {children(handleAction, <>{tempoActive && <span className="badge badge-light badge-pill count mr-1">{seconds}</span>}</>)}
  </>

  // return <button {...rest} onClick={handleAction}>
  //   {tempoActive && <span className="badge badge-light badge-pill count">{seconds}</span>}
  //   {children}
  // </button>

}