import React from 'react'

type StarInputProps = {
  value: number,
  fontSize?: number,
}

export const StarDisplay = ({ value, fontSize }: StarInputProps) => {


  const fontSizeDefault = fontSize || 36
  // const showColors = true

  return <div className={"d-flex flex-column"}>

    <div className={"d-flex "}>
      <span style={{ fontSize: fontSizeDefault, color: value < 3 ? "red" : value < 4 ? "orange" : value < 6 ? "green" : "" }} className={value >= 1 ? "icon-star-full" : "icon-star-shallow"}></span>
      <span style={{ fontSize: fontSizeDefault, color: value < 3 ? "red" : value < 4 ? "orange" : value < 6 ? "green" : "" }} className={value >= 2 ? "icon-star-full" : "icon-star-shallow"}></span>
      <span style={{ fontSize: fontSizeDefault, color: value < 3 ? "red" : value < 4 ? "orange" : value < 6 ? "green" : "" }} className={value >= 3 ? "icon-star-full" : "icon-star-shallow"}></span>
      <span style={{ fontSize: fontSizeDefault, color: value < 3 ? "red" : value < 4 ? "orange" : value < 6 ? "green" : "" }} className={value >= 4 ? "icon-star-full" : "icon-star-shallow"}></span>
      <span style={{ fontSize: fontSizeDefault, color: value < 3 ? "red" : value < 4 ? "orange" : value < 6 ? "green" : "" }} className={value >= 5 ? "icon-star-full" : "icon-star-shallow"}></span>
    </div>
  </div>

}
