import React, {useState} from "react"
import {Event, EventSourceTypeEnum, EventStatusEnum} from "model"
import {EventEdit} from "../Edit/EventEdit";
import {TimeFormat} from "components/common/Format/TimeFormat";
import {ReservationButton} from "components/front/PMS/Reservation/Button/ReservationButton";
import { Modal } from "@zipou/front_tools";

type EventViewProps = {
  event: Event,
}

export const EventView = (props: EventViewProps) => {

  const [isExpanded, updateIsExpanded] = useState(false)
  const event: Event = props?.event;

  const renderEvent = (event: Event) => {

  const className = event?.status === EventStatusEnum.EVENT_STATUS_PENDING ? "badge badge-warning" : event?.status === EventStatusEnum.EVENT_STATUS_CONFIRMED ? "badge badge-success" : "badge badge-danger"

    switch (event?.sourceType) {
      case EventSourceTypeEnum.PLANNING_EVENT_SOURCE_RESERVATION: {
        return <span key={`event_${event.id}`} className={className}>
          {event.Service?.name} - <TimeFormat value={new Date(event?.utcDate)} /> - <ReservationButton reservationId={event.sourceId} />
        </span>
      }
    }

  }


  return <div style={{cursor: "pointer", padding: 3}}>
    {renderEvent(event)}
    <Modal display={isExpanded} onClose={() => updateIsExpanded(false)}>
      <EventEdit eventId={event?.id} />
    </Modal>
  </div>

}