import { PriceFormatFunction } from "components/common/Format/PriceFormat";
import { ConfirmationData, ConfirmationDataParkingEnum } from "model";
import React from "react";
import { useTranslation } from "react-i18next";

type ConfirmationDataDisplayProps = {
  confirmationData: ConfirmationData
  isFrench: boolean,
}

export const ConfirmationDataDisplay = (props: ConfirmationDataDisplayProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'confirmation' });

  // const formatDate = (date: Date): string => {
  //   return date?.toLocaleString("FR-fr", {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     timeZone: 'UTC',
  //   })
  // }

  const confirmationData = props?.confirmationData
  const hasComment = !!confirmationData?.comment
  const hasParkingLegacy = !!confirmationData?.hasParking
  const hasParking = !!confirmationData?.parking && (confirmationData?.parking !== ConfirmationDataParkingEnum.NO_PARKING && confirmationData?.parking !== ConfirmationDataParkingEnum.PARKING_FULL)
  const hasArrhes = confirmationData?.arrhesAmountInCents && confirmationData?.arrhesAmountInCents > 0

  const hasContent = hasComment || hasArrhes || hasParkingLegacy || hasParking


  return <>
    {hasContent && <tr>
      <th>{t("remarks")}</th>
      <td>
        <table className="table table-stripped">
          <tbody>
            {hasComment && <tr>
              <td>{confirmationData?.comment}</td>
            </tr>}
            {hasParkingLegacy && <tr>
              <td>{t("hasParking")}
              </td>
            </tr>}
            {hasParking && <tr>
              <td>{t(confirmationData?.parking)}
              </td>
            </tr>}
            {hasArrhes && <tr>
              <td>
                <b>
                  {t("arrhesAmount", { amount: PriceFormatFunction(confirmationData?.arrhesAmountInCents) })}
                </b>
              </td>
            </tr>}
          </tbody>
        </table>
      </td>
    </tr>}
  </>

}