import React, {useContext, useState} from "react"
import {useQuery} from "@apollo/react-hooks"
import {Pagination} from "components/common/Navigation/Pagination/Pagination"


import {HotelBadge} from "../../../PMS/Hotel/Badge/HotelBadge"
import {userContext} from "context/User"
import {EventStatusEnum, Event, EventSourceTypeEnum} from "model"
import eventListGql from "graphql/PLANNING/Event/query/eventList.gql"
import {DateTimeFormat} from "components/common/Format/DateTimeFormat"
import {EventStatus} from "../Status/EventStatus"
import {EventSourceTypeDisplay} from "../Source/Type/Display/EventSourceTypeDisplay"
import {EventEdit} from "../Edit/EventEdit"
import {ReservationButton} from "components/front/PMS/Reservation/Button/ReservationButton"
import {Route, useHistory} from "react-router-dom"
import {URL_PLANNING_EVENT_EDIT, URL_PLANNING_EVENT_LIST} from "constant/url"
import { Modal } from "@zipou/front_tools"
// import {OverlayTrigger, Tooltip} from "react-bootstrap"


export type EventListProps = {
  eventIdFromUrl?: string,
}

export const EventList = (props: EventListProps) => {

  const limit = 20;

  const user = useContext(userContext)
  const history = useHistory()

  const [focusHotelIds, updateFocusHotelIds] = useState(null)
  const [statusFilter, updateStatusFilter] = useState<EventStatusEnum[]>([EventStatusEnum.EVENT_STATUS_CONFIRMED, EventStatusEnum.EVENT_STATUS_PENDING, EventStatusEnum.EVENT_STATUS_CANCELLED])
  const [focusStatus, updateFocusStatus] = useState<any>(false)
  const [hotelIdsFilter, updateHotelIdsFilter] = useState<any>(user?.hotels)

  const isMulti = user?.hotels?.length > 1

  const {data, refetch, loading} = useQuery(eventListGql, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      cursor: {
        page: 1,
        limit,
      },
      input: {
        orderField: "event.utcDate",
        orderDirection: "DESC",
        status: statusFilter,
        hotelIdList: hotelIdsFilter || [],
      }
    }
  })


  const response = data?.list

  const cursor = response?.cursor
  const eventList: Event[] = response?.nodes
  const hasResults = eventList?.length > 0

  const eventIdFromUrl = props?.eventIdFromUrl

  return <div className="confirmation-list">
    <Route path={URL_PLANNING_EVENT_EDIT}>
      <Modal display={true} onClose={() => history.push(URL_PLANNING_EVENT_LIST)}>
        <EventEdit eventId={eventIdFromUrl} onDone={() => {
          //   updateFocusEdit(null)
          refetch({
            ...data?.list?.cursor,
            input: {
              ...data?.list.input,
            },
          })
          history.push(URL_PLANNING_EVENT_LIST)
        }
        } />
      </Modal>
    </Route>

    {focusStatus && <Modal display={true} onClose={() => updateFocusStatus(false)}>
      <ul className="list-group">
        {Object.keys(EventStatusEnum).map(eventStatus => {

          //@ts-ignore
          const statusValue: EventStatusEnum = EventStatusEnum[eventStatus]

          const isChecked = statusFilter?.includes(statusValue);
          return <li key={`eventStatus_${eventStatus}`} className="list-group-item" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <input type="checkbox" checked={isChecked} id={`cb_${eventStatus}`} onChange={(e: any) => updateStatusFilter(e.target.checked ? [...(statusFilter || []), statusValue] : statusFilter?.filter((el) => el !== eventStatus))}></input>
            <label htmlFor={`cb_${eventStatus}`}>
              <EventStatus status={statusValue} />
            </label>
          </li>
        })}
      </ul>
    </Modal>}
    <Modal display={!!focusHotelIds} onClose={() => updateFocusHotelIds(null)}>
      <button className="btn btn-sm btn-warning" onClick={() => updateHotelIdsFilter([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => updateHotelIdsFilter(user?.Hotel?.map((h: any) => h.id))} style={{marginLeft: 5}}>Select All</button>
      <ul className="list-group">
        {user?.Hotel?.map((hotel: any) => {
          const isChecked = hotelIdsFilter?.includes(hotel?.id);
          return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateHotelIdsFilter(e.target.checked ? [...(hotelIdsFilter || []), hotel?.id] : hotelIdsFilter?.filter((el: any) => el !== hotel?.id))}></input>
            <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
          </li>
        })}
      </ul>
    </Modal>
    {(loading) && <div className="position-fixed" style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <div className="alert alert-warning">Chargement...</div>
    </div>}
    <div className="content">
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            {isMulti && <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{whiteSpace: "nowrap"}} onClick={() => {
                updateFocusHotelIds(true)
              }}>
                Hotel
              </button>
            </th>}
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{whiteSpace: "nowrap"}} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "event.date",
                  },
                })
              }}>
                Date {data?.list?.input?.orderField === "event.date" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              Service
            </th>
            <th className="text-white">
              Provenance
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{whiteSpace: "nowrap"}} onClick={() => updateFocusStatus(true)}>
                Statut <span className="badge badge-sm badge-dark">{statusFilter?.length}</span>
              </button>
            </th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {(!hasResults && !loading) && <tr>
            <td colSpan={isMulti ? 6 : 5}><div className="alert alert-primary">Rien à afficher</div></td>
          </tr>}
          {hasResults && eventList?.map((eventItem) => {
            const {utcDate, Hotel, status, Service, sourceType, sourceId} = eventItem

            return <tr key={`event${eventItem?.id}`}>
              {isMulti && <td><HotelBadge hotel={Hotel} /></td>}
              <td><DateTimeFormat value={utcDate} /></td>
              <td>{Service?.name}</td>
              <td>
                <EventSourceTypeDisplay sourceType={sourceType as EventSourceTypeEnum} />
                <span className="ml-2" />
                <ReservationButton reservationId={sourceId} />
              </td>
              <td><EventStatus status={status} /></td>
              <td>
                <button className="btn btn-dark btn-dark btn-sm" onClick={() => history.push(URL_PLANNING_EVENT_EDIT?.replace(":id", eventItem?.id))}>
                  <span className="icon icon-edit"> </span> Modifier
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="pagination-container">
      <Pagination cursor={cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit,
          }
        })
      }} />
    </div>
  </div >
}