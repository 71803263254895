// import {TemplateEdit} from "components/front/COM/Template/Edit/TemplateEdit";
import React from "react"

export const TemplateEditRoute = (props: any) => {


  return <div>
    <h1>Editer un template</h1>
    {/* <TemplateEdit templateId={id}/> */}
  </div>

}