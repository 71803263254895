import { getLoginCookie, saveLoginCookie } from "helpers/Cookies";

// import USER_LOGIN_BY_TOKEN_MUTATION from "graphql/User/mutation/userLoginByToken"

// export const handleLoginByGoogleToken = async (tokenId: string) => {

//   const apolloClient = await getClient()

//   const response = await apolloClient.mutate({
//     mutation: userLoginByGoogleToken,
//     variables: {
//       token: tokenId,
//     }
//   })

//   const { loginToken } = response?.data || {}
//   const { token, expiration } = loginToken || {};
//   saveLoginToken(token, expiration)

//   await apolloClient.query({
//     query: USER_LOGIN_PROFILE_QUERY,
//   })
// }

// export const handleLoginByToken = async (id: string, loginToken: string) => {
//   const apolloClient = await getClient()

//   const response: any = await apolloClient.mutate({
//     mutation: USER_LOGIN_BY_TOKEN_MUTATION,
//     variables: {
//       id,
//       token: loginToken,
//     }
//   })

//   const { data } = response || {};
//   const { userLoginByToken } = data || {};
//   const { token, expiration } = userLoginByToken || {};

//   saveLoginToken(token, expiration)

//   console.log("QUERY PROFILE")
//   await apolloClient.query({
//     query: USER_LOGIN_PROFILE_QUERY,
//     fetchPolicy: "network-only",
//     context: {
//       loginToken: token,
//     }
//   })

//   console.log("QUERY PROFILE DONE")
//   return true

// }

export const saveLoginToken = (token: string, expiration: string | number) => {
  saveLoginCookie({
    token,
    expiration
  })
}

export const findToken = () => {
  const cookie = getLoginCookie();
  const { token } = cookie || {};
  return token;
}