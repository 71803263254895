import gql from "graphql-tag";

export default gql`

  fragment EventServiceFragment on Event {
    id
    serviceId
    sourceId
    utcDate
    status
    Service {
      id
      name
    }
  }

`