import gql from "graphql-tag";
import ConfirmationFragment from "../fragment/ConfirmationFragment";

export default gql`

  ${ConfirmationFragment}

  mutation confirmationSetData($id: String!, $input: ConfirmationDataInput!) {
    confirmation: confirmationSetData(id: $id, input: $input) {
      ...ConfirmationFragment
    }
  }

  
`