import gql from "graphql-tag";
import TokenFragment from "../fragment/TokenFragment";

export default gql`

  ${TokenFragment}

  mutation tokenSetDeleted($id: String!) {
    token: tokenSetDeleted(id: $id) {
      ...TokenFragment
    }
  }

`