import { TextInput } from "@zipou/front_tools"
import { SeasonalityInput } from "model"
import React from "react"

type SeasonalityFormProps = {
  seasonality: SeasonalityInput,
  error: any,
  onChange: (seasonality: SeasonalityInput) => void,
}


export const SeasonalityForm = ({ seasonality, error, onChange }: SeasonalityFormProps) => {


  const dayList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  return <div className="input-group">
    <span className="input-group-text">Saison</span>
    <div className="form-control" style={{ height: "100%" }}>
      <div className="input-group">
        <TextInput errors={error} id="name" label="Nom" value={seasonality?.name} onChange={(v) => {
          onChange({
            ...seasonality,
            name: v,
          })
        }} rightContent={<span className="badge badge-warning">Que des lettres et pas d'espaces</span>} />
        <div className="input-group">
          <span className="input-group-text">Début</span>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <span className="input-group-text">Jour</span>
              <select className="form-control" value={seasonality?.startDay ? `${seasonality?.startDay}` : ""} onChange={(e) => {
                onChange({
                  ...seasonality,
                  startDay: Number(e.target.value),
                })
              }} >
                <option value="" disabled>Choisissez le jour</option>
                {dayList?.map((day, idx) => {
                  return <option value={`${day}`} key={`day_${idx}`}>{day}</option>
                })}
              </select>
              {error && error.startDay && <span className="input-group-text">
                <span className="icon-warning-sign" />
              </span>}
            </div>
            <div className="input-group">
              <span className="input-group-text">Mois</span>
              <select className="form-control" value={seasonality?.startMonth ? `${seasonality?.startMonth}` : ""} onChange={(e) => {
                onChange({
                  ...seasonality,
                  startMonth: Number(e.target.value),
                })
              }} >
                <option value="" disabled>Choisissez le mois</option>
                {monthList?.map((month, idx) => {
                  return <option value={`${month}`} key={`month_${idx}`}>{month}</option>
                })}
              </select>
              {error && error.startMonth && <span className="input-group-text">
                <span className="icon-warning-sign" />
              </span>}
            </div>
          </div>
        </div>
        <div className="input-group">
          <span className="input-group-text">Fin</span>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <span className="input-group-text">Jour</span>
              <select className="form-control" value={seasonality?.endDay ? `${seasonality?.endDay}` : ""} onChange={(e) => {
                onChange({
                  ...seasonality,
                  endDay: Number(e.target.value),
                })
              }} >
                <option value="" disabled>Choisissez le jour</option>
                {dayList?.map((day, idx) => {
                  return <option value={`${day}`} key={`day_${idx}`}>{day}</option>
                })}
              </select>
              {error && error.endDay && <span className="input-group-text">
                <span className="icon-warning-sign" />
              </span>}
            </div>
            <div className="input-group">
              <span className="input-group-text">Mois</span>
              <select className="form-control" value={seasonality?.endMonth ? `${seasonality?.endMonth}` : ""} onChange={(e) => {
                onChange({
                  ...seasonality,
                  endMonth: Number(e.target.value),
                })
              }} >
                <option value="" disabled>Choisissez le mois</option>
                {monthList?.map((month, idx) => {
                  return <option value={`${month}`} key={`month_${idx}`}>{month}</option>
                })}
              </select>
              {error && error.endMonth && <span className="input-group-text">
                <span className="icon-warning-sign" />
              </span>}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

}