import { TextInput } from "@zipou/front_tools"
import { PhoneConfigInput } from "model"
import React from "react"


type PhoneConfigFormProps = {
  phoneConfig: PhoneConfigInput,
  errors: any,
  onChange: (phoneConfig: PhoneConfigInput) => void,
}


export const PhoneConfigForm = ({ phoneConfig, errors, onChange }: PhoneConfigFormProps) => {

  return <>
    <TextInput id={"phoneConfigId"} value={phoneConfig?.phoneConfigId} errors={errors} label="PhoneConfigId" onChange={(v) => {
      onChange({
        ...phoneConfig,
        phoneConfigId: v
      })
    }} />
    <TextInput id={"token"} value={phoneConfig?.token} errors={errors} label="Token" onChange={(v) => {
      onChange({
        ...phoneConfig,
        token: v
      })
    }} />
    <TextInput id={"clic2CallSequenceId"} value={phoneConfig?.clic2CallSequenceId} errors={errors} label="clic2CallSequenceId" onChange={(v) => {
      onChange({
        ...phoneConfig,
        clic2CallSequenceId: v
      })
    }} />
  </>

}