import { Loop } from "helpers/Utils"
import React from "react"

type PropositionSelectButtonProps = {
    isDisabled: boolean,
    valueIndex: string,
    nbRooms: number,
    onAdd: (index: number) => void

}

export const PropositionSelectButton = (props: PropositionSelectButtonProps) => {

    const isSingleChoice = props?.nbRooms === 1

    return <div>
        {!isSingleChoice && <select className="form-control form-control-sm" disabled={props?.isDisabled} value={props?.valueIndex || ""} onChange={(e: any) => {
            props?.onAdd(Number(e.target.value))
        }}>
            <option value="" disabled></option>
            {Loop(props?.nbRooms).map((index: number) => {
                const roomNumber = index + 1
                return <option key={`select_${index}`} value={`${index}`}>Ch {roomNumber}</option>
            })}
        </select>}
        {isSingleChoice && <button className="btn btn-dark btn-sm" disabled={props?.isDisabled} onClick={() => {
            props?.onAdd(0)
        }}>Ajouter</button>}
    </div>
}