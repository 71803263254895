import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}

  fragment ReservationWidgetFragment on Reservation {
    id
    status
    pmsId
    updateDate
    creationDate
    Hotel {
      ...HotelDisplayFragment
    }
  }

`