import gql from "graphql-tag";
import PostStayQuestionStatsFragment from "../../Question/fragment/PostStayQuestionStatsFragment";

export default gql`

${PostStayQuestionStatsFragment}

fragment PostStayGroupItemStatsFragment on PostStayGroupItem {
  id
  type
  Question {
    ...PostStayQuestionStatsFragment
  }
  Separator {
    labelEn
    labelFr
  }
}

`