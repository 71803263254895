import React, {useState} from "react"

import {useQuery} from "@apollo/client"
import reservationGet from "graphql/PMS/Reservation/query/reservationGet"
import {ReservationFullDetails} from "../Details/ReservationFullDetails"
import { Modal } from "@zipou/front_tools"

type ReservationDetailsModalProps = {
  reservationId: string,
}

export const ReservationDetailsModal = (props: ReservationDetailsModalProps) => {

  const [showDetails, updateShowDetails] = useState<boolean>(false)

  const {data} = useQuery(reservationGet, {
    variables: {
      id: props?.reservationId,
    },
    skip: !props?.reservationId
  })

  const reservation = data?.reservation

  return <div className="">
    <Modal display={showDetails} onClose={() => updateShowDetails(false)}>
      <ReservationFullDetails reservationId={reservation?.id} />
    </Modal>
    <button className="btn btn-dark" onClick={() => updateShowDetails(!showDetails)}>Voir la reservation</button>
  </div>
}