import { useMutation, useQuery } from "@apollo/react-hooks"
import templateDeleteMutation from "graphql/APP/Template/mutation/templateDelete"
import templateListQuery from "graphql/APP/Template/query/templateList"
import React, { useContext, useState } from "react"
import { TemplateTypeDisplay } from "../Type/Display/TemplateTypeDisplay"
import { TemplateAdd } from "../Add/TemplateAdd"
import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import { TemplateEdit } from "../Edit/TemplateEdit"
import { Template, TemplateTypeEnum } from "model"
import { alertContext } from "context/Alert/alert"

type TemplateListProps = {
  hotelId: string,
}


export const TemplateList = ({ hotelId }: TemplateListProps) => {

  const [focusAdd, updateFocusAdd] = useState(false)
  const [focusEdit, updateFocusEdit] = useState<Template | null>()

  const { setError } = useContext(alertContext)

  const [mutate] = useMutation(templateDeleteMutation)
  const { data, refetch } = useQuery<{ list: Template[] }>(templateListQuery, {
    variables: {
      hotelId
    },
    skip: !hotelId,
  })

  const onDelete = (id: string) => {
    mutate({
      variables: {
        id,
      },
      refetchQueries: [{
        query: templateListQuery,
        variables: {
          hotelId
        }
      }]
    })
      .catch((e: any) => {
        console.log("e", e.message)
        setError(e.message)
      })
  }
  const list = data?.list
  const hasContent = list?.length > 0

  return <div>
    <Modal display={!!focusEdit} title={"Editer un template"} size="xl" onClose={() => updateFocusEdit(null)}>
      <TemplateEdit hotelId={hotelId} templateId={focusEdit?.id} onDone={() => {
        updateFocusEdit(null)
        refetch()
      }} />
    </Modal>
    <Modal display={!!focusAdd} size="xl" title="Ajouter un template" onClose={() => updateFocusAdd(false)}>
      <TemplateAdd onDone={() => {
        updateFocusAdd(false)
        refetch()
      }} hotelId={hotelId} />
    </Modal>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Type</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {hasContent && list?.map((template) => {
          return <tr key={`template_${template?.id}`}>
            <td>{template?.name}</td>
            <td><TemplateTypeDisplay type={template?.type as TemplateTypeEnum} /></td>
            <td className="">
              <button className="btn btn-info btn-sm ml-1" onClick={() => updateFocusEdit(template)}>
                <IconEdit />
              </button>
              <button className="btn btn-danger btn-sm ml-1" onClick={() => onDelete(template.id)}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
        {!hasContent && <tr>
          <td colSpan={3}>
            <div className="alert alert-warning">Rien à afficher</div>
          </td>
        </tr>}
      </tbody>
    </table>
    <button className="btn btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
  </div>

}