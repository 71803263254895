import gql from "graphql-tag";
import ReservationGroupLinkPublicFragment from "../../GroupLink/fragment/ReservationGroupLinkPublicFragment";

export default gql`

${ReservationGroupLinkPublicFragment}

fragment ReservationGroupPublicFragment on ReservationGroup {
  id
  arrivalDate
  departureDate
  totalIncTax
  totalPeople
  wholeDates
  DefaultRate {
    ...ReservationRateFragment
  }
  Hotel {
    ...HotelPublicFragment
  }
  ReservationGroupLinkConfirmed {
    ...ReservationGroupLinkPublicFragment
  }
  ReservationGroupLink {
    ...ReservationGroupLinkPublicFragment
  }
  Contact {
    ...ContactPublicFragment
  }
}

`