import gql from "graphql-tag";

export default gql`

  fragment PriceRateTypePublicFragment on PriceRateType {
    id
    code
    isBreakfastIncluded
    isRefundable
    isGiftBox
    isGroup
    isIncludedInConfirmationTotal
    labelFr
    labelEn
    descFr
    descEn
    conditionsFR
    conditionsEN
    # conditionsCancelFR
    # conditionsCancelEN
  }

`