
import gql from "graphql-tag";
import TokenPublicFragment from "../fragment/TokenPublicFragment";

export default gql`

  ${TokenPublicFragment}

  query tokenGetByPublicIdAndPublicSalt($id: String!, $salt: String!) {
    token: tokenGetByPublicIdAndPublicSalt(id: $id, salt: $salt) {
      ...TokenPublicFragment
    }
  }
`