import gql from "graphql-tag";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";

export default gql`

  ${ContactFragment}

  fragment CustomerAddressFragment on CustomerAddress {
    id
    address1
    address2
    zipCode
    city
    country
    sourceId
    sourceType
    Contact {
      ...ContactFragment
    }
  }

`