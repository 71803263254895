import gql from "graphql-tag";

export default gql`

  fragment RoomTypeLabelFragment on RoomType {
    id
    type
    descImgUrls
    labelFr
    labelEn
  }

`