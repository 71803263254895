import { PictureUploader } from "components/common/Form/PictureUploader/PictureUploader";
import React, { useRef, useState } from "react"
import EmailEditor from 'react-email-editor';
import { JSONImportForm } from "./JSONImportForm";
import { ClickAndCopy } from "components/common/Form/ClickAndCopy";
import { IconClose, Modal } from "@zipou/front_tools";

type UnlayerFormProps = {
  errors: any,
  contentJSON: any,
  onChange: (contentHTML: string, contentJSON: string) => void,
}

export const UnlayerForm = (props: UnlayerFormProps) => {

  const [jsonContent, updateJsonContent] = useState<any>()
  const [focusDisplayImport, updateFocusDisplayImport] = useState<boolean>()
  const [focusDisplayExport, updateFocusDisplayExport] = useState<boolean>()
  const [focusPictureHandler, updateFocusPictureHandler] = useState<any>(false)
  const editorRef = useRef(null)
  const imageCallbackRef = useRef(null)

  const onReady = () => {
    // editor is ready
    if (props?.contentJSON) {
      editorRef.current.editor.loadDesign(props?.contentJSON);
      updateJsonContent(props?.contentJSON)
    }
    editorRef?.current?.editor?.addEventListener('design:updated', (data: any) => {
      onChange()
    })
    editorRef?.current?.editor?.registerCallback('selectImage', function (data: any, done: any) {
      imageCallbackRef.current = done;
      updateFocusPictureHandler(true)
    });
  };


  const onChange = () => {
    editorRef.current.editor.exportHtml((data: any) => {
      const { design, html } = data;
      updateJsonContent(design)
      props?.onChange(html, design)
    });
  }

  const onImageUpload = (url: string) => {
    imageCallbackRef.current({ url })
    updateFocusPictureHandler(false)

  }

  return <div>
    <Modal display={!!focusPictureHandler} onClose={() => updateFocusPictureHandler(false)}>
      <PictureUploader onDone={onImageUpload} />
    </Modal>
    {/* <Modal display={focusDisplayImport} onClose={() => updateFocusDisplayImport(false)}> */}
    {focusDisplayImport && <div style={{ backgroundColor: "white", border: "1px black solid", zIndex: 10000, position: "absolute", width: 500, height: 200, bottom: 50, right: 50 }}>
      <div className="card">
        <div className="card-header d-flex" style={{ alignItems: "center", justifyContent: "center" }} >
          Importer
          <span style={{ position: "absolute", right: 0, top: 0 }}>
            <button className="btn btn-sm" onClick={() => updateFocusDisplayImport(false)}>
              <IconClose />
            </button>
          </span>
        </div>
        <div className="card-body">
          <JSONImportForm onChange={(json) => {
            editorRef.current.editor.loadDesign(json)
            editorRef.current.editor.exportHtml((data: any) => {
              const { design, html } = data;
              updateJsonContent(design)
              props?.onChange(html, design)
            });
            updateFocusDisplayImport(false)
          }} />
        </div>
      </div>
    </div>}
    {/* </Modal> */}
    <Modal display={focusDisplayExport} onClose={() => updateFocusDisplayExport(false)}>
      <div className="input-group">
        <span className="input-group-text">Exporter</span>
        <textarea className="form-control" disabled>
          {jsonContent && JSON.stringify(jsonContent)}
        </textarea>
        <ClickAndCopy value={jsonContent && JSON.stringify(jsonContent)} />
      </div>
    </Modal>
    <div className="card">
      <div className="card-body">
        <EmailEditor
          style={{ height: "80vh" }}
          ref={editorRef}
          onReady={() => onReady()}
          options={{
            fonts: {
              customFonts: [
                {
                  label: "Monospace",
                  value: "Monospace",
                  url: ""
                },
                {
                  label: "Verdana",
                  value: "Verdana",
                  url: ""
                },
                {
                  label: "Helvetica",
                  value: "Helvetica",
                  url: ""
                },
                {
                  label: "Arial",
                  value: "Arial",
                  url: ""
                },
                {
                  label: "Georgia",
                  value: "Georgia",
                  url: ""
                },
                {
                  label: "Tahoma",
                  value: "Tahoma",
                  url: ""
                },
                {
                  label: "Courier New",
                  value: "Courier New",
                  url: ""
                },
                {
                  label: "Palatino",
                  value: "Palatino",
                  url: ""
                },
                {
                  label: "Futura",
                  value: "Futura",
                  url: ""
                },
                {
                  label: "Calibri",
                  value: "Calibri",
                  url: ""
                },
              ]
            }
            ,
            mergeTags: {
              Paiement: {
                name: "Paiement",
                mergeTags: {
                  Montant: {
                    name: "Montant",
                    value: "{{ payment.amount }}",
                    sample: "123.10 €"
                  },
                  publicUrlFr: {
                    name: "Lien",
                    value: "{{ paymentUrl }}",
                  },
                  startHasContact: {
                    name: "| Début Si Cardex =>",
                    value: "{% if contact %}",
                    sample: "| Début Si Cardex =>",
                  },
                  elseIfContact: {
                    name: "<= Sinon =>",
                    value: "{% else %}",
                    sample: "<= Sinon =>",
                  },
                  endIfContact: {
                    name: "<= Fin SI |",
                    value: "{% endif %}",
                    sample: "<= Fin SI",
                  },
                }
              },
              Contact: {
                name: "Contact",
                mergeTags: {
                  Prénom: {
                    name: "Prénom",
                    value: "{{ contact.firstname }}",
                    sample: "John"
                  },
                  Nom: {
                    name: "Nom",
                    value: "{{ contact.lastname }}",
                    sample: "Smith"
                  },
                  Salutations: {
                    name: "Salutations",
                    value: "{{ greetings }}",
                    sample: "Monsieur Smith"
                  }
                }
              },
              Reservation: {
                name: "Reservation",
                mergeTags: {
                  arrivalDate: {
                    name: "Date d'Arrivée",
                    value: "{{ utils.utilsDateFormat(wholeStayDates.arrivalDate) }}",
                    sample: "20/01/2024"
                  },
                  departureDate: {
                    name: "Date de Départ",
                    value: "{{ utils.utilsDateFormat(wholeStayDates.departureDate) }}",
                    sample: "21/01/2024"
                  },
                  nbNights: {
                    name: "Nombre de Nuits",
                    value: "{{ nbNights }}",
                    sample: "1"
                  },
                  nbPeople: {
                    name: "Nombre de Personne",
                    value: "{{ nbPeople }}",
                    sample: "1"
                  },
                  totalReservation: {
                    name: "Montant Reservation Total",
                    value: "{{ total }}",
                    sample: "123,25 €"
                  },
                  publicId: {
                    name: "Numéro(s) de Résa",
                    value: "{{ reservationIds }}",
                    sample: "15268"
                  },
                  priceRateType: {
                    name: "Code Tarifs",
                    mergeTags: {
                      labelFr: {
                        name: "Label Français",
                        value: "{{ priceRateType.labelFr }}",
                        sample: "CHAMBRE ET PETIT DEJEUNER"
                      },
                      labelEn: {
                        name: "Label Anglais",
                        value: "{{ priceRateType.labelEn }}",
                        sample: "BREAKFAST INCLUDED"
                      },
                      Tags: {
                        name: "Conditionnel",
                        mergeTags: {
                          startIsGiftBox: {
                            name: "Début Si Coffret Cadeau =>",
                            value: "{% if priceRateType.isGiftBox %}",
                            sample: "Début Si Coffret Cadeau =>",
                          },
                          startIsRefundable: {
                            name: "Début Si est remboursable =>",
                            value: "{% if priceRateType.isRefundable %}",
                            sample: "Début Si est remboursable =>",
                          },
                          endIf: {
                            name: "<= Fin SI",
                            value: "{% endif %}",
                            sample: "<= Fin SI",
                          },
                          else: {
                            name: "<= sinon =>",
                            value: "{% else %}",
                            sample: "<= sinon =>",
                          },
                        }
                      },
                    }
                  },
                  RoomType: {
                    name: "Type de Chambre",
                    mergeTags: {
                      labelFr: {
                        name: "Label Français",
                        value: "{{ roomType.labelFr }}",
                        sample: "Chambre Classique"
                      },
                      labelEn: {
                        name: "Label Anglais",
                        value: "{{ roomType.labelEn }}",
                        sample: "Standard Room"
                      },
                    }
                  },
                  Tags: {
                    name: "Conditions",
                    mergeTags: {
                      startPdjIncluded: {
                        name: "Début PDJ Inclus =>",
                        value: "{% if priceRateType.isBreakfastIncluded or isBreakfastIncluded %}",
                        sample: "Début PDJ Inclus =>",
                      },
                      endPdjIncluded: {
                        name: "<= Fin SI",
                        value: "{% endif %}",
                        sample: "<= Fin SI",
                      },
                      else: {
                        name: "<= sinon =>",
                        value: "{% else %}",
                        sample: "<= sinon =>",
                      },
                    }
                  },
                }
              },

              Confirmation: {
                name: "Confirmation",
                mergeTags: {
                  DetailsLink: {
                    name: "URL du Plus de Details",
                    value: "{{ detailsUrl }}",
                    sample: "{{ detailsUrl }}",
                  },
                  Data: {
                    name: "Données de Confirmation",
                    mergeTags: {
                      startParkingIncluded: {
                        name: "| Début Parking Payant Inclus =>",
                        value: "{% if confirmation.confirmationData and confirmation.confirmationData.parking == 'HAS_PARKING' %}",
                        sample: "| Début Parking Inclus =>",
                      },
                      startParkingFree: {
                        name: "| Début Parking Offert =>",
                        value: "{% if confirmation.confirmationData and confirmation.confirmationData.parking == 'FREE_PARKING' %}",
                        sample: "| Début Parking Inclus =>",
                      },
                      startNoParking: {
                        name: "| Début Fin PAS de Parking =>",
                        value: "{% if confirmation.confirmationData and confirmation.confirmationData.parking == 'NO_PARKING' %}",
                        sample: "| Début Parking Inclus =>",
                      },
                      endIf: {
                        name: "<= Fin SI",
                        value: "{% endif %}",
                        sample: "<= Fin SI",
                      },
                      comment: {
                        name: "comment",
                        value: "{% if confirmation.confirmationData and confirmation.confirmationData.comment %}{{ confirmation.confirmationData.comment }}{% endif %}",
                        sample: "Commentaire",
                      },
                      arrhesAmountInCents: {
                        name: "Montant des Arrhes",
                        value: "{{ arrhesAmountInCents }}",
                        sample: "Montant des Arrhes",
                      },
                      else: {
                        name: "<= sinon =>",
                        value: "{% else %}",
                        sample: "<= sinon =>",
                      },
                    }
                  }
                }
              },
              PostStay: {
                name: "PostStay",
                mergeTags: {
                  postStayUrl: {
                    name: "URL Questionnaire",
                    value: "{{ postStayUrl }}",
                    sample: "{{ postStayUrl }}",
                  }
                }
              }
            },
            tools: {
              menu: {
                enabled: false
              }
            }
          }}


        />
        {/* </div> */}
      </div>
      <div className="card-footer">
        <button className="btn btn-sm btn-primary ml-2" onClick={() => updateFocusDisplayExport(true)}>Exporter</button>
        <button className="btn btn-sm btn-secondary ml-2" onClick={() => updateFocusDisplayImport(true)}>Importer</button>
      </div>
    </div>
  </div>

}