import gql from "graphql-tag";
import EventFragmentGql from "graphql/PLANNING/Event/fragment/EventFragment.gql";

export default gql`

  ${EventFragmentGql}

  mutation reservationAddEvent($id: String!, $input: EventInput!) {
    event: reservationAddEvent(id: $id, input: $input) {
      ...EventFragment
    }
  }

`