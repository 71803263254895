import React from "react"
import {Link, Route} from "react-router-dom"


export type BreadcrumbProps = {
  itemMap: {[key: string]: {component: any, breadcrumb?: {label: String, active?: boolean, link?: string}[]}}
}

export const Breadcrumb = (props: BreadcrumbProps) => {

  const itemMap = props?.itemMap

  return <nav aria-label="breadcrumb">
    {Object.keys(itemMap).map((key: string, idx: number) => {
      const value = itemMap[key];
      return <Route key={`route_${key}_${idx}`} path={key} exact>
        {value?.breadcrumb && <ol className="breadcrumb">
          {value?.breadcrumb && value.breadcrumb?.map((line, index) => {
            return <li key={`${line.link}_${index}`} className={line.active ? "breadcrumb-item active" : "breadcrumb-item"}>
              {line.link && <Link to={line.link}>{line.label}</Link>}
              {!line.link && <span>{line.label}</span>}
            </li>
          })}
        </ol>}
      </Route>
    })}
  </nav >

}
