import gql from "graphql-tag";
import ConfirmationFragment from "../fragment/ConfirmationFragment";

export default gql`

  ${ConfirmationFragment}

  subscription confirmationUpdate {
    confirmation: confirmationUpdate {
      ...ConfirmationFragment
    }
  }

`