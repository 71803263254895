import { useQuery } from "@apollo/react-hooks"
import { ContactSourceDetails } from "components/front/PMS/Contact/Details/ContactSourceDetails"
import customerPhoneGetGql from "graphql/CRM/Customer/Phone/query/customerPhoneGet.gql"
import { CustomerPhone } from "model"
import React from "react"

type CustomerPhoneDetailsProps = {
  id: string,
}

export const CustomerPhoneDetails = ({ id }: CustomerPhoneDetailsProps) => {

  const { data } = useQuery<{ customerPhone: CustomerPhone }>(customerPhoneGetGql, {
    variables: {
      id,
    },
    skip: !id,
  })

  const customerPhone = data?.customerPhone

  return <div>
    <table className="table table-bordered table stripped">
      <tbody>
        <tr>
          <th>Numéro</th>
          <td>{customerPhone?.phone}</td>
        </tr>
        <tr>
          <th>CountryCode</th>
          <td>{customerPhone?.countryCode}</td>
        </tr>
        <tr>
          <th>Source Type</th>
          <td>{customerPhone?.sourceType}</td>
        </tr>
        <tr>
          <th>Source</th>
          <td>
            <ContactSourceDetails contact={customerPhone?.Contact} />
          </td>
        </tr>

      </tbody>
    </table>
  </div>
}