import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"

import { formatErrorResponseForJoi, parseGraphqlServerError } from "helpers/Apollo"

import propositionAddOrUpdateGql from "graphql/PMS/Proposition/mutation/propositionAddOrUpdate"
import propositionGetGql from "graphql/PMS/Proposition/query/propositionGet.gql"


// import './style.scss'
import { Proposition, PropositionInput, PropositionRowRoom, PropositionSentStatusEnum, PropositionStatusEnum } from "model"
import { PropositionForm } from "../Form/PropositionForm"
import { useHistory, useLocation } from "react-router-dom"
import { URL_PROPOSITION_ADD, URL_PROPOSITION_EDIT } from "constant/url"

type PropositionEditProps = {
  id?: string,
}

export const PropositionEdit = (props: PropositionEditProps) => {

  const location = useLocation()
  const history = useHistory()

  const [success, updateSuccess] = useState(false)
  const [error, updateError] = useState(null)
  const [warning, updateWarning] = useState(null)

  const [proposition, updateProposition] = useState<PropositionInput>({
    hotelId: null,
    contactId: null,
    nbRooms: 1,
    nbAdults: 2,
    nbChildren: 0,
    nbSpecial: 0,
    propositionRow: []
  })

  const getInput = (proposition: Proposition): PropositionInput => {

    const { publicId, status, __typename, rowsHaveSameDates, paymentId, sentStatus, PropositionPayment, Hotel, Contact, pushStatus, rowSelectedId, isExpired, ...propositionStripped } = proposition
    const input = {
      ...propositionStripped,
      hotelId: proposition?.Hotel?.id,
      propositionRow: proposition?.propositionRow?.map(rowRaw => {
        const { __typename, totalToValid, propositionId, hasSameDates, nbNights, totalRow, ...row } = rowRaw
        return {
          ...row,
          roomList: row?.roomList.map((rowRoom: PropositionRowRoom) => {
            const { __typename, hasDelogement, depositPushed, optionList, sameConditions, pmsId, discountPushed, confirmedPush, error, Option, discount, ...itemStripped } = rowRoom
            return {
              ...itemStripped,
              ...(discount ? {
                discount: {
                  percent: discount?.percent,
                  forced: discount?.forced,
                }
              } : {}),
              optionList: optionList?.map(option => {
                const { __typename, Option, optionPushed, error, ...optionStripped } = option
                const { discount } = optionStripped || {}
                return {
                  ...optionStripped,
                  ...(discount ? {
                    discount: {
                      percent: discount?.percent,
                      forced: discount?.forced,
                    }
                  } : {}),
                }
              }),
              itemList: rowRoom?.itemList?.map((itemRaw) => {
                const { __typename, PriceRate, date, Room, pmsId, discountPushed, confirmedPush, error, ...item } = itemRaw
                return item
              })
            }
          })
        }

      })
    }
    return input
  }

  const { data } = useQuery<{ result: { proposition: Proposition, warning: any } }>(propositionGetGql, {
    variables: {
      id: props?.id
    },
    skip: !props?.id,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const proposition = data?.result?.proposition
      const warningParsed = parseGraphqlServerError(data?.result?.warning)
      updateWarning(warningParsed)
      const input = getInput(proposition)
      updateProposition(input)
    }
  })

  const [mutate] = useMutation<{ result: { proposition: Proposition, warning: any } }, { id: string, input: PropositionInput }>(propositionAddOrUpdateGql)

  const onSave = () => {
    return mutate({
      variables: {
        id: props?.id,
        input: proposition
      }
    })
      .then((response) => {
        if (response?.data?.result?.warning) {
          const warningParsed = parseGraphqlServerError(response?.data?.result?.warning)
          updateWarning(warningParsed)
        } else {
          updateWarning(null)
        }

        if (location.pathname === URL_PROPOSITION_ADD && response?.data?.result?.proposition?.id) {
          history.push(URL_PROPOSITION_EDIT.replace(':id', response?.data?.result?.proposition?.id))
        }
        updateError(null)
        updateSuccess(true)
        const input = getInput(response?.data?.result?.proposition)
        updateProposition(input)
      })
      .catch((e) => {
        updateError(formatErrorResponseForJoi(e))
        throw (e)
      })
  }

  return <div className="proposition-container">
    <h1>Proposition</h1>
    <PropositionForm
      warnings={warning}
      errors={error}
      input={proposition}
      onChange={proposition => updateProposition(proposition)}
      onSave={onSave}
    />


  </div>

}