import gql from "graphql-tag";
import ConfirmationQuestionFragment from "../fragment/ConfirmationQuestionFragment";

export default gql`

${ConfirmationQuestionFragment}

query confirmationQuestionGetByConfirmationId($confirmationId: String!) {
  question: confirmationQuestionGetByConfirmationId(confirmationId: $confirmationId) {
    ...ConfirmationQuestionFragment
  }
}
`