import React, { useState } from "react"

import { useQuery } from "@apollo/client"
import reservationGet from "graphql/PMS/Reservation/query/reservationGet"
import { ReservationFullDetails } from "../Details/ReservationFullDetails"
import { Modal } from "@zipou/front_tools"

type ReservationCTIDetailsProps = {
  reservationId: string,
  isRinging: boolean,
}

export const ReservationCTIDetails = (props: ReservationCTIDetailsProps) => {

  const [showDetails, updateShowDetails] = useState<boolean>(false)
  // const ringingContextValue = useContext(ringingContext)

  const { data } = useQuery(reservationGet, {
    variables: {
      id: props?.reservationId,
    },
    skip: !props?.reservationId
  })

  const reservation = data?.reservation


  return <div className="">
    <Modal display={showDetails} onClose={() => updateShowDetails(false)}>
      <ReservationFullDetails reservationId={reservation?.id} />
    </Modal>
    {props?.isRinging && <div className="card border-danger">
      <div className="card-header bg-danger text-white"><span className="icon-phone"></span> Appel en cours...</div>
      <div className="card-body" style={{ display: "flex", flexDirection: "column" }}>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>Id Resa</th>
              <td>#{reservation?.pmsId}</td>
            </tr>
            <tr>
              <th>Contact</th>
              <td>{reservation?.Contact?.firstName} {reservation?.Contact?.lastName?.toUpperCase()}</td>
            </tr>
            <tr>
              <td colSpan={2}><button className="btn btn-warning" onClick={() => updateShowDetails(true)}>Plus de détails</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>}
  </div>
}