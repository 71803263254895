// import { PhoneSipClient } from "@zipou/phone_front"
import { userContext } from "context/User"
import React, { useContext } from "react"

type PhoneSipClientWrapperProps = {
}

export const PhoneSipClientWrapper = ({ }: PhoneSipClientWrapperProps) => {

  const user = useContext(userContext)
  const phoneSipConfig = user?.PhoneSipConfig

  return <>
    {/* {phoneSipConfig && <PhoneSipClient user={phoneSipConfig?.user} server={phoneSipConfig?.server} password={phoneSipConfig?.password} />} */}
  </>

}