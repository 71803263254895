import gql from "graphql-tag";
import TerminalListFragmentGql from "../fragment/TerminalListFragment.gql";

export default gql`

  ${TerminalListFragmentGql}

  query terminalList($hotelId: String!) {
    list: terminalList(hotelId: $hotelId) {
      ...TerminalListFragment
    }
  }

`