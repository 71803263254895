import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks"
import terminalUpdateGql from "graphql/BILLING/Terminal/subscription/terminalUpdate.gql"
import React from "react"
import { TerminalStatusEnum } from "model"
import terminalCancelActionGql from "graphql/BILLING/Terminal/mutation/terminalCancelAction.gql"
import terminalProcessTokenGql from "graphql/BILLING/Terminal/mutation/terminalProcessToken.gql"
import { IconLoading } from "@zipou/front_tools"
import terminalGetGql from "graphql/BILLING/Terminal/query/terminalGet.gql"

type TerminalProcessTokenProps = {
  terminalId: string,
  tokenId: string,
}

export const TerminalProcessToken = ({ terminalId, tokenId }: TerminalProcessTokenProps) => {

  useSubscription(terminalUpdateGql, {
    variables: {
      id: terminalId,
    }
  })

  const { data } = useQuery(terminalGetGql, {
    variables: {
      id: terminalId
    }
  })

  const terminal = data?.terminal

  const terminalProcessTokenMutation = useMutation(terminalProcessTokenGql)
  const terminalCancelActionMutation = useMutation(terminalCancelActionGql)

  const isStatusIdle = terminal?.status === TerminalStatusEnum.STATUS_IDLE
  const isStatusProcessing = terminal?.status === TerminalStatusEnum.STATUS_PROCESSING

  const loading = terminalProcessTokenMutation[1].loading || terminalCancelActionMutation[1].loading

  const onTokenConfirm = () => {
    terminalProcessTokenMutation[0]({
      variables: {
        tokenId,
        terminalId: terminal?.id,
      }
    })
  }

  const onCancelAction = () => {
    terminalCancelActionMutation[0]({
      variables: {
        terminalId: terminal?.id,
      }
    })
  }

  return <div>
    {isStatusIdle && <button className="btn btn-sm btn-primary ml-1" onClick={() => onTokenConfirm()}>{loading && <IconLoading />} Confirmer l'empreinte</button>}
    {isStatusProcessing && <button className="btn btn-sm btn-danger ml-1" onClick={() => onCancelAction()}>{loading && <IconLoading />} Annuler</button>}
  </div>

}