import React from "react"
import {EventList} from "components/front/PLANNING/Event/List/EventList"

export const EventListRoute = (props: any) => {

  const eventIdFromUrl = props?.match?.params?.id

  return <div>
    <h1>Liste des Evenements</h1>
    <EventList eventIdFromUrl={eventIdFromUrl} />
  </div>
}