import React from "react"
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

type FlagSelectorProps = {

}

export const FlagSelector = (props: FlagSelectorProps) => {
  const location = useLocation();

  const {i18n} = useTranslation('translation');

  const langUrl = new URLSearchParams(location?.search)?.get("lang")

  const onLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  }

  const defaultLang = i18n.language || langUrl || "fr"

  return <div className="flag" style={{position: "fixed", top: 10, right: 10}}>
  <select className="form-control" value={defaultLang} onChange={(e: any) => onLanguageChange(e.target.value)}>
    <option value="fr">FR</option>
    <option value="en">EN</option>
  </select>
</div>
}