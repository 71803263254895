import gql from "graphql-tag";
import StripeConfigFragmentGql from "graphql/BILLING/Stripe/Config/fragment/StripeConfigFragment.gql";
import StripeConfigPublicFragmentGql from "graphql/BILLING/Stripe/Config/fragment/StripeConfigPublicFragment.gql";

export default gql`

${StripeConfigPublicFragmentGql}

fragment BillingConfigFragment on BillingConfig {
  id
  type
  templateId
  StripeConfig {
    ...StripeConfigPublicFragment
  }
}
`