import { ApolloProvider } from "@apollo/react-hooks";
import { tokenContext } from "context/tokenContext";
import { getClient } from "helpers/Apollo";
import { getLoginCookie } from "helpers/Cookies";
import { saveLoginToken } from "helpers/User";
import React, { useState } from "react"
import { Router } from "routes";


import "static/css/common.scss"
import "static/css/icons.scss"

const App = () => {

  const findToken = () => {
    const cookie = getLoginCookie();
    const { token } = cookie || {};
    return token;
  }


  const [token, updateToken] = useState<string>(findToken())
  const [expiration, updateExpiration] = useState<number>(0)

  const apolloClient = getClient(token)

  const onTokenChange = (token: string, expiration: number) => {
    saveLoginToken(token, expiration)
    updateToken(token)
    updateExpiration(expiration)
  }

  return <div>
    <tokenContext.Provider value={{ token, expiration, updateToken: onTokenChange }}>
      <ApolloProvider client={apolloClient}>
        <Router />
      </ApolloProvider>
    </tokenContext.Provider>
  </div>
}

export default App;
