import { ReservationStatusEnum } from "model"
import React from "react"

type ReservationStatusProps = {
  value: ReservationStatusEnum | string,
}
export const ReservationStatus = (props: ReservationStatusProps) => {

  const formatStatus = (status: string) => {
    switch (status) {
      case ReservationStatusEnum.CANCELLED: {
        return <span className="badge badge-danger">{status}</span>
      }

      case ReservationStatusEnum.DEPARTED: {
        return <span className="badge badge-warning">{status}</span>
      }

      case ReservationStatusEnum.CONFIRMED: {
        return <span className="badge badge-success">{status}</span>
      }

      case ReservationStatusEnum.PROVISIONAL: {
        return <span className="badge badge-info">{status}</span>
      }

      case ReservationStatusEnum.BLOCKED: {
        return <span className="badge badge-dark">{status}</span>
      }

      case ReservationStatusEnum.STAYING: {
        return <span className="badge badge-primary">{status}</span>
      }

      default: {
        return <span className="badge badge-secondary">{status}</span>
      }
    }
  }

  return <span>{formatStatus(props?.value)}</span>


}