import { useQuery } from "@apollo/react-hooks"
import { AutoCompleteInput } from "components/common/Form/AutoCompleteInput"
import contactGetGql from "graphql/PMS/Contact/query/contactGet.gql"
import contactSearchByTextGql from "graphql/PMS/Contact/query/contactSearchByText.gql"
import React, { useEffect, useState } from "react"
import { ContactAdd } from "../Add/ContactAdd"
import { Modal } from "@zipou/front_tools"

type ContactSearchFieldProps = {
  label: string,
  hotelId: string,
  value?: string,
  disabled?: boolean,
  placeholder?: string,
  isError?: boolean,
  isWarning?: boolean,
  onChange: (value: string, object: any) => void
}

export const ContactSearchField = (props: ContactSearchFieldProps) => {

  // const [contact, updateContact] = useState(null)
  const [value, updateValue] = useState(null)
  const [showAddContactModal, updateShowAddContactModal] = useState(false)
  const { refetch } = useQuery(contactSearchByTextGql, {
    skip: true,
  })

  useQuery(contactGetGql, {
    variables: {
      id: props?.value,
    },
    skip: !props?.value,
    onCompleted: (response: any) => {
      updateValue(response?.contact)
    }
  })

  //Detect that hotelId has changed
  useEffect(() => {
    if (value && props?.hotelId) {
      onValueChange(null)
    }
  }, [props?.hotelId])

  const onValueChange = (value: any) => {
    updateValue(value)
    props?.onChange(value?.id, value)
  }

  const onSearch = async (textValue: string) => {
    const response = await refetch({
      text: textValue,
      hotelId: props?.hotelId,
    })
    return response?.data?.list || []
  }

  const handleContactAddDone = (contact: any) => {
    updateShowAddContactModal(false)
    onValueChange(contact)
  }

  return <div>
    <Modal display={showAddContactModal} title="Ajouter un CARDEX" onClose={() => updateShowAddContactModal(false)}>
      <ContactAdd hotelId={props?.hotelId} onDone={handleContactAddDone} />
      {/* <ContactForm contact={contact} onChange={(todo: any) => updateContact(todo)} errors={null} hotelId={props?.hotelId}/> */}
    </Modal>
    <AutoCompleteInput
      placeholder={"Chercher un contact dans le PMS"}
      disabled={!props?.hotelId || props?.disabled}
      onSearch={onSearch}
      label={props?.label}
      onChange={onValueChange}
      value={value}
      isError={props?.isError}
      isWarning={props?.isWarning}
      displayValue={(value: any) => `${value?.pmsId || ""} - ${value?.firstName || ""} ${value?.lastName || ""} ${value?.email || ""}`}
      displayResult={(value: any) => `${value?.pmsId || ""} - ${value?.firstName || ""} ${value?.lastName || ""}  ${value?.email || ""}`}
      renderAlways={() => <li className="list-group-item list-group-item-info">
        <button className="btn btn-sm btn-dark" type="button" onClick={() => updateShowAddContactModal(true)}>Ajouter</button>
        <span className="ml-1">Ajouter un cardex</span>
      </li>}
      renderNoResult={() => <li className="list-group-item list-group-item-danger">Pas de resultats </li>}
    />
  </div>
}
