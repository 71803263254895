import { useMutation } from "@apollo/react-hooks"
import userAdd from "graphql/APP/User/mutation/userAdd"
import userListGql from "graphql/APP/User/query/userList.gql"
import { UserInput } from "model"
import React, { useState } from "react"
import { UserForm } from "../Form/UserForm"
import { formatErrorResponseForJoi } from "helpers/Apollo"


type UserAddProps = {
  onDone?: () => void,
}

export const UserAdd = ({ onDone }: UserAddProps) => {

  const [user, updateUser] = useState<UserInput>()
  const [errors, updateErrors] = useState<any>()
  const [formUpdated, updateFormUpdated] = useState(false)


  const [mutate] = useMutation(userAdd, {
    refetchQueries: [{
      query: userListGql,
    }]
  })

  const onReset = () => {
    onDone && onDone()
  }

  const onSave = () => {
    mutate({
      variables: {
        input: user,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const onChange = (user: UserInput) => {
    updateUser(user)
    updateFormUpdated(true)
  }

  return <div>
    <UserForm user={user} onChange={onChange} errors={errors} />
    {formUpdated && <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => onSave()}>Enregister</button>
      <button className="btn btn-danger btn-sm ml-1" onClick={() => onReset()}>Annuler</button>
    </div>}
  </div>




}