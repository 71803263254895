import gql from "graphql-tag";
import TemplateFragment from "../fragment/TemplateFragment";

export default gql`

  ${TemplateFragment}

  mutation templateUpdate($id: String!, $hotelId: String!, $input: TemplateInput!) {
    template: templateUpdate(id: $id, hotelId: $hotelId, input: $input) {
      ...TemplateFragment
    }
  }
`