import gql from "graphql-tag";
import ChannelTargetFragmentGql from "../fragment/ChannelTargetFragment.gql";

export default gql`

  ${ChannelTargetFragmentGql}

  mutation channelTargetUpdate($id: String!, $clientId: String!, $channelId: String!, $input: ChannelTargetInput!) {
    channelTarget: channelTargetUpdate(id: $id, clientId: $clientId, channelId: $channelId, input: $input) {
      ...ChannelTargetFragment
    }
  }
`