import { useMutation, useQuery } from "@apollo/react-hooks"
import { SaveButton } from "components/common/Form/SaveButton"
import { URL_CRM_SEGMENTATION_LIST } from "constant/url"
import { alertContext } from "context/Alert/alert"
import segmentationAdd from "graphql/CRM/Segmentation/mutation/segmentationAdd"
import segmentationDelete from "graphql/CRM/Segmentation/mutation/segmentationDelete"
import segmentationEdit from "graphql/CRM/Segmentation/mutation/segmentationEdit"
// import segmentationSync from "graphql/CRM/Segmentation/mutation/segmentationSync"
import segmentationCount from "graphql/CRM/Segmentation/query/segmentationCount"
import segmentationGet from "graphql/CRM/Segmentation/query/segmentationGet"
import segmentationList from "graphql/CRM/Segmentation/query/segmentationList"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { SegmentationForm } from "../Form/SegmentationForm"
import { SegmentationStatus } from "../Status/SegmentationStatus"

import './style.scss'

type SegmentationEditProps = {
  onDone?: () => void,
  open?: boolean,
  id?: string,
}

export const SegmentationEdit = (props: SegmentationEditProps) => {

  const history = useHistory()
  const [errors, updateErrors] = useState<any>(null)
  const [open, updateOpen] = useState<boolean>(props?.open ?? false)
  const [formUpdated, updateFormUpdated] = useState<boolean>(false)
  const [countDisplay, updateCountDisplay] = useState<number>(null)
  const [loading, updateLoading] = useState<boolean>(false)
  const [countLoading, updateCountLoading] = useState<boolean>(false)
  const [segmentation, updateSegmentation] = useState<any>({})
  const [mutateEdit] = useMutation(segmentationEdit)
  const [mutateAdd] = useMutation(segmentationAdd)
  const [mutateDelete] = useMutation(segmentationDelete)
  // const [mutateSync] = useMutation(segmentationSync)

  const { data } = useQuery(segmentationGet, {
    skip: !props?.id,
    variables: {
      id: props?.id,
    }
  })

  useEffect(() => {
    updateSegmentation(data?.segmentation)
  }, [data?.segmentation])

  const { refetch } = useQuery(segmentationCount, {
    skip: true,
  })

  const { setMessage } = useContext(alertContext)

  const onSave = async () => {
    const { id, __typename, status, lastError, lastSyncDate, progress, ...input } = segmentation
    console.log("SEGMENTATION", input)
    updateLoading(true)
    return new Promise(resolve => {
      if (id) {
        resolve(mutateEdit({
          variables: {
            id,
            input,
          },
          refetchQueries: [{
            query: segmentationList,
          }]
        }))
      } else {
        resolve(mutateAdd({
          variables: {
            input,
          },
          refetchQueries: [{
            query: segmentationList,
          }]
        }))
      }
    })
      .then(() => {
        updateLoading(false)
        updateFormUpdated(false);
        if (props?.onDone) {
          props?.onDone()
        } else {
          setMessage("OK :)")
        }
      })
      .catch((e: any) => {
        updateLoading(false)
        updateErrors(formatErrorResponseForJoi(e))
        // setError(e.message)
      })
  }

  const updateCount = () => {
    const { id, __typename, status, lastError, displayCustomerDashboard, progress, picto, color, lastSyncDate, ...input } = segmentation || {}
    updateCountLoading(true)
    refetch({
      input,
    })
      ?.then((response: any) => {
        updateCountDisplay(response?.data?.count)
        updateCountLoading(false)
        // setMessage(`${response?.data?.count} résultats`)
      })
      ?.catch((e: any) => {
        updateCountLoading(false)
        // setError(e.message)
      })
  }

  const onChange = (form: any) => {
    updateSegmentation(form);
    updateFormUpdated(true);
    updateCountDisplay(null)
  }

  const resetForm = () => {
    updateFormUpdated(false)
    updateSegmentation(data?.segmentation)
    updateCountDisplay(null)
  }

  const deleteList = () => {
    mutateDelete({
      variables: {
        id: segmentation?.id,
      },
      refetchQueries: [{
        query: segmentationList,
      }]
    })
      .then(() => history.push(URL_CRM_SEGMENTATION_LIST))
  }

  // const syncList = () => {
  //   mutateSync({
  //     variables: {
  //       id: segmentation?.id,
  //     },
  //   })
  //     .then(() => console.log("OK"))
  //     .catch((e: any) => console.log("ERROR", e))
  // }


  const canDelete = !!segmentation?.id
  const hasCount = countDisplay != null
  // const isSyncable = (segmentation?.status !== "SYNCING" && segmentation?.id)

  return <div className="contact-list-edit-container">
    <div className="card">
      <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => updateOpen(!open)}>
        {segmentation?.name}
        <SegmentationStatus segmentation={segmentation} />
      </div>
      <div className={open ? "collapse-container collapse-visible" : "collapse-container"}>
        <div className="card-body">
          {segmentation?.status === "ERROR" && <div className="alert alert-danger">
            <h2>Oups :(</h2>
            {segmentation?.lastError}
          </div>}
          {hasCount && <div className="position-sticky top-0 alert alert-warning" style={{ top: 10, zIndex: 1000 }}>{`${countDisplay} client(s) trouvé(s)`}</div>}
          <SegmentationForm errors={errors} segmentation={segmentation} onChange={(form: any) => onChange(form)} />
        </div>
        <div className="card-footer">
          <div className="actions">
            <button disabled={countLoading} className="btn btn-sm btn-secondary" onClick={() => updateCount()}>{countLoading && <span className="icon-loading" />}Aperçu</button>
            {formUpdated && <SaveButton className="btn btn-sm btn-dark" label="Enregistrer" loading={loading} onClick={() => onSave()} />}
            {/* {isSyncable && <button className="btn btn-warning" type="button" onClick={() => syncList()}>Mettre à jour</button>} */}
            {/* {segmentation?.id && <a rel="noreferrer" href={`${getRootUrl()}crm/contact_list/export/csv/${segmentation?.id}?access_token=${findToken()}`} target={"_blank"} ><button className="btn btn-info" type="button">Télécharger en CSV</button></a>} */}
            {formUpdated && <button className="btn btn-sm btn-danger" type="button" onClick={() => resetForm()}>Annuler</button>}
            {canDelete && <button className="btn btn-sm btn-danger" type="button" onClick={() => deleteList()}>Effacer</button>}
          </div>
        </div>
      </div>
    </div>
  </div>
}