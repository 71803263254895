import { useMutation } from "@apollo/react-hooks"
import { PriceInput } from "components/common/Form/PriceInput"
import paymentCaptureGql from "graphql/BILLING/Payment/mutation/paymentCapture.gql"
import { isFieldOnError } from "helpers/Apollo"
import React, { useState } from "react"

type PaymentCaptureConfirmProps = {
  payment: any,
  onClose: () => void
}

export const PaymentCaptureConfirm = (props: PaymentCaptureConfirmProps) => {

  const [captureValue, updateCaptureValue] = useState(props?.payment?.amount)
  const [error, updateError] = useState(null)

  const [mutate] = useMutation(paymentCaptureGql)

  const onCapture = () => {
    return mutate({
      variables: {
        id: props?.payment.id,
        amount: captureValue,
      }
    })
      .then(() => props?.onClose())
      .catch((e: any) => {
        console.log("E", e)
        updateError(e)

      })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    onCapture()
  }

  const hasError = !!error
  return <div className="card">
    <form onSubmit={onSubmit}>
      <h1>Forcer le paiement</h1>
      {hasError && <div className="alert alert-danger">{error?.message}</div>}
      <div style={{ fontSize: 14 }}>Attention, vous ne pouvez forcer le paiement qu'une seule fois.</div>
      <span style={{ fontSize: 14 }}>Le montant du paiement peut-être inférieur ou égal au montant initial.</span>
      <div className="card-body">
        <PriceInput isError={isFieldOnError(error, "amount")} disabled={false} label="Montant" value={captureValue} onChange={(value: number) => {
          updateCaptureValue(value)
        }} />
      </div>
      <div className="card-footer">
        <button className="btn btn-dark" type="submit">Valider</button>
        <button className="btn btn-danger" style={{ marginLeft: 5 }} onClick={() => props?.onClose()}>Annuler</button>
      </div>
    </form>
  </div>
}