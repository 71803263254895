import gql from "graphql-tag";
import NotificationFragment from "../fragment/NotificationFragment";

export default gql`

${NotificationFragment}

mutation notificationSetRead($id: String!, $isRead: Boolean!) {
  notification: notificationSetRead(id: $id, isRead: $isRead) {
    ...NotificationFragment
  }
}

`