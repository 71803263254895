import {ContactDedupList} from "components/front/PMS/Contact/Dedup/List/ContactDedupList";
import React from "react"
import {RouteComponentProps} from "react-router-dom";


export const ContactDedupListRoute = (props: RouteComponentProps) => {

  return <div>
    <ContactDedupList />
  </div>

}