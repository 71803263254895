import React from "react"

import {useQuery} from "@apollo/react-hooks"
import contactGetGql from "graphql/PMS/Contact/query/contactGet.gql"

type ContactViewProps = {
  contactId: any,
}

export const ContactView = (props: ContactViewProps) => {

  const {data} = useQuery(contactGetGql, {
    variables: {
      id: props?.contactId,
    },
    skip: !props?.contactId,
  })

  const contact = data?.contact

  // id
  //   pmsId
  //   firstName
  //   lastName
  //   gender
  //   email
  //   updateDate
  //   type

  return <div>
    <table className="table table-bordered table-stripped">
      <tbody>
        <tr>
          <th>ID Pms</th>
          <td>{contact?.pmsId}</td>
        </tr>
        <tr>
          <th>Prénom</th>
          <td>{contact?.firstName}</td>
        </tr>
        <tr>
          <th>Nom</th>
          <td>{contact?.lastName}</td>
        </tr>
        <tr>
          <th>Genre</th>
          <td>{contact?.gender}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{contact?.email}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{contact?.type}</td>
        </tr>
        <tr>
          <th>Téléphones</th>
          <td>
            <ul className="list-group">{contact?.phoneNumberValid?.map((phone: string) => <li className="list-group-item">{phone}</li>)}</ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

}