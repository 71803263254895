import gql from "graphql-tag";
import StripeConfigPublicFragmentGql from "graphql/BILLING/Stripe/Config/fragment/StripeConfigPublicFragment.gql";

export default gql`

  ${StripeConfigPublicFragmentGql}

  fragment HotelBillingFragment on Hotel {
    id
    name
    logoUrl
    cgvUrlEn
    cgvUrlFr
    address
    phoneNumber
    backgroundUrl
    checkInHour
    checkOutHour
    themeColor
    phoneConfigId
    BillingConfig {
      id
      type
      StripeConfig {
        ...StripeConfigPublicFragment
      }      
    }
  }
`