import gql from "graphql-tag";
import CorpFragmentGql from "../fragment/CorpFragment.gql";

export default gql`

${CorpFragmentGql}

query corpList {
  list: corpList {
    ...CorpFragment
  }
}

`