import React, { useContext, useState } from "react"
import { useQuery } from "@apollo/react-hooks"

import customerGetQuery from "graphql/CRM/Customer/query/customerGet"

import './style.scss'

import { Customer, SegmentationMember, UserRoleEnum } from "model"
import { PriceFormat } from "components/common/Format/PriceFormat"
import { ReservationListByCustomerId } from "components/front/PMS/Reservation/List/ReservationListByCustomerId"
import { CustomerAddressDisplayInline } from "../Address/Display/CustomerAddressDisplayInline"
import { CustomerEmailDisplay } from "../Email/Display/CustomerEmailDisplay"
import { CustomerPhoneDisplay } from "../Phone/Display/CustomePhoneDisplay"
import { CustomerOrigin } from "../Origin/CustomerOrigin"
import { NewsletterRegistrationList } from "../../NewsletterRegistration/List/CustomerNewsletterRegistrationList"
import { userContext } from "context/User"
import { Modal } from "@zipou/front_tools"


type CustomerDetailsProps = {
  customerId: string,
  showProposition?: boolean,
  showContacts?: boolean,
  showReservation?: boolean,
}

export const CustomerDetails = (props: CustomerDetailsProps) => {

  const { customerId } = props;
  const [focusReservation, updateFocusReservation] = useState(false)
  const [focusNewsletterRegistration, updateFocusNewsletterRegistration] = useState(false)

  const { data, loading } = useQuery(customerGetQuery, {
    variables: {
      id: customerId,
    },
    skip: !customerId,
    fetchPolicy: "cache-and-network",
  })

  const user = useContext(userContext)
  const hasDebug = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  const customer: Customer = data?.customer || {}

  const customerMetric = customer?.CustomerMetric

  const hasCa = customerMetric?.caTotal > 0
  const hasProposition = customerMetric?.nbProposition > 0
  const hasReservations = customerMetric?.nbReservation > 0;

  const segmentationMemberList = customer?.SegmentationMember?.filter(member => member?.Segmentation?.displayCustomerDashboard)
  const hasSegmentationMember = segmentationMemberList?.length > 0

  const newsletterRegistrationList = customer?.CustomerNewsletterRegistration?.map((cnr) => cnr.NewsletterRegistration)
  const hasNewsletterRegistrations = customer?.CustomerNewsletterRegistration?.length > 0

  const phoneNumberList = customer?.CustomerPhone
  const hasPhoneNumbers = phoneNumberList?.length > 0

  const addressList = customer?.CustomerAddress
  const hasAddress = addressList?.length > 0

  const emailList = customer?.CustomerEmail
  const hasEmail = emailList?.length > 0

  const header = (customer?.firstName || customer?.lastName) ? `${customer?.firstName} ${customer?.lastName}` : "Client"

  return <div className="customer-details-container">
    <Modal display={focusReservation} onClose={() => updateFocusReservation(false)}>
      <ReservationListByCustomerId customerId={customer?.id} />
    </Modal>
    <Modal display={focusNewsletterRegistration} onClose={() => updateFocusNewsletterRegistration(false)}>
      <NewsletterRegistrationList newsletterRegistrationList={newsletterRegistrationList} />
    </Modal>
    {loading && <div>
      <span className="icon-loading"></span>
    </div>}
    {!loading && <div className="row">
      <div className="col-12 col-sm-12">
        <h2 className="text-capitalize">{header}</h2>
        <div className="d-flex justify-content-around pt-2">
          <span className="d-flex flex-column text-left">
            {hasEmail && emailList.map((customerEmail, index) => {
              return <span key={`customerEmail${customerEmail?.id}`} className="mt-1">
                <CustomerEmailDisplay customerEmail={customerEmail} />
              </span>
            })}

          </span>
          <span className="d-flex flex-column text-align-left" style={{ textAlign: 'left' }}>
            {hasPhoneNumbers && phoneNumberList?.map(customerPhoneNumber => {
              return <span key={`phone_${customerPhoneNumber.id}`} className="mt-1">
                <CustomerPhoneDisplay customerPhone={customerPhoneNumber} />
              </span>
            })}
          </span>
          <span style={{ textAlign: 'left' }}>
            {hasAddress && addressList?.map((customerAddress, index) => {
              return <div key={`address_${customerAddress?.id}`} className="d-flex flex-align-center mt-1">
                <span><CustomerAddressDisplayInline customerAddress={customerAddress} /></span>
              </div>
            })}

          </span>
          {/* <span>
            {customer?.addressList?.map((address: any, index: number) => {
              return <AddressDisplay key={`address_${index}`} address={address} />
              // return <span>{address?.address1} {address?.address2} - {address?.zip} {address?.zipCode} {address?.city} {address?.country}  </span>
            })}
          </span> */}
        </div>
      </div>
      <div className="col-12 d-flex justify-content-around">
        <div className="d-flex align-items-center justify-content-center">
          {customer?.origin && <span className="p-3"><CustomerOrigin origin={customer?.origin} /></span>}
          {hasReservations && <span><span style={{ fontSize: "2rem" }} className="p-1">{customerMetric?.nbReservation}</span> séjour(s)</span>}
          {hasProposition && <span><span style={{ fontSize: "2rem" }} className="p-3">{customerMetric?.nbProposition}</span> proposition(s)</span>}
          {hasCa && <span><span style={{ fontSize: "2rem" }} className="p-3"><PriceFormat value={customerMetric?.caTotal * 100} /></span> CA Corp</span>}
        </div>
        {hasSegmentationMember && <div className="d-flex">
          {segmentationMemberList?.map((segmentationMember: SegmentationMember) => {
            return <h2>
              <span className="m-1 badge badge-info">
                {segmentationMember?.Segmentation?.name}
              </span>
            </h2>
          })}
        </div>}
      </div>
      <div className="col-12 d-flex justify-content-around mt-2">
        {hasReservations && <button className="btn btn-sm btn-dark" onClick={() => updateFocusReservation(true)}>Historique de séjours</button>}
        {hasNewsletterRegistrations && <button className="btn btn-sm btn-dark" onClick={() => updateFocusNewsletterRegistration(true)}>Inscriptions aux Newsletter</button>}
      </div>
      {hasDebug && <div className="ml-3">
        <span className="badge badge-sm badge-secondary">{customer?.id}</span>
      </div>}
    </div>}
  </div>

}