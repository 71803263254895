import { IconLoading, IconTrash, IconWarning, IconWarningSign } from "@zipou/front_tools"
import React, { useEffect, useRef, useState } from "react"

interface AutoCompleteInputProps<T> {
  onSearch: (value: string) => Promise<any[]>
  onChange: (value: T) => void,
  displayValue: (value: T) => any,
  displayResult: (value: T) => any,
  renderNoResult?: () => any,
  renderAlways?: () => any,
  minLength?: number,
  label: string,
  isError: boolean,
  isWarning?: boolean,
  value: T,
  disabled: boolean,
  placeholder?: string,
}

export const AutoCompleteInput = <T extends unknown>(props: AutoCompleteInputProps<T>) => {

  let timeout = useRef(null)

  const [resultListIndexSelected, updateResultListIndexSelected] = useState(-1)
  const [textValue, updateTextValue] = useState("")
  const [oversizedList, updateOversizedList] = useState(false)
  const [noResults, updateNoResults] = useState(false)
  const [loading, updateLoading] = useState(false)
  const [resultList, updateResultList] = useState([])


  const onChange = (value: string) => {
    if (value.length >= (props?.minLength || 2)) {
      timeout && clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        onSearch(value)
      }, 500)
    }
    updateNoResults(false)
    updateTextValue(value)
  }


  const onSearch = async (value: string) => {
    updateLoading(true)
    updateNoResults(false)
    updateOversizedList(false);
    try {
      const list = await props?.onSearch(value)
      updateResultListIndexSelected(-1)
      if (list?.length > 30) {
        updateResultList([])
        updateNoResults(false)
        updateLoading(false)
        updateOversizedList(true);
      } else {

        if (list?.length === 0) {
          updateLoading(false)
          updateNoResults(true)
          updateOversizedList(false);
          updateResultList(list)
        } else {
          updateNoResults(false)
          updateLoading(false)
          updateOversizedList(false);
          updateResultList(list)
        }
      }
    } catch (e) {
      console.log("ERROR", e)
    }

  }

  const onSelectValue = (value: any) => {
    // updateValue(value)
    updateResultList([])
    onClearText()
    props?.onChange(value)
  }

  const handleKeyDown = (e: any) => {
    const nbResults = resultList?.length
    if (e.keyCode === 38 && (resultListIndexSelected > 0)) {
      //UP
      updateResultListIndexSelected(resultListIndexSelected - 1)
    } else {
      if (e.keyCode === 40 && (resultListIndexSelected < nbResults - 1)) {
        //DOWN
        updateResultListIndexSelected(resultListIndexSelected + 1)
      } else {
        if (e.keyCode === 13) {
          e.preventDefault()
          onSelectValue(resultList[resultListIndexSelected])
        }
      }
    }
  }

  const onClear = () => {
    // updateValue(null)
    updateNoResults(false)
    updateTextValue("")
    updateResultListIndexSelected(-1)
    updateResultList([])
    updateLoading(false)
    props?.onChange(null)
  }

  const onClearText = () => {
    updateTextValue("")
    updateLoading(false)
    updateNoResults(false)
    updateResultList([])

  }


  useEffect(() => {
    if (props?.value) {
      updateNoResults(false)
      updateResultListIndexSelected(-1)
      updateResultList([])
    }
  }, [props?.value])


  // const segmentation = data?.list
  const hasResults = resultList?.length > 0
  const hasValue = !!props?.value
  const hasText = !!textValue

  return <div className="search-auto-container">
    <div className="input-group" style={{ paddingBottom: 5 }}>
      <span className="input-group-text">{props?.label}</span>
      <input type="text" onKeyDown={handleKeyDown} className="form-control" placeholder={props?.placeholder || ""} disabled={hasValue || props?.disabled} value={hasValue ? props?.displayValue(props?.value) : textValue} onChange={(e: any) => {
        onChange(e.target.value)
      }} />
      {loading && <span className="input-group-text">
        <IconLoading />
      </span>}
      {(hasValue && !props?.disabled) && <span className="input-group-text" onClick={() => onClear()}>
        <IconTrash />
      </span>}
      {(hasText && !props?.disabled) && <span className="input-group-text" onClick={() => onClearText()}>
        <IconTrash />
      </span>}
      {props?.isError && <span className="input-group-text">
        <IconWarningSign />
      </span>}
      {props?.isWarning && <span className="input-group-text">
        <IconWarning />
      </span>}
    </div>
    <ul className="list-group" style={{ marginTop: -5, position: "absolute", zIndex: 1000, border: "1px #CCC solid" }}>
      {oversizedList && <li className="list-group-item list-group-item-warning">Trop de resultats</li>}
      {(noResults && !props?.renderNoResult) && <li className="list-group-item list-group-item-danger">Pas de resultats</li>}
      {(noResults && props?.renderNoResult) && props.renderNoResult()}
      {hasResults && resultList?.map((result: any, index: number) => {
        const isActive = index === resultListIndexSelected
        return <li key={`autocomplete_result_${index}`} onMouseEnter={() => updateResultListIndexSelected(index)} onMouseLeave={() => updateResultListIndexSelected(-1)} className={isActive ? "list-group-item active text-white" : "list-group-item bg-secondary text-white"} onClick={() => onSelectValue(result)}>{props?.displayValue(result)} </li>
      })}
      {((hasResults || noResults) && props?.renderAlways) && props?.renderAlways()}
    </ul>
  </div>

}