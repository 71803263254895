import { useMutation } from "@apollo/react-hooks"
import { IconLoading } from "@zipou/front_tools"
import { PriceInput } from "components/common/Form/PriceInput"
import chargeRefundGql from "graphql/BILLING/Charge/mutation/chargeRefund.gql"
import { isFieldOnError } from "helpers/Apollo"
import { Charge } from "model"
import React, { useState } from "react"

type ChargeRefundProps = {
  charge: Charge,
  onClose: () => void
}

export const ChargeRefund = ({ charge, onClose }: ChargeRefundProps) => {


  const amount = charge?.amount

  const [captureValue, updateCaptureValue] = useState(amount)
  const [error, updateError] = useState(null)

  const [mutate, { loading }] = useMutation(chargeRefundGql)

  const onRefund = () => {
    return mutate({
      variables: {
        id: charge.id,
        amount: captureValue,
      }
    })
      .then(() => onClose())
      .catch((e: any) => {
        console.log("E", e)
        updateError(e)
      })
  }


  const hasError = !!error
  return <>
    <h1>Rembourser le paiement</h1>
    {hasError && <div className="alert alert-danger">{error?.message}</div>}
    <div style={{ fontSize: 14 }}>Attention, vous ne pouvez rembourser le paiement qu'une seule fois.</div>
    <span style={{ fontSize: 14 }}>Le montant du remboursement peut-être inférieur ou égal au montant initial.</span>
    <div className="card-body">
      <PriceInput isError={isFieldOnError(error, "amount")} disabled={false} label="Montant" value={captureValue} onChange={(value: number) => {
        updateCaptureValue(value)
      }} />
    </div>
    <button disabled={loading} className="btn btn-sm btn-dark" onClick={() => onRefund()}>
      {loading && <IconLoading />}
      Valider
    </button>
    <button className="btn btn-sm btn-danger" style={{ marginLeft: 5 }} onClick={() => onClose()}>Annuler</button>
  </>
}