import {ReservationFullDetails} from "components/front/PMS/Reservation/Details/ReservationFullDetails";
import React from "react"

export const ReservationDetailsRoute = (props: any) => {

  const {match} = props;
  const {params} = match || {}

  return <div>
    <ReservationFullDetails reservationId={params?.id} />
  </div>
}