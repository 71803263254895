import gql from "graphql-tag";
import TargetFragmentGql from "../fragment/TargetFragment.gql";

export default gql`

  ${TargetFragmentGql}

  query targetPing($id: String!, $clientId: String!) {
    target: targetPing(id: $id, clientId: $clientId) {
      ...TargetFragment
    }
  }

`