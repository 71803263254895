import gql from "graphql-tag";
import PaymentPublicFragment from "../fragment/PaymentPublicFragment";

export default gql`

  ${PaymentPublicFragment}

  query paymentGetById($id: String!) {
    payment: paymentGetById(id: $id) {
      ...PaymentPublicFragment
    }
  }
`