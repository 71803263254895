import gql from "graphql-tag";
import ChannelTargetFragmentGql from "../../ChannelTarget/fragment/ChannelTargetFragment.gql";
// import MessageFragmentGql from "../../Message/fragment/MessageFragment.gql";

export default gql`

  ${ChannelTargetFragmentGql}

  fragment ChannelFragment on Channel {
    id
    status
    options
    ChannelTarget {
      ...ChannelTargetFragment
    }
  }

`
/*

  ${MessageFragmentGql}


    messageList {
      ...MessageFragment
    }
*/