import gql from "graphql-tag";
import ChannelFragmentGql from "../fragment/ChannelFragment.gql";

export default gql`

  ${ChannelFragmentGql}

  subscription channelUpdate($id: String!, $clientId: String!, $channelId: String!) {
    channel: channelUpdate(id: $id, clientId: $clientId, channelId: $channelId) {
      ...ChannelFragment
    }
  }

`