import {useMutation, useQuery} from "@apollo/react-hooks"
import {URL_PLANNING_LIST} from "constant/url"
import planningEditGql from "graphql/PLANNING/Planning/mutation/planningEdit.gql"
import planningGetGql from "graphql/PLANNING/Planning/query/planningGet.gql"
import {formatErrorResponseForJoi} from "helpers/Apollo"
import {Planning} from "model"
import React, {useState} from "react"
import {useHistory} from "react-router-dom"
import {PlanningForm} from "../Form/PlanningForm"

type PlanningEditProps = {
  id?: string,
  onDone?: () => void,
}

export const PlanningEdit = (props: PlanningEditProps) => {

  const history = useHistory()
  const [mutate] = useMutation(planningEditGql)
  const [errors, updateErrors] = useState<any>(null)
  const [planning, updatePlanning] = useState<Partial<Planning>>({})


  useQuery(planningGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
    onCompleted: (data) => {
      updatePlanning(data?.planning)
    }
  })



  const onSubmit = async () => {
    updateErrors(null)
    const {id, __typename, ...input} = planning
    return mutate({
      variables: {
        id: props?.id,
        input,
      }
    })
      .then(() => {
        props?.onDone ? props?.onDone() : history.push(URL_PLANNING_LIST)
      })
      .catch((e: any) => {
        const errors = formatErrorResponseForJoi(e)
        updateErrors(errors)
      })
  }


  return <div>
    <div className="card">
      <PlanningForm errors={errors} planning={planning} onChange={(planning) => updatePlanning(planning)} />
      <div className="card-footer">
        <div className="actions">
          <button type="submit" className="btn btn-info" onClick={() => onSubmit()}>Enregistrer</button>
        </div>
      </div>
    </div>
  </div>
}
