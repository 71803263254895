import gql from "graphql-tag";
import BillingConfigFragment from "graphql/BILLING/Config/fragment/BillingConfigFragment";

export default gql`

  ${BillingConfigFragment}

  fragment HotelFragment on Hotel {
    id
    name
    activeFetcher
    lastFetch
    lastFetchError
    logoUrl
    address
    phoneNumber
    backgroundUrl
    checkInHour
    checkOutHour
    themeColor
    phoneConfigId
    BillingConfig {
      ...BillingConfigFragment
    }
  }
`