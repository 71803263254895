import gql from "graphql-tag";
import EventServiceFragment from "graphql/PLANNING/Event/fragment/EventServiceFragment";

export default gql`

  ${EventServiceFragment}


query confirmationListEvent($id: String!) {
  list: confirmationListEvent(id: $id) {
    ...EventServiceFragment
  }

}

`