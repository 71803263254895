import gql from "graphql-tag";

export default gql`

query propositionComputeTotal($input: PropositionRowInput!) {
  propositionRowTotal: propositionComputeTotal(input: $input) {
    total
    totalExtra
    totalDiscountExtra
    totalHosting
    totalDiscountHosting
    totalDiscount
    totalExtraWithoutDiscount
    totalHostingWithoutDiscount
  }
}

`