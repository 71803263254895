import {SelectInput} from 'components/common/Form/SelectInput'
import {TextInput} from 'components/common/Form/TextInput'
import {isFieldOnError} from 'helpers/Apollo'
import React from 'react'
import {ContactCountrySearchField} from '../Field/ContactCountrySearchField'

export type ContactFormProps = {
  contact: any,
  hotelId: any,
  onChange: (data: any) => void,
  errors: any
}

export const ContactForm = (props: ContactFormProps) => {

  // const [contact, updateContact] = useState<any>({})
  const {contact, errors} = props

  return <div>
    {errors?.global && <div className="alert alert-danger">
      <h2>Une erreur PMS s'est produite...</h2>
      <p>Probablement que le Téléphone ou l'Email existe déjà dans la base CARDEX</p>
    </div>}
    <div className="alert alert-warning">Tous les champs sont obligatoires</div>
    <TextInput id="email" label='Email' onChange={(e: any) => props?.onChange({...contact, email: e.target.value})} value={contact?.email || ""} errors={props.errors} />
    <TextInput id="lastName" label='Nom' onChange={(e: any) => props?.onChange({...contact, lastName: e.target.value})} value={contact?.lastName || ""} errors={props.errors} />
    <TextInput id="firstName" label='Prénom' onChange={(e: any) => props?.onChange({...contact, firstName: e.target.value})} value={contact?.firstName || ""} errors={props.errors} />
    <SelectInput id="gender" label="Genre" choiceList={[{id: "MALE", label: 'MASCULIN'}, {id: "FEMALE", label: "FEMININ"}]} value={contact?.gender || ""} onChange={(e: any) => props?.onChange({...contact, gender: e.target.value})} errors={props.errors} disabled={false} />
    <TextInput id="zipcode" label='Code Postal' onChange={(e: any) => props?.onChange({...contact, zipcode: e.target.value})} value={contact?.zipcode || ""} errors={props.errors} />
    <TextInput id="city" label='Ville' onChange={(e: any) => props?.onChange({...contact, city: e.target.value})} value={contact?.city || ""} errors={props.errors} />
    <ContactCountrySearchField isError={isFieldOnError(props?.errors, "countryCode")}  hotelId={props?.hotelId} label={"Pays"} onChange={(id: any, v: any) => props?.onChange({...contact, countryCode: v.codeShort})} />
    <TextInput id="phoneNumber" label='Numéro de téléphone' onChange={(e: any) => props?.onChange({...contact, phoneNumber: e.target.value})} value={contact?.phoneNumber || ""} errors={props.errors} />
    <ContactCountrySearchField isError={isFieldOnError(props?.errors, "nationalityCode")} hotelId={props?.hotelId} label={"Nationality"} onChange={(id: any, v: any) => props?.onChange({...contact, nationalityCode: v.codeShort})} />
  </div>
}