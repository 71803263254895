import gql from "graphql-tag";

export default gql`

  fragment ChannelTargetFragment on ChannelTarget {
    id
    targetId
    isWriting
  }

`