import gql from "graphql-tag";
import EventFragmentGql from "../fragment/EventFragment.gql";

export default gql`

  ${EventFragmentGql}

  mutation eventEdit($id: String!,  $input: EventInput!) {
    event: eventEdit(id: $id, input: $input) {
      ...EventFragment
    }
  }
`