import { useQuery } from '@apollo/react-hooks';
import eventListByPlanning from 'graphql/PLANNING/Event/query/eventListByPlanning';
import planningGetGql from 'graphql/PLANNING/Planning/query/planningGet.gql';
import { Loop } from 'helpers/Utils';
import React, { useState } from 'react'
import { EventView } from '../../Event/View/EventView';
import { Event, Planning } from "model"
import { DateInput } from 'components/common/Form/DateInput';

enum PlanningPeriod {
  DAY = "DAY",
  WEEK = "WEEK",
}

type PlanningViewProps = {
  id: string,
}

export const PlanningView = (props: PlanningViewProps) => {

  const { data } = useQuery(planningGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
  })

  const planning: Planning = data?.planning || {}

  // const [mousePosition, updateMousePosition] = useState(null)
  const [period, updatePeriod] = useState(PlanningPeriod.WEEK)
  const [focusDate] = useState<{ dateStart: Date, dateEnd: Date }>(null)
  const [startDate, updateStartDate] = useState<Date>(new Date())


  const { data: eventData } = useQuery(eventListByPlanning, {
    fetchPolicy: 'network-only',
    variables: {
      id: props?.id,
      input: {
        startDate,
        nbDays: period === PlanningPeriod.DAY ? 1 : 7,
      }
    },
    skip: !planning?.hotelId,
  })


  const eventList: Event[] = eventData?.list || []

  // const onEventAdd = () => {
  //   updateFocus(null, null)
  // }

  // const updateFocus = (dateStart: Date, dateEnd: Date) => {
  //   // updateFocusDate({dateStart, dateEnd})
  // }


  const findEventByDate = (dateStart: Date, dateEnd: Date) => {

    return eventList
      ?.filter((event) => {

        return new Date(event.utcDate).getTime() > dateStart.getTime() && new Date(event.utcDate).getTime() <= dateEnd.getTime()
      })
      ?.sort((el1: any, el2: any) => new Date(el1.date).getTime() - new Date(el2.date).getTime())
  }

  const renderHeader = () => {

    const nbIter = (period === PlanningPeriod.WEEK) ? 7 : 1
    // return <thead onMouseEnter={() => updateMousePosition(null)}>
    return <thead>
      <th></th>
      {Loop(nbIter).map(colIndex => {
        const date = new Date(startDate).getTime() + (colIndex * 1000 * 3600 * 24)
        return <th>
          <span>{new Date(date).toLocaleDateString("fr-FR")}</span>
        </th>
      })}

    </thead>
  }


  return <div className="planning-container">
    <h1>Planning {planning?.name}</h1>
    <div className="controls card">
      <div className="card-header">
        Paramètres
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <DateInput label={"Date"} value={startDate?.getTime()} onChange={(s, d) => updateStartDate(d)} />
          </div>
          <div className="col-6">
            <div className="input-group">
              <span className="input-group-text">Affichage</span>
              <select className="form-control" value={period} onChange={(e: any) => {
                updatePeriod(e.target.value)
              }}>
                <option value={PlanningPeriod.DAY}>Jour</option>
                <option value={PlanningPeriod.WEEK}>Semaine</option>
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="col-12">
            <div className='d-flex justify-content-center'>
              <button className="btn btn-sm btn-dark ml-1 mr-1" onClick={() => updateStartDate(new Date(startDate.getTime() - (1000 * 3600 * 24 * 30)))}>{"<-- Mois "}</button>
              <button className="btn btn-sm btn-dark ml-1 mr-1" onClick={() => updateStartDate(new Date(startDate.getTime() - (1000 * 3600 * 24 * 7)))}>{"<- Semaine "}</button>
              <button className="btn btn-sm btn-dark ml-1 mr-1" onClick={() => updateStartDate(new Date(startDate.getTime() - (1000 * 3600 * 24)))}>{"< Jour "}</button>
              <button className="btn btn-sm btn-dark ml-1 mr-1" onClick={() => updateStartDate(new Date(startDate.getTime() + (1000 * 3600 * 24)))}>{"Jour >"}</button>
              <button className="btn btn-sm btn-dark ml-1 mr-1" onClick={() => updateStartDate(new Date(startDate.getTime() + (1000 * 3600 * 24 * 7)))}>{"Semaine ->"}</button>
              <button className="btn btn-sm btn-dark ml-1 mr-1" onClick={() => updateStartDate(new Date(startDate.getTime() + (1000 * 3600 * 24 * 30)))}>{"Mois -->"}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="planning">
      <table className="table table-bordered">
        {renderHeader()}
        <tbody>
          {planning?.rowList?.map((row: any, rowIndex: number) => {
            // {Loop(NB_HOURS).map((rowIndex: number) => {
            const nbColumns = (period === PlanningPeriod.DAY) ? 1 : 7

            // const rowDate = new Date(startDate).getTime() + (rowIndex * 1000 * 3600);
            const { hourStart, hourEnd } = row;
            const rowStartDate = new Date(startDate).getTime() + (Number(hourStart - 1) * 1000 * 3600);
            const rowEndDate = new Date(startDate).getTime() + (Number(hourEnd - 1) * 1000 * 3600);

            return <tr key={`row_key_${rowIndex}`} style={{ height: planning?.options?.rowHeight }}>
              {/* <td className="" onMouseEnter={() => updateMousePosition(null)} > */}
              <td className="">
                {row?.label}
                <span style={{ display: 'none' }}>{new Date(rowStartDate).toLocaleString()} - {new Date(rowEndDate).toLocaleString()}</span>
              </td>
              {Loop(nbColumns).map((columnIndex: number) => {

                const cellStartDate = new Date(rowStartDate).getTime() + ((columnIndex) * 1000 * 3600 * 24);
                const cellEndDate = cellStartDate + (Number(Number(hourEnd) - Number(hourStart)) * 1000 * 3600);
                // const isFocused = new Date(cellStartDate).getTime() === new Date(focusDate?.dateStart).getTime() && new Date(cellEndDate).getTime() === new Date(focusDate?.dateEnd).getTime()
                // const isFocused = false;

                const eventForDateList = findEventByDate(new Date(cellStartDate), new Date(cellEndDate))
                const hasEvent = eventForDateList?.length > 0
                // const isMouseHere = (rowIndex === mousePosition?.row && columnIndex === mousePosition?.column)

                // return <td key={`td_key_${rowIndex}_${columnIndex}`} onMouseEnter={() => updateMousePosition({ row: rowIndex, column: columnIndex })}>
                return <td key={`td_key_${rowIndex}_${columnIndex}`}>
                  <span style={{ display: "none" }}>{new Date(cellStartDate).toLocaleString()} - {new Date(cellEndDate).toLocaleString()}</span>
                  {/* {isMouseHere && <div style={{position: "relative", width: '100%', height: 0}}>
                    <span onClick={() => updateFocus(new Date(cellStartDate), new Date(cellEndDate))} style={{position: "absolute", top: 0, right: 0, fontSize: 16}}>
                      <span className="icon-circle-plus" style={{color: "white"}} />
                    </span>
                  </div>} */}
                  <div style={{ display: "flex", flexDirection: "column" }}>

                    {/* {isFocused && new Date(cellDate).toLocaleTimeString("fr-FR", {})} */}
                    {hasEvent && eventForDateList?.map((event: any) => <EventView event={event} />)}
                    {/* {isFocused && <div style={{position: "absolute", zIndex: 1000}}>
                      <EventAdd date={new Date(cellStartDate)} onDone={onEventAdd} hotelId={planning?.hotelId} onCancel={() => {
                        updateFocus(null, null)
                      }} />
                    </div>} */}
                  </div>
                </td>
              })}
              {/* <td></td> */}
            </tr>
          })}
        </tbody>
      </table>
    </div>

  </div>
}