import { useQuery } from "@apollo/react-hooks"
import { PaymentConfirm } from "components/front/BILLING/Payment/Confirm/PaymentConfirm"
import { HotelContainer } from "components/public/PMS/Hotel/HotelContainer"
import paymentGetByPublicIdAndPublicSalt from "graphql/BILLING/Payment/query/paymentGetByPublicIdAndPublicSalt"
import React from "react"

export const PaymentConfirmPublicIdAndSaltRoute = (props: any) => {

  const { params } = props?.match
  const { id, salt } = params

  const { data } = useQuery(paymentGetByPublicIdAndPublicSalt, {
    variables: {
      id,
      salt,
    },
    pollInterval: 2000,
  })

  const payment = data?.payment

  return <div>
    {payment && <HotelContainer hotel={payment?.Hotel}>
      <div className="">
        <div className="card-body">
          <PaymentConfirm payment={payment} />
        </div>
      </div>
    </HotelContainer>}
  </div>
}