import { useSubscription } from "@apollo/react-hooks"
import { StripeContainer } from "components/front/BILLING/Stripe/Container/StripeContainer"
import tokenUpdateGql from "graphql/BILLING/Token/subscription/tokenUpdate.gql"
import { BillingConfigTypeEnum, Token } from "model"
import React from "react"
import { TokenConfirmStripe } from "./Stripe/TokenConfirmStripe"

export type TokenConfirmProps = {
  token: Token,
  newWindow?: boolean,
  onCancel?: () => void,
}

export const TokenConfirm = ({ token, newWindow, onCancel }: TokenConfirmProps) => {


  useSubscription(tokenUpdateGql, {
    variables: {
      id: token?.id,
    },
    skip: !token?.id,
    // onSubscriptionData: (data) => {
    //   console.log("data", data?.subscriptionData)
    // }
  })

  switch (token?.provider) {
    case BillingConfigTypeEnum.STRIPE: {

      return <StripeContainer stripePk={token?.Hotel?.BillingConfig?.StripeConfig?.publishableKey}>
        <TokenConfirmStripe token={token} />
      </StripeContainer>


    }

    // case "PAYTWEAK": {
    //   return <TokenConfirmPaytweak payment={payment} newWindow={props?.newWindow} />
    // }

    default: {
      return <h1>DEFAULT</h1>
    }
  }


}