import gql from "graphql-tag";
import TokenFragment from "../fragment/TokenFragment";

export default gql`

  ${TokenFragment}

  query tokenGet($id: String!) {
    token: tokenGet(id: $id) {
      ...TokenFragment
    }
  }
`