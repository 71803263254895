import gql from "graphql-tag";

export default gql`

  fragment ServicePreStayFragment on Service {
    id
    name
    type
    labelFr
    descFr
    labelEn
    descEn
    pictoUrl
  }

`