import React from "react"

import {SegmentationEdit} from "components/front/CRM/Segmentation/Edit/SegmentationEdit"
import {URL_CRM_SEGMENTATION_LIST} from "constant/url"
import {useHistory} from "react-router-dom"


export const SegmentationEditRoute = (props: any) => {

  const history = useHistory()

  const {params} = props?.match
  const {id}= params

  return <div>
    <h1>Modifier la segmentation</h1>
    <SegmentationEdit id={id} onDone={ () => history.push(URL_CRM_SEGMENTATION_LIST)} open={true} />
  </div>
}