import { PriceFormat } from 'components/common/Format/PriceFormat';
import { Option, PreStay, PreStayContentInput } from 'model';
import React from 'react'
import { useTranslation } from 'react-i18next';

type OptionsAnswerProps = {
  preStayContent: PreStayContentInput,
  optionList: Option[],
  onChange: (preStayContent: PreStayContentInput) => void,
}

export const OptionsAnswer = ({ preStayContent, onChange, optionList }: OptionsAnswerProps) => {

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'prestay' });
  const isFrench = i18n.language === "fr"

  const optionSelected = preStayContent?.optionsSelected


  // const hasOptionToPropose = optionList?.length > 0

  return <div>
    <h1>{t("options.title")}</h1>
    <h2>{t("options.desc")}</h2>
    <div>
      <div className="" style={{ marginTop: 15 }}>
        <div>
          {optionList?.map(option => {

            const optionIsSelected = optionSelected?.find((optSel: any) => optSel.optionId === option.id)

            return <div className="row d-flex align-items-center" key={`option_${option?.id}`} style={{ padding: "15px 5px 5px 5px", minHeight: 90 }}>
              <div className='col-12 col-sm-2 d-flex'>
                <img alt={option?.labelEn} width={80} height={80} style={{ opacity: 1 }} src={option?.File?.url} className='img-responsive' />
              </div>
              <div className='col-12 col-sm-6 d-flex flex-column text-left align-items-start'>
                <span className='font-weight-bold' style={{ fontSize: "1.1rem" }}>{isFrench ? option?.labelFr : option?.labelEn}</span>
                <span className='pt-1' style={{ color: "#333" }}>{isFrench ? option?.descFr : option?.descEn}</span>
              </div>
              <div className='col-12 col-sm-4 d-flex justify-content-end align-items-end pr-3 '>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group pr-0">
                      <span className='form-control' style={{ minWidth: 80 }}>
                        <PriceFormat value={option?.amountIncVat} withDecimal={false} />
                      </span>
                      <select className='form-control' value={optionIsSelected?.quantity} style={{ backgroundColor: "var(--theme-color)", color: "white" }} onChange={(e) => {
                        const quantity = Number(e.target.value)
                        const isAlreadyThere = preStayContent?.optionsSelected?.find((el: any) => el.optionId === option?.id)
                        onChange({
                          ...preStayContent,
                          optionsSelected: isAlreadyThere ? preStayContent?.optionsSelected?.map((el: any) => (el?.optionId === option?.id) ? {
                            quantity: quantity,
                            optionId: el?.optionId,
                          } : el) : [
                            ...(preStayContent?.optionsSelected || []),
                            {
                              quantity: quantity,
                              optionId: option?.id,
                            }
                          ],
                        })
                      }}>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>


      <div className="row d-flex align-items-center" style={{ padding: "15px 5px 5px 5px", minHeight: 90 }}>
        <div className='col-12 col-sm-2'>

        </div>
        <div className="d-flex flex-column col-10 pr-3 justify-content-start text-left">
          <span className="text-uppercase">{t("options.anythingelse")}</span>
          <textarea style={{ minHeight: 100 }} value={preStayContent?.optionsComment} className="form-control" onChange={(e) => {
            onChange({
              ...preStayContent,
              optionsComment: e.target.value,
            })
          }}></textarea>
        </div>
      </div>

    </div>
  </div>

}