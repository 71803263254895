import React from "react"

import {Search} from "components/front/Search/Search"

export const SearchRoute = (props: any) => {

  const {params} = props?.match
  const {query} = params

  return <div className="container">
    <Search query={query} />
  </div>
}