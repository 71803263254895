import {isFieldOnError} from "helpers/Apollo"
import React from "react"


type NumberInputProps = {
  id?: string,
  label: string,
  value: string | number,
  onChange: (data: any, v: number) => void
  errors: any,
}

export const NumberInput = (props: NumberInputProps) => {

  return <div className="input-group">
  <span className="input-group-text">{props?.label}</span>
  <input className='form-control' type="number" placeholder={props?.label} value={props?.value || ""} onChange={(e: any) => props?.onChange(e, Number(e.target.value))} />
  {isFieldOnError(props?.errors, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
</div>

}