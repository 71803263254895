import React from "react"
import { PostStayQuestion, PostStayQuestionTypeEnum } from 'model'
import { IconInfo, IconWarningSign, TextInput, Tooltip } from "@zipou/front_tools"


type PostStayQuestionFormProps = {
  postStayQuestion: PostStayQuestion,
  errors: any,
  onChange: (data: any) => void,
}


export const PostStayQuestionForm = ({ postStayQuestion, errors, onChange }: PostStayQuestionFormProps) => {


  const isChoice = postStayQuestion?.type === PostStayQuestionTypeEnum.TYPE_CHOICE || postStayQuestion?.type === PostStayQuestionTypeEnum.TYPE_CHOICE_RADIO

  return <div>
    <TextInput label="name" errors={errors} value={postStayQuestion?.name} id={'name'} onChange={(v) => {
      onChange({
        ...postStayQuestion,
        name: v
      })
    }} />
    <div className="input-group">
      <span className="input-group-text">Type de Question</span>
      <select className="form-control" value={postStayQuestion?.type || ""} onChange={(e: any) => {
        onChange({
          ...postStayQuestion,
          type: e.target.value
        })
      }}>
        <option value={""} disabled>Choisissez une valeur</option>
        {Object.keys(PostStayQuestionTypeEnum).map(v => {
          return <option key={`option_${v}`} value={v}>{v}</option>
        })}
      </select>
      {errors?.type && <span className="input-group-text"><span className="icon-warning-sign" /></span>}
    </div>
    <div className="input-group">
      <span className="input-group-text">Titre</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput label="labelFr" errors={errors} value={postStayQuestion?.labelFr} id={'labelFr'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            labelFr: v
          })
        }} />
        <TextInput label="labelEn" errors={errors} value={postStayQuestion?.labelEn} id={'labelEn'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            labelEn: v
          })
        }} />
      </div>
    </div>
    {isChoice && <div className="input-group">
      <span className="input-group-text">Choix</span>
      <div className="form-control" style={{ height: "100%" }}>
        {postStayQuestion?.choiceList?.map((choice: any, index: number) => {
          return <div className="input-group" key={`choice_${choice?.id}_${index}`}>
            <span className="input-group-text">#{index + 1}</span>
            <div className="form-control" style={{ height: "100%" }}>
              <TextInput value={choice?.id} label={`Valeur`} errors={errors?.choiceList && errors?.choiceList[index]} onChange={(v) => {
                onChange({
                  ...postStayQuestion,
                  choiceList: postStayQuestion?.choiceList.map((el: any, idx: number) => {
                    return index === idx ? {
                      ...el,
                      id: v
                    } : el
                  })
                })
              }} />
              <TextInput disabled id="label" value={choice?.label} label={`Label`} errors={errors?.choiceList[index]} onChange={(v) => {
                onChange({
                  ...postStayQuestion,
                  choiceList: postStayQuestion?.choiceList.map((el: any, idx: number) => {
                    return index === idx ? {
                      ...el,
                      labelFr: v
                    } : el
                  })
                })
              }} />
              <TextInput id="labelFr" value={choice?.labelFr} label={`Label Fr`} errors={errors?.choiceList[index]} onChange={(v) => {
                onChange({
                  ...postStayQuestion,
                  choiceList: postStayQuestion?.choiceList.map((el: any, idx: number) => {
                    return index === idx ? {
                      ...el,
                      labelFr: v
                    } : el
                  })
                })
              }} />
              <TextInput id="labelEn" value={choice?.labelEn} label={`Label En`} errors={errors?.choiceList[index]} onChange={(v) => {
                onChange({
                  ...postStayQuestion,
                  choiceList: postStayQuestion?.choiceList.map((el: any, idx: number) => {
                    return index === idx ? {
                      ...el,
                      labelEn: v
                    } : el
                  })
                })
              }} />
            </div>
          </div>
        })}
        <button className="btn btn-sm btn-dark" onClick={() => {
          onChange({
            ...postStayQuestion,
            choiceList: [
              ...(postStayQuestion.choiceList || []),
              {
                id: "",
                label: "",
              },
            ]
          })

        }}>Ajouter</button>
      </div>
      {errors?.choiceList && <span className="input-group-text">
        <IconWarningSign />
      </span>}
    </div>}
    <div className="input-group">
      <span className="input-group-text">Description</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput label="descFr" errors={errors} value={postStayQuestion?.descFr} id={'descFr'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            descFr: v
          })
        }} />
        <TextInput label="descEn" errors={errors} value={postStayQuestion?.descEn} id={'descEn'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            descEn: v
          })
        }} />
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">Conditions</span>
      <div className="form-control" style={{ height: "100%" }}>

        <TextInput label="Champ" errors={errors} value={postStayQuestion?.conditionKey} id={'conditionKey'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            conditionKey: v
          })
        }} />
        <TextInput label="Valeur pour afficher" errors={errors} value={postStayQuestion?.conditionValue} id={'conditionValue'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            conditionValue: v
          })
        }} />
        <TextInput label="Valeur d'alerte" errors={errors} value={postStayQuestion?.alertValue} id={'alertValue'} onChange={(v) => {
          onChange({
            ...postStayQuestion,
            alertValue: v
          })
        }} />
      </div>
      <span className="input-group-text">
        <Tooltip label={"Indiquer une condition pour afficher cette question. 'Champ' est le nom de la question d'ou vient la condition. 'Valeur pour afficher' indique pour quelle valeur il faut afficher cette question."}>
          <IconInfo />
        </Tooltip>
      </span>
    </div>
  </div>

}