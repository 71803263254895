import { useQuery } from "@apollo/react-hooks"
import { Template } from "model"
import React from "react"

import templateListQuery from "graphql/APP/Template/query/templateList"
import { SelectInput } from "@zipou/front_tools"

type TemplateSelectFieldProps = {
  id: string,
  label: string,
  hotelId: string,
  value: string,
  errors: string,
  onChange: (value: string) => void,
}


export const TemplateSelectField = ({ id, label, value, hotelId, errors, onChange }: TemplateSelectFieldProps) => {

  const { data } = useQuery<{ list: Template[] }>(templateListQuery, {
    variables: {
      hotelId,
    }
  })
  const templateList = data?.list


  return <>
    <SelectInput id={id} value={value} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label={label} errors={errors} onChange={v => {
      onChange(v)
    }} />

  </>

}