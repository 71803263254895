import gql from "graphql-tag";

export default gql`


  fragment CheckinDetailsFragment on Checkin {
    id
    name
    phone
    mail
    address
    zip
    city
    country
  }

`