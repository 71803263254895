import { Contact as ContactComponent } from "components/public/PHONE/Contact/Contact"

import { useQuery } from "@apollo/react-hooks"
import { PreStayAnswer } from "components/front/PMS/Stay/Pre/Answer/PreStayAnswer"
import { HotelContainer } from "components/public/PMS/Hotel/HotelContainer"
import preStayGetByIdAndPublicId from "graphql/PMS/Stay/Pre/query/preStayGetByIdAndPublicId"
import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { PreStay } from "model"


export const PreStayAnswerRoute = (props: RouteComponentProps<{ id: string, publicId: string }>) => {

  const { id, publicId } = props?.match?.params

  const { data, loading } = useQuery(preStayGetByIdAndPublicId, {
    variables: {
      id,
      publicId,
    }
  })

  const preStay: PreStay = data?.preStay

  const hotel = preStay?.Hotel
  const reservation = preStay?.Reservation
  const hasReservation = !!reservation
  const contact = preStay?.Contact
  const contactDefaultPhone = (contact?.phoneNumberValid?.length > 0 && contact?.phoneNumberValid[0]) || ""


  return <div>
    <HotelContainer hotel={hotel} loading={loading}>
      <PreStayAnswer preStay={preStay} reservation={reservation} hotel={hotel} />
      {(hasReservation) && <ContactComponent
        hotelId={hotel?.id}
        phoneNumber={contactDefaultPhone}
        countryCode={reservation?.Contact?.countryCode}
        extraVars={{ reservationId: reservation?.id }}
      />}
    </HotelContainer>
  </div>

}