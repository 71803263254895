import { ServiceInput } from "model"
import React from "react"
import { ServiceForm } from "../Form/ServiceForm"

type ServiceEditProps = {
  service: ServiceInput,
  onChange: (service: ServiceInput) => void,
  errors: any,
  hotelId: string,
}

export const ServiceEdit = ({ hotelId, service, errors, onChange }: ServiceEditProps) => {

  return <div>
    <ServiceForm service={service} errors={errors} onChange={onChange} />
  </div>


}