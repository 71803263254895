import gql from "graphql-tag";
import NotificationFragment from "../fragment/NotificationFragment";

export default gql`

  ${NotificationFragment}

  subscription notificationSubscription {
    notification: notificationSubscription {
      ...NotificationFragment
    }
  }

`