import gql from "graphql-tag";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";
import ReceiptInfoFragmentGql from "graphql/BILLING/ReceiptInfo/fragment/ReceiptInfoFragment.gql";

export default gql`

  ${HotelPublicFragment}
  ${ReceiptInfoFragmentGql}
  ${ContactListFragment}

  fragment PaymentFragment on Payment {
    id
    status
    hotelId
    contactId
    publicId
    publicSalt
    email
    mobilePhoneNumber
    amount
    notes
    isError
    lastError
    publicUrl
    publicPath
    stripeClientSecret
    captureType
    captureStatus
    captureValue
    creationDate
    captureDate
    provider
    hasValidEmail
    sendStatus
    Hotel {
      ...HotelPublicFragment
    }
    receiptInfo {
      ...ReceiptInfoFragment
    }
    Contact {
      ...ContactListFragment
    }


  }

`