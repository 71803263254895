import gql from "graphql-tag";
import FileFragment from "../fragment/FileFragment";

export default gql`

${FileFragment}

mutation fileAdd($input: FileInput!) {
  file: fileAdd(input: $input) {
    ...FileFragment
  }
}
 
`