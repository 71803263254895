import { useQuery } from "@apollo/react-hooks"
import { BooleanInput, SelectInput } from "@zipou/front_tools"
import { ConfirmationConfigDataTypeEnum, ConfirmationConfigInput, ConfirmationConfigTypeEnum, Template } from "model"
import React from "react"
import templateListQuery from "graphql/APP/Template/query/templateList"

type ConfirmationConfigFormProps = {
  hotelId: string,
  confirmationConfig: ConfirmationConfigInput,
  errors: any,
  onChange: (confirmationConfig: ConfirmationConfigInput) => void,
}


export const ConfirmationConfigForm = ({ confirmationConfig, errors, hotelId, onChange }: ConfirmationConfigFormProps) => {


  const { data } = useQuery<{ list: Template[] }>(templateListQuery, {
    variables: {
      hotelId,
    }
  })
  const templateList = data?.list



  return <div>

    <div className="input-group">
      <span className='input-group-text'>Copies des envois</span>
      <div className="form-control" style={{ height: "100%" }}>
        {confirmationConfig?.dest?.map((dest: string, index: number) => {
          return <div className="input-group" key={`confDest_${index}`}>
            <span className="input-group-text">Email</span>
            <input className="form-control" type="text" value={dest} onChange={(e: any) => {
              onChange({
                ...confirmationConfig,
                dest: confirmationConfig.dest?.map((el: any, id: number) => (id === index) ? e.target.value : el)
              })
            }} />
            <span className="input-group-text">
              <span className="icon-close" onClick={() => {
                onChange({
                  ...confirmationConfig,
                  dest: confirmationConfig?.dest?.filter((el: any, id: number) => id !== index)
                })
              }}></span>
            </span>

          </div>
        })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...confirmationConfig,
              dest: [
                ...(confirmationConfig?.dest ? confirmationConfig?.dest : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>

    <div className="input-group">
      <span className='input-group-text'>Type de Confirmation</span>
      <select className="form-control" value={confirmationConfig?.type || ""} onChange={(e: any) => {
        onChange({
          ...confirmationConfig,
          type: e.target.value,
        })
      }}>
        <option value="" disabled>Choisissez une valeur</option>
        {Object.values(ConfirmationConfigTypeEnum)?.map((el, index: number) => {
          return <option value={`${el}`} key={`conf_type_${index}`}>{el}</option>
        })}
      </select>
    </div>


    <div className="input-group">
      <span className="input-group-text">Données des confirmations</span>
      <div className="form-control" style={{ height: "100%" }}>
        <div className="d-flex align-items-center justify-content-start">
          {Object.keys(ConfirmationConfigDataTypeEnum).map(dataType => {
            const isChecked = !!confirmationConfig?.dataTypeActive?.find(el => el === dataType)
            const value = dataType as ConfirmationConfigDataTypeEnum
            return <div key={`form_${dataType}`} className="mr-2">
              <label htmlFor={dataType}><input id={dataType} type="checkbox" checked={isChecked} onChange={(el: React.ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...confirmationConfig,
                  dataTypeActive: el.target.checked ? [...(confirmationConfig.dataTypeActive || []), value] : confirmationConfig.dataTypeActive?.filter(el => el !== value)
                })
              }} />
                {dataType}
              </label>
            </div>
          })}
        </div>
      </div>
    </div>

    <div className="input-group">
      <span className='input-group-text'>Canaux</span>
      <div className="form-control" style={{ height: "100%" }}>
        <input type="checkbox" id="allowNull" checked={confirmationConfig?.channel?.includes(null)} onChange={e => {
          onChange({
            ...confirmationConfig,
            channel: e.target.checked ? [...(confirmationConfig?.channel || []), null] : confirmationConfig?.channel?.filter((el: any) => el !== null)
          })
        }} /><label htmlFor="allowNull">Autoriser un channel Vide</label>
        {confirmationConfig?.channel
          ?.filter((el: any) => el !== null)
          ?.map((dest: string, index: number) => {
            return <div className="input-group" key={`confDest_${index}`}>
              {/* <span className="input-group-text">Channel</span> */}
              <input className="form-control" type="text" value={dest} onChange={(e: any) => {
                onChange({
                  ...confirmationConfig,
                  channel: confirmationConfig?.channel?.map((el: any, id: number) => (id === index) ? e.target.value : el)
                })
              }} />
              <span className="input-group-text">
                <span className="icon-close" onClick={() => {
                  onChange({
                    ...confirmationConfig,
                    channel: confirmationConfig?.channel?.filter((el: any, id: number) => id !== index)
                  })
                }}></span>
              </span>

            </div>
          })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...confirmationConfig,
              channel: [
                ...(confirmationConfig?.channel ? confirmationConfig?.channel : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>


    <BooleanInput errors={errors} label={"demoMode"} value={confirmationConfig?.demoMode} onChange={(b) => onChange({
      ...confirmationConfig,
      demoMode: b,
    })} />

    <SelectInput id={"templateId"} value={confirmationConfig?.templateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template' errors={errors} onChange={v => {
      onChange({
        ...confirmationConfig,
        templateId: v,
      })
    }} />



  </div>


}

/**
 * 
 *   dest?: InputMaybe<Array<Scalars['String']>>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<ConfirmationConfigTypeEnum | `${ConfirmationConfigTypeEnum}`>;
  dataTypeActive?: InputMaybe<Array<ConfirmationConfigDataTypeEnum | `${ConfirmationConfigDataTypeEnum}`>>;
  demoMode?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['String']>;
 * 
 * 
 */