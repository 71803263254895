import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}

  fragment CorpFragment on Corp {
    id
    name
    Hotel {
      ...HotelDisplayFragment
    }
  }

`