import { SelectInput } from "@zipou/front_tools"
import { PmsConfigInput, PmsConfigTypeEnum } from "model"
import React from "react"
import { PmsConfigHotsoftForm } from "../Hotsoft/Form"

type PmsConfigFormProps = {
  pmsConfig: PmsConfigInput,
  errors: any,
  onChange: (input: PmsConfigInput) => void,
}

export const PmsConfigForm = ({ errors, pmsConfig, onChange }: PmsConfigFormProps) => {

  const isTypeHotsoft = pmsConfig?.type === PmsConfigTypeEnum.HOTSOFT

  return <div>

    <SelectInput isDefaultActive defaultLabel="Aucune" id="type" label="Type d'Interface" errors={errors} value={pmsConfig?.type || ""} choiceList={[{ id: PmsConfigTypeEnum.HOTSOFT, label: "HOTSOFT" }, { id: PmsConfigTypeEnum.DEMO, label: "DEMO" }]}
      onChange={(v) => {
        onChange({
          ...pmsConfig,
          type: v as PmsConfigTypeEnum,
        })
      }}
    />
    {isTypeHotsoft && <div className="input-group">

      <span className="input-group-text">Hotsoft</span>
      <div className="form-control" style={{ height: "100%" }}>

        <PmsConfigHotsoftForm errors={errors} hotsoftConfig={pmsConfig?.hotsoftConfig} onChange={hotsoftConfig => {
          onChange({
            ...pmsConfig, hotsoftConfig,
          })
        }} />

      </div>
    </div>


    }
  </div>
}