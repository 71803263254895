// import {SelectInput} from "components/common/Form/SelectInput";
// import {TextField} from "components/common/Form/TextField";
// import {TextInput} from "components/common/Form/TextInput";
import React, { useState } from "react"
// import EmailEditor from 'react-email-editor';
import { UnlayerForm } from "./UnlayerForm";
import { IconWarningSign, Modal, SelectInput, TextField, TextInput } from "@zipou/front_tools";
import { TemplateTypeEnum, TemplateInput } from "model";

type TemplateFormProps = {
  errors: any,
  template: TemplateInput,
  onChange: (data: any) => void,
}

export const TemplateForm = ({ errors, template, onChange }: TemplateFormProps) => {

  const [focusUnlayerTemplateFr, updateFocusUnlayerTemplateFr] = useState<boolean>()
  const [focusUnlayerTemplateEn, updateFocusUnlayerTemplateEn] = useState<boolean>()
  // const [focusDisplayImportFr, updateFocusDisplayImportFr] = useState<boolean>()
  // const [focusDisplayExportFr, updateFocusDisplayExportFr] = useState<boolean>()
  // const [focusDisplayImportEn, updateFocusDisplayImportEn] = useState<boolean>()
  // const [focusDisplayExportEn, updateFocusDisplayExportEn] = useState<boolean>()

  const isHtml = template?.type === TemplateTypeEnum.TYPE_HTML
  const isUnlayer = template?.type === TemplateTypeEnum.TYPE_UNLAYER

  return <div>
    {/* <Modal display={focusDisplayImportFr} onClose={() => updateFocusDisplayImportFr(false)}>
      <JSONImportForm onChange={(json) => {
        onChange({
          ...template,
          contentFrJSON: json,
        })
      }} />
    </Modal>
    <Modal display={focusDisplayExportFr} onClose={() => updateFocusDisplayExportFr(false)}>
      <div className="input-group">
        <span className="input-group-text">Exporter</span>
        <textarea className="form-control" disabled>
          {template?.contentFrJSON && JSON.stringify(template?.contentFrJSON)}
        </textarea>
        <ClickAndCopy value={template?.contentFrJSON && JSON.stringify(template?.contentFrJSON)} />
      </div>
    </Modal>
    <Modal display={focusDisplayImportEn} onClose={() => updateFocusDisplayImportEn(false)}>
      <JSONImportForm onChange={(json) => {
        onChange({
          ...template,
          contentEnJSON: json,
        })
      }} />
    </Modal>
    <Modal display={focusDisplayExportEn} onClose={() => updateFocusDisplayExportEn(false)}>
      <div className="input-group">
        <span className="input-group-text">Exporter</span>
        <textarea className="form-control" disabled>
          {template?.contentEnJSON && JSON.stringify(template?.contentEnJSON)}
        </textarea>
        <ClickAndCopy value={template?.contentEnJSON && JSON.stringify(template?.contentEnJSON)} />
      </div>
    </Modal> */}
    {focusUnlayerTemplateFr && <Modal display={focusUnlayerTemplateFr} size="xl" onClose={() => updateFocusUnlayerTemplateFr(false)}>
      <UnlayerForm errors={errors} contentJSON={template?.contentFrJSON} onChange={(html, json) => {
        onChange({
          ...template,
          contentFrHTML: html,
          contentFrJSON: json,
        })
      }} />
    </Modal>}
    {focusUnlayerTemplateEn && <Modal display={focusUnlayerTemplateEn} size="xl" onClose={() => updateFocusUnlayerTemplateEn(false)}>
      <UnlayerForm errors={errors} contentJSON={template?.contentEnJSON} onChange={(html, json) => {
        onChange({
          ...template,
          contentEnHTML: html,
          contentEnJSON: json,
        })
      }} />
    </Modal>}
    <TextInput errors={errors} value={template?.name || ""} label="Nom" id="name" onChange={(v) => {
      onChange({
        ...template,
        name: v,
      })
    }}
    />
    <SelectInput value={template?.type} disabled={false} choiceList={[{ id: TemplateTypeEnum.TYPE_UNLAYER, label: "Editeur" }, { id: TemplateTypeEnum.TYPE_HTML, label: "Importation HTML" }]} errors={errors} label="Type" id="type" onChange={(v) => {
      onChange({
        ...template,
        type: v,
      })
    }} />
    {isHtml && <div>
      <TextField label="HTML Version FR" isError={errors} value={template?.contentFrHTML} onChange={(html: string) => onChange({
        ...template,
        contentFrHTML: html,
        contentFrJSON: null,
      })} />
      <TextField label="HTML Version EN" isError={errors} value={template?.contentEnHTML} onChange={(html: string) => onChange({
        ...template,
        contentEnHTML: html,
        contentEnJSON: null,
      })} />
    </div>}
    {isUnlayer && <div>
      <div className="input-group">
        <span className="input-group-text">Version FR</span>
        <div className="form-control" style={{ height: '100%' }}>
          <button className="btn btn-sm btn-warning" onClick={() => updateFocusUnlayerTemplateFr(true)}>Modifier</button>
          {/* <button className="btn btn-sm btn-primary ml-2" onClick={() => updateFocusDisplayExportFr(true)}>Exporter</button>
          <button className="btn btn-sm btn-secondary ml-2" onClick={() => updateFocusDisplayImportFr(true)}>Importer</button> */}
        </div>
        {errors?.contentFrHTML && <span className="input-group-text">
          <IconWarningSign />
        </span>}
      </div>
      <div className="input-group">
        <span className="input-group-text">Version EN</span>
        <div className="form-control" style={{ height: '100%' }}>
          <button className="btn btn-sm btn-warning" onClick={() => updateFocusUnlayerTemplateEn(true)}>Modifier</button>
          {/* <button className="btn btn-sm btn-primary ml-2" onClick={() => updateFocusDisplayExportEn(true)}>Exporter</button>
          <button className="btn btn-sm btn-secondary ml-2" onClick={() => updateFocusDisplayImportEn(true)}>Importer</button> */}
        </div>
        {errors?.contentEnHTML && <span className="input-group-text">
          <IconWarningSign />
        </span>}
      </div>
    </div>}

    {/* {renderSubForm(template?.type)} */}
  </div>

}