import {PreStayList} from "components/front/PMS/Stay/Pre/List/PreStayList"
import React from "react"
import {RouteComponentProps} from "react-router-dom"

export const PreStayListRoute = (props: RouteComponentProps<{id: string}>) => {


  return <div>
    <h1>Pre Stay</h1>
    <PreStayList focusViewId={props?.match?.params?.id} />
  </div>
}