import React from 'react'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

export type StripeContainerProps = {
  stripePk: string,
  children: any,
}

export const StripeContainer = (props: StripeContainerProps) => {

  const {children} = props
  // const stripe_publishable = process.env.REACT_APP_STRIPE_PUBLISHABLE || "pk_test_AES9SKiCoemfCO3z4RDbKnql"
  const stripe_publishable = props?.stripePk
  const stripePromise = loadStripe(stripe_publishable);
  return (
    <Elements stripe={stripePromise}>
      <div>
        {children}
      </div>
    </Elements>
  )


}

// export class StripeContainerClass extends Component {

//   constructor(props) {
//     super(props)
//     this.state = {
//       stripe: null
//     }
//   }

//   componentDidMount() {
//     const {stripe_publishable} = Vars
//     if (window.Stripe) {
//       this.setState({stripe: window.Stripe(stripe_publishable)});
//     }
//     // else {
//     //   document.querySelector('#stripe-js').addEventListener('load', () => {
//     //     // Create Stripe instance once Stripe.js loads
//     //     this.setState({stripe: window.Stripe(stripe_publishable)});
//     //   });
//     // }
//   }


//   render() {
//     const {children} = this.props
//     const {stripe} = this.state

//     // console.log({stripe})

//     return (
//       <StripeProvider stripe={stripe}>
//         <Elements fonts={[
//           {cssSrc: "https://fonts.googleapis.com/css?family=Lato"}
//         ]}>
//           <div>
//           {children}
//           </div>
//         </Elements>
//       </StripeProvider>
//     )
//   }
// }
