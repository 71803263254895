import gql from "graphql-tag";
import ReservationConfirmationFragmentGql from "../../fragment/ReservationConfirmationFragment.gql";

export default gql`

  ${ReservationConfirmationFragmentGql}

  fragment ReservationGroupLinkConfirmationFragment on ReservationGroupLink {
    id
    groupId
    reservationId
    Reservation {
      ...ReservationConfirmationFragment
    }
  }

`