import gql from "graphql-tag";
import EventServiceFragment from "graphql/PLANNING/Event/fragment/EventServiceFragment";

export default gql`

  ${EventServiceFragment}

  mutation preStayAddEvent($id: String!, $publicId: String!, $input: EventPreStayInput!) {
    event: preStayAddEvent(id: $id, publicId: $publicId, input: $input) {
      ...EventServiceFragment
    }
  }

`