import gql from "graphql-tag";
import FileFragment from "graphql/APP/File/fragment/FileFragment";

export default gql`

  ${FileFragment}

  fragment RoomTypeFragment on RoomType {
    id
    type
    canBeSelectedInProposition
    labelFr
    labelEn
    descImgUrls
    imgPictureFileIds
    ImgFile {
      ...FileFragment
    }
    descCapacityFr
    descTextFr
    descCapacityEn
    descTextEn
  }

`