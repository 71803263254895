import React from "react"
import { OptionInput } from "model"
import { OptionForm } from "../Form/OptionForm"

type OptionEditProps = {
  hotelId: string,
  option: OptionInput,
  onChange: (option: OptionInput) => void,
  errors: any,
}


export const OptionEdit = ({ option, hotelId, onChange, errors }: OptionEditProps) => {

  return <div>
    <OptionForm open={true} option={option} errors={errors} hotelId={hotelId} onChange={onChange} />
  </div>
}