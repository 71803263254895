import gql from "graphql-tag";

export default gql`

  fragment ContactListFragment on Contact {
    id
    pmsId
    gender
    firstName
    lastName
    email
    type
    isFrench
    forceIsFrench
  }
`