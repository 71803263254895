// import {DateFormat} from "components/common/Format/DateFormat"
// import { PriceFormat } from "components/common/Format/PriceFormat"
import { PriceFormat } from "@zipou/front_tools"
import { userContext } from "context/User"
import { UserRoleEnum } from "model"
import React, { useContext } from "react"

type ReservationPricePackageListProps = {
  reservationPricePackageList: any[]
}

export const ReservationPricePackageList = (props: ReservationPricePackageListProps) => {

  const user = useContext(userContext)
  const hasDebug = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  return <div>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark  text-white">
          {hasDebug && <th className="text-white">Id</th>}
          <th className="text-white">Code</th>
          <th className="text-white">Label</th>
          <th className="text-white">Quantite</th>
          <th className="text-white">Montant</th>
        </tr>
      </thead>
      <tbody>
        {props?.reservationPricePackageList?.map((rPPackage: any) => {
          return <tr>
            {hasDebug && <td className="">{rPPackage?.id}</td>}
            <td className="">{rPPackage?.PLUCode}</td>
            <td className="">{rPPackage?.Option?.labelFr}</td>
            <td className="">{rPPackage?.quantity}</td>
            <td className=""><PriceFormat value={rPPackage?.ammountIncVat * 100} /></td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}