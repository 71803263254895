import gql from "graphql-tag";
import ContactSearchIndivFragment from "../fragment/ContactSearchIndivFragment";

export default gql`

  ${ContactSearchIndivFragment}

  query contactSearchByText($hotelId: String!, $text: String!) {
    list: contactSearchByText(hotelId: $hotelId, text: $text) {
      ...ContactSearchIndivFragment
    }
  }

`