import { PostStayDisplay } from "components/front/PMS/Stay/Post/Display/PostStayDisplay"
import { PostStayList } from "components/front/PMS/Stay/Post/List/PostStayList"
import React from "react"
import { RouteComponentProps } from "react-router-dom"

export const PostStayDisplayRoute = (props: RouteComponentProps<{ id: string }>) => {
  const { id } = props?.match?.params
  return <div>
    <PostStayDisplay postStayId={id} />
  </div>
}