import gql from "graphql-tag";
import HotelBillingFragment from "graphql/PMS/Hotel/fragment/HotelBillingFragment";
import ReceiptInfoFragmentGql from "graphql/BILLING/ReceiptInfo/fragment/ReceiptInfoFragment.gql";

export default gql`

  ${HotelBillingFragment}
  ${ReceiptInfoFragmentGql}

  fragment TokenPublicFragment on Token {
    id
    status
    hotelId
    publicId
    publicSalt
    contactId
    isError
    lastError
    provider
    creationDate
    captureDate
    sendStatus
    Options
    receiptInfo {
      ...ReceiptInfoFragment
    }
    Options
    Hotel {
      ...HotelBillingFragment
    }

  }

`