import gql from "graphql-tag";
import TemplateFragment from "../fragment/TemplateFragment";

export default gql`

  ${TemplateFragment}

  query templateList($hotelId: String!) {
    list: templateList(hotelId: $hotelId) {
      ...TemplateFragment
    }
  }

`