import gql from "graphql-tag";
import PropositionFragment from "../fragment/PropositionFragment";

export default gql`
  ${PropositionFragment}

  mutation propositionValid($id: String!, $input: PropositionValidInput!) {
    proposition: propositionValid(id: $id, input: $input) {
      ...PropositionFragment
    }
  }

`