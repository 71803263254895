import gql from "graphql-tag";
import UserConfigFragmentGql from "../fragment/UserConfigFragment.gql";

export default gql`

  ${UserConfigFragmentGql}

  query userConfigList {
    list: userConfigList {
      ...UserConfigFragment
    }
  }

`