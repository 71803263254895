import gql from "graphql-tag";

export default gql`

fragment NetworkConfigFragment on NetworkConfig  {
  id
  hostingConfigId
  token
}


`