import gql from "graphql-tag";
import PostStayPublicFragment from "../fragment/PostStayPublicFragment";

export default gql`

  ${PostStayPublicFragment}

  query postStayGetByIdAndPublicId($id: String!, $publicId: String!) {
    postStay: postStayGetByIdAndPublicId(id: $id, publicId: $publicId) {
      ...PostStayPublicFragment
    }
  }

`