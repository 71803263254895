import gql from "graphql-tag";
import FileFragment from "../fragment/FileFragment";

export default gql`

  ${FileFragment}

  query fileGet($id: String!) {
    file: fileGet(id: $id) {
      ...FileFragment
    }
  }
`