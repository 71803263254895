import { Option, PropositionRowRoomOptionInput, ReservationOptionQuantityTypeEnum } from "model"
// import { NumberInput } from "components/common/Form/NumberInput"
import { OptionSearch } from "components/front/PMS/Option/Search/OptionSearch"
import React, { useEffect } from "react"
import { DiscountInput } from "../Discount/DiscountInput"
import { useQuery } from "@apollo/react-hooks"
import optionGetGql from "graphql/PMS/Option/query/optionGet.gql"
import { NumberInput, SelectInput } from "@zipou/front_tools"


type PropositionRowOptionSelectProps = {
  hotelId: string,
  nbNights: number,
  nbChildren: number,
  nbAdults: number,
  nbSpecial: number,
  optionSelected: PropositionRowRoomOptionInput[],
  onChange: (d: PropositionRowRoomOptionInput[]) => void
}

export const PropositionRowOptionSelect = (props: PropositionRowOptionSelectProps) => {

  const onChange = (optionSelected: PropositionRowRoomOptionInput[]) => {
    props?.onChange(optionSelected)
  }

  const optionSelected = props?.optionSelected || []
  // const optionList = optionListGqlResponse?.data?.list
  const hasOptions = optionSelected?.length > 0

  return <div>
    <OptionSearch hotelId={props?.hotelId} onSelect={(option: Option) => {

      const alreadySelected = optionSelected.find(el => el.optionId === option?.id)
      if (!alreadySelected) {
        onChange([...(optionSelected || []), { quantity: null, quantityType: ReservationOptionQuantityTypeEnum.TYPE_PER_NIGHT, optionId: option?.id, amountIncVat: option?.amountIncVat }])
      }

    }} value={{}} />
    {hasOptions && <div className="input-group">
      <span className="input-group-text">Options Incluses</span>
      <div className="form-control" style={{ height: "100%" }}>
        {optionSelected?.map((rowRoomOption, idx) => {
          return <PropositionRowOptionSelectItem key={`rowOption_${idx}`} nbNights={props?.nbNights} nbAdults={props?.nbAdults} nbChildren={props?.nbChildren} nbSpecial={props?.nbSpecial} rowRoomOption={rowRoomOption} onChange={(option) => {
            onChange(optionSelected?.map((opt: PropositionRowRoomOptionInput) => (option.optionId === opt.optionId) ? option : opt))
          }}
            onDelete={() =>
              onChange(optionSelected?.filter((opt: PropositionRowRoomOptionInput) => (rowRoomOption.optionId !== opt.optionId)))
            }
          />
        })}
      </div>
    </div>}
  </div>
}


type PropositionRowOptionSelectItemProps = {
  rowRoomOption: PropositionRowRoomOptionInput,
  nbNights: number,
  nbChildren: number,
  nbAdults: number,
  nbSpecial: number,
  onChange: (data: PropositionRowRoomOptionInput) => void,
  onDelete: () => void,
}

export const PropositionRowOptionSelectItem = ({ rowRoomOption, nbAdults, nbNights, nbChildren, nbSpecial, onChange, onDelete }: PropositionRowOptionSelectItemProps) => {

  const { data } = useQuery<{ option: Option }>(optionGetGql, {
    variables: {
      id: rowRoomOption?.optionId,
    },
    skip: !rowRoomOption
  })

  const option = data?.option
  const optionChecked = true
  const isChecked = !!optionChecked
  const { isPerNight, isPerChildren, isPerAdult, isPerSpecial } = option || {}

  // const coef = (isPerNight ? nbNights : 1) * (((isPerAdult && nbAdults > 0) ? nbAdults : 0) + ((isPerChildren && nbChildren > 0) ? nbChildren : 0) + ((isPerSpecial && nbSpecial > 0) ? nbSpecial : 0))

  const hasManyNights = nbNights > 1

  // const hasCoef = !!(coef && coef > 0)
  const optionQuantity = rowRoomOption?.quantity
  const optionDiscount = rowRoomOption?.discount

  const optionAmountFormatted = option?.amountIncVat && Number(option?.amountIncVat / 100).toFixed(2)

  // useEffect(() => {
  //   if (optionQuantity === null && coef) {
  //     onChange({ ...rowRoomOption, quantity: coef })
  //   }
  // }, [optionQuantity])


  return <div className="card row-option" key={`option_${option?.id}`}>
    <div className="card-header bg-secondary text-white">
      <label htmlFor={`option_${option?.id}`}>{option?.labelFr}
        <span style={{ marginLeft: 5 }} className="badge badge-dark">{optionAmountFormatted} €</span>
        {isPerNight && <span style={{ marginLeft: 5 }} className="badge badge-info">Par nuit</span>}
        {isPerAdult && <span style={{ marginLeft: 5 }} className="badge badge-info">Par adultes</span>}
        {isPerChildren && <span style={{ marginLeft: 5 }} className="badge badge-info">Par enfants</span>}
        {isPerSpecial && <span style={{ marginLeft: 5 }} className="badge badge-info">Par special</span>}
      </label>
    </div>
    <div className={isChecked ? "collapse-container-option collapse-visible-option" : "collapse-container-option"}>
      <div className="card-body" >
        {/* {hasCoef && <div className="alert alert-warning">Quantité suggérée: {coef}</div>} */}

        {hasManyNights && <SelectInput label="Type de Quantité" id="quantityType" errors={{}} value={rowRoomOption.quantityType || ""} choiceList={[{ id: ReservationOptionQuantityTypeEnum.TYPE_TOTAL, label: "QUANTITE TOTALE" }, { id: ReservationOptionQuantityTypeEnum.TYPE_PER_NIGHT, label: "QUANTITE PAR NUIT" }]}
          onChange={(v) => {
            onChange({ ...rowRoomOption, quantityType: v as ReservationOptionQuantityTypeEnum })
          }}
        />}

        <NumberInput id="quantity" label="Quantité" value={`${optionQuantity}` || ""} onChange={(_, v) => {
          onChange({ ...rowRoomOption, quantity: v })
        }} errors={{}}
        />
        <DiscountInput label={`Réduction sur ${optionAmountFormatted} €`} discount={optionDiscount} onChange={(v) => {
          onChange({ ...rowRoomOption, discount: v })
        }} />
        <button className="btn btn-sm btn-danger" onClick={() => {
          onDelete()
        }}>
          <span className="icon-trash"></span> Supprimer
        </button>
      </div>
    </div>
  </div>


}