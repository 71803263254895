import gql from "graphql-tag";

export default gql`

  fragment PlanningFragment on Planning {
    id
    name
    hotelId
    rowList
    options
  }

`