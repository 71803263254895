import gql from "graphql-tag";

export default gql`

  fragment ContactDedupFragment on Contact {
    id
    pmsId
    firstName
    lastName
    gender
    email
    updateDate
    type
    forceIsFrench
    phone1
    phone2
  }
`