import React from "react"
// import {useTranslation} from "react-i18next"

import {useQuery, useSubscription} from "@apollo/react-hooks";
// import {Chat, TargetTypeEnum} from "components/common/CHAT/Chat";
// import {Clic2Call} from "components/public/PHONE/Clic2Call/Clic2Call";
import {Contact} from "components/public/PHONE/Contact/Contact";
import {HotelContainer} from "components/public/PMS/Hotel/HotelContainer";
import {PropositionPublicDetails} from "components/public/PMS/Proposition/PropositionPublicDetails";
import propositionGetByIdAndPublicIdGql from "graphql/PMS/Proposition/query/propositionGetByIdAndPublicId.gql";
import propositionUpdateGql from "graphql/PMS/Proposition/subscription/propositionUpdate.gql";


export const PropositionPublicDetailsRoute = (props: any) => {

  const {match} = props;
  const {params} = match || {}
  const {id, publicId} = params

  useSubscription(propositionUpdateGql, {
    variables: {
      id,
      publicId,
    },
    skip: !(id && publicId),
  })

  const {data, loading} = useQuery(propositionGetByIdAndPublicIdGql, {
    variables: {
      id,
      publicId,
    },
    skip: !(id && publicId)
  })


  const proposition = data?.proposition
  const contactDefaultPhone = (proposition?.Contact?.phoneNumberValid && proposition?.Contact?.phoneNumberValid > 0 && proposition?.Contact?.phoneNumberValid[0]) || ""

  return <HotelContainer hotel={proposition?.Hotel} loading={loading}>
      <PropositionPublicDetails proposition={proposition} />
    {proposition && <Contact
      phoneNumber={contactDefaultPhone}
      countryCode={proposition?.Contact?.countryCode}
      extraVars={{propositionId: proposition?.id}}
      hotelId={proposition?.Hotel?.id}
    />}
  </HotelContainer>
}