import { useMutation, useQuery } from "@apollo/react-hooks"
import { userContext } from "context/User"
import paymentSendGql from "graphql/BILLING/Payment/mutation/paymentSend.gql"
import paymentGetById from "graphql/BILLING/Payment/query/paymentGetById"
import { findToken } from "helpers/User"
import { getRootUrl } from "helpers/Utils"
import { Payment, PaymentSendStatusEnum, UserRoleEnum } from "model"
import React, { useContext } from "react"

type PaymentSendProps = {
  paymentId: string,
  onDone: () => void,
}


export const PaymentSend = ({ paymentId, onDone }: PaymentSendProps) => {

  const { data } = useQuery<{ payment: Payment }>(paymentGetById, {
    variables: {
      id: paymentId
    }
  })

  const [mutate] = useMutation(paymentSendGql)

  const onSend = () => {
    mutate({
      variables: {
        id: paymentId
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch(e => {
        console.log("e", e)
      })
  }


  const user = useContext(userContext)
  const hasDebugRole = user.roles.includes(UserRoleEnum.ROLE_DEBUG)
  const payment = data?.payment
  const paymentIsSendable = payment?.hasValidEmail && payment.sendStatus !== PaymentSendStatusEnum.SEND_STATUS_SENT
  const paymentIsReSendable = payment?.hasValidEmail && payment.sendStatus === PaymentSendStatusEnum.SEND_STATUS_SENT

  return <div className="card">
    {hasDebugRole && <div className="card-header">Identifiant: {payment?.id}</div>}
    {payment && <div>

      <div className="card-body">
        <iframe style={{ height: '80vh' }} width='100%' title='confirmation' src={`${getRootUrl()}payment/${payment?.id}?access_token=${findToken()}`}></iframe>
      </div>
      <div className="card-footer">
        {paymentIsSendable && <button className="btn btn-sm btn-success ml-1" onClick={() => onSend()}>Envoyer</button>}
        {paymentIsReSendable && <button className="btn btn-sm btn-warning ml-1" onClick={() => onSend()}>Renvoyer</button>}
        <button className="btn btn-sm btn-danger ml-1" onClick={() => onDone()}>Annuler</button>
        {/* {isConfirmationSendable(confirmation) && <button className="btn btn-success" onClick={() => props?.onSend()}>Envoyer</button>}
        {isConfirmationReSendable(confirmation) && <button className="btn btn-warning" onClick={() => props?.onSend()}>Renvoyer à nouveau</button>} */}
      </div>
    </div>}
  </div>


}