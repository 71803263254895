import gql from "graphql-tag";
import HotelEditFragment from "../fragment/HotelEditFragment";

export default gql`

${HotelEditFragment}

mutation hotelUpdate($id: String!, $input: HotelInput!) {
  hotel: hotelUpdate(id: $id, input: $input) {
    ...HotelEditFragment
  }
}

`