import { HotelTokenInput } from "model"
import React from "react"
import { HotelTokenForm } from "../Form/HotelTokenForm"
import { IconTrash } from "@zipou/front_tools"

type HotelTokenListFormProps = {
  hotelId: string,
  hotelTokenList: HotelTokenInput[],
  onChange: (hotelTokenList: HotelTokenInput[]) => void,
  errors: any,
}


export const HotelTokenListForm = ({ hotelTokenList, hotelId, onChange, errors }: HotelTokenListFormProps) => {


  return <div className="input-group">
    <span className="input-group-text">Token</span>
    <div className="form-control" style={{ height: "100%" }}>
      {hotelTokenList?.map((hotelToken, index) => {
        return <div className="input-group">
          <div className="form-control" style={{ height: "100%" }}>
            <HotelTokenForm hotelToken={hotelToken} errors={errors && errors[index]} onChange={(v) => {
              onChange(hotelTokenList?.map((el, i) => i === index ? v : el))
            }}
            />
          </div>
          <span className="input-group-text" onClick={() => onChange((hotelTokenList || [])?.filter((el, i) => i !== index))}>
            <IconTrash />
          </span>
        </div>
      })}
      <div>
        <button className="btn btn-sm btn-dark" onClick={() => onChange([...(hotelTokenList || []), {}])}>Ajouter</button>
      </div>
    </div>
  </div>


}