import gql from "graphql-tag";
import NotificationFragment from "../fragment/NotificationFragment";

export default gql`

  ${NotificationFragment}

  query notificationList{
    list: notificationList {
      ...NotificationFragment
    }
  }

`