import gql from "graphql-tag";
import CheckinFragmentGql from "../fragment/CheckinFragment.gql";

export default gql`

  ${CheckinFragmentGql}

  query checkinList($input: CheckinListInput!) {
    list: checkinList(input: $input) {
      ...CheckinFragment
    }
  }

`