import gql from "graphql-tag";
import SegmentationFragment from "../fragment/SegmentationFragment";

export default gql`

  ${SegmentationFragment}

  query segmentationGet($id: String!) {
    segmentation: segmentationGet(id: $id) {
      ...SegmentationFragment
    }
  }

`