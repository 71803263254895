import { useMutation } from "@apollo/react-hooks"
import { CardCvcElement, CardExpiryElement, CardNumberElement, PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { PriceFormat } from "components/common/Format/PriceFormat"
import paymentConfirmGql from "graphql/BILLING/Payment/mutation/paymentConfirm.gql"
import { Payment, PaymentStatusEnum, PaymentStripeOptions } from "model"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PaymentReceiptDisplay } from "../../Receipt/PaymentReceiptDisplay"
import { IconLock, Modal } from "@zipou/front_tools"

export type PaymentConfirmStripeProps = {
  payment: Payment,
  onCancel?: () => void,
}

export const PaymentConfirmStripe = (props: PaymentConfirmStripeProps) => {

  const urlParams = new URLSearchParams(window.location.search);
  const noWallet = urlParams.get('noWallet') === ""

  const [displayReceipt, updateDisplayReceipt] = useState(false)
  const [hasError, updateHasError] = useState(false)
  const [loading, updateLoading] = useState(false)
  const payment = props?.payment
  const stripe = useStripe();
  const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    // console.log("stripe", stripe)
    // console.log("payment", payment)
    if (stripe && payment) {
      const pr = stripe.paymentRequest({
        country: 'FR',
        currency: 'eur',
        total: {
          label: payment?.Hotel?.name,
          amount: payment.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        // console.log("result", result)
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, payment]);


  useEffect(() => {
    if (paymentRequest) {
      // console.log("SETTING PR CALLBACK")
      const clientSecret = (payment?.PaymentOptions as PaymentStripeOptions)?.stripeClientSecret

      paymentRequest.on('paymentmethod', async (ev: any) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              console.log("PAYMENT ERROR")
              console.log(error.message)
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
              console.log("PAYMENT OK")
            }
          } else {
            // The payment has succeeded.
            console.log("PAYMENT OK")
          }
        }
      });
    }

  }, [paymentRequest])


  const { t } = useTranslation('translation', { keyPrefix: 'billing' });

  const [mutate] = useMutation<{ payment: Payment }>(paymentConfirmGql)

  const onSubmit = (e: any) => {
    e.preventDefault()
    updateLoading(true)
    /** @ts-ignore */
    stripe?.createPaymentMethod({
      type: "card",
      /** @ts-ignore */
      card: elements.getElement(CardNumberElement),
      /** @ts-ignore */
      //eslint-disable-next-line
      card: elements.getElement(CardExpiryElement),
      /** @ts-ignore */
      //eslint-disable-next-line
      card: elements.getElement(CardCvcElement),
    })
      .then((stripeResponse: any) => {

        const pmId = stripeResponse?.paymentMethod?.id
        return mutate({
          variables: {
            id: payment?.id,
            options: {
              paymentMethodId: pmId,
            }
          }
        })
      })
      .then(async (response) => {
        const payment = response?.data?.payment
        switch (payment?.status) {

          case PaymentStatusEnum.STATUS_PENDING_CUSTOMER: {

            const paymentOptions = payment?.PaymentOptions as PaymentStripeOptions
            return stripe?.confirmCardPayment(paymentOptions.stripeClientSecret)

          }
          case PaymentStatusEnum.STATUS_DONE: {
            return
          }
          default: {
            console.log("STATUS", payment?.status)
            return
          }
        }

      })
      .then(() => {
        updateLoading(false)
        console.log("DONE")
      })
      .catch((error: any) => {
        updateHasError(true)
        updateLoading(false)
        console.log("ERROR", error)
      })
  }

  const isPreAuth = payment.captureType === "CAPTURE_TYPE_PRE_AUTH"

  const isDisabled = (payment?.status === "STATUS_DONE" || payment?.isError || loading || payment?.status === "STATUS_DELETED") || false
  const isSuccess = payment?.status === "STATUS_DONE"
  const isError = !!payment?.isError || hasError
  const isDeleted = payment?.status === "STATUS_DELETED"
  const Hotel = payment?.Hotel

  const displayWalletButton = (paymentRequest && !isDisabled && !noWallet)

  const canDisplayReceipt = isSuccess && payment?.receiptInfo

  return <div className="">
    <Modal display={displayReceipt} title={t("receipt")} onClose={() => updateDisplayReceipt(false)}>
      <PaymentReceiptDisplay publicId={payment?.publicId} salt={payment?.publicSalt} />
    </Modal>
    <form onSubmit={onSubmit}>
      <div className="">
        {isSuccess && <div className="alert alert-success">
          {!isPreAuth && <span>{t("payment_ok")}</span>}
          {isPreAuth && <span>{t("preauth_ok")}</span>}
          {canDisplayReceipt && <p>
            <button className="btn btn-sm btn-info mt-2" onClick={() => updateDisplayReceipt(true)}>{t("display_receipt")}</button>
          </p>}
        </div>}
        {(isError && !isSuccess) && <div>
          <div className="alert alert-danger">
            {t("error")}
            <div className="mt-2">
              <span>{Hotel?.name}</span><br />
              <span>{Hotel?.phoneNumber}</span>
            </div>
          </div>
        </div>
        }
        {isDeleted && <Modal display={true}>
          <div className="alert alert-danger">{t("link_expired")}</div>
          <div>
            <span>{Hotel?.name}</span><br />
            <span>{Hotel?.address}</span><br />
            <span>{Hotel?.phoneNumber}</span>
          </div>
        </Modal>}
        {/* <h1>Paiement</h1> */}
        {isPreAuth && !isSuccess && <div style={{ paddingTop: 5 }} >
          <div className="alert alert-warning">
            {t("preauth_warning")}.
          </div>
        </div>}
        <div style={{ paddingTop: 5 }} >
          <p>{t("confirm_resa_insert_info")}</p>
        </div>
        <div style={{ paddingTop: 5 }}>
          <span className="text-dark">{t("amount")}: <span className="" style={{ fontWeight: "bold" }}><PriceFormat value={payment?.amount} /></span></span>
        </div>
        <div className="row">
          <div className="card-number col-12">
            <div className="input-group">
              <span className="text-dark">{t("card_number")}</span>
              <CardNumberElement options={{ disabled: isDisabled }} className="stripe-cardnumber-element public-input" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="input-group">
              <span className="text-dark">{t("expiration")}</span>
              <CardExpiryElement options={{ disabled: isDisabled }} className="stripe-cardexpiry-element public-input" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="input-group">
              <span className="text-dark">{t("crypto")}</span>
              <CardCvcElement options={{ disabled: isDisabled }} className="stripe-cardcvc-element public-input" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6" style={{ textAlign: "left" }}>
            <div className="input-group">
              <button className="btn btn-sm btn-dark" type="submit" disabled={isDisabled}>{loading && <span className="icon-loading"> </span>}{t("confirm")}</button>
            </div>
          </div>
          <div className="col-12 col-sm-6" style={{ textAlign: "right" }}>
            <div className="input-group">
              <p><IconLock /><span className="ml-2" style={{ fontSize: 12 }}>{t("secure")}</span></p>
            </div>
          </div>
        </div>
        <div className="row">
          {displayWalletButton && <div className="col-12 col-sm-4 d-flex align-items-center pt-2 pt-sm-0">
            <div className="align-items-center" style={{ flexBasis: '100%' }}>
              <PaymentRequestButtonElement options={{ paymentRequest, style: { paymentRequestButton: { height: "35px" } } }} />
            </div>
          </div>}
        </div>
      </div >
    </form >
  </div >

}