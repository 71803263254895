import { useMutation } from "@apollo/react-hooks"
import { PriceInput } from "components/common/Form/PriceInput"
import paymentRefundGql from "graphql/BILLING/Payment/mutation/paymentRefund.gql"
import { isFieldOnError } from "helpers/Apollo"
import { Payment } from "model"
import React, { useState } from "react"

type PaymentRefundConfirmProps = {
  payment: any,
  onClose: () => void
}

export const PaymentRefundConfirm = (props: PaymentRefundConfirmProps) => {

  const payment: Payment = props?.payment
  const amount = payment?.captureValue || payment.amount
  const [captureValue, updateCaptureValue] = useState(amount)
  const [error, updateError] = useState(null)

  const [mutate] = useMutation(paymentRefundGql)

  const onRefund = () => {
    return mutate({
      variables: {
        id: props?.payment.id,
        amount: captureValue,
      }
    })
      .then(() => props?.onClose())
      .catch((e: any) => {
        console.log("E", e)
        updateError(e)

      })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    onRefund()
  }

  const hasError = !!error
  return <div className="card">
    <form onSubmit={onSubmit}>
      <h1>Rembourser le paiement</h1>
      {hasError && <div className="alert alert-danger">{error?.message}</div>}
      <div style={{ fontSize: 14 }}>Attention, vous ne pouvez rembourser le paiement qu'une seule fois.</div>
      <span style={{ fontSize: 14 }}>Le montant du remboursement peut-être inférieur ou égal au montant initial.</span>
      <div className="card-body">
        <PriceInput isError={isFieldOnError(error, "amount")} disabled={false} label="Montant" value={captureValue} onChange={(value: number) => {
          updateCaptureValue(value)
        }} />
      </div>
      <div className="card-footer">
        <button className="btn btn-dark" type="submit">Valider</button>
        <button className="btn btn-danger" style={{ marginLeft: 5 }} onClick={() => props?.onClose()}>Annuler</button>
      </div>
    </form>
  </div>
}