import gql from "graphql-tag";
import ReservationFragment from "../fragment/ReservationFragment";

export default gql`

  ${ReservationFragment}

  query reservationGetByFilters($input: ReservationFilterInput!, $order: OrderInput!, $cursor: CursorInput!) {
    reservationGetByFilters(input: $input, order: $order, cursor: $cursor) {
      reservationList: nodes {
        ...ReservationFragment
      }
      cursor {
        page
        hasMore
        totalPages
      }
    }
  }


`