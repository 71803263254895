import gql from "graphql-tag";
import ReservationGroupLinkConfirmationFragment from "../../GroupLink/fragment/ReservationGroupLinkConfirmationFragment";

export default gql`

  ${ReservationGroupLinkConfirmationFragment}

  fragment ReservationGroupConfirmationListFragment on ReservationGroup {
    id
    ReservationGroupLink {
      ...ReservationGroupLinkConfirmationFragment
    }
  }

`