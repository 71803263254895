import { PriceRateCancelConditionInput } from "model"
import React from "react"
import { PriceRateCancelConditionForm } from "../Form/PriceRateCancelConditionForm"

type PriceRateCancelConditionEditProps = {
  priceRateCancelCondition: PriceRateCancelConditionInput,
  onChange: (priceRateCancelCondition: PriceRateCancelConditionInput) => void,
  errors: any,
}

export const PriceRateCancelConditionEdit = ({ priceRateCancelCondition, onChange, errors }: PriceRateCancelConditionEditProps) => {
  return <div>
    <PriceRateCancelConditionForm priceRateCancelCondition={priceRateCancelCondition} errors={errors} onChange={onChange} />
  </div>
}