import gql from "graphql-tag";
import PaymentFragment from "graphql/BILLING/Payment/fragment/PaymentFragment";
import HotelPropositionFragment from "graphql/PMS/Hotel/fragment/HotelPropositionFragment";

// import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";
import PropositionRowFragmentGql from "../Row/fragment/PropositionRowFragment.gql";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";
import PropositionPaymentPublicFragmentGql from "../Payment/fragment/PropositionPaymentPublicFragment.gql";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";

export default gql`

  ${HotelPropositionFragment}
  ${PropositionRowFragmentGql}
  ${PropositionPaymentPublicFragmentGql}
  ${ContactListFragment}

  fragment PropositionFragment on Proposition {
    id
    publicId
    hotelId
    status
    pushPms
    pushStatus
    sentStatus
    rowSelectedId
    status
    contactId
    nbRooms
    nbAdults
    nbChildren
    nbSpecial
    isExpired
    expirationDate
    comment
    rowsHaveSameDates
    Contact {
      ...ContactListFragment
    }
    Hotel {
      ...HotelPropositionFragment
    }
    PropositionPayment {
      ...PropositionPaymentPublicFragment
    }
    propositionRow {
      ...PropositionRowFragment
    }
  }

`