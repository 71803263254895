import React, { useContext, useState } from "react"
import { CorpForm } from "../Form/CorpForm"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { Corp, CorpInput, UserRoleEnum } from "model"
import corpGetGql from "graphql/APP/Corp/query/corpGet.gql"
import corpEditGql from "graphql/APP/Corp/mutation/corpEdit.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import corpListGql from "graphql/APP/Corp/query/corpList.gql"
import { userContext } from "context/User"

type CorpEditProps = {
  corpId: string,
  onDone: () => void,
}

export const CorpEdit = ({ corpId, onDone }: CorpEditProps) => {


  const [corp, updateCorp] = useState<CorpInput>()
  const [errors, updateErrors] = useState<any>()

  const user = useContext(userContext)
  const hasDebug = user?.roles.includes(UserRoleEnum.ROLE_DEBUG)

  const [mutate] = useMutation(corpEditGql, {
    refetchQueries: [{
      query: corpListGql,
    }]
  })

  const { data } = useQuery<{ corp: Corp }>(corpGetGql, {
    variables: {
      id: corpId,
    },
    skip: !corpId,
    onCompleted: (data) => {
      updateCorp({
        name: data?.corp?.name,
        hotelIdList: data?.corp?.Hotel?.map((h) => h.id) || [],
      })
    }
  })

  const onChange = (corp: CorpInput) => {
    updateCorp(corp)
  }

  const onReset = () => {
    updateCorp({
      name: data?.corp?.name,
      hotelIdList: data?.corp?.Hotel?.map((h) => h.id) || [],
    })
  }

  const onSave = () => {
    mutate({
      variables: {
        id: corpId,
        input: corp
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div>
    {hasDebug && <div className="input-group">
      <span className="input-group-text">Id</span>
      <input className="form-control" disabled value={corpId} />
    </div>}
    <CorpForm corp={corp} onChange={onChange} errors={errors} />
    <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => onSave()}>Enregister</button>
      <button className="btn btn-danger btn-sm ml-1" onClick={() => onReset()}>Annuler</button>
    </div>
  </div>

}
