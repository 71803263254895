import React from "react"
import { PostStayResponseStatusEnum } from "model"

export type PostStayResponseStatusProps = {
  status: PostStayResponseStatusEnum
}

export const PostStayResponseStatus = ({ status }: PostStayResponseStatusProps) => {


  switch (status) {
    case PostStayResponseStatusEnum.POST_STAY_RESPONSE_STATUS_TO_ANSWER: {
      return <span className="badge badge-sm badge-warning">NON REPONDU</span>
    }

    case PostStayResponseStatusEnum.POST_STAY_RESPONSE_STATUS_ANSWERED: {
      return <span className="badge badge-sm badge-success">REPONDU</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{status}</span>
    }

  }


}