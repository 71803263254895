import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${CursorFragment}
  ${PaymentFragment}

  query paymentList($cursor: CursorInput!, $input: PaymentListInput!) {
    list: paymentList(cursor: $cursor, input: $input) {
      input
      cursor {
        ...CursorFragment
      }
      nodes {
        ...PaymentFragment
      }
    }
  }
`