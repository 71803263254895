import gql from "graphql-tag";

export default gql`

  fragment HotelTokenFragment on HotelToken {
    id
    token
    label
    expiration
    status
  }

`