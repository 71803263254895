import gql from "graphql-tag";
import PostStayGroupItemFragment from "../Item/PostStayGroupItemFragment";
import PostStayGroupItemStatsFragment from "../Item/PostStayGroupItemStatsFragment";

export default gql`

  ${PostStayGroupItemStatsFragment}

  fragment PostStayGroupStatsFragment on PostStayGroup {
    id
    priority
    labelFr
    descFr
    labelEn
    descEn
    PostStayGroupItem {
      ...PostStayGroupItemStatsFragment
    }
  }

`