import React, { useState } from "react"


import './style.scss'
import { isFieldOnError } from "helpers/Apollo"
import { BooleanInput } from "components/common/Form/BooleanInput"
import { TextInput } from "components/common/Form/TextInput"
import { RoomType, RoomTypeInput } from "model"
import { IconTrash, Modal, TextField } from "@zipou/front_tools"
import { FileEdit } from "components/front/APP/File/Edit/FileEdit"
import { FileAdd } from "components/front/APP/File/Add/FileAdd"
import { FileDisplay } from "components/front/APP/File/Display/FileDisplay"

type RoomTypeFormProps = {
  roomType?: RoomTypeInput,
  hotelId: string,
  errors: any,
  onChange: (room: RoomTypeInput) => void
}

export const RoomTypeForm = ({ hotelId, roomType, errors, onChange }: RoomTypeFormProps) => {

  const [focusFileAdd, updateFocusFileAdd] = useState<boolean>(false)

  const hasErrors = errors && Object.keys(errors).length > 0

  return <div className="card room-type-container">
    <div className="card-body">
      <TextInput value={roomType?.type} errors={errors?.type} label="Type" onChange={(e, v) => {
        onChange({
          ...roomType,
          type: v,
        })
      }} />

      <TextInput value={roomType?.labelFr} errors={errors?.labelFr} label="Libellé Français" onChange={(e, v) => {
        onChange({
          ...roomType,
          labelFr: v,
        })
      }} />

      <TextInput value={roomType?.labelEn} errors={errors?.labelEn} label="Libellé Anglais" onChange={(e, v) => {
        onChange({
          ...roomType,
          labelEn: v,
        })
      }} />

      <BooleanInput value={roomType?.canBeSelectedInProposition} label={"Proposition ?"} errors={errors} onChange={(value: boolean) => {
        onChange({
          ...roomType,
          canBeSelectedInProposition: value,
        })
      }} />
      <div className="input-group">
        <span className='input-group-text'>Images Locales</span>
        <div className="form-control" style={{ height: "100%" }}>
          <Modal title="Ajouter un fichier" display={focusFileAdd} onClose={() => updateFocusFileAdd(false)}>
            <FileAdd hotelId={hotelId} onDone={(file) => {
              onChange({
                ...roomType,
                imgPictureFileIds: [...(roomType?.imgPictureFileIds || []), file?.id]
              })
              updateFocusFileAdd(false)
            }} />
          </Modal>
          {roomType?.imgPictureFileIds?.map((id: string, index: number) => {
            return <div className="input-group">
              <div className="form-control" style={{ height: "100%" }} key={`descImgUrl_${index}`}>
                <FileDisplay id={id} displayPreview />
              </div>
              <span className="input-group-text">
                <button className="btn btn-dark btn-sm" onClick={() => onChange({
                  ...roomType,
                  imgPictureFileIds: roomType?.imgPictureFileIds?.filter((el) => el !== id)
                })}>
                  <IconTrash />
                </button>
              </span>
            </div>

          })}
          <div className="">
            <button className="btn btn-dark btn-sm" onClick={() => {
              updateFocusFileAdd(true)
            }}>Ajouter</button>
          </div>
        </div>
      </div>
      <div className="input-group">
        <span className='input-group-text'>Images</span>
        <div className="form-control" style={{ height: "100%" }}>
          {roomType?.descImgUrls?.map((descImgUrl: string, index: number) => {
            return <div className="input-group" key={`descImgUrl_${index}`}>
              <span className="input-group-text">Url</span>
              <input className="form-control" type="text" value={descImgUrl} onChange={(e: any) => {
                onChange({
                  ...roomType,
                  descImgUrls: roomType?.descImgUrls?.map((el: any, id: number) => (id === index) ? e.target.value : el)
                })
              }} />
              <span className="input-group-text">
                <span className="icon-close" onClick={() => {
                  onChange({
                    ...roomType,
                    descImgUrls: roomType?.descImgUrls?.filter((el: any, id: number) => id !== index)
                  })
                }}></span>
              </span>

            </div>
          })}
          <div className="">
            <button className="btn btn-dark btn-sm" onClick={() => {
              onChange({
                ...roomType,
                descImgUrls: [
                  ...(roomType?.descImgUrls || []),
                  ""
                ],
              })
            }}>Ajouter</button>
          </div>
        </div>
      </div>

      <div className="input-group">
        <span className="input-group-text">Description FR</span>
        <div className="form-control" style={{ height: "100%" }}>
          <TextField isError={isFieldOnError(errors, "descTextFr")} value={roomType?.descTextFr || ""} label={"Principale"} onChange={(value) => {
            onChange({
              ...roomType,
              descTextFr: value,
            })
          }} />
          <TextField isError={isFieldOnError(errors, "descCapacityFr")} value={roomType?.descCapacityFr || ""} label={"Secondaire"} onChange={(value) => {
            onChange({
              ...roomType,
              descCapacityFr: value,
            })
          }} />

        </div>
      </div>
      <div className="input-group">
        <span className="input-group-text">Description EN</span>
        <div className="form-control" style={{ height: "100%" }}>
          <TextField isError={isFieldOnError(errors, "descTextEn")} value={roomType?.descTextEn || ""} label={"Principale"} onChange={(value) => {
            onChange({
              ...roomType,
              descTextEn: value,
            })
          }} />
          <TextField isError={isFieldOnError(errors, "descCapacityEn")} value={roomType?.descCapacityEn || ""} label={"Secondaire"} onChange={(value) => {
            onChange({
              ...roomType,
              descCapacityEn: value,
            })
          }} />
        </div>
      </div>
    </div>
  </div>
}

/*

descImgUrl: string
descCapacityFr: string
descTextFr: string
descCapacityEn: string
descTextEn: string


*/