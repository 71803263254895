// import {TemplateList} from "components/front/COM/Template/List/TemplateList"
import {URL_BACKOFFICE_TEMPLATE_ADD_ROUTE} from "constant/url"
import React from "react"
import {Link} from "react-router-dom"

export const TemplateListRoute = () => {
  return <div>
    {/* <TemplateList /> */}
    <div>
      <Link to={URL_BACKOFFICE_TEMPLATE_ADD_ROUTE}><button className="btn btn-info">Ajouter</button></Link>
    </div>

  </div>
}