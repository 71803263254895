import {useQuery} from "@apollo/react-hooks"
import reservationListByCustomerIdGql from "graphql/PMS/Reservation/query/reservationListByCustomerId.gql"
import {Reservation} from "model"
import React from "react"
import {ReservationList} from "./ReservationList"

type ReservationListByCustomerIdProps = {
  customerId: string,
}

export const ReservationListByCustomerId = (props: ReservationListByCustomerIdProps) => {

  const {data, loading} = useQuery(reservationListByCustomerIdGql, {
    variables: {
      customerId: props?.customerId,
    }
  })

  // const reservationList = data?.list
  const reservationList = [...data?.list || []]?.sort((el1: Reservation, el2: Reservation) => el1?.arrivalDate < el2?.arrivalDate ? 1 : -1 )


  return <div>
    {loading && <div>
      <span className="icon-loading"></span>
    </div>}
    {!loading && <ReservationList list={reservationList} />}
  </div>
}