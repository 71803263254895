import gql from "graphql-tag";

export default gql`

  fragment MessageFragment on Message {
    id
    senderId
    date
    content {
      type
      value
      options
    }
  }

`