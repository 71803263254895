import gql from "graphql-tag";
import ChannelMessageFragmentGql from "../fragment/ChannelMessageFragment.gql";

export default gql`

  ${ChannelMessageFragmentGql}

  query channelGet($id: String!, $clientId: String!, $channelId: String!) {
    channel: channelGet(id: $id, clientId: $clientId, channelId: $channelId) {
      ...ChannelMessageFragment
    }
  }

`