import { IconEdit, Modal } from "@zipou/front_tools"
import { hotelContext } from "context/hotelContext"
import { Room, RoomInput } from "model"
import React, { useContext, useState } from "react"
import { RoomForm } from "../Form/RoomForm"

type RoomListProps = {
  roomList: RoomInput[],
  onChange: (roomList: RoomInput[]) => void,
  errors: any,
}


export const RoomList = ({ roomList, onChange, errors }: RoomListProps) => {


  const [focusEdit, updateFocusEdit] = useState<RoomInput>()

  const hotel = useContext(hotelContext)
  const RoomType = hotel?.RoomType
  const getRoomType = (typeId: string) => RoomType?.find((type => type?.id === typeId))

  return <div>
    {!!focusEdit && <Modal display title="Editer une chambre" onClose={() => updateFocusEdit(null)}>
      <RoomForm room={roomList?.find(r => r.id === focusEdit?.id)} errors={errors} onChange={(room => {
        onChange(roomList?.map((r) => r.id === focusEdit?.id ? room : r))
      })} />
    </Modal>}
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">
            Numéro
          </th>
          <th className="bg-dark text-white">
            Etage
          </th>
          <th className="bg-dark text-white">
            Type
          </th>
          <th className="bg-dark text-white">
            Actions
          </th>

        </tr>
      </thead>
      <tbody>
        {roomList?.map((room, index) => {
          return <tr key={`room_${room?.id}_${index}`}>
            <td>
              {room?.number}
            </td>
            <td>
              {room?.floor}
            </td>
            <td>
              {getRoomType(room?.roomTypeId)?.labelFr}
            </td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusEdit(room)}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}