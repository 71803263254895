import {DateFormat} from "components/common/Format/DateFormat"
import React from "react"


type ExpirationStatusProps = {
  date: Date,
}

export const ExpirationStatus = (props: ExpirationStatusProps) => {

  const now = Date.now();
  const time = props?.date.getTime();

  const before = time - now
  const daysBefore = (before / 24 / 3600 / 1000)


  switch (true) {
    case daysBefore <= 0: {
      return <span className="badge badge-dark">
        <DateFormat value={props?.date} />
      </span>
    }
    case daysBefore < 2: {
      return <span className="badge badge-danger">
        <DateFormat value={props?.date} />
      </span>
    }
    case daysBefore >= 2 && daysBefore < 10: {
      return <span className="badge badge-warning">
        <DateFormat value={props?.date} />
      </span>
    }

    case daysBefore >= 10: {
      return <span className="badge badge-success">
        {/* NB Jours: {daysBefore} */}
        <DateFormat value={props?.date} />
      </span>
    }
  }


  return <span>
    <span className="badge badge-info">
      <DateFormat value={props?.date} />
    </span>
  </span>
}