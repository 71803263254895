import gql from "graphql-tag";

export default gql`

fragment ConfirmationDataFragment on ConfirmationData {
  hasParking
  arrhesAmountInCents
  comment
  parking
  forcedContactId
}

`