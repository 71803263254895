import gql from "graphql-tag";
import SeasonalityFragment from "../fragment/SeasonalityFragment";

export default gql`

${SeasonalityFragment}

query seasonalityList($hotelId: String!) {
  list: seasonalityList(hotelId: $hotelId) {
    ...SeasonalityFragment
  }
}
`