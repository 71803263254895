import { Segmentation, SegmentationSyncStatus } from 'model'
import React from 'react'

type SegmentationStatusProps = {
  segmentation: Segmentation,
}

export const SegmentationStatus = (props: SegmentationStatusProps) => {

  const segmentation = props?.segmentation

  switch (segmentation?.status) {

    default: {
      return <span className="badge badge-dark">{segmentation?.status}</span>
    }

    case SegmentationSyncStatus.TODO: {
      return <span className="badge badge-info">A FAIRE</span>
    }

    case SegmentationSyncStatus.SYNCING: {
      return <span>
        <span className="badge badge-warning text-dark"><span className="icon-loading" />SYNCHRO EN COURS</span>
        <div className="progress">
          <div className="progress-bar bg-warning  progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${segmentation?.progress}%` }}></div>
        </div>
      </span>
    }

    case SegmentationSyncStatus.SYNCED: {
      return <span className="badge badge-success">SYNCHRONISE</span>
    }

    case SegmentationSyncStatus.STOPPED: {
      return <span className="badge badge-secondary">STOPPED</span>
    }

    case SegmentationSyncStatus.ERROR: {
      return <span className="badge badge-danger">ERREUR</span>
    }
  }
}