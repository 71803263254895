import gql from "graphql-tag";
import PaymentPublicFragment from "graphql/BILLING/Payment/fragment/PaymentPublicFragment";
import ContactPublicFragment from "graphql/PMS/Contact/fragment/ContactPublicFragment";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";
import PriceRateTypePublicFragmentGql from "graphql/PMS/PriceRate/Type/fragment/PriceRateTypePublicFragment.gql";
import PropositionPaymentPublicFragmentGql from "../Payment/fragment/PropositionPaymentPublicFragment.gql";
import RoomTypeFragmentGql from "graphql/PMS/Room/Type/fragment/RoomTypeFragment.gql";

export default gql`

${HotelPublicFragment}
${ContactPublicFragment}
${PropositionPaymentPublicFragmentGql}
${OptionFragmentGql}
${PriceRateTypePublicFragmentGql}
${RoomTypeFragmentGql}
${PaymentPublicFragment}

fragment PropositionPublicFragment on Proposition {
  id
  publicId
  status
  rowSelectedId
  sentStatus
  expirationDate
  isExpired
  nbRooms
  nbChildren
  nbAdults
  nbSpecial
  status
  comment
  IsPaymentNeededToConfirm
  rowsHaveSameDates
  PaymentActive {
    ...PaymentPublicFragment
  }
  PropositionPayment {
    ...PropositionPaymentPublicFragment
  }
  propositionRow {
    id
    comment
    arrivalDate
    departureDate
    nbNights
    hasSameDates
    totalIncTax
    paymentCondition
    totalToValid
    total {
      total
      hostingTotal
      extraTotal
      totalFull
      total
    }
    roomList {
      StayDates {
        arrivalDate
        departureDate
      }
      DefaultRateType {
        id
        labelFr
        labelEn
        descFr
        descEn
      }
      hasDelogement
      roomIndex
      isUpgrade
      Option {
        quantity
        quantityType
        discount {
          percent
          forced
        }
        Option {
          ...OptionFragment
        }
      }
      discount {
        percent
        forced
      }
      itemList {
        roomId
        date
        dateStamp
        rateId
        PriceRate {
          id
          valueIncTax
          PriceRateType {
            ...PriceRateTypePublicFragment
          }
        }
        Room {
          id
          RoomType {
            ...RoomTypeFragment
          }
        }
        priceRateList {
          id
          typeId
          code
          roomType
          valueIncTax
          valueExcTax
          PriceRateType {
            ...PriceRateTypePublicFragment
          }
        }
      }
    }
  }
  Contact {
    ...ContactPublicFragment
  }
  Hotel {
    ...HotelPublicFragment
  }
}

`