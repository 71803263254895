import React from "react"

import "./style.scss"

type WidgetProps = {
  type: string
  value: string
  label: string
}
export const Widget = (props: WidgetProps) => {

  return <div className="widget-container">
    <div className={`card card-${props?.type} border-dark`}>
      <div className="card-header bg-dark  text-white">{props?.label}</div>
      <div className="card-body">{props?.value}</div>
    </div>
  </div>

}