import {createContext} from "react"

export const alertContext = createContext({
  message: null,
  type: "",
  duration: 2000,
  isVisible: false,
  setVisible: (e: boolean) => {},
  setError: (e: any) => {},
  setMessage: (e: any, onClose?: () => void) => {},
})