import { useQuery } from "@apollo/react-hooks"
import { PhoneConfigModule } from "@zipou/phone_front"
import hotelGet from "graphql/PMS/Hotel/query/hotelGet"
import { Hotel } from "model"
import React from "react"

type PhoneConfigEditProps = {
  hotelId: string,
}

export const PhoneConfigEdit = ({ hotelId }: PhoneConfigEditProps) => {

  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId
  })


  const hotel = data?.hotel
  const hasCreds = hotel?.PhoneConfig?.phoneConfigId && hotel?.PhoneConfig?.token
  return <div>
    {hasCreds && <PhoneConfigModule
      phoneConfigId={hotel?.PhoneConfig?.phoneConfigId}
      token={hotel?.PhoneConfig?.token}
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
    />}
    {!hasCreds && <div className="alert alert-danger">Aucune configuration trouvée</div>}
  </div>
}