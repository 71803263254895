import gql from "graphql-tag";
import SegmentationFragment from "../fragment/SegmentationFragment";

export default gql`

  ${SegmentationFragment}

  subscription segmentationUpdate {
    segmentationUpdate {
      ...SegmentationFragment
    }
  }
`