export type JoiError<T> = { [K in keyof T]: boolean }

export const Loop = (nbIteration: number) => {
  return Array.from(Array(Number(nbIteration)).keys())
}

export const capitalize = (string: String) => {
  return string && string?.charAt(0).toUpperCase() + string?.slice(1)?.toLowerCase();
}

export const getRootUrl = () => {
  return process.env.NODE_ENV === "development" ? `${window.location.protocol}//localhost:4500${process.env.REACT_APP_API_ENDPOINT}` : `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_API_ENDPOINT}`
}