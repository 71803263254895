import gql from "graphql-tag";

export default gql`

  mutation targetRegister($input: TargetRegisterInput!) {
    target: targetRegister(input: $input) {
      id
      clientId
      channelList {
        id
      }
    }
  }

`