import gql from "graphql-tag";
import NotificationFragment from "../fragment/NotificationFragment";

export default gql`

${NotificationFragment}

mutation notificationSetAllRead {
  list: notificationSetAllRead {
    ...NotificationFragment
  }
}

`