import { useMutation } from "@apollo/react-hooks"
import corpAddGql from "graphql/APP/Corp/mutation/corpAdd.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { CorpInput } from "model"
import React, { useState } from "react"
import { CorpForm } from "../Form/CorpForm"
import corpListGql from "graphql/APP/Corp/query/corpList.gql"

type CorpAddProps = {
  onDone: () => void,
}

export const CorpAdd = ({ onDone }: CorpAddProps) => {

  const [corp, updateCorp] = useState<CorpInput>()
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(corpAddGql, {
    refetchQueries: [{
      query: corpListGql,
    }]
  })

  const onSave = () => {
    mutate({
      variables: {
        input: corp
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const onChange = (corp: CorpInput) => {
    updateCorp(corp)
  }

  const onReset = () => {
    onDone && onDone()
  }


  return <div>
    <CorpForm corp={corp} onChange={onChange} errors={errors} />
    <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => onSave()}>Enregister</button>
      <button className="btn btn-danger btn-sm ml-1" onClick={() => onReset()}>Annuler</button>
    </div>
  </div>
}