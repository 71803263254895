import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}

  fragment ContactSearchFragment on Contact {
    id
    pmsId
    firstName
    lastName
    email
    updateDate
    forceIsFrench
    Hotel {
      ...HotelDisplayFragment
    }
  }
`