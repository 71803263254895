import {PlanningView} from "components/front/PLANNING/Planning/View/PlanningView"
import React from "react"

export const PlanningViewRoute = (props: any) => {

  const {params} = props?.match
  const {id} = params;

  return <div>
    <PlanningView id={id} />
  </div>
}