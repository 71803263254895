import {useMutation} from '@apollo/react-hooks'
import {formatErrorResponseForJoi} from 'helpers/Apollo'
import {ReservationGroupLink} from 'model'
import React, {useState} from 'react'
import {ReservationGroupForm} from '../Form/ReservationGroupForm'
import reservationGroupAdd from 'graphql/PMS/Reservation/Group/mutation/reservationGroupAdd'

type ReservationGroupAddProps = {
  onDone?: () => void
}

export const ReservationGroupAdd = (props: ReservationGroupAddProps) => {

  const [reservationGroup, updateReservationGroup] = useState<any>()
  const [isUpdated, updateIsUpdated] = useState<any>()
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(reservationGroupAdd)

  const onSave = () => {
    const {__typename, id, Hotel, ReservationGroupLink, ...input} = reservationGroup
    mutate({
      variables: {
        input: {
          ...input,
          ReservationGroupLink: ReservationGroupLink?.map((rgl: ReservationGroupLink) => ({
            id: rgl?.id,
            groupId: rgl.groupId,
            reservationId: rgl.reservationId,
          }))
        },
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const onReset = () => {
    updateIsUpdated(false)
    updateErrors(null)
    updateReservationGroup(null)
  }

  const onChange = (group: any) => {

    updateIsUpdated(true)
    updateReservationGroup(group)
  }

  return <div>
    <ReservationGroupForm errors={errors} reservationGroup={reservationGroup} onChange={onChange} />
    <div>
      <button className='btn btn-sm btn-dark ml-2' onClick={() => onSave()}>Enregistrer</button>
      {isUpdated && <button className='btn btn-sm btn-danger ml-2' onClick={() => onReset()}>Annuler</button>}
    </div>

  </div>

}