import gql from "graphql-tag";
import OptionFragmentGql from "../fragment/OptionFragment.gql";

export default gql`

  ${OptionFragmentGql}

  query optionList($hotelId: String!) {
    list: optionList(hotelId: $hotelId) {
      ...OptionFragment
    }
  }

`