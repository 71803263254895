import gql from "graphql-tag";
import DeviceFragmentGql from "../fragment/DeviceFragment.gql";

export default gql`

${DeviceFragmentGql}

subscription deviceUsageChange($id: String!, $token: String!) {
  device: deviceUsageChange(id: $id, token: $token) {
    ...DeviceFragment
  }
}

`