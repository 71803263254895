import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import SegmentationEmailMemberFragmentGql from "../fragment/SegmentationEmailMemberFragment.gql";

export default gql`

  ${CursorFragment}
  ${SegmentationEmailMemberFragmentGql}

  query segmentationEmailMemberListBySegmentationId($segmentationId: String!, $cursor: CursorInput!) {
    list: segmentationEmailMemberListBySegmentationId(segmentationId: $segmentationId, cursor: $cursor) {
      cursor {
        ...CursorFragment
      }
      nodes {
        ...SegmentationEmailMemberFragment
      }
    }
  }

`