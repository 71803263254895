import { useQuery } from '@apollo/react-hooks'
import { NumberInput } from 'components/common/Form/NumberInput'
import { TextInput } from 'components/common/Form/TextInput'
import { HotelListSelect } from 'components/front/PMS/Hotel/List/HotelListSelect'
import serviceListByHotelId from 'graphql/PMS/Service/query/serviceListByHotelId'
import { isFieldOnError } from 'helpers/Apollo'
import { Planning, PlanningInput, Service } from 'model'
import React from 'react'
import { PlanningRow } from '../Row/PlanningRow'

type PlanningFormProps = {
  planning: Partial<Planning>,
  errors: any,
  onChange: (planning: PlanningInput) => void,
}

export const PlanningForm = (props: PlanningFormProps) => {

  const planning = props?.planning
  const errors = props?.errors


  const { data } = useQuery(serviceListByHotelId, {
    variables: {
      hotelId: planning?.hotelId,
    },
    skip: !planning?.hotelId,
  })

  const onChange = (planning: PlanningInput) => {
    props?.onChange(planning)
  }

  const onRemoveRow = (index: number) => {
    onChange({
      ...planning,
      rowList: planning?.rowList.filter((el: any, idx: number) => idx !== index)
    })
  }

  const onEditRow = (index: number, value: any) => {
    onChange({
      ...planning,
      rowList: planning?.rowList.map((el: any, idx: number) => (idx === index) ? value : el)
    })
  }

  const serviceList: Service[] = data?.list
  const hasError = !!props?.errors


  return <div>
    {hasError && <div className="alert alert-danger">Oups, il y'a une erreur</div>}
    <div className="card-body">
      <TextInput id={"name"} label={"Nom"} value={planning?.name || ""} errors={errors} onChange={(e, v) => {
        onChange({
          ...planning,
          name: v,
        })
      }} />

      <HotelListSelect isError={isFieldOnError(errors, "hotelId")} hotelId={planning?.hotelId || ""} label="Hotel" onChange={(hotelId: string) => onChange({
        ...planning,
        hotelId,
      })} />

      <NumberInput id={"rowHeight"} errors={errors?.options} label='Row Height' value={planning?.options?.rowHeight || ""} onChange={(e, v) => {
        onChange({
          ...planning,
          options: {
            ...planning?.options,
            rowHeight: v
          }
        })
      }} />
      <div className="input-group">
        <span className="input-group-text">Row List</span>
        <div className="form-control" style={{ height: "100%" }}>
          {planning?.rowList?.map((row: any, index: number) => {

            const errorForRow = errors?.rowList && errors?.rowList[`${index}`]

            return <div className="input-group" key={`row_${index}`}>
              <span className="input-group-text"></span>
              <div className="form-control" style={{ height: "100%" }}>
                <PlanningRow row={row} onChange={(value: any) => onEditRow(index, value)} errors={errorForRow || {}} />
              </div>
              <span className="input-group-text"> <span className="icon-trash" onClick={() => onRemoveRow(index)}></span></span>
            </div>
          })}
          <button type="button" className="btn btn-dark" onClick={() => onChange({
            ...planning,
            rowList: [
              ...(planning?.rowList || []),
              {
                label: "",
                hourStart: "",
                hourEnd: "",
              }
            ]
          })}>Ajouter</button>
        </div>
        {isFieldOnError(errors, "rowList") && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
      </div>
      <div className="input-group">
        <span className="input-group-text">Event Type</span>
        <div className="form-control" style={{ height: "100%" }}>
          {serviceList?.map((service) => {
            const isChecked = !!planning?.options?.serviceIdList?.includes(service?.id)
            return <div key={`service${service?.id}`}>
              <input type="checkbox" checked={isChecked} id={service?.id} onChange={(e: any) => {
                if (e.target.checked) {
                  onChange({
                    ...planning,
                    options: {
                      ...planning?.options,
                      serviceIdList: [
                        ...(planning?.options?.serviceIdList || []),
                        service?.id,
                      ]
                    }
                  })
                } else {
                  onChange({
                    ...planning,
                    options: {
                      ...planning?.options,
                      serviceIdList: planning?.options?.serviceIdList?.filter((el: string) => el !== service?.id) || []
                    }
                  })
                }
              }}></input><label htmlFor={service?.id}>{service?.name}</label>
            </div>
          })}
        </div>
        {isFieldOnError(errors, "eventTypeList") && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
      </div>
    </div>
  </div>

}