import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import { PriceRateType, PriceRateTypeInput } from "model"
import React, { useState } from "react"
import { PriceRateTypeEdit } from "../Edit/PriceRateTypeEdit"

type PriceRateTypeListProps = {
  hotelId: string,
  priceRateTypeList: PriceRateTypeInput[],
  onChange: (priceRateTypeList: PriceRateTypeInput[]) => void,
  errors: any,
}


export const PriceRateTypeList = ({ priceRateTypeList, hotelId, onChange, errors }: PriceRateTypeListProps) => {

  // const [focusEdit, updateFocusEdit] = useState<PriceRateTypeInput | null>()
  const [focusIndex, updateFocusIndex] = useState<number | null>(null)

  const hasFocus = focusIndex !== null
  const hasContent = priceRateTypeList?.length > 0

  const onDelete = (index: number) => {
    onChange(priceRateTypeList?.filter((el, idx) => idx !== index))
  }


  return <div>
    <Modal display={hasFocus} onClose={() => updateFocusIndex(null)}>
      <PriceRateTypeEdit hotelId={hotelId} priceRateType={priceRateTypeList?.find((prt, idx) => idx === focusIndex)} errors={errors && errors[focusIndex]} onChange={(priceRateTypeUpdated) => {
        onChange(priceRateTypeList?.map((prt, idx) => idx === focusIndex ? priceRateTypeUpdated : prt))
      }} />
    </Modal>
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Code</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {!hasContent && <tr>
          <td colSpan={2}>
            <div className="alert alert-warning">Aucun</div>
          </td>
        </tr>}
        {hasContent && priceRateTypeList.map((priceRateType, index) => {

          const isOnError = errors && !!errors[index]

          return <tr className={isOnError ? " text-white bg-danger" : ""}>
            <td>{priceRateType?.code}</td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusIndex(index)}>
                <IconEdit />
              </button>
              <button className="btn btn-sm btn-danger ml-1" onClick={() => onDelete(index)}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <button className="btn btn-sm btn-dark" onClick={() => {
      onChange([
        ...(priceRateTypeList || []),
        {
          code: "",
          isBreakfastIncluded: false,
          isRefundable: false,
          isGiftBox: false,
          isGroup: false,
          isFree: false,
          isIncludedInConfirmationTotal: false,
          labelFr: "",
          labelEn: "",
          conditionsFR: "",
          conditionsEN: "",
          hasSpa: false,
          hasFood: false,
          hasDrink: false,
          hasService: false,
          canBeSelectedInProposition: false,
        },
      ])
      updateFocusIndex(priceRateTypeList?.length)
    }}>Ajouter</button>
  </div >


}