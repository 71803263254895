import gql from "graphql-tag";
import SegmentationFragment from "../fragment/SegmentationFragment";

export default gql`

${SegmentationFragment}

mutation segmentationEdit($id: String!, $input: SegmentationInput!) {
  segmentation: segmentationEdit(id: $id, input: $input) {
    ...SegmentationFragment
  }
}

`