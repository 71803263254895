import gql from "graphql-tag";
import HotelFragment from "../fragment/HotelFragment";

export default gql`

  ${HotelFragment}

  query hotelList {
    list: hotelList {
      ...HotelFragment
    }
  }
`