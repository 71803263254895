import { useMutation, useQuery } from "@apollo/react-hooks"
import propositionSendGql from "graphql/PMS/Proposition/mutation/propositionSend.gql"
import propositionGetGql from "graphql/PMS/Proposition/query/propositionGet.gql"
import { findToken } from "helpers/User"
import { getRootUrl } from "helpers/Utils"
import { Proposition, PropositionSentStatusEnum, PropositionStatusEnum } from "model"
import React from "react"

type PropositionSendProps = {
  id: string,
  onDone?: () => void,
}

export const PropositionSend = ({ id, onDone }: PropositionSendProps) => {

  const { data } = useQuery<{ result: { warning: any, proposition: Proposition } }>(propositionGetGql, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  })

  const [sendPropositionMutate, { loading: loadingSend }] = useMutation(propositionSendGql);

  const onSend = async () => {
    sendPropositionMutate({
      variables: {
        id: proposition?.id,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e: any) => {
        // setError(`Ooops... ${e.message}`)
        console.log('ERROR', e)
      })
  }

  const proposition = data?.result?.proposition
  const isValid = proposition?.status === PropositionStatusEnum.STATUS_VALID
  const canSend = isValid && proposition?.sentStatus !== PropositionSentStatusEnum.STATUS_SENT

  return <div>
    <div className="card">
      <div className="card-body">
        <iframe style={{ height: '80vh', border: "0px #CCC solid" }} width='100%' title="proposition" src={`${getRootUrl()}proposition/${proposition?.id}?access_token=${findToken()}`} />
      </div>
      <div className="card-footer">
        <button className="btn btn-success btn-sm" disabled={!canSend} onClick={onSend} style={{ marginRight: 5 }}>{loadingSend && <span className="icon-loading"></span>}Envoyer</button>
      </div>
    </div>
  </div>

}