import React from "react"

import {SegmentationEdit} from "components/front/CRM/Segmentation/Edit/SegmentationEdit"
import {URL_CRM_SEGMENTATION_LIST} from "constant/url"
import {useHistory} from "react-router-dom"


export const SegmentationAddRoute = () => {

  const history = useHistory()

  return <div>
    <h1>Ajouter une segmentation</h1>
    <SegmentationEdit onDone={ () => history.push(URL_CRM_SEGMENTATION_LIST)} open={true} />
  </div>
}