import gql from "graphql-tag";
import ReservationListFragment from "graphql/PMS/Reservation/fragment/ReservationListFragment";
import CheckinIdentityPictureFragmentGql from "../Identity/Picture/fragment/CheckinIdentityPictureFragment.gql";

export default gql`

  ${ReservationListFragment}
  ${CheckinIdentityPictureFragmentGql}

  fragment CheckinFragment on Checkin {
    id
    arrivalDate
    status
    reservationId
    name
    phone
    mail
    address
    zip
    city
    country
    tokenId
    Reservation {
      ...ReservationListFragment
    }
    CheckinIdentityPicture {
      ...CheckinIdentityPictureFragment
    }


  }

`