import { NetworkModuleHotelDisplay } from "components/front/NETWORK/Module/NetworkModuleHotelDisplay"
import React from "react"


export const NetworkRouteHotel = (props: any) => {

  const match = props?.match
  const params = match?.params
  const hotelId = params?.hotelId


  return <div>
    {hotelId && <NetworkModuleHotelDisplay hotelId={hotelId} />}
  </div>

}