import { useMutation, useQuery } from "@apollo/react-hooks"
import { userContext } from "context/User"
import userStopImpersonationGql from "graphql/APP/User/mutation/userStopImpersonation"
import userProfile from "graphql/APP/User/query/userProfile"
import { clearLoginCookie } from "helpers/Cookies"
import React, { useState } from "react"
import { useContext } from "react"
import { ImpersonateForm } from "../Impersonation"
import { Modal } from "@zipou/front_tools"



export const UserProfileDisplay = () => {

  const user = useContext(userContext)
  const [displayRefresh, updateDisplayRefresh] = useState(false)
  const [displayImpersonate, updateDisplayImpersonate] = useState(false)
  const [mutate] = useMutation(userStopImpersonationGql)

  const { refetch } = useQuery(userProfile, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const isAdmin = user?.isAdmin
  const isImpersontated = !!user?.impersonator

  const onLogout = () => {
    clearLoginCookie()
    refetch()
    updateDisplayRefresh(true)
  }

  const onStopImpersonation = () => {
    mutate()
      .then(() => {
        updateDisplayRefresh(true)
        return refetch()
      })

  }

  return <div className="card border-dark">

    <Modal display={displayRefresh}>
      <div className="alert alert-warning">
        Vous êtes déconnecté :)
      </div>
      <p>Vous pouvez raffrachir la page</p>
    </Modal>
    <Modal display={displayImpersonate} onClose={() => updateDisplayImpersonate(false)}>
      <ImpersonateForm onDone={() => {
        refetch()
        updateDisplayImpersonate(false)
        updateDisplayRefresh(true)
      }} />
    </Modal>
    <div className="card-header bg-dark text-white">
      <span>Connecté en tant que {user?.email}</span>
    </div>
    <div className="card-body">
      <button className="btn btn-sm btn-warning mr-1" onClick={() => onLogout()}>Déconnexion</button>
      {(isAdmin && !isImpersontated) && <button className="btn btn-sm btn-danger mr-1" onClick={() => updateDisplayImpersonate(true)}>Impersonate</button>}
      {isImpersontated && <button className="btn btn-sm btn-danger mr-1" onClick={() => onStopImpersonation()}>Stop Impersonation</button>}
    </div>
  </div>


}