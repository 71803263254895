import { TokenSendStatusEnum } from "model"
import React from "react"

type TokenSendStatusProps = {
  status: TokenSendStatusEnum | string
}

export const TokenSendStatus = ({ status }: TokenSendStatusProps) => {
  switch (status) {

    case TokenSendStatusEnum.SEND_STATUS_IDLE: {
      return <span className="badge badge-sm badge-secondary">Non Envoyé</span>
    }

    case TokenSendStatusEnum.SEND_STATUS_DELIVERED: {
      return <span className="badge badge-sm badge-success">Délivré</span>
    }

    case TokenSendStatusEnum.SEND_STATUS_SENT: {
      return <span className="badge badge-sm badge-primary">Envoyé</span>
    }

    case TokenSendStatusEnum.SEND_STATUS_ERROR: {
      return <span className="badge badge-sm badge-dark badge-danger">Erreur</span>
    }

    case TokenSendStatusEnum.SEND_STATUS_READ: {
      return <span className="badge badge-sm badge-success">Lu</span>
    }

    default: {
      return <span className="badge badge-sm badge-dark">{status}</span>
    }
  }
}
