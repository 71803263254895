import gql from "graphql-tag"
import LoginTokenFragment from "../fragment/LoginTokenFragment"

export default gql`

  ${LoginTokenFragment}

  mutation userLoginByGoogleToken($token: String!){
    loginToken: userLoginByGoogleToken(token: $token) {
      ...LoginTokenFragment
    }
  }

`