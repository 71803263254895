import gql from "graphql-tag";
import ChargeFragmentGql from "../fragment/ChargeFragment.gql";

export default gql`

${ChargeFragmentGql}

mutation chargeRefund($id: String!, $amount: Float!) {
  charge: chargeRefund(id: $id, amount: $amount) {
    ...ChargeFragment
  }
}
`