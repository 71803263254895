import {useMutation} from "@apollo/react-hooks"
import pictureAddGql from "graphql/APP/Picture/mutation/pictureAdd.gql"
import React, {useRef, useState} from "react"


type PictureUploaderProps = {
  onDone?: (url: string) => void,
}

export const PictureUploader = (props: PictureUploaderProps) => {

  const [oversizeError, updateOversizeError] = useState<boolean>(false)

  const refInputFile = useRef(null)
  const [mutate] = useMutation(pictureAddGql)

  const handleButtonClick = (e: any) => {
    refInputFile.current.click(e)
  }

  const handleInputFileChange = (event: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (upload) => {
      // console.log('UPLOAD', upload)
      mutate({
        variables: {
          input: {
            base64: upload.target.result,
          }
        }
      })
        .then((response) => {
          // response.data.picture.url
          props.onDone(response.data.picture.url)
        })
        .catch((e: any) => console.log("EERROR", e))
      // uploadStaticImage(upload.target.result)
      // .then((url)=> {

      // });
    }
    if (file) {
      const {size} = file;

      if (size > 1024000) {
        updateOversizeError(true)
        return;
      }

      const type = file.type.split("/")[0];
      if (type === "image") {
        reader.readAsDataURL(file);
      }
    }
  }

  return <div>
    {oversizeError && <div className="alert alert-danger">
      Attention, votre fichier et trop volumineux...
      </div>}
    <button className="btn btn-dark btn-sm" onClick={handleButtonClick}>Importer une image</button>
    <input style={{display: "none"}} ref={refInputFile} className="form-control" type="file" accept="image/*" onChange={handleInputFileChange}  ></input>
  </div >

}