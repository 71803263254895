import {useQuery} from "@apollo/react-hooks"
import {AutoCompleteInput} from "components/common/Form/AutoCompleteInput"
import contactCountryListGql from "graphql/PMS/Contact/query/contactCountryList.gql"
import React, {useEffect, useState} from "react"

type ContactCountrySearchFieldProps = {
  label: string,
  hotelId: string,
  value?: string,
  disabled?: boolean,
  placeholder?: string,
  isError?: boolean,
  onChange: (value: string, object: any) => void
}

export const ContactCountrySearchField = (props: ContactCountrySearchFieldProps) => {

  const [value, updateValue] = useState(null)
  const [countryList, updateCountryList] = useState(null)

  useQuery(contactCountryListGql, {
    variables: {
      id: props?.hotelId,
    },
    onCompleted: (response: any) => {
      updateCountryList(response?.list)
    }
  })

  //Detect that hotelId has changed
  useEffect(() => {
    if (value && props?.hotelId) {
      onValueChange(null)
    }
  }, [props?.hotelId])

  const onValueChange = (value: any) => {
    updateValue(value)
    props?.onChange(value?.id, value)
  }

  const onSearch = async (textValue: string) => {
    return countryList.filter((country: any) => (country.codeShort.toLowerCase().includes(textValue.toLowerCase()) || country.name.toLowerCase().includes(textValue.toLowerCase())))
  }

  return <div>
    <AutoCompleteInput
      placeholder={"Chercher un pays"}
      disabled={!props?.hotelId || props?.disabled}
      onSearch={onSearch}
      label={props?.label}
      onChange={onValueChange}
      value={value}
      isError={props?.isError}
      displayValue={(value: any) => `${value?.codeShort || ""} - ${value?.name || ""}`}
      displayResult={(value: any) => `${value?.codeShort || ""} - ${value?.name || ""}`}
      renderNoResult={() => <li className="list-group-item list-group-item-danger">Pas de resultats </li>}
    />
  </div>
}
