import {isFieldOnError} from "helpers/Apollo"
import React from "react"


type ColorInputProps = {
  id?: string,
  label: string,
  rightLabel?: any,
  value: string,
  onChange: (data: any, v: string) => void
  errors?: any,
}

export const ColorInput = (props: ColorInputProps) => {

  const value = `#${props?.value}` || ""

  return <div className="input-group">
  <span className="input-group-text">{props?.label}</span>
  <input type="text" className='form-control' placeholder={props?.label} value={value} onChange={(e: any) => props?.onChange(e, e.target.value?.replace("#", ""))} />
  <input type="color" className='form-control' placeholder={props?.label} value={value || ""} onChange={(e: any) => props?.onChange(e, e.target.value?.replace("#", ""))} />
  {props?.rightLabel && <span className='input-group-text'>{props?.rightLabel}</span>}
  {isFieldOnError(props?.errors, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
</div>

}