import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${PaymentFragment}

  mutation paymentAdd($input: PaymentInput!) {
    payment: paymentAdd(input: $input) {
      ...PaymentFragment
    }
  }

`