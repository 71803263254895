import gql from "graphql-tag";
import PriceRateFragmentGql from "../fragment/PriceRateFragment.gql";

export default gql`

  ${PriceRateFragmentGql}

  query priceRateListByCategory($hotelId: String!, $date: DateTime!, $roomTypeId: String!, $nbAdults: Float!, $nbChildren: Float!, $nbSpecial: Float!) {
    list: priceRateListByCategory(hotelId: $hotelId, date: $date, roomTypeId: $roomTypeId, nbAdults: $nbAdults, nbChildren: $nbChildren, nbSpecial: $nbSpecial) {
      ...PriceRateFragment
    }
  }
`
