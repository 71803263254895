import {TimeFormat} from "components/common/Format/TimeFormat"
import React from "react"


type MessageDisplayProps = {
  message: any,
  clientAuth: any,
}

export const MessageDisplay = (props: MessageDisplayProps) => {
  const {content, senderId, date} = props?.message

  const isSenderAdmin = senderId === "ADMIN"
  const isSender = senderId === props?.clientAuth?.clientId

  const renderContentItem = (contentItem: any, isSender: boolean) => {
    const {type, value} = contentItem
    switch (type) {
      default:
      case "TYPE_TEXT": {
        return isSenderAdmin ? <div>
          <span style={{fontWeight: "bold", fontStyle: "italic", color: "#00356b"}}>{value}</span>
        </div> : <div style={{maxWidth: '100%', overflowWrap: "break-word"}}>
            <span style={{color: "black"}}><TimeFormat value={new Date(date)} /> </span>
            <span style={isSender ? {fontWeight: "bold", color: "#000000"} : {fontStyle: "italic", color: "#222222"}}>{value}</span>
          </div>
      }
    }

  }

  return content?.map((contentItem: any, index: number) => {
    return <div key={`contentItem_${index}_${senderId}`} style={{display: "flex", flexDirection: "column", alignItems: (isSender) ? "flex-end" : "flex-start"}}>
      {renderContentItem(contentItem, isSender)}
    </div>

  })
}