import {PropositionRow} from "model";
import React from "react"
import {useTranslation} from "react-i18next";

type PropositionRowTotalProps = {
  row: PropositionRow,
  showDetails?: boolean,
  onFocus?: () => void,
}

export const PropositionRowTotal = (props: PropositionRowTotalProps) => {

  const {t} = useTranslation('translation', {keyPrefix: 'proposition'});

  const propositionRow = props?.row

  // console.log('propositionRow', propositionRow)

  const {total, hostingTotal, extraTotal} = propositionRow?.total || {}
  const hasExtra = extraTotal > 0

  return <div style={{display: "flex", justifyContent: "center"}}>
    <div className="col-9 col-sm-6">
      {!props?.showDetails && <div className="">
        <div className="card-body">
          {t("Total")} <span className="ml-2 text-dark" style={{textTransform: "capitalize", fontWeight: "bolder", color: ""}}>{total} €</span>
          {
            //<button onClick={() => props?.onFocus()} style={{backgroundColor: "inherit", borderColor: "white", fontSize: 12, color: "#00356b", textDecoration: "underline"}} className="ml-1"> <span className="icon-info" /> </button>
          }
        </div>
      </div>}
      {props?.showDetails && <table className="table table-bordered">
        <tbody>
          <tr>
            <th>{t("Total")}</th>
            <td>
              <table>
                <tbody>
                  <tr>
                    <th>{t("hebergement")}</th>
                    <td>{hostingTotal} €</td>
                  </tr>
                  {hasExtra && <tr>
                    <th>{t("extra")}</th>
                    <td>{extraTotal} €</td>
                  </tr>}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>}
    </div>
  </div>



}