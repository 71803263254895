import gql from "graphql-tag";
import NewsListFragmentGql from "../fragment/NewsListFragment.gql";
import NewsEditFragmentGql from "../fragment/NewsEditFragment.gql";

export default gql`

  ${NewsEditFragmentGql}

  query newsGet($id: String!) {
    news: newsGet(id: $id) {
      ...NewsEditFragment
    }
  }

`