import React from "react"
import { Event, ServiceTypeEnum } from "model"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
import { EventStatus } from "components/front/PLANNING/Event/Status/EventStatus"
import { useTranslation } from "react-i18next"
import { DurationFormat } from "components/common/Format/DurationFormat"

type ConfirmationEventDisplayProps = {
  serviceEventList: Event[],
}

export const ConfirmationEventDisplay = (props: ConfirmationEventDisplayProps) => {

  const serviceEventList = props?.serviceEventList

  const { t } = useTranslation('translation', { keyPrefix: 'confirmation' });

  const serviceEventListSorted = [...serviceEventList || []].sort((el1, el2) => el1.utcDate < el2?.utcDate ? -1 : 1)

  return <>
    <ul className="list-group">
      {serviceEventListSorted?.map((e: Event) => {

        const service = e.Service
        const serviceType = service?.type

        switch (serviceType) {
          case ServiceTypeEnum.TYPE_RESTAURANT: {
            return <li className="list-group-item">{e?.Service?.name} - <DateTimeFormat value={e?.source?.utcDate} showUTC /> - {t("nbPeople", { count: Number(e?.source?.nbPeople) })} - <EventStatus status={e.status} /> </li>
          }

          case ServiceTypeEnum.TYPE_SPA: {
            const hasNbPeople = !!e?.source?.nbPeople
            const hasDuration = !!e?.source?.duration
            return <li className="list-group-item">{e?.Service?.name} - <DateTimeFormat value={e?.source?.utcDate} showUTC /> {hasNbPeople ? `- ${t("nbPeople", { count: Number(e?.source?.nbPeople) })} ` : ``} {hasDuration ? <>{` - ${t("duration")}: `}<DurationFormat valueInMinutes={e?.source?.duration} /> </> : ``} <EventStatus status={e.status} /></li>
          }

          default: {
            return <li className="list-group-item">{e?.Service?.name} - <DateTimeFormat value={e?.source?.utcDate} showUTC /> - <EventStatus status={e.status} /></li>
          }
        }

      })}
    </ul>
  </>
}