import { Modal } from "components/common/Navigation/Modal/Modal";
import { Hotel, PreStay, PreStayContentInput } from "model";
import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import { TransfertForm } from "./Transfert/TransfertForm";

export type ArrivalAnswerProps = {
  preStayContent: PreStayContentInput,
  hotel: Hotel,
  onChange: (preStayContent: PreStayContentInput) => void,
}

export const ArrivalAnswer = ({ preStayContent, hotel, onChange }: ArrivalAnswerProps) => {

  const { t } = useTranslation('translation', { keyPrefix: 'prestay' });

  const [displayTransportModal, updateDisplayTransportModal] = useState(false)

  // const preStay = props?.preStay
  const themeColor = `#${hotel?.themeColor}`

  return <div>
    <Modal display={displayTransportModal} onClose={() => updateDisplayTransportModal(false)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <TransfertForm themeColor={themeColor} transfertData={preStayContent?.arrival?.transfertData} onChange={transfertData => {
        onChange({
          ...(preStayContent || {}),
          arrival: {
            ...(preStayContent?.arrival || {}),
            transfertData,
          }
        })
      }} />
    </Modal>
    <h1>{t("arrival.title")}</h1>
    <h2>{t("arrival.desc")}</h2>
    <div>
      <div className="row pt-3 d-flex justify-content-center align-items-center">

        <div className="d-flex flex-column col-12 col-md-8">
          <span className="text-left pb-1">{t("arrival.time")}</span>
        </div>

        <div className="d-flex flex-column col-6 col-md-2">
          <select className="form-control" value={preStayContent?.arrival?.arrivalHours || ""} onChange={(e) => {
            onChange({
              ...(preStayContent || {}),
              arrival: {
                ...(preStayContent?.arrival || {}),
                arrivalHours: e.target.value,
              }
            })
          }} >
            <option value="0">0H</option>
            <option value="1">1H</option>
            <option value="2">2H</option>
            <option value="3">3H</option>
            <option value="4">4H</option>
            <option value="5">5H</option>
            <option value="6">6H</option>
            <option value="7">7H</option>
            <option value="8">8H</option>
            <option value="9">9H</option>
            <option value="10">10H</option>
            <option value="11">11H</option>
            <option value="12">12H</option>
            <option value="" disabled>-</option>
            <option value="13">13H</option>
            <option value="14">14H</option>
            <option value="15">15H</option>
            <option value="16">16H</option>
            <option value="17">17H</option>
            <option value="18">18H</option>
            <option value="19">19H</option>
            <option value="20">20H</option>
            <option value="21">21H</option>
            <option value="22">22H</option>
            <option value="23">23H</option>
          </select>
        </div>

        <div className="d-flex flex-column col-6 col-md-2">
          <select className="form-control" value={preStayContent?.arrival?.arrivalMinutes || ""} onChange={(e) => {
            onChange({
              ...(preStayContent || {}),
              arrival: {
                ...(preStayContent?.arrival || {}),
                arrivalMinutes: e.target.value,
              }
            })
          }} >
            <option value="" disabled>-</option>
            <option value="0">0</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="45">45</option>
          </select>
        </div>

      </div>

      <div className="row pt-3 d-flex justify-content-start align-items-center">

        <div className="d-flex flex-column col-12 col-md-8 justify-content-start">
          <span className="text-left pb-1">{t("arrival.transfert")}</span>
        </div>

        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Non
        </div>

        <div className="d-flex flex-column col-3 col-md-2">
          <div className="custom-control custom-switch">

            <input type="checkbox" className="custom-control-input" id="arrival.transfert" checked={preStayContent?.arrival?.transfert === true ? true : false} onChange={(e) => {
              if (e.target.checked) updateDisplayTransportModal(true)
              onChange({
                ...(preStayContent || {}),
                arrival: {
                  ...(preStayContent?.arrival || {}),
                  transfert: e.target.checked,
                }
              })
            }} />
            <label className="custom-control-label" style={{ "--theme-color": themeColor } as React.CSSProperties} htmlFor="arrival.transfert"></label>
          </div>
        </div>

        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Oui
        </div>

      </div>

      <div className="row pt-3 d-flex justify-content-start align-items-center">

        <div className="d-flex flex-column col-12 col-md-8 justify-content-start">
          <span className="text-left pb-1">{t("arrival.parking")}</span>
        </div>

        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Non
        </div>

        <div className="d-flex flex-column col-3 col-md-2">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="arrival.parking" checked={preStayContent?.arrival?.parking === true ? true : false} onChange={(e) => {
              onChange({
                ...(preStayContent || {}),
                arrival: {
                  ...(preStayContent?.arrival || {}),
                  parking: e.target.checked ? true : false,
                }
              })
            }} />
            <label className="custom-control-label" style={{ "--theme-color": themeColor } as React.CSSProperties} htmlFor="arrival.parking"></label>
          </div>
        </div>

        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Oui
        </div>

      </div>

      <div className="row pt-3 d-flex justify-content-start align-items-center">

        <div className="d-flex flex-column col-12 col-md-8 justify-content-start">
          <span className="text-left pb-1">{t("arrival.charging")}</span>
        </div>

        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Non
        </div>

        <div className="d-flex flex-column col-3 col-md-2">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="arrival.charging" checked={preStayContent?.arrival?.charging === true ? true : false} onChange={(e) => {
              onChange({
                ...(preStayContent || {}),
                arrival: {
                  ...(preStayContent?.arrival || {}),
                  charging: e.target.checked ? true : false,
                }
              })
            }} />
            <label className="custom-control-label" style={{ "--theme-color": themeColor } as React.CSSProperties} htmlFor="arrival.charging"></label>
          </div>
        </div>

        <div className="d-flex flex-column text-uppercase col-2 col-md-1 pt-2">
          Oui
        </div>

      </div>

      <div className="row pt-3 d-flex justify-content-start align-items-center">
        <div className="d-flex flex-column col-12 justify-content-start text-left">
          <span className="">{t("arrival.anythingelse")}</span>
          <textarea value={preStayContent?.arrival?.comment} className="form-control" onChange={(e) => {
            onChange({
              ...preStayContent,
              arrival: {
                ...(preStayContent?.arrival || {}),
                comment: e.target.value
              }
            })
          }}></textarea>

        </div>
      </div>

    </div>
  </div>

}