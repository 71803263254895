import React from "react";
import BootstrapModal from "react-bootstrap/Modal"

import "./style.scss"

type ModalProps = {
  display: boolean,
  children: any,
  onClose?: () => void,
  title?: any,
  size?: "lg" | "sm" | "xl",
  centered?: boolean,
  footer?: any,
  themeColor?: string,
  contentClassName?: string,
}

export const Modal = (props: ModalProps) => {

  const handleClose = () => {
    const {onClose} = props;
    onClose && onClose();
  }

  const {display, children, onClose, title, size = "lg", centered = true, footer} = props;

  const displayHeader = onClose || title

  return (
    <BootstrapModal show={display} onHide={handleClose} size={size} aria-labelledby="contained-modal-title-vcenter" centered={centered} contentClassName={props?.contentClassName}>
      <BootstrapModal.Body style={{padding: "25px 10px", border: "5px #888 solid", "--theme-color": props?.themeColor || ""} as React.CSSProperties}>
      {displayHeader && <div className="" style={{justifyContent: "center",}}>
        <span className="text-dark"><h3 style={{padding: 0, margin: 0, fontWeight: "bold"}}>{title}</h3></span>
        {!!onClose && <div style={{position: "absolute", right: 5, top: 5, zIndex: 5000}}>
          <button onClick={handleClose} style={{backgroundColor: "inherit", border: "0", color: "#999", zIndex: 5000}}><span className="icon-close-naked"></span></button>
        </div>}
        {title && <div className="" style={{borderTop: "1px black solid", margin: "10px 10px 20px 10px"}}></div>}
      </div>}
        {children}
      </BootstrapModal.Body>
      {footer && <BootstrapModal.Footer>
        {footer}
      </BootstrapModal.Footer>}
    </BootstrapModal>
  )
}