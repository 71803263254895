import React, { useState } from "react"

type DateTimeSelectInputProps = {
  value: Date,
  label?: string,
  isError?: boolean,
  isWarning?: boolean,
  onChange: (value: Date) => void
}

export const DateTimeSelectInput = (props: DateTimeSelectInputProps) => {

  const valueWhenEmpty = new Date(`${new Date().toISOString().split('T')[0]}T08:00:00.000Z`)
  
  const initialValue = props?.value ? new Date(props?.value) : valueWhenEmpty;
  const initialDate = new Date(new Date(initialValue).getTime() + 1000 * 3600 * 5).toISOString().split('T')[0] || new Date().toISOString().split('T')[0]
  const [dateValue, updateDateValue] = useState<string>(initialDate || "")

  const initialMinutes = initialValue ? (initialValue?.getUTCMinutes()) < 10 ? `0${initialValue?.getUTCMinutes()}` : initialValue?.getUTCMinutes() : 0
  const initialHours = initialValue ? (initialValue?.getUTCHours()) < 10 ? `0${initialValue?.getUTCHours()}` : initialValue?.getUTCHours() : 0
  const initialTime = initialValue ? `${initialHours}:${initialMinutes}` : "00:00"

  const [timeValue, updateTimeValue] = useState(initialTime)

  const onChange = (date: any, time: any) => {
    const value = `${date}T${time}:00.000Z`
    const dateValue = new Date(value)
    props?.onChange(dateValue)
  }

  const onTimeChange = (e: any) => {
    const value = e.target.value
    updateTimeValue(value)
    onChange(dateValue, value)
  }

  const onDateChange = (e: any) => {
    const value = e.target.value
    updateDateValue(value)
    onChange(value, timeValue)
  }

  return <div className="input-group">
    {props?.label && <span className="input-group-text">{props?.label}</span>}
    <input type="date" className="form-control" placeholder={"Date"} value={dateValue || ""} onChange={onDateChange} />
    <select className="form-control" placeholder={"Time"} value={timeValue || ""} onChange={onTimeChange}>
      <option value="00:00">Choisissez</option>
      <optgroup label="Déjeuner"></optgroup>
      <option value="11:45">11:45</option>
      <option value="12:00">12:00</option>
      <option value="12:15">12:15</option>
      <option value="12:30">12:30</option>
      <option value="12:45">12:45</option>
      <option value="13:00">13:00</option>
      <option value="13:15">13:15</option>
      <option value="13:30">13:30</option>
      <option value="13:45">13:45</option>
      <option value="14:00">14:00</option>
      <option value="14:15">14:15</option>
      <option value="14:30">14:30</option>
      <optgroup label="Diner"></optgroup>
      <option value="19:00">19:00</option>
      <option value="19:15">19:15</option>
      <option value="19:30">19:30</option>
      <option value="19:45">19:45</option>
      <option value="20:00">20:00</option>
      <option value="20:15">20:15</option>
      <option value="20:30">20:30</option>
      <option value="20:45">20:45</option>
      <option value="21:00">21:00</option>
      <option value="21:15">21:15</option>
      <option value="21:30">21:30</option>
      <option value="21:45">21:45</option>
      <option value="22:00">22:00</option>
      <option value="22:15">22:15</option>
      <option value="22:30">22:30</option>
      <option value="22:45">22:45</option>
      <option value="23:00">23:00</option>
    </select>
    {props?.isError && <span className="input-group-text"><span className="icon-warning-sign" /></span>}
    {props?.isWarning && <span className="input-group-text"><span className="icon-warning" /></span>}
  </div>
}