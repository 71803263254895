import { TokenStatusEnum } from "model"
import React from "react"

type TokenStatusProps = {
  status: TokenStatusEnum,
}

export const TokenStatus = ({ status }: TokenStatusProps) => {
  switch (status) {

    case "STATUS_INITIAL": {
      return <span className="badge badge-info">INITIAL</span>
    }

    case "STATUS_PENDING_CUSTOMER": {
      return <span className="badge badge-warning">EN ATTENTE CLIENT</span>
    }

    case "STATUS_DONE": {
      return <span className="badge badge-success">CONFIRME</span>
    }

    case "STATUS_DELETED": {
      return <span className="badge badge-danger">SUPPRIME</span>
    }

    default: {
      return <span className="badge badge-info">{status}</span>
    }
  }
}

/*

  STATUS_INITIAL = "STATUS_INITIAL",
  STATUS_PENDING_CUSTOMER = "STATUS_PENDING_CUSTOMER",
  STATUS_DONE = "STATUS_DONE",
*/