import React, { useContext, useState } from "react"
import { CheckinForm } from "../Form/CheckinForm"
import { Checkin, CheckinInput, CheckinStatusEnum, UserRoleEnum } from "model"
import { useMutation, useQuery } from "@apollo/react-hooks"
import checkinGetGql from "graphql/PMS/Checkin/query/checkinGet.gql"
import { CheckinStatus } from "../Status/CheckinStatus"
import { userContext } from "context/User"
import { ReservationDetails } from "../../Reservation/Details/ReservationDetails"
import checkinUpdateGql from "graphql/PMS/Checkin/mutation/checkinUpdate.gql"
import { IconLoading } from "@zipou/front_tools"

type CheckinEditProps = {
  checkinId: string,
}

export const CheckinEdit = ({ checkinId }: CheckinEditProps) => {

  const user = useContext(userContext)
  const isDebug = user.roles.includes(UserRoleEnum.ROLE_DEBUG)

  const [checkin, updateCheckin] = useState<CheckinInput>()
  const [errors, updateErrors] = useState<any>()

  const getInput = (checkin: Checkin): CheckinInput => {
    return {
      details: {
        name: checkin?.name,
        phone: checkin?.phone,
        mail: checkin?.mail,
        address: checkin?.address,
        zip: checkin?.zip,
        city: checkin?.city,
        country: checkin?.country,
      },
      billing: {
        tokenId: checkin.tokenId,
      },
      identity: {
        checkinIdentityPicture: checkin?.CheckinIdentityPicture?.map((cip) => {
          const { id, ...rest } = cip
          return rest
        })
      }
    }
  }



  const { data } = useQuery<{ checkin: Checkin }>(checkinGetGql, {
    variables: {
      id: checkinId
    },
    skip: !checkinId,
    onCompleted: (data) => {
      updateCheckin(getInput(data?.checkin))
    }
  })

  const [mutate, { loading }] = useMutation<{ checkin: Checkin }, { id: string, input: CheckinInput }>(checkinUpdateGql)

  const onSave = () => {
    mutate({
      variables: {
        id: data?.checkin?.id,
        input: checkin,
      }
    })
      .then(() => console.log("OK"))
      .catch((e) => console.log("ERROR", e))
  }

  return <div>

    <table className="table table-bordered table-striped">
      <tbody>
        {isDebug && <tr>
          <th>
            Id
          </th>
          <td>
            {data?.checkin?.id}
          </td>
        </tr>}
        <tr>
          <th>
            Status
          </th>
          <td>
            <CheckinStatus status={data?.checkin?.status as CheckinStatusEnum} />
          </td>
        </tr>
        <tr>
          <th>
            Reservation
          </th>
          <td>
            <ReservationDetails open reservationId={data?.checkin?.reservationId} />
          </td>
        </tr>
      </tbody>
    </table>
    <CheckinForm reservation={data?.checkin?.Reservation} errors={errors} checkin={checkin} onChange={updateCheckin} />
    <div>
      <button disabled={loading} className="btn btn-sm btn-dark" onClick={() => onSave()}>
        {loading && <IconLoading />} Enregistrer
      </button>
    </div>
  </div>


}