import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks'
import notificationListGql from 'graphql/APP/Notification/query/notificationList.gql'
import React, { useContext, useState } from 'react'
import { Notification } from "model"
// import {userContext} from 'context/User'

import { NotificationDisplay } from "../Display/NotificationDisplay"

import './style.scss'
import NotificationSubscriptionGql from 'graphql/APP/Notification/subscription/NotificationSubscription.gql'
import { useHistory } from 'react-router-dom'
import notificationSetReadGql from 'graphql/APP/Notification/mutation/notificationSetRead.gql'
import notificationSetAllReadGql from 'graphql/APP/Notification/mutation/notificationSetAllRead.gql'
import { IconBars, IconClose } from '@zipou/front_tools'
import { userContext } from 'context/User'

type NotificationHelperProps = {

}

export const NotificationHelper = (props: NotificationHelperProps) => {

  const history = useHistory()

  const [display, updateDisplay] = useState<boolean>(false)

  const { data, refetch } = useQuery(notificationListGql)

  const [mutate] = useMutation(notificationSetReadGql)
  const [mutateAllRead] = useMutation(notificationSetAllReadGql)

  const onNotifClick = (notification: Notification, target: string) => {
    history.push(target)
    updateDisplay(false)
    //Set Read
    mutate({
      variables: {
        id: notification?.id,
        isRead: true,
      }
    })
  }

  const onClearAll = () => {
    mutateAllRead()
  }

  const user = useContext(userContext)

  useSubscription(NotificationSubscriptionGql, {
    onSubscriptionData: () => {
      refetch()
    },
    skip: !user,
  })


  // const user = useContext(userContext)

  const notificationList: Notification[] = [...data?.list || []].sort((el1, el2) => new Date(el1?.date)?.getTime() > new Date(el2?.date)?.getTime() ? -1 : 1) || []
  const hasNotifs = notificationList?.length > 0
  const nbUnread = hasNotifs ? notificationList?.filter(el => el.status === "STATUS_UNREAD")?.length : 0
  const hasUnread = hasNotifs && nbUnread > 0

  return <div className='notification-container'>

    <button className="btn btn-sm btn-light" onClick={() => updateDisplay(!display)}>
      <IconBars />
      {hasUnread && <span className="badge badge-danger">{nbUnread}</span>}
    </button>
    <div className={display ? "banner visible" : "banner"}>
      <div className="close">
        <button className="btn btn-light btn-sm" onClick={() => updateDisplay(false)}>
          <IconClose />
        </button>
      </div>
      <div className="content">
        <h2>Notifications</h2>
        {hasUnread && <div className='mb-2 mt-2' style={{ width: "100%" }} onClick={() => onClearAll()}>
          <button className="btn btn-sm btn-info" style={{ width: "100%" }}>Marquer toutes comme lues</button>
        </div>}
        {hasNotifs && notificationList?.map(notification => {
          return <div className='mb-4' key={`notification_${notification?.id}`}>
            <NotificationDisplay notification={notification} onClick={onNotifClick} />
          </div>
        })}

        {!hasNotifs && <div className="alert alert-info">Rien à afficher </div>}

      </div>
    </div>

  </div>

}