import gql from "graphql-tag";
import CorpFragmentGql from "../fragment/CorpFragment.gql";

export default gql`

  ${CorpFragmentGql}

  mutation corpEdit($id: String!, $input: CorpInput!) {
    corp: corpEdit(id: $id, input: $input) {
      ...CorpFragment
    }
  }

`