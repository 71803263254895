import { HotelEdit } from "components/front/PMS/Hotel/Edit/HotelEdit"
import React from "react"
import { RouteComponentProps } from "react-router-dom"

export const HotelEditRoute = (props: RouteComponentProps<{ hotelId: string, focus?: string }>) => {

  const { hotelId, focus } = props?.match?.params
  return <div>
    <HotelEdit id={hotelId} open={true} focus={focus} />
  </div>
}