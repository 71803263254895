import gql from "graphql-tag";
import EventPublicFragmentGql from "graphql/PLANNING/Event/fragment/EventPublicFragment.gql";
import ContactPublicFragment from "graphql/PMS/Contact/fragment/ContactPublicFragment";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import ReservationPublicFragment from "graphql/PMS/Reservation/fragment/ReservationPublicFragment";
import ReservationRateFragment from "graphql/PMS/Reservation/Rate/fragment/ReservationRateFragment";
import ConfirmationAnswerFragment from "../Answer/fragment/ConfirmationAnswerFragment";
import ReservationGroupPublicFragment from "graphql/PMS/Reservation/Group/fragment/ReservationGroupPublicFragment";
import ConfirmationDataFragment from "../Data/fragment/ConfirmationDataFragment";

export default gql`

${EventPublicFragmentGql}
${ReservationPublicFragment}
${ContactPublicFragment}
${HotelPublicFragment}
${ConfirmationAnswerFragment}
${ReservationRateFragment}
${ReservationGroupPublicFragment}
${ConfirmationDataFragment}

fragment ConfirmationPublicFragment on Confirmation {
  id
  creationDate
  status
  confirmationData {
    ...ConfirmationDataFragment
  }
  Answer {
    ...ConfirmationAnswerFragment
  }
  Hotel {
    ...HotelPublicFragment
  }
  Contact {
    ...ContactPublicFragment
  }
  ServiceEvent {
    ...EventPublicFragment
  }
  ReservationGroup {
    ...ReservationGroupPublicFragment
  }
  Reservation {
    ...ReservationPublicFragment
  }

}

`