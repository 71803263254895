import gql from "graphql-tag";

export default gql`


  fragment CheckinIdentityPictureFragment on CheckinIdentityPicture {
    id
    encryptedFileId  
  }

`