import gql from "graphql-tag";

export default gql`

fragment PhoneSipConfigFragment on PhoneSipConfig {
  user
  password
  server
}

`