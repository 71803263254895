import {Reservation} from "model"
import React from "react"
import {ReservationDetails} from "../Details/ReservationDetails"

type ReservationListProps = {
  list: Reservation[],
}

export const ReservationList = (props: ReservationListProps) => {

  const {list} = props
  const hasReservations = list?.length > 0;
  const listSorted = list?.slice()?.sort((el1: any, el2: any) => (el1?.arrivalStamp > el2.arrivalStamp) ? -1 : 1)

  return <div className="card">
    <div className="card-header bg-dark text-white">
      Réservation
    </div>
      <div className="card-body">
        <div className="row">
          {hasReservations && listSorted?.map((resa: any, index: number) => {
            return <div className="col-12">
              <ReservationDetails reservationId={resa?.id} key={`resa_${index}`} open={index===0} name={resa?.Hotel?.name} status={resa?.status} arrivalDate={resa.arrivalDate} themeColor={resa?.Hotel?.themeColor} />
            </div>
          })}
        </div>
        {!hasReservations && <div className="alert alert-secondary">Aucune réservation</div>}
      </div>
    </div>
  }