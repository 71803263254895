import { useQuery } from '@apollo/react-hooks'
import { EventListForReservation } from 'components/front/PLANNING/Event/List/EventListForReservation'
import preStayGet from 'graphql/PMS/Stay/Pre/query/preStayGet'
import { PreStay } from 'model'
import React from 'react'

type PreStayViewProps = {
  preStayId: string,
}

export const PreStayView = (props: PreStayViewProps) => {

  const { data } = useQuery<{ preStay: PreStay }>(preStayGet, {
    variables: {
      id: props?.preStayId,
    },
    skip: !props?.preStayId,
  })

  const preStay: PreStay = data?.preStay
  const content = preStay?.preStayContent
  const checkin = preStay?.Checkin

  const hasOptions = content?.optionsSelected


  return <div>
    <div className='card'>
      <div className='card-header'>
        <h2>Check-In</h2>
      </div>
      <div className='card-body'>
        <table className="table table-bordered table-stripped">
          <tbody>
            <tr>
              <th>Tél</th>
              <td>
                <span className='badge badge-success'>
                  {checkin?.phone}
                </span>
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                <span className='badge badge-success'>
                  {checkin?.mail}
                </span>
              </td>
            </tr>
            {/* <tr>
              <th>Identité</th>
              <td>
                <table className='table table-stripped table-bordered'>
                  <tr>
                    <td>N°</td>
                    <td>{content?.checkin?.idNumber}</td>
                  </tr>
                  <tr>
                    <td>Expiration</td>
                    <td>{content?.checkin?.expiration}</td>
                  </tr>
                  <tr>
                    <td>Edité le</td>
                    <td><span className='badge badge-success'>{content?.checkin?.issue}</span></td>
                  </tr>
                </table>
              </td>
            </tr> */}
            <tr>
              <th>Adresse</th>
              <td>
                <table className='table table-stripped table-bordered'>
                  <tr>
                    <td>Adresse</td>
                    <td>
                      <span className='badge badge-success'>
                        {checkin?.address}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Expiration</td>
                    <td>
                      <span className='badge badge-success'>
                        {checkin?.zip} {checkin?.city}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Edité le</td>
                    <td>
                      <span className='badge badge-success'>{checkin?.country}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="mt-3" />

    <div className='card'>
      <div className='card-header'>
        <h2>Services</h2>
      </div>
      <div className='card-body'>
        <table className="table table-bordered table-stripped">
          <tbody>
            <tr>
              <th>Arrivée</th>
              <td>
                <span className='badge badge-success'>
                  {content?.arrival?.arrivalHours}:{content?.arrival?.arrivalMinutes}
                </span>
              </td>
            </tr>
            <tr>
              <th>Transfert ?</th>
              <td>{content?.arrival?.transfert ? <span className='badge badge-success'>OUI</span> : <span className='badge badge-dark'>NON</span>}</td>
            </tr>
            <tr>
              <th>Place de Parking</th>
              <td>{content?.arrival?.parking ? <span className='badge badge-success'>OUI</span> : <span className='badge badge-dark'>NON</span>}</td>
            </tr>
            <tr>
              <th>Borne de Recharge</th>
              <td>{content?.arrival?.charging ? <span className='badge badge-success'>OUI</span> : <span className='badge badge-dark'>NON</span>}</td>
            </tr>
            <tr>
              <th>Commentaire</th>
              <td>
                <span className='badge badge-warning'>
                  {content?.arrival?.comment}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div className="mt-3" />

    <div className='card'>
      <div className='card-header'>
        <h2>Options</h2>
      </div>
      <div className='card-body'>
        {!hasOptions && <div className='alert alert-secondary'>Aucune option sélectionnée</div>}
        {hasOptions && <table className='table table-bordered table-striped'>
          <thead>
            <tr>
              <th>Option</th>
              <th>Quantité</th>
            </tr>
          </thead>
          <tbody>
            {content?.optionsSelected?.map(option => {
              return <tr>
                <td>{option?.Option?.labelFr}</td>
                <td>{option?.quantity}</td>
              </tr>
            })}

          </tbody>
        </table>}
      </div>
    </div>

    <div className="mt-3" />

    <div className='card'>
      <div className='card-header'>
        <h2>Services</h2>
      </div>
      <div className='card-body'>
        <EventListForReservation reservationId={preStay?.Reservation?.id} hotelId={preStay?.hotelId} />
      </div>
    </div>
  </div>

}