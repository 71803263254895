import gql from "graphql-tag";
import PropositionPushFragment from "../fragment/PropositionPushFragment";

export default gql`

  ${PropositionPushFragment}

  subscription PropositionPushFragment($id: String!) {
    proposition: propositionPushUpdate(id: $id) {
      ...PropositionPushFragment
    }
  }

`