import gql from "graphql-tag";
import BillingConfigFragment from "graphql/BILLING/Config/fragment/BillingConfigFragment";
import PhoneConfigFragmentGql from "graphql/PHONE/Config/fragment/PhoneConfigFragment.gql";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";

export default gql`

  ${BillingConfigFragment}
  ${OptionFragmentGql}
  ${PhoneConfigFragmentGql}

  fragment HotelPropositionFragment on Hotel {
    id
    name
    logoUrl
    address
    phoneNumber
    backgroundUrl
    checkInHour
    checkOutHour
    themeColor
    phoneConfigId
    cgvUrlFrFileId
    cgvUrlEnFileId
    PhoneConfig {
      ...PhoneConfigFragment
    }
    BillingConfig {
      ...BillingConfigFragment
    }
    Option {
      ...OptionFragment
    }
  }
`