import gql from "graphql-tag";
import EventServiceFragment from "graphql/PLANNING/Event/fragment/EventServiceFragment";



export default gql`

  ${EventServiceFragment}

  mutation confirmationAddEvent($id: String!, $event: EventInput!) {
    event: confirmationAddEvent(id: $id, event: $event) {
      ...EventServiceFragment
    }
  }


`