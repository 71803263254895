import React, { useContext, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';

import './style.scss'
import { useMutation } from "@apollo/react-hooks";
import { LoginToken } from "model";

import userLoginByGoogleTokenMutation from "graphql/APP/User/mutation/userLoginByGoogleToken";
import { tokenContext } from "context/tokenContext";

export type LoginFormProps = {
  onSuccess?: () => void,
  // onLogin: (tokenId: string) => Promise<boolean>,
}

export const LoginForm = (props: LoginFormProps) => {

  const { updateToken } = useContext(tokenContext)
  const [alert, updateAlert] = useState(null)

  const [loginByGoogleTokenMutate] = useMutation<{ loginToken: LoginToken }>(userLoginByGoogleTokenMutation)

  const handleLoginByGoogleToken = async (tokenId: string) => {
    const response = await loginByGoogleTokenMutate({
      variables: {
        token: tokenId,
      }
    })

    const { loginToken } = response?.data || {}
    const { token, expiration } = loginToken || {};

    // saveLoginToken(token, expiration)
    updateToken(token, expiration)
    props.onSuccess()

    return true
  }


  const handleGoogleLogin = async (payload: any) => {
    const { credential } = payload
    if (credential) {
      handleLoginByGoogleToken(credential)
        .then((response: any) => {
          // console.log("RESPONSE", response)
          if (!response) {
            updateAlert("Oups, cette adresse ne semble pas être autorisée :(")
          }
        })
    }
  }


  return <div className='login-container'>

    <h1>Connexion</h1>
    {alert && <div className="col-12">
      <div className="alert alert-danger">{alert}</div>
    </div>}
    <div className="row">
      <div className="col-12">
        <div>
          <GoogleLogin onSuccess={handleGoogleLogin}
            auto_select={false}
            useOneTap={true}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
      </div>
    </div>
  </div >

}
