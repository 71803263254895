import gql from "graphql-tag";
import FileFragment from "../fragment/FileFragment";

export default gql`

  ${FileFragment}

  query fileListByHotelId($hotelId: String!) {
    list: fileListByHotelId(hotelId: $hotelId) {
      ...FileFragment
    }
  }
`