import {useQuery} from "@apollo/react-hooks";
import {Contact as ContactComponent} from "components/public/PHONE/Contact/Contact"
import {ConfirmationPublicDetails} from "components/public/PMS/Confirmation/Details/ConfirmationPublicDetails";
import {HotelContainer} from "components/public/PMS/Hotel/HotelContainer";
import confirmationGetByIdAndPublicId from "graphql/PMS/Confirmation/query/confirmationGetByIdAndPublicId";
import React from "react"

export const ConfirmationPublicDetailsRoute = (props: any) => {

  const {match} = props;
  const {params} = match

  const {publicId, id} = params


  const {data, loading} = useQuery(confirmationGetByIdAndPublicId, {
    variables: {
      id,
      publicId,
    },
  })


  const hotel = data?.confirmation?.Hotel;
  const contact = data?.confirmation?.Contact;
  const confirmation = data?.confirmation
  const reservation = confirmation?.Reservation
  const hasReservation = !!reservation
  const contactDefaultPhone = (contact?.phoneNumberValid?.length > 0 && contact?.phoneNumberValid[0]) || ""

  return <HotelContainer hotel={hotel} loading={loading}>
    <ConfirmationPublicDetails confirmation={confirmation} />
    {(hasReservation) && <ContactComponent
      hotelId={hotel?.id}
      phoneNumber={contactDefaultPhone}
      countryCode={reservation?.Contact?.countryCode}
      extraVars={{reservationId: reservation?.id}}
    />}
  </HotelContainer>
}