import gql from "graphql-tag";

import UserFragment from "../fragment/UserFragment"

export default gql`

  ${UserFragment}

  query userProfile {
    userProfile {
      ...UserFragment
      isAdmin
    }
  }
`