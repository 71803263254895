import React, { useState } from "react"

type DateTimeSelectFullInputProps = {
  value: Date,
  label?: string,
  isError?: boolean,
  isWarning?: boolean,
  onChange: (value: any) => void
}

export const DateTimeSelectFullInput = (props: DateTimeSelectFullInputProps) => {

  const valueWhenEmpty = new Date(`${new Date().toISOString().split('T')[0]}T08:00:00.000Z`)

  const initialValue = props?.value ? new Date(props?.value) : valueWhenEmpty;
  const initialDate = new Date(new Date(initialValue).getTime() + 1000 * 3600 * 5).toISOString().split('T')[0] || new Date().toISOString().split('T')[0]


  const initialMinutes = initialValue ? (initialValue?.getUTCMinutes()) < 10 ? `0${initialValue?.getUTCMinutes()}` : initialValue?.getUTCMinutes() : 0
  const initialHours = initialValue ? (initialValue?.getUTCHours()) < 10 ? `0${initialValue?.getUTCHours()}` : initialValue?.getUTCHours() : 0
  // const initialTime = initialValue ? `${initialHours}:${initialMinutes}` : "00:00"

  // console.log("props?.value", props?.value)
  // console.log("valueWhenEmpty", valueWhenEmpty)
  // console.log("initialHours", initialHours)
  // console.log("initialMinutes", initialMinutes)
  // console.log("initialValue", initialValue)
  // console.log("initialDate", initialDate)


  const [dateValue, updateDateValue] = useState<string>(initialDate || "")
  const [hourValue, updateHourValue] = useState(initialHours || "")
  const [minuteValue, updateMinuteValue] = useState(initialMinutes || "")

  const onChange = (date: any, hour: any, minute: any) => {
    const value = `${date}T${hour}:${minute}:00.000Z`
    const dateValue = new Date(value)
    const isValid = dateValue instanceof Date && !isNaN(dateValue.getTime())
    if (isValid) props?.onChange(dateValue)
  }

  const onHourChange = (e: any) => {
    const value = e.target.value
    updateHourValue(value)
    onChange(dateValue, value, minuteValue)
  }

  const onMinuteChange = (e: any) => {
    const value = e.target.value
    updateMinuteValue(value)
    onChange(dateValue, hourValue, value)
  }

  const onDateChange = (e: any) => {
    const value = e.target.value
    updateDateValue(value)
    onChange(value, hourValue, minuteValue)
  }

  return <div className="input-group">
    {props?.label && <span className="input-group-text">{props?.label}</span>}
    <input type="date" className="form-control" placeholder={"Date"} value={dateValue || ""} onChange={onDateChange} />
    <select className="form-control" placeholder={"Heure"} value={hourValue || ""} onChange={onHourChange}>
      <option value="" disabled>Heure</option>
      <option value="07">07</option>
      <option value="08">08</option>
      <option value="09">09</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
    </select>
    <select className="form-control" placeholder={"Minute"} value={minuteValue || ""} onChange={onMinuteChange}>
      <option value="" disabled>Minutes</option>
      <option value="00">00</option>
      <option value="05">05</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
      <option value="25">25</option>
      <option value="30">30</option>
      <option value="35">35</option>
      <option value="40">40</option>
      <option value="45">45</option>
      <option value="50">50</option>
      <option value="55">55</option>
    </select>
    {props?.isError && <span className="input-group-text"><span className="icon-warning-sign" /></span>}
    {props?.isWarning && <span className="input-group-text"><span className="icon-warning" /></span>}
  </div>
}