import { useQuery } from "@apollo/react-hooks"
import { DateInput } from "components/common/Form/DateInput"
import { SelectInput } from "components/common/Form/SelectInput"
import { BooleanInput } from "components/common/Form/BooleanInput"
import { TagInput } from "components/common/Form/TagInput/TagInput"
import { TextInput } from "components/common/Form/TextInput"
import contactCountryListGql from "graphql/PMS/Contact/query/contactCountryList.gql"
// import hotelListQuery from "graphql/PMS/Hotel/query/hotelList"
import React from "react"
import { Hotel, ReservationStatusEnum, SegmentationComparisonEnum, SegmentationHasStayTypeEnum, SegmentationInput, SegmentationNbAdultsStayEnum, SegmentationNbChildrenStayEnum, SegmentationOptionsCustomerEmailDomainTypeEnum, SegmentationOptionsOriginTypeEnum, SegmentationStayWhenEnum, SegmentationStayWhereEnum } from "model"
import { IconTrash, NumberInput } from "@zipou/front_tools"
import { ReservationStatus } from "components/front/PMS/Reservation/Status/ReservationStatus"
import hotelListGql from "graphql/PMS/Hotel/query/hotelList"

// Customer qui ont déjà sejournée dans X hotels entre
type SegmentationFormProps = {
  segmentation: SegmentationInput,
  errors: any,
  onChange: (form: SegmentationInput) => void,
}

export const SegmentationForm = (props: SegmentationFormProps) => {


  const countryListResponse = useQuery(contactCountryListGql)
  const countryList = countryListResponse?.data?.list

  const { data } = useQuery<{ list: Hotel[] }>(hotelListGql)
  const hotelList = data?.list

  const segmentation = props?.segmentation
  const { name, displayCustomerDashboard, options } = segmentation || {};
  const { stay, contact, origin, customer } = options || {};

  const mustSelectStay = stay?.type === SegmentationHasStayTypeEnum.NO || stay?.type === SegmentationHasStayTypeEnum.YES
  const mustSelectResort = stay?.where === SegmentationStayWhereEnum.IN_INTERSECTION || stay?.where === SegmentationStayWhereEnum.IN_UNION || stay?.where === SegmentationStayWhereEnum.BESIDE
  const mustSelectDate = stay?.when === SegmentationStayWhenEnum.BESIDE || stay?.when === SegmentationStayWhenEnum.BETWEEN
  const hasOriginPms = origin?.type === SegmentationOptionsOriginTypeEnum.ORIGIN_PMS

  const updateForm = (value: any) => {
    props?.onChange(value)
  }


  return <div>

    <TextInput label={"Nom de la liste"} id="name" errors={props?.errors} value={name || ""} onChange={(e: any) => {
      updateForm({
        ...segmentation,
        name: e.target.value,
      })
    }} />

    <BooleanInput label={"Afficher dans la fiche client ?"} id="name" errors={props?.errors} value={displayCustomerDashboard} onChange={(v) => {
      updateForm({
        ...segmentation,
        displayCustomerDashboard: v,
      })
    }} />

    <div className="input-group">
      <div className="input-group-text">Origine</div>
      <div className="form-control" style={{ height: "100%" }}>

        <div className="input-group">
          <div className="input-group-text">Source</div>
          <select className="form-control" value={origin?.type || ""} onChange={(e: any) => {
            updateForm({
              ...segmentation,
              options: {
                ...options,
                origin: {
                  ...origin,
                  type: (e.target.value) === "" ? null : (e.target.value as SegmentationOptionsOriginTypeEnum),
                }
              }
            })
          }} >
            <option value="">Toutes</option>
            <option value={SegmentationOptionsOriginTypeEnum.ORIGIN_PMS}>PMS</option>
            <option value={SegmentationOptionsOriginTypeEnum.ORIGIN_WIDGET}>WIDGET</option>
            <option value={SegmentationOptionsOriginTypeEnum.ORIGIN_ECOMMERCE}>SITE INTERNET</option>
          </select>
        </div>

        {hasOriginPms && <div className="input-group">
          <div className="input-group-text">Etablissement</div>
          <div className="form-control" style={{ height: "100%" }}>
            {hotelList?.map((hotel: any) => {
              return <div key={`hotel_${hotel?.id}`} className="form-check">
                <input type="checkbox" value={hotel?.id} id={`hotel_check_${hotel?.id}`} checked={(origin?.hotelList?.includes(hotel?.id))} onChange={(e: any) => {
                  updateForm({
                    ...segmentation,
                    options: {
                      ...options,
                      origin: {
                        ...origin,
                        hotelList: e.target.checked ? [...(origin?.hotelList || []), hotel?.id] : [...(origin?.hotelList?.filter((id: any) => id !== hotel?.id) || [])]
                      }
                    }
                  })
                }} />
                <label className="form-check-label" htmlFor={`hotel_check_${hotel?.id}`}>{hotel?.name}</label>
              </div>
            })}
          </div>
        </div>}
      </div>
    </div>
    <div className="input-group">
      <div className="input-group-text">Réservation</div>
      <div className="form-control" style={{ height: "100%" }}>
        <div className="input-group">
          <div className="input-group-text">A une résa</div>
          <select className="form-control" value={stay?.type || ""} onChange={(e: any) => {
            updateForm({
              ...segmentation,
              options: {
                ...options,
                stay: {
                  ...stay,
                  type: e.target.value === "" ? null : e.target.value as SegmentationHasStayTypeEnum,
                }
              }
            })
          }} >
            <option value="">Peu importe</option>
            <option value={SegmentationHasStayTypeEnum.YES}>Oui</option>
            <option value={SegmentationHasStayTypeEnum.NO}>Non</option>
          </select>
        </div>

        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Statuts</div>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="d-flex flew-row" style={{ flexWrap: "wrap", alignContent: "center", justifyContent: "flex-start" }}>
              {Object.values(ReservationStatusEnum).map((status) => {
                const checked = options?.stay?.statusList?.includes(status as ReservationStatusEnum)
                return <div className="ml-2 mr-2 d-flex flex-row" style={{ whiteSpace: "nowrap" }}>
                  <input type="checkbox" id={`status_${status}`} checked={checked} onChange={(e) => {
                    updateForm({
                      ...segmentation,
                      options: {
                        ...options,
                        stay: {
                          ...stay,
                          statusList: e.target.checked ? [...(options?.stay?.statusList || []), status] : options?.stay?.statusList?.filter(s => s !== status)
                        }
                      }
                    })
                  }}></input>
                  <label htmlFor={`status_${status}`} style={{ marginBottom: 0 }}>
                    <ReservationStatus value={status} />
                  </label>
                </div>
              })}
            </div>
          </div>
        </div>}

        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Où</div>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <div className="input-group-text">Où ?</div>
              <select className="form-control" name="type" value={stay?.where || ""} onChange={(e: any) => {
                updateForm({
                  ...segmentation,
                  options: {
                    ...options,
                    stay: {
                      ...stay,
                      where: (e.target.value === "") ? null : e.target.value,
                    }
                  }
                })
              }} >
                <option value="">Dans n'importe quel établissement</option>
                <option value={SegmentationStayWhereEnum.IN_UNION}>Dans l'un ou l'autre des établissements séléctionnés (Union)</option>
                <option value={SegmentationStayWhereEnum.IN_INTERSECTION}>Dans tous les établissements séléctionnés (Intersection)</option>
                <option value={SegmentationStayWhereEnum.BESIDE}>Partout sauf dans l'établissement</option>
              </select>
            </div>
            {stay?.where === SegmentationStayWhereEnum.IN_INTERSECTION && <div className="alert alert-warning">
              Attention, le calcul des intersections est long
            </div>}
            {mustSelectResort && <div className="input-group">
              <div className="input-group-text">Etablissement</div>
              <div className="form-control" style={{ height: "100%" }}>
                {hotelList?.map((hotel: any) => {
                  return <div key={`hotel_${hotel?.id}`} className="form-check">
                    <input type="checkbox" value={hotel?.id} id={`hotel_check_${hotel?.id}`} checked={(stay?.hotelList?.includes(hotel?.id))} onChange={(e: any) => {
                      updateForm({
                        ...segmentation,
                        options: {
                          ...options,
                          stay: {
                            ...stay,
                            hotelList: e.target.checked ? [...(stay?.hotelList || []), hotel?.id] : [...(stay?.hotelList?.filter((id: any) => id !== hotel?.id) || [])]
                          }
                        }
                      })
                    }} />
                    <label className="form-check-label" htmlFor={`hotel_check_${hotel?.id}`}>{hotel?.name}</label>
                  </div>
                })}
              </div>
            </div>}
          </div>
        </div>}
        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Quand</div>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <div className="input-group-text">Quand ?</div>
              <select className="form-control" name="when" value={stay?.when || ""} onChange={(e: any) => {
                updateForm({
                  ...segmentation,
                  options: {
                    ...options,
                    stay: {
                      ...stay,
                      when: (e.target.value === "") ? null : e.target.value,
                    }
                  }
                })
              }} >
                <option value="">A n'importe quelle date</option>
                <option value={SegmentationStayWhenEnum.BETWEEN}>Entre</option>
                <option value={SegmentationStayWhenEnum.BESIDE}>N'importe quand sauf entre</option>
              </select>
            </div>
            {mustSelectDate &&
              <div>
                <DateInput value={stay?.dateStart || ""} label="Date Début" onChange={(_, d) => {
                  updateForm({
                    ...segmentation,
                    options: {
                      ...options,
                      stay: {
                        ...stay,
                        dateStart: d,
                      }
                    }
                  })
                }} />
                <DateInput value={stay?.dateEnd || ""} label="Date Fin" onChange={(_, d) => {
                  updateForm({
                    ...segmentation,
                    options: {
                      ...options,
                      stay: {
                        ...stay,
                        dateEnd: d,
                      }
                    }
                  })
                }} />
              </div>}
          </div>
        </div>}
        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Combien de personnes</div>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <div className="input-group-text">Nombre d'adultes</div>
              <select className="form-control" name="nbAdults" value={stay?.nbAdults || ""} onChange={(e: any) => {
                updateForm({
                  ...segmentation,
                  options: {
                    ...options,
                    stay: {
                      ...stay,
                      nbAdults: (e.target.value === "") ? null : e.target.value,
                    }
                  }
                })
              }} >
                <option value="">Peu importe</option>
                <option value={SegmentationNbAdultsStayEnum.ONE}>1</option>
                <option value={SegmentationNbAdultsStayEnum.TWO}>2</option>
                <option value={SegmentationNbAdultsStayEnum.MORE}>{">2"}</option>
              </select>
            </div>
            <div className="input-group">
              <div className="input-group-text">Nombre d'enfants</div>
              <select className="form-control" name="nbChildren" value={stay?.nbChildren || ""} onChange={(e: any) => {
                updateForm({
                  ...segmentation,
                  options: {
                    ...options,
                    stay: {
                      ...stay,
                      nbChildren: (e.target.value === "") ? null : e.target.value,
                    }
                  }
                })
              }} >
                <option value="">Peu importe</option>
                <option value={SegmentationNbChildrenStayEnum.ZERO}>Sans enfant</option>
                <option value={SegmentationNbChildrenStayEnum.ANY}>Avec un/des enfant(s)</option>
              </select>
            </div>
          </div>
        </div>}
        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Combien de nuits</div>
          <div className="form-control" style={{ height: "100%" }}>
            <SelectInput defaultValueActive defaultValue="Peu Importe" value={stay?.nbNight?.type || ""} label="Opérateur" errors={{}} choiceList={[
              { id: SegmentationComparisonEnum.TYPE_EQUAL, label: "Egal à" },
              { id: SegmentationComparisonEnum.TYPE_LESS_THAN, label: "Inférieur à" },
              { id: SegmentationComparisonEnum.TYPE_MORE_THAN, label: "Supérieur à" },
            ]} onChange={(e, value) => {
              const newValue = value === "" ? null : value as SegmentationComparisonEnum
              updateForm({
                ...segmentation,
                options: {
                  ...options,
                  stay: {
                    ...stay,
                    nbNight: {
                      ...stay?.nbNight,
                      type: newValue
                    }
                  }
                }
              })
            }} />
            <NumberInput id={"value"} value={`${stay?.nbNight?.value}` || ""} label="Valeur" errors={{}} onChange={(e, value) => {
              updateForm({
                ...segmentation,
                options: {
                  ...options,
                  stay: {
                    ...stay,
                    nbNight: {
                      ...stay?.nbNight,
                      value,
                    }
                  }
                }
              })
            }} />
          </div>
        </div>}
        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Combien de Reservations</div>
          <div className="form-control" style={{ height: "100%" }}>
            <SelectInput defaultValueActive defaultValue="Peu importe" value={stay?.nbReservations?.type || ""} label="Opérateur" errors={{}} choiceList={[
              { id: SegmentationComparisonEnum.TYPE_EQUAL, label: "Egal à" },
              { id: SegmentationComparisonEnum.TYPE_LESS_THAN, label: "Inférieur à" },
              { id: SegmentationComparisonEnum.TYPE_MORE_THAN, label: "Supérieur à" },
            ]} onChange={(e, value) => {
              updateForm({
                ...segmentation,
                options: {
                  ...options,
                  stay: {
                    ...stay,
                    nbReservations: {
                      ...stay?.nbReservations,
                      type: value === "" ? null : value as SegmentationComparisonEnum
                    }
                  }
                }
              })
            }} />
            <NumberInput id={"value"} value={`${stay?.nbReservations?.value}` || ""} label="Valeur" errors={{}} onChange={(e, value) => {
              updateForm({
                ...segmentation,
                options: {
                  ...options,
                  stay: {
                    ...stay,
                    nbReservations: {
                      ...stay?.nbReservations,
                      value,
                    }
                  }
                }
              })
            }} />
          </div>
        </div>}
        {mustSelectStay && <div className="input-group">
          <div className="input-group-text">Options & Services</div>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <div className="input-group-text">A consommé du Spa ?</div>
              <select className="form-control" name="hasSpa" value={stay?.hasSpa === true ? 1 : stay?.hasSpa === false ? 0 : ""} onChange={(e: any) => {
                updateForm({
                  ...segmentation,
                  options: {
                    ...options,
                    stay: {
                      ...stay,
                      hasSpa: e.target.value === "1" ? true : e.target.value === "0" ? false : null,
                    }
                  }
                })
              }} >
                <option value="">Peu importe</option>
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>
          </div>
        </div>}
      </div>
    </div>
    <div className="input-group">
      <div className="input-group-text">Cardex</div>
      <div className="form-control" style={{ height: "100%" }}>
        <SelectInput id={"gender"} label="Genre" defaultValueActive defaultValue="Peu Importe" errors={props?.errors} disabled={false} choiceList={[{ id: "MALE", label: "MASCULIN" }, { id: "FEMALE", label: "FEMININ" }]} value={contact?.gender} onChange={(_, v) => {
          updateForm({
            ...segmentation,
            options: {
              ...options,
              contact: {
                ...contact,
                gender: (v === "") ? null : v,
              }
            }
          })
        }} />
        <TagInput placeholder="Rechercher un pays" minLength={1} value={contact?.country?.map((el: string) => ({ id: el, label: countryList?.find((el2: any) => el2.codeShort === el)?.name }))} choiceList={countryList?.map((country: any) => ({ id: country?.codeShort, label: country?.name }))} label="Pays" id="country" onChange={(v: any[]) => {
          updateForm({
            ...segmentation,
            options: {
              ...options,
              contact: {
                ...contact,
                country: v.map((el: any) => el.id),
              }
            }
          })
        }} />

      </div>
    </div>
    <div className="input-group">
      <div className="input-group-text">Client</div>
      <div className="form-control" style={{ height: "100%" }}>

        <div className="input-group">
          <div className="input-group-text">Domaine d'Email</div>
          <div className="form-control" style={{ height: "100%" }}>
            <SelectInput label="Action" id="type" value={customer?.emailDomain?.type || ""} errors={{}} defaultValueActive defaultValue="Peu Importe" choiceList={[{ id: SegmentationOptionsCustomerEmailDomainTypeEnum.TYPE_INCLUDE, label: "Inclure uniquement" }, { id: SegmentationOptionsCustomerEmailDomainTypeEnum.TYPE_EXCLUDE, label: "Exclure" }]} onChange={(_, v) => {
              updateForm({
                ...segmentation,
                options: {
                  ...options,
                  customer: {
                    ...customer,
                    emailDomain: {
                      ...(customer?.emailDomain || {}),
                      type: (v === "") ? null : v as SegmentationOptionsCustomerEmailDomainTypeEnum
                    }
                  }
                }
              })
            }} />

            {customer?.emailDomain?.type && <div className="input-group">
              <div className="input-group-text">Liste</div>
              <div className="form-control" style={{ height: "100%" }}>
                {customer?.emailDomain?.domainList?.map((domain, index) => {
                  return <div>
                    <TextInput value={domain} id="domain" errors={props?.errors?.customer?.emailDomain?.domainList && props?.errors?.customer?.emailDomain?.domainList[index]} label="Domaine" onChange={(_, v) => {
                      updateForm({
                        ...segmentation,
                        options: {
                          ...options,
                          customer: {
                            ...customer,
                            emailDomain: {
                              ...(customer?.emailDomain || {}),
                              domainList: customer?.emailDomain?.domainList?.map((domain, idx) => (idx === index) ? v : domain)
                            }
                          }
                        }
                      })
                    }} rightLabel={
                      <span onClick={() => {
                        updateForm({
                          ...segmentation,
                          options: {
                            ...options,
                            customer: {
                              ...customer,
                              emailDomain: {
                                ...(customer?.emailDomain || {}),
                                domainList: customer?.emailDomain?.domainList?.filter((domain, idx) => (idx !== index))
                              }
                            }
                          }
                        })
                      }}>
                        <IconTrash />
                      </span>
                    } />
                  </div>
                })}

                <button className="btn btn-sm btn-dark" onClick={() => {
                  updateForm({
                    ...segmentation,
                    options: {
                      ...options,
                      customer: {
                        ...customer,
                        emailDomain: {
                          ...(customer?.emailDomain || {}),
                          domainList: [
                            ...(customer?.emailDomain?.domainList || []),
                            "",
                          ]
                        }
                      }
                    }
                  })
                }}>Ajouter</button>
              </div>
            </div>}


          </div>
        </div>

      </div>
    </div>
  </div>
}