import gql from "graphql-tag";
import TerminalFragmentGql from "../fragment/TerminalFragment.gql";

export default gql`

${TerminalFragmentGql}

  query terminalGet($id: String!) {
    terminal: terminalGet(id: $id) {
      ...TerminalFragment
    }
  }

`