import gql from "graphql-tag";
import HotelEditFragment from "../fragment/HotelEditFragment";

export default gql`

  ${HotelEditFragment}

  query hotelGet($id: String!) {
    hotel: hotelGet(id: $id) {
      ...HotelEditFragment
    }
  }

`