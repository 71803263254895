import gql from "graphql-tag";
import PropositionPublicFragment from "../fragment/PropositionPublicFragment";

export default gql`

  ${PropositionPublicFragment}

  mutation propositionSelectRow($id: String!, $publicId: String!, $rowId: String!) {
    proposition: propositionSelectRow(id: $id, publicId: $publicId, rowId: $rowId) {
      ...PropositionPublicFragment
    }
  }

`