import gql from "graphql-tag";

export default gql` 

fragment VideoConfigFragment on VideoConfig {
  id
  token
  videoConfigId
}

`