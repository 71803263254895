import gql from "graphql-tag";
import UserFragment from "../fragment/UserFragment";

export default gql`

  ${UserFragment}

  mutation userAdd($input: UserInput!) {
    userAdd(input: $input) {
      ...UserFragment
    }
  }

`