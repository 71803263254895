import gql from "graphql-tag";
import RoomTypeLabelFragmentGql from "../fragment/RoomTypeLabelFragment.gql";

export default gql`

  ${RoomTypeLabelFragmentGql}

  query roomTypeGet($id: String!) {
    roomType: roomTypeGet(id: $id) {
      ...RoomTypeLabelFragment
    }
  }

`