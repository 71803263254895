import gql from "graphql-tag";
import PaymentFragment from "graphql/BILLING/Payment/fragment/PaymentFragment";
import PaymentListFragment from "graphql/BILLING/Payment/fragment/PaymentListFragment";
import TokenFragment from "graphql/BILLING/Token/fragment/TokenFragment";
import CustomerNameFragment from "graphql/CRM/Customer/fragment/CustomerNameFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}
  ${CustomerNameFragment}
  ${TokenFragment}
  ${PaymentListFragment}

  fragment ContactFragment on Contact {
    id
    pmsId
    firstName
    lastName
    gender
    email
    updateDate
    type
    phone1
    phone2
    isPhone1Valid
    isPhone2Valid
    countryCode
    forceIsFrench
    isFrench
    Token {
      ...TokenFragment
    }
    Payment {
      ...PaymentListFragment
    }
    Customer {
      ...CustomerNameFragment
    }
    Hotel {
      ...HotelDisplayFragment
    }
  }
`