import { RoomTypeInput, UserRoleEnum } from "model"
import React, { useContext } from "react"
import { RoomTypeForm } from "../Form/RoomTypeForm"
import { userContext } from "context/User"
import { TextInput } from "@zipou/front_tools"

type RoomTypeEditProps = {
  hotelId: string,
  roomType: RoomTypeInput,
  errors: any,
  onChange: (roomType: RoomTypeInput) => void,
}

export const RoomTypeEdit = ({ roomType, hotelId, errors, onChange }: RoomTypeEditProps) => {

  const user = useContext(userContext)

  const hasDebug = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  return <div>
    <TextInput label="Id" value={roomType?.id} disabled onChange={() => { }} errors={{}} />
    <RoomTypeForm roomType={roomType} errors={errors} onChange={onChange} hotelId={hotelId} />
  </div>

}