import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import TokenFragment from "../fragment/TokenFragment";
import gql from "graphql-tag";

export default gql`

${CursorFragment}
${TokenFragment}

query tokenList($cursor: CursorInput!, $input: TokenListInput!) {
  list: tokenList(cursor: $cursor, input: $input) {
    input
    cursor {
      ...CursorFragment
    }
    nodes {
      ...TokenFragment
    }
  }
}

`