import { TemplateTypeEnum } from "model"
import React from "react"

type TemplateTypeDisplayProps = {
  type: TemplateTypeEnum
}

export const TemplateTypeDisplay = (props: TemplateTypeDisplayProps) => {
  switch (props?.type) {

    case TemplateTypeEnum.TYPE_HTML: {
      return <span className="badge badge-dark">HTML</span>
    }

    case TemplateTypeEnum.TYPE_UNLAYER: {
      return <span className="badge badge-secondary">EDITEUR</span>
    }


    default: {
      return <span className="badge badge-info">{props?.type}</span>
    }
  }
}