import gql from "graphql-tag";
import RoomTypeFragmentGql from "graphql/PMS/Room/Type/fragment/RoomTypeFragment.gql";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";
import PriceRateTypeFragmentGql from "graphql/PMS/PriceRate/Type/fragment/PriceRateTypeFragment.gql";
import PriceRateCancelConditionFragment from "graphql/PMS/PriceRate/CancelCondition/fragment/PriceRateCancelConditionFragment.gql";
import BillingConfigAdminFragment from "graphql/BILLING/Config/fragment/BillingConfigAdminFragment";
import ServiceEditFragment from "graphql/PMS/Service/fragment/ServiceEditFragment";
import PostStayConfigFragment from "graphql/PMS/Stay/Post/Config/fragment/PostStayConfigFragment";
import SeasonalityFragment from "graphql/PMS/Seasonality/fragment/SeasonalityFragment";
import PhoneConfigFragmentGql from "graphql/PHONE/Config/fragment/PhoneConfigFragment.gql";
import NetworkConfigFragmentGql from "graphql/NETWORK/Config/fragment/NetworkConfigFragment.gql";
import PmsConfigFragmentGql from "graphql/PMS/Config/fragment/PmsConfigFragment.gql";
import GoogleConfigFragmentGql from "graphql/PMS/Social/Google/fragment/GoogleConfigFragment.gql";
import TripAdvisorConfigGql from "graphql/PMS/Social/TripAdvisor/fragment/TripAdvisorConfig.gql";
import ConfirmationConfigFragmentGql from "graphql/PMS/Confirmation/Config/fragment/ConfirmationConfigFragment.gql";
import PropositionConfigFragmentGql from "graphql/PMS/Proposition/Config/fragment/PropositionConfigFragment.gql";
import HotelTokenFragmentGql from "../Token/fragment/HotelTokenFragment.gql";
import VideoConfigFragmentGql from "graphql/VIDEO/Config/fragment/VideoConfigFragment.gql";
import RoomFragmentGql from "graphql/PMS/Room/fragment/RoomFragment.gql";
import StripeConfigFragmentGql from "graphql/BILLING/Stripe/Config/fragment/StripeConfigFragment.gql";
import DeviceFragmentGql from "graphql/APP/Device/fragment/DeviceFragment.gql";

export default gql`

  ${HotelTokenFragmentGql}
  ${NetworkConfigFragmentGql}
  ${RoomFragmentGql}
  ${RoomTypeFragmentGql}
  ${OptionFragmentGql}
  ${PriceRateTypeFragmentGql}
  ${PriceRateCancelConditionFragment}
  ${BillingConfigAdminFragment}
  ${PostStayConfigFragment}
  ${ServiceEditFragment}
  ${SeasonalityFragment}
  ${PhoneConfigFragmentGql}
  ${PmsConfigFragmentGql}
  ${GoogleConfigFragmentGql}
  ${TripAdvisorConfigGql}
  ${ConfirmationConfigFragmentGql}
  ${PropositionConfigFragmentGql}
  ${VideoConfigFragmentGql}
  ${DeviceFragmentGql}

  fragment HotelEditFragment on Hotel {
    id
    name
    initials
    email
    activeFetcher
    lastFetch
    lastFetchError
    logoUrl
    logoMonoUrl
    address
    phoneNumber
    cgvUrlEn
    cgvUrlFr
    cgvUrlEnFileId
    cgvUrlFrFileId
    backgroundUrl
    checkInHour
    checkOutHour
    breakfastStartHour
    breakfastEndHour
    themeColor
    backgroundThemeColor
    stayTax
    phoneConfigId
    notificationDest
    emailSender {
      name
      address
    }
    displayRoomPictureDisclosure
    displayRoomCategoryInDetails
    BillingConfig {
      ...BillingConfigAdminFragment
    }
    Option {
      ...OptionFragment
    }
    Room {
      ...RoomFragment
    }
    RoomType {
      ...RoomTypeFragment
    }
    PriceRateType {
      ...PriceRateTypeFragment
    }
    PriceRateCancelCondition {
      ...PriceRateCancelConditionFragment
    }
    PostStayConfig {
      ...PostStayConfigFragment
    }
    ServiceList {
      ...ServiceEditFragment
    }
    Seasonality {
      ...SeasonalityFragment
    }
    PhoneConfig {
      ...PhoneConfigFragment
    }
    NetworkConfig {
      ...NetworkConfigFragment
    }
    PmsConfig {
      ...PmsConfigFragment
    }
    GoogleConfig {
      ...GoogleConfigFragment
    }
    TripAdvisorConfig {
      ...TripAdvisorConfigFragment
    }
    ConfirmationConfig {
      ...ConfirmationConfigFragment
    }
    PropositionConfig {
      ...PropositionConfigFragment
    }
    HotelToken {
      ...HotelTokenFragment
    }
    VideoConfig {
      ...VideoConfigFragment
    }
    Device {
      ...DeviceFragment
    }
    
  }
`