import { SeasonalityInput } from "model"
import React from "react"
import { SeasonalityForm } from "../Form/SeasonalityForm"

type SeasonalityEditProps = {
  seasonality: SeasonalityInput,
  errors: any,
  onChange: (seasonality: SeasonalityInput) => void,
}

export const SeasonalityEdit = ({ seasonality, errors, onChange }: SeasonalityEditProps) => {
  return <div>
    <SeasonalityForm seasonality={seasonality} onChange={onChange} error={errors} />
  </div>
}