import { CheckinStatusEnum } from "model"
import React from "react"

type CheckinStatusProps = {
  status: CheckinStatusEnum,
}


export const CheckinStatus = ({ status }: CheckinStatusProps) => {

  switch (status) {
    case CheckinStatusEnum.STATUS_TODO: {
      return <span className="badge badge-sm badge-info">A FAIRE</span>
    }
    case CheckinStatusEnum.STATUS_PRE_CHECK_IN: {
      return <span className="badge badge-sm badge-warning">PRE CHECK IN</span>
    }

    case CheckinStatusEnum.STATUS_EXPIRED: {
      return <span className="badge badge-sm badge-expire">EXPIRE</span>
    }

    default: {
      return <span className="badge badge-sm badge-danger">NON COMMENCE</span>
    }
  }


}