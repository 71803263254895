import { getRootUrl } from "helpers/Utils"
import React from "react"

type FileDisplayProps = {
  fileId: string,
}

export const FileContentDisplay = (props: FileDisplayProps) => {

  const rootUrl = `${getRootUrl()}file/${props?.fileId}`

  return <>
    <iframe title="File" src={rootUrl} width={"100%"} height={"100%"} style={{minHeight: "90vh"}} />
  </>
}