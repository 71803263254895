import gql from "graphql-tag";


export default gql`

  fragment CustomerListFragment on Customer {
    id
    mainEmail
    firstName
    lastName
    origin
  }

`