import gql from "graphql-tag";
import NewsEditFragmentGql from "../fragment/NewsEditFragment.gql";

export default gql`

  ${NewsEditFragmentGql}

  mutation newsAdd($input: NewsInput!) {
    news: newsAdd(input: $input) {
      ...NewsEditFragment
    }
  }

`