import { Modal } from "@zipou/front_tools"
import { Customer } from "model"
import React, { useState } from "react"
import { CustomerDetails } from "../Details/CustomerDetails"

export type CustomerButtonProps = {
  customer: Customer
}


export const CustomerButton = ({ customer }: CustomerButtonProps) => {

  const [focus, updateFocus] = useState(false)

  const label = (customer?.lastName && `${customer?.firstName?.charAt(0)?.toUpperCase()}${customer?.firstName?.slice(1)} ${customer?.lastName?.toUpperCase()}`) || customer?.mainEmail
  const header = (customer?.firstName || customer?.lastName) ? `${customer?.firstName} ${customer?.lastName}` : "Client"

  return <>
    {focus && <Modal title={header} display={!!focus} onClose={() => updateFocus(false)}>
      <CustomerDetails customerId={customer?.id} showProposition={true} showReservation={true} showContacts={true} />
    </Modal>}
    <button className="btn btn-sm btn-dark" onClick={() => updateFocus(true)} style={{ cursor: "pointer" }}>
      {label}
    </button>
  </>
}