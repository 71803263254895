import { useQuery } from "@apollo/react-hooks"
import { IconArrowDown, IconArrowRIght, IconArrowUp, IconArrowTrendUp, IconArrowTrendDown, Modal } from "@zipou/front_tools"
import postStayAnswerStatsGetByQuestionIdWithFiltersGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetByQuestionIdWithFilters.gql"
import { PostStayAnswerStats, PostStayAnswerStatsFilterInput } from "model"
import React, { useState } from "react"

type PostStayAnswerStatsDisplayProps = {
  questionId: string,
  filters: PostStayAnswerStatsFilterInput
}

export const PostStayAnswerStatsDisplay = ({ questionId, filters }: PostStayAnswerStatsDisplayProps) => {

  const [displayModal, updateDisplayModal] = useState(false)

  const { data } = useQuery<{ postStayAnswerStats: PostStayAnswerStats }>(postStayAnswerStatsGetByQuestionIdWithFiltersGql, {
    variables: {
      questionId,
      input: filters,
    }
  })
  const stats = data?.postStayAnswerStats
  const hasData = !!stats

  const hasChoice = hasData && stats?.choiceValue
  const hasList = hasData && stats?.listValue && stats?.listValue?.length > 0
  const previousStats = stats?.StatBefore
  const hasPrevious = !!previousStats
  const isEqual = hasPrevious && stats?.avgValue == previousStats?.avgValue
  const isUp = hasPrevious && stats?.avgValue > previousStats?.avgValue
  const isDown = hasPrevious && stats?.avgValue < previousStats?.avgValue


  const displayValue = (value: number) => {
    if (value < 3) {
      return <span style={{ fontSize: 22, color: "red" }}>
        {Number(stats?.avgValue).toFixed(2)}
      </span>
    }
    if (value < 4) {
      return <span style={{ fontSize: 22, color: "#856404" }}>
        {Number(stats?.avgValue).toFixed(2)}
      </span>
    }
    return <span style={{ fontSize: 22, color: "green" }}>
      {Number(stats?.avgValue).toFixed(2)}
    </span>

  }




  return <>
    <Modal display={displayModal} title="Différentes Valeur" onClose={() => updateDisplayModal(false)}>
      <div className="card">
        <ul className="list-group">
          {stats?.listValue?.map((v, i) => {
            return <li key={`an_${i}`} className="list-group-item">{v}</li>
          })}
        </ul>
      </div>
    </Modal>
    {hasList && <div>
      <button className="btn btn-warning btn-sm" onClick={() => updateDisplayModal(true)}>Voir {stats?.listValue?.length} réponse(s)</button>
    </div>}
    {hasData && <div>
      {!hasChoice && <div className="d-flex  flex-row">
        {displayValue(stats?.avgValue)}
        {/* <span style={{ fontSize: 22 }}>
          {Number(stats?.avgValue).toFixed(2)}
        </span> */}
        {hasPrevious && <span className="ml-3">
          {/* <span style={{ fontSize: 16 }}>{previousStats?.avgValue}</span> */}
          {isUp && <span style={{ color: "green", fontSize: 22 }}> <IconArrowTrendUp /></span>}
          {isDown && <span style={{ color: "red", fontSize: 22 }}><IconArrowTrendDown /></span>}
          {isEqual && <span style={{ color: "blue", fontSize: 22 }}><IconArrowRIght /></span>}
        </span>}
      </div>}
      {hasChoice && <table className="table table-striped table-bordered" style={{ margin: 0 }}>
        <tbody>
          {stats?.choiceValue && Object.keys(stats?.choiceValue)?.map((statsKey: any) => {
            const value = stats?.choiceValue[statsKey]?.percent
            const previousValue = previousStats?.choiceValue && previousStats?.choiceValue[statsKey] && previousStats?.choiceValue[statsKey]?.percent

            const hasPrevious = !!previousValue
            const isEqual = hasPrevious && (value.percent == previousValue)
            const isUp = hasPrevious && (value > previousValue)
            const isDown = hasPrevious && (value < previousValue)

            return <tr>
              <td style={{ fontSize: 20, textTransform: "capitalize" }}>{statsKey}</td>
              <td style={{ fontSize: 20 }}>
                {Number(value).toFixed(0)}%
                {isUp && <span style={{ color: "green", fontSize: 22 }}> <IconArrowTrendUp /></span>}
                {isDown && <span style={{ color: "red", fontSize: 22 }}><IconArrowTrendDown /></span>}
                {isEqual && <span style={{ color: "blue", fontSize: 22 }}><IconArrowRIght /></span>}
              </td>
            </tr>
          })}
        </tbody>
      </table>}
    </div >}
    {!hasData && <span className="">Aucune donnée</span>}
  </>
}