import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";
import ContactDedupFragment from "../../fragment/ContactDedupFragment";

export default gql`

  ${ContactDedupFragment}
  ${HotelDisplayFragment}

  fragment ContactDedupListFragment on ContactDedup {
    id
    contactLeftId
    contactRightId
    status
    Hotel {
      ...HotelDisplayFragment
    }
    ContactLeft {
      ...ContactDedupFragment
    }
    ContactRight {
      ...ContactDedupFragment
    }
  }

`