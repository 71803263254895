import gql from "graphql-tag";
import ReservationListFragment from "../fragment/ReservationListFragment";

export default gql`

  ${ReservationListFragment}

  query reservationListByCustomerId($customerId: String!) {
    list: reservationListByCustomerId(customerId: $customerId) {
      ...ReservationListFragment
    }
  }

`