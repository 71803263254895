import gql from "graphql-tag";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import ReceiptInfoFragment from "./../../ReceiptInfo/fragment/ReceiptInfoFragment.gql";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";
import ChargeFragmentGql from "graphql/BILLING/Charge/fragment/ChargeFragment.gql";
import TerminalFragmentGql from "graphql/BILLING/Terminal/fragment/TerminalFragment.gql";

export default gql`

  ${HotelPublicFragment}
  ${ReceiptInfoFragment}
  ${ContactListFragment}
  ${ChargeFragmentGql}
  ${TerminalFragmentGql}

  fragment TokenFragment on Token {
    id
    status
    hotelId
    contactId
    publicId
    publicSalt
    notes
    type
    isError
    lastError
    publicUrl
    publicPath
    creationDate
    captureDate
    provider
    sendStatus
    Options
    terminalId
    Terminal {
      ...TerminalFragment      
    }
    Charge {
      ...ChargeFragment
    }
    Hotel {
      ...HotelPublicFragment
    }
    receiptInfo {
      ...ReceiptInfoFragment
    }
    Contact {
      ...ContactListFragment
    }


  }

`