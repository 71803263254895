import { useState } from "react"
import { NewsForm } from "../Form/NewsForm"
import { News, NewsInput } from "model"
import React from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import newsUpdateGql from "graphql/APP/News/mutation/newsUpdate.gql"
import newsGetGql from "graphql/APP/News/query/newsGet.gql"

type NewsEditProps = {
  id: string,
  onDone: () => void,
}

export const NewsEdit = ({ id, onDone }: NewsEditProps) => {

  const [news, updateNews] = useState<NewsInput>(null)
  const [errors, updateErrors] = useState<any>(null)

  const [mutate] = useMutation(newsUpdateGql)

  useQuery<{ news: News }>(newsGetGql, {
    variables: {
      id
    },
    skip: !id,
    onCompleted: (data) => {
      const { id, ...newsInput } = data?.news
      updateNews(newsInput)
    },
  })


  const onReset = () => {
    onDone && onDone()
  }

  const onSave = () => {
    mutate({
      variables: {
        id,
        input: news,
      }
    })
      .then(() => onDone && onDone())
      .catch((e) => updateErrors(formatErrorResponseForJoi(e)))
  }

  return <div>

    {news && <NewsForm news={news} errors={errors} onChange={(n) => {
      // console.log('n', n);
      updateNews(n)
    }
    } />}

    <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-danger btn-sm ml-1" onClick={() => onReset()}>Annuler</button>
    </div>
  </div>
}