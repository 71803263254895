import { useQuery } from "@apollo/react-hooks"
// import {alertContext} from "context/Alert/alert"
import React, { useEffect, useState } from "react"

import searchQuery from "graphql/Search/query/search"

import "./style.scss"
import { CustomerDetails } from "../CRM/Customer/Details/CustomerDetails"
// import {ReservationDetails} from "../PMS/Reservation/Details/ReservationDetails"
import { ReservationFullDetails } from "../PMS/Reservation/Details/ReservationFullDetails"
import { ContactView } from "../PMS/Contact/View/ContactView"
import { HotelBadge } from "../PMS/Hotel/Badge/HotelBadge"
import { ReservationButton } from "../PMS/Reservation/Button/ReservationButton"
import { Customer, Reservation } from "model"
import { Modal } from "@zipou/front_tools"
import { CustomerButton } from "../CRM/Customer/Button/CustomerButton"

type SearchProps = {
  query?: string,
}

export const Search = (props: SearchProps) => {

  const hasSearchQuery = !!props?.query

  const [customerId, updateCustomerId] = useState(null)
  const [contactId, updateContactId] = useState(null)
  const [reservationId, updateReservationId] = useState(null)

  const [text, updateText] = useState(props?.query || "")
  const [loading, updateLoading] = useState(hasSearchQuery)
  const [resultList, updateResultList] = useState<any[]>(null)

  const { refetch } = useQuery(searchQuery, {
    skip: !hasSearchQuery,
    fetchPolicy: "no-cache",
    variables: {
      text: props?.query,
    },
    onCompleted: (data: any) => {
      updateResultList(data?.search)
      updateLoading(false)
    }
  })

  const onSubmit = async (e: any) => {
    e.preventDefault();
    onSearch(text)
  }

  const onSearch = async (text: string) => {
    updateLoading(true)
    try {
      const response = await refetch({
        text,
      })
      updateResultList(response?.data?.search)
      updateLoading(false)

    }
    catch (e) {
      updateLoading(false)
      console.log("e")
    }

  }

  const onChange = (value: string) => {
    updateText(value)
  }

  useEffect(() => {

    if (props?.query && text !== props?.query) {
      updateText(props?.query)
      onSearch(props?.query)
    }

  }, [props?.query])


  const customerList: Customer[] = resultList?.filter(el => el.__typename === "Customer") || []
  const segmentation = resultList?.filter(el => el.__typename === "Contact") || []
  const reservationList: Reservation[] = resultList?.filter(el => el.__typename === "Reservation") || []
  const hasCustomerResults = customerList?.length !== 0 || false
  const hasContactResults = segmentation?.length !== 0 || false
  const hasReservationResults = reservationList?.length !== 0 || false

  return <div className="search-container">
    <Modal size="xl" display={!!customerId} onClose={() => updateCustomerId(null)}>
      <CustomerDetails customerId={customerId} showProposition={true} showReservation={true} showContacts={true} />
    </Modal>
    <Modal size="xl" title="Details" display={!!reservationId} onClose={() => updateReservationId(null)}>
      <ReservationFullDetails reservationId={reservationId} />
    </Modal>
    <Modal title="Details" display={!!contactId} onClose={() => updateContactId(null)}>
      <ContactView contactId={contactId} />
    </Modal>
    <div className="search-form">
      <div className="card">
        <div className="card-header bg-dark text-white">
          Rechercher
        </div>
        <div className="card-body">
          <form onSubmit={onSubmit}>
            <div className="input-group">
              <span className="input-group-text">Rechercher</span>
              <input type="text" name="search" autoComplete="off" value={text} className="form-control" placeholder="Entrez un texte pour rechercher par email, numéro, nom et prénom" disabled={loading} onChange={(e: any) => onChange(e.target.value)} />
              {loading && <span className="input-group-text"><span className="icon-loading"></span></span>}
              <button type="submit" className="btn btn-sm btn-dark" disabled={loading}>Rechercher</button>
            </div>
          </form>
        </div>
        <div className="card-footer">
          Total: {resultList?.length || 0}
        </div>
      </div>
    </div>
    <div className="search-result">
      <div className="row">
        {hasReservationResults &&
          <div className="col-12 col-sm-6">
            <div className="card">
              <div className="card-header bg-dark text-white">Reservation</div>
              <div className="card-body">
                <ul className="list-group">
                  {reservationList?.map((reservation) => {
                    //@ts-ignore
                    const { id, pmsId, HotelSearch } = reservation
                    /* eslint-disable jsx-a11y/anchor-is-valid */
                    return <li className="list-group-item" key={id}>
                      <ReservationButton reservationId={id} /> - <HotelBadge hotel={HotelSearch} />
                      {/* <a onClick={() => updateReservationId(id)} style={{cursor: "pointer"}}><span>{label}</span></a> */}
                    </li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        }
        {hasCustomerResults &&
          <div className="col-12 col-sm-6">
            <div className="card">
              <div className="card-header bg-dark text-white">Customer</div>
              <div className="card-body">
                <ul className="list-group">
                  {customerList?.map((customer) => {
                    // const { id, firstName, lastName, mainEmail } = customer
                    // const label = (lastName && `${firstName?.charAt(0)?.toUpperCase()}${firstName?.slice(1)} ${lastName?.toUpperCase()}`) || mainEmail
                    /* eslint-disable jsx-a11y/anchor-is-valid */
                    return <li className="list-group-item" key={customer?.id}>
                      <CustomerButton customer={customer} />
                      {/* <button className="btn btn-sm btn-dark" onClick={() => updateCustomerId(id)} style={{ cursor: "pointer" }}>{label}</button> */}
                    </li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        }
        {hasContactResults &&
          <div className="col-12 col-sm-6">
            <div className="card">
              <div className="card-header bg-dark text-white">Contacts</div>
              <div className="card-body">
                <ul className="list-group">
                  {segmentation?.map((contact: any) => {
                    const { id, pmsId, firstName, lastName, email, Hotel } = contact
                    const label = (lastName && `${firstName} ${lastName}`) || email
                    return <li className="list-group-item" key={id}>
                      <span><button onClick={() => updateContactId(contact?.id)} className="btn btn-sm btn-dark text-white">{pmsId}</button> - <HotelBadge hotel={Hotel} /> - {label}</span>
                    </li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        }
      </div>
    </div>

  </div>
}


