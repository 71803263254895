import gql from "graphql-tag";
import ConfirmationPublicFragment from "../fragment/ConfirmationPublicFragment";

export default gql`

  ${ConfirmationPublicFragment}

  query confirmationGetByIdAndPublicId($id: String!,  $publicId: String!) {
    confirmation: confirmationGetByIdAndPublicId(id: $id, publicId: $publicId) {
      ...ConfirmationPublicFragment
    }
  }
`