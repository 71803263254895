import gql from "graphql-tag";
import CorpFragmentGql from "../fragment/CorpFragment.gql";

export default gql`

  ${CorpFragmentGql}

  mutation corpAdd($input: CorpInput!) {
    corp: corpAdd(input: $input) {
      ...CorpFragment
    }
  }

`