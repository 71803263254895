import gql from "graphql-tag";
import SegmentationMemberFragmentGql from "graphql/CRM/Segmentation/Member/fragment/SegmentationMemberFragment.gql";
import CustomerAddressFragmentGql from "../Address/fragment/CustomerAddressFragment.gql";
import CustomerEmailFragment from "../Email/fragment/CustomerEmailFragment";
import CustomerPhoneFragmentGql from "../Phone/fragment/CustomerPhoneFragment.gql";
import CustomerNewsletterRegistrationFragmentGql from "../NewsletterRegistration/fragment/CustomerNewsletterRegistrationFragment.gql";


export default gql`

  ${SegmentationMemberFragmentGql}
  ${CustomerAddressFragmentGql}
  ${CustomerPhoneFragmentGql}
  ${CustomerEmailFragment}
  ${CustomerNewsletterRegistrationFragmentGql}

  fragment CustomerDetailsFragment on Customer {
    id
    mainEmail
    firstName
    lastName
    origin
    CustomerMetric {
      id
      caTotal
      nbReservation
      nbProposition
    }
    CustomerNewsletterRegistration {
      ...CustomerNewsletterRegistrationFragment
    }
    SegmentationMember {
      ...SegmentationMemberFragment
    }
    CustomerPhone {
      ...CustomerPhoneFragment
    }
    CustomerAddress {
      ...CustomerAddressFragment
    }
    CustomerCard {
      id
      gender
      firstName
      lastName
      company
    }
    CustomerEmail {
      ...CustomerEmailFragment
    }
  }

`

