import { useQuery } from "@apollo/react-hooks"
import { ModuleAcl, NetworkModule } from "@zipou/network_front"
import hotelGet from "../../../../graphql/PMS/Hotel/query/hotelGet"
import { Hotel, UserRoleEnum } from "model"
import React, { useContext } from "react"
import { userContext } from "context/User"

type NetworkModuleHotelDisplayProps = {
  hotelId: string
}

export const NetworkModuleHotelDisplay = ({ hotelId }: NetworkModuleHotelDisplayProps) => {

  const user = useContext(userContext)

  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId,
  })
  const hotel = data?.hotel

  const hasWriteRights = user?.roles.includes(UserRoleEnum.ROLE_NETWORK_VIEW_WRITE)

  return <>
    {hotel && <NetworkModule moduleConfig={{
      url: "https://www.box4b.fr/graphql",
      id: hotel?.NetworkConfig?.hostingConfigId,
      token: hotel?.NetworkConfig?.token,
      moduleAcl: hasWriteRights ? ModuleAcl.ACL_READ_WRITE : ModuleAcl.ACL_READ
    }} />}
  </>
}