import React, {useState} from "react"


import {UserConfig} from "model"
import {UserConfigForm} from "../Form/UserConfigForm"
import {useMutation} from "@apollo/react-hooks"
import configAddGql from "graphql/APP/User/Config/mutation/userConfigAdd.gql"
import {formatErrorResponseForJoi} from "helpers/Apollo"
import configListGql from "graphql/APP/User/Config/query/userConfigList.gql"

type UserConfigAddProps = {
  onDone?: () => void,
}

export const UserConfigAdd = (props: UserConfigAddProps) => {

  const [config, updateConfig] = useState<Partial<UserConfig>>()
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(configAddGql)

  const onCancel = () => {
    props?.onDone()
  }

  const onSave = () => {
    mutate({
      variables: {
        input: config,
      },
      refetchQueries: [{
        query: configListGql
      }]
    })
    .then(() => {
      props?.onDone()
    })
    .catch(e => {
      updateErrors(formatErrorResponseForJoi(e))
    })
  }

return <div>
    <UserConfigForm config={config} onChange={config => updateConfig(config)} errors={errors} />
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => onSave() }>Envoyer</button>
      {props?.onDone && <button className="btn btn-sm btn-danger" style={{marginLeft: 5}} onClick={() => onCancel()}>Annuler</button>}
    </div>
  </div>

}