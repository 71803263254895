import { useQuery } from "@apollo/react-hooks"
import { userContext } from "context/User"
import checkinListGql from "graphql/PMS/Checkin/query/checkinList.gql"
import { Checkin, CheckinListInput, CheckinStatusEnum, ReservationListForCheckinInput } from "model"
import React, { useContext, useState } from "react"
import { ReservationButton } from "../../Reservation/Button/ReservationButton"
import { DateFormat, DateInput, IconEdit, Modal } from "@zipou/front_tools"
import { ContactEditButton } from "../../Contact/Widget/ContactEditButton"
import { CheckinStatus } from "../Status/CheckinStatus"
import { CheckinEdit } from "../Edit/CheckinEdit"

type CheckinListProps = {

}


export const CheckinList = ({ }: CheckinListProps) => {

  const user = useContext(userContext)

  const [focusEdit, updateFocusEdit] = useState<Checkin>(null)

  const [input, updateInput] = useState<CheckinListInput>({
    arrivalDate: new Date(),
    hotelIds: user?.hotels,
  })

  const { data } = useQuery<{ list: Checkin[] }>(checkinListGql, {
    variables: {
      input,
    },
    skip: !user,
  })

  const checkinList = data?.list
  const hasCheckin = checkinList?.length > 0

  return <div>
    {focusEdit && <Modal display title="Checkin" onClose={() => updateFocusEdit(null)}>
      <CheckinEdit checkinId={focusEdit?.id} />
    </Modal>}
    <h1>Checkin</h1>
    <div>
      <div className="input-group">
        <DateInput label="Date d'Arrivée" value={input?.arrivalDate} onChange={(_, d) => updateInput({
          ...input,
          arrivalDate: d,
        })} />
      </div>
    </div>
    <div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr className="text-white bg-dark">
            <th className="text-white bg-dark">Id</th>
            <th className="text-white bg-dark">Contact</th>
            <th className="text-white bg-dark">Arrivée</th>
            <th className="text-white bg-dark">Statut</th>
            <th className="text-white bg-dark">Actions</th>
          </tr>
        </thead>
        <tbody>
          {!hasCheckin && <tr><td colSpan={6}>
            <div className="alert alert-info">Rien à afficher</div>
          </td></tr>}
          {checkinList?.map(checkin => {
            return <tr key={`checkinList${checkin.id}`}>
              <td>
                <ReservationButton reservationId={checkin.reservationId} />
              </td>
              <td>
                <ContactEditButton contact={checkin?.Reservation?.Contact} />
              </td>
              <td><DateFormat value={new Date(checkin?.arrivalDate)} /></td>
              <td>
                <CheckinStatus status={checkin?.status as CheckinStatusEnum} />
              </td>
              <td>
                <button className="btn btn-sm btn-warning" onClick={() => updateFocusEdit(checkin)}>
                  <IconEdit />
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>

  </div >

}