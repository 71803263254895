import gql from "graphql-tag";
import ServiceConfirmationFragment from "../fragment/ServiceConfirmationFragment";

export default gql`

${ServiceConfirmationFragment}

query serviceListByConfirmationId($confirmationId: String!) {
  list: serviceListByConfirmationId(confirmationId: $confirmationId) {
    ...ServiceConfirmationFragment
  }
}

`