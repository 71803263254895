import gql from "graphql-tag";
import EventFragmentGql from "graphql/PLANNING/Event/fragment/EventFragment.gql";

export default gql`

  ${EventFragmentGql}

  query reservationListEvent($reservationId: String!) {
    list: reservationListEvent(reservationId: $reservationId) {
      ...EventFragment
    }
  }

`