import gql from "graphql-tag";
import PreStayPublicFragment from "../fragment/PreStayPublicFragment";

export default gql`

  ${PreStayPublicFragment}

  mutation preStayAnswerByIdAndPublicId($id: String!, $publicId: String!, $input: PreStayContentInput!) {
    preStay: preStayAnswerByIdAndPublicId(id: $id, publicId: $publicId, input: $input) {
      ...PreStayPublicFragment
    }
  }

`