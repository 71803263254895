import { IconEdit, Modal } from "@zipou/front_tools"
import { CustomerPhone } from "model"
import React, { useState } from "react"
import { CustomerPhoneDetails } from "../Details/CustomerPhoneDetails"
import { PhoneCallButton } from "components/front/PHONE/Call/Button/PhoneCallButton"

type CustomerPhoneDisplayProps = {
  customerPhone: CustomerPhone,
}


export const CustomerPhoneDisplay = ({ customerPhone }: CustomerPhoneDisplayProps) => {

  const [displayDetails, updateDisplayDetails] = useState(false)

  return <span style={{ whiteSpace: "nowrap" }}>
    {displayDetails && <Modal display={displayDetails} title="Téléphone" onClose={() => updateDisplayDetails(false)}>
      <CustomerPhoneDetails id={customerPhone?.id} />
    </Modal>}
    <button className="btn btn-sm btn-dark mr-1" onClick={() => updateDisplayDetails(true)}>
      <IconEdit />
    </button>
    <PhoneCallButton phoneNumber={customerPhone?.phone} countryCode={customerPhone?.countryCode} />
    {/* <span className="">{customerPhone?.phone}</span> */}
  </span>
}