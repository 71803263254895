import gql from "graphql-tag";
import SegmentationListFragment from "../fragment/SegmentationListFragment";

export default gql`

  ${SegmentationListFragment}

  query segmentationList {
    list: segmentationList {
      ...SegmentationListFragment
    }
  }

`