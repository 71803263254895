import gql from "graphql-tag";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";

export default gql`

${ContactListFragment}

  fragment ReservationListFragment on Reservation {
    id
    status
    hotelId
    pmsId
    departureDate
    arrivalDate
    updateDate
    nbNight
    creationDate
    Contact {
      ...ContactListFragment
    }
    Hotel {
      id
      name
      themeColor
    }
  }

`