import {SegmentationList} from "components/front/CRM/Segmentation/List/SegmentationList"
import {URL_CRM_SEGMENTATION_ADD} from "constant/url"
import React from "react"
import {useHistory} from "react-router-dom"


export const SegmentationListRoute = () => {

  const history = useHistory()


  return <div>
    <SegmentationList />
    <div className="actions" style={{paddingTop: 20}}>
      <button onClick={() => history.push(URL_CRM_SEGMENTATION_ADD)} className="btn btn-dark btn-sm">Ajouter</button>
    </div>
  </div>
}