import gql from "graphql-tag";
import ContactFragment from "../fragment/ContactFragment";

export default gql`

${ContactFragment}

mutation contactEdit($id: String!, $input: ContactEditInput!) {
  contact: contactEdit(id: $id, input: $input) {
    ...ContactFragment
  }
}

`