import gql from "graphql-tag";
import PaymentPublicFragment from "graphql/BILLING/Payment/fragment/PaymentPublicFragment";

export default gql`

  ${PaymentPublicFragment}

  fragment PropositionPaymentPublicFragment on PropositionPayment {
    id
    paymentId
    propositionId
    pushPmsId
    Payment {
      ...PaymentPublicFragment
    }


  }

`