import gql from "graphql-tag";
import PaymentPublicFragment from "../fragment/PaymentPublicFragment";

export default gql`

  ${PaymentPublicFragment}

  mutation paymentConfirm($id: String!, $options: JSONObject) {
    payment: paymentConfirm(id: $id, options: $options) {
      ...PaymentPublicFragment
    }
  }

`