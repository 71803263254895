import { useQuery } from "@apollo/react-hooks"
import { ContactSourceDetails } from "components/front/PMS/Contact/Details/ContactSourceDetails"
import customerAddressGetGql from "graphql/CRM/Customer/Address/query/customerAddressGet.gql"
import { CustomerAddress } from "model"
import React from "react"

type CustomerAddressDetailsProps = {
  id: string,
}

export const CustomerAddressDetails = ({ id }: CustomerAddressDetailsProps) => {

  const { data } = useQuery<{ customerAddress: CustomerAddress }>(customerAddressGetGql, {
    variables: {
      id,
    },
    skip: !id,
  })

  const customerAddress = data?.customerAddress

  return <div>
    <table className="table table-bordered table stripped">
      <tbody>
        <tr>
          <th>Adresse1</th>
          <td>{customerAddress?.address1}</td>
        </tr>
        <tr>
          <th>Adresse2</th>
          <td>{customerAddress?.address2}</td>
        </tr>
        <tr>
          <th>ZipCode</th>
          <td>{customerAddress?.zipCode}</td>
        </tr>
        <tr>
          <th>City</th>
          <td>{customerAddress?.city}</td>
        </tr>
        <tr>
          <th>Country</th>
          <td>{customerAddress?.country}</td>
        </tr>
        <tr>
          <th>Source Type</th>
          <td>{customerAddress?.sourceType}</td>
        </tr>
        <tr>
          <th>Source</th>
          <td>
            <ContactSourceDetails contact={customerAddress?.Contact} />
          </td>
        </tr>

      </tbody>
    </table>
  </div>

}