import { PreStay, PreStayContentInput } from "model";
import React from "react"
import { useTranslation } from "react-i18next";

export type PreCheckinAnswerProps = {
  preStayContent: PreStayContentInput,
  onChange: (preStayContent: PreStayContentInput) => void,
}

export const PreCheckinAnswer = ({ preStayContent, onChange }: PreCheckinAnswerProps) => {

  const { t } = useTranslation('translation', { keyPrefix: 'prestay' });


  return <div>
    <h1>{t("checkin.title")}</h1>
    <h2>{t("checkin.desc")}</h2>
    <div>
      <div className="row">

        <div className="d-flex flex-column col-12 col-md-12 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.name")}</span>
          <input type="text" className="form-control" value={preStayContent?.checkin?.name || ""} placeholder={t("checkin.name")} onChange={(e) => {
            onChange({
              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                name: e.target.value,
              }
            })
          }} />
        </div>
      </div>
      <div className="row">

        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.phoneNumber")}</span>
          <input type="text" className="form-control" value={preStayContent?.checkin?.phone || ""} placeholder={t("checkin.phoneNumber")} onChange={(e) => {
            onChange({
              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                phone: e.target.value,
              }
            })
          }} />
        </div>

        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.mail")}</span>
          <input type="text" className="form-control" value={preStayContent?.checkin?.mail} placeholder={t("checkin.mail")} onChange={(e) => {
            onChange({
              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                mail: e.target.value,
              }
            })
          }} />
        </div>
      </div>
      <div className="row">
        <div className="d-flex flex-column col-12 col-md-8 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.address")}</span>
          <input type="text" value={preStayContent?.checkin?.address || ""} className="form-control" placeholder={t("checkin.address")} onChange={(e) => {
            onChange({

              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                address: e.target.value,
              }
            })
          }} />
        </div>
      </div>
      <div className="row">

        <div className="d-flex flex-column col-12 col-md-3 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.zip")}</span>
          <input type="text" value={preStayContent?.checkin?.zip || ""} className="form-control" placeholder={t("checkin.zip")} onChange={(e) => {
            onChange({
              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                zip: e.target.value,
              }
            })
          }} />
        </div>
        <div className="d-flex flex-column col-12 col-md-4 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.city")}</span>
          <input type="text" value={preStayContent?.checkin?.city || ""} className="form-control" placeholder={t("checkin.city")} onChange={(e) => {
            onChange({
              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                city: e.target.value,
              }
            })
          }} />
        </div>
        <div className="d-flex flex-column col-12 col-md-4 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("checkin.country")}</span>
          <input type="text" value={preStayContent?.checkin?.country || ""} className="form-control" placeholder={t("checkin.country")} onChange={(e) => {
            onChange({
              ...preStayContent,
              checkin: {
                ...(preStayContent?.checkin || {}),
                country: e.target.value,
              }
            })
          }} />
        </div>
      </div>
    </div>
  </div>

}