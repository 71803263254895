import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}

  fragment ReservationSearchFragment on Reservation {
    id
    pmsId
    arrivalDate
    departureDate
    status
    HotelSearch: Hotel {
      ...HotelDisplayFragment
    }
  }

`