import { useQuery } from "@apollo/react-hooks"
import { Modal } from "@zipou/front_tools"
import { EventAddByConfirmation } from "components/front/PLANNING/Event/Add/EventAddByConfirmation"
import { EventBadge } from "components/front/PLANNING/Event/Badge/EventBadge"
import { EventEdit } from "components/front/PLANNING/Event/Edit/EventEdit"
import confirmationGet from "graphql/PMS/Confirmation/query/confirmationGet"
import confirmationListEvent from "graphql/PMS/Confirmation/query/confirmationListEvent.gql"
import serviceListByConfirmationIdGql from "graphql/PMS/Service/query/serviceListByConfirmationId.gql"
import { Service, Event, Confirmation } from "model"
import React, { useState } from "react"


export type ConfirmationAnswerServiceProps = {
  confirmationId: string,
  hotelId: string
}

export const ConfirmationAnswerService = (props: ConfirmationAnswerServiceProps) => {

  const [focusAdd, updateFocusAdd] = useState<Service>()
  const [focusEdit, updateFocusEdit] = useState<Event>()


  const confirmationId = props?.confirmationId

  const confirmationGetData = useQuery<{ confirmation: Confirmation }>(confirmationGet, {
    variables: {
      id: confirmationId,
    },
    skip: !confirmationId
  })

  const serviceListData = useQuery(serviceListByConfirmationIdGql, {
    variables: {
      confirmationId,
    },
    skip: !confirmationId,
  })

  const eventResponse = useQuery(confirmationListEvent, {
    variables: {
      id: confirmationId,
    },
    skip: !confirmationId,
  })

  const confirmation: Confirmation = confirmationGetData?.data?.confirmation
  const serviceList: Service[] = serviceListData?.data?.list
  const eventList: Event[] = eventResponse?.data?.list

  const arrivalDate = confirmation?.Reservation?.arrivalDate || confirmation?.ReservationGroup?.wholeDates?.arrivalDate || null
  const arrivalDateWithOffset = arrivalDate && new Date(arrivalDate).getTime() + 2 * 3600 * 1000
  const arrivalDateFormatted = arrivalDateWithOffset && new Date(`${new Date(arrivalDateWithOffset)?.toISOString().split('T')[0]}T09:00:00.000Z`)

  return <div>
    <Modal title="Ajouter un service" display={!!focusAdd} onClose={() => updateFocusAdd(null)}>
      <EventAddByConfirmation date={arrivalDateFormatted} confirmationId={confirmationId} hotelId={props?.hotelId} serviceId={focusAdd?.id} onDone={() => updateFocusAdd(null)} />
    </Modal>
    <Modal title="Modifier un service" display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <EventEdit eventId={focusEdit?.id} onDone={() => {
        eventResponse.refetch()
        updateFocusEdit(null)
      }} />
    </Modal>
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Service</th>
          <th className="bg-dark text-white">Reservation en cours</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {serviceList?.map(service => {

          const eventListForThisService = eventList?.filter(e => e.serviceId === service?.id)
          const hasEvent = eventListForThisService?.length > 0

          return <tr key={`service_${service?.id}`}>
            <td>{service?.name}</td>
            <td>
              {!hasEvent && <div className="alert alert-dark">Aucune reservation</div>}
              {hasEvent && <table className="table table-bordered table-stripped">
                <tbody>
                  {eventListForThisService?.map(e => {
                    return <tr key={`event_${e?.id}`}>
                      <td>
                        <EventBadge event={e} />
                      </td>
                      <td>
                        <button className="btn btn-sm btn-dark" onClick={() => updateFocusEdit(e)}>
                          <span className="icon-edit"></span>
                        </button>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>}
            </td>
            <td>
              <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(service)}>
                Ajouter <span className="icon-circle-plus"></span>
              </button>
            </td>
          </tr>
        })}

      </tbody>
    </table>

  </div>
}