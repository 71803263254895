import React, { useContext } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { IconEdit } from "@zipou/front_tools"
import { userContext } from "context/User"
import { PhoneConfigEdit } from "components/front/PHONE/Config/Edit/PhoneConfigEdit"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import { URL_PHONE_CONFIG_ID, URL_PHONE_DIRECTORY_ID } from "constant/url"
import { PhoneDirectory } from 'components/front/PHONE/Directory/PhoneDirectory'

export const PhoneDirectoryRoute = (props: RouteComponentProps<{ id?: string }>) => {

  const history = useHistory()

  const match = props?.match
  const params = match?.params
  const id = params?.id

  const user = useContext(userContext)

  const hotelList = user?.Hotel
  const hotelListFilteredWithPhoneConfig = hotelList?.filter(hotel => !!hotel?.PhoneConfig?.id)
  const hasHotel = hotelListFilteredWithPhoneConfig?.length > 0
  const isMulti = hotelListFilteredWithPhoneConfig?.length > 1
  // console.log("hotelListFilteredWithPhoneConfig", hotelListFilteredWithPhoneConfig)

  return <>
    {id && <PhoneDirectory
      hotelId={id}
    />}
    {(hasHotel && !id) && <div>
      {!isMulti && <PhoneDirectory
        hotelId={hotelListFilteredWithPhoneConfig[0]?.id}
      />}
      {isMulti && <div>
        <table className="table table-bordered table-stripped">
          <thead>
            <tr className="bg-dark text-white">
              <th className="bg-dark text-white">Hotel</th>
              <th className="bg-dark text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            {hotelListFilteredWithPhoneConfig?.map(hotel => {
              return <tr>
                <td>
                  <HotelBadge hotel={hotel} />
                </td>
                <td>
                  <button className="btn btn-sm btn-warning" onClick={() => history?.push(URL_PHONE_DIRECTORY_ID?.replace(":id", hotel?.id))}>
                    <IconEdit />
                  </button>
                </td>
              </tr>
            })}
          </tbody>

        </table>
      </div>}

    </div>
    }
  </>
}