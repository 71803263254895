import gql from "graphql-tag";
import EventFragmentGql from "../fragment/EventFragment.gql";

export default gql`

  ${EventFragmentGql}

  query eventGet($id: String!) {
    event: eventGet(id: $id){
      ...EventFragment
    }
  }
`