import gql from "graphql-tag";
import ServiceFragment from "graphql/PMS/Service/fragment/ServiceFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}
  ${ServiceFragment}

  fragment EventFragment on Event {
    id
    eventType
    hotelId
    label
    utcDate
    status
    serviceId
    sourceType
    sourceId
    source
    Hotel {
      ...HotelDisplayFragment
    }
    Service {
      ...ServiceFragment
    }
  }

`