import React, {Suspense} from "react"
// import {Playground} from 'graphql-playground-react'

const OtherComponent = React.lazy(() => import('components/front/APP/Graphql/GraphiQL'));

export const graphqlPlaygroundRoute = () => {

  return <div>
    <Suspense fallback={<div>Chargement...</div>}>
      <OtherComponent />
    </Suspense>
  </div>
}

