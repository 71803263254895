import gql from "graphql-tag";
import EventFragmentGql from "../fragment/EventFragment.gql";

export default gql`

  ${EventFragmentGql}

  query eventListByPlanning($id: String!, $input: PlanningViewInput!) {
    list: eventListByPlanning(id: $id, input: $input){
      ...EventFragment
    }
  }
`