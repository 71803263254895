import {useQuery} from "@apollo/react-hooks"
import {AutoCompleteInput} from "components/common/Form/AutoCompleteInput"
import optionListGql from "graphql/PMS/Option/query/optionList.gql"
import {Option} from "model"
import React, {useState} from "react"

export type OptionSearchProps = {
  hotelId: string,
  value: any,
  onSelect: (option: Option) => void
}


export const OptionSearch = (props: OptionSearchProps) => {

  const [text, updateText] = useState<any>("")

  const {data} = useQuery(optionListGql, {
    variables: {
      hotelId: props?.hotelId,
    },
    skip: !props?.hotelId,
  })

  const onSearch = (text: string) => {
    return data?.list?.filter((option: Option) => option?.labelFr.toLowerCase().includes(text.toLowerCase()))
  }

  return <AutoCompleteInput
    disabled={false}
    displayResult={(value: Option) => value.labelFr}
    onSearch={onSearch} displayValue={(value: Option) => value.labelFr}
    label={"Ajouter une Option"}
    placeholder={"Rechercher une option"}
    isError={false}
    value={text}
    onChange={(value: Option) => {
      props?.onSelect(value)
      updateText(null)
    }}
  />

}