import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import PreStayListFragmentGql from "../fragment/PreStayListFragment.gql";

export default gql`

  ${PreStayListFragmentGql}
  ${CursorFragment}

  query preStayListByCursorAndInput($input: PreStayListInput!, $cursor: CursorInput!) {
    list: preStayListByCursorAndInput(input: $input, cursor: $cursor) {
      nodes {
        ...PreStayListFragment
      }
      input,
      cursor {
        ...CursorFragment
      }
    }
  }

`