import { TextInput } from "@zipou/front_tools"
import React, { useState } from "react"

type JSONImportFormProps = {
  onChange: (json: any) => void,
}

export const JSONImportForm = ({ onChange }: JSONImportFormProps) => {

  const [value, updateValue] = useState("")
  const [error, updateError] = useState(false)
  const [success, updateSuccess] = useState(false)

  const validateJSON = (v: string) => {
    updateValue(v)
    updateError(false)
    let json = null
    try {
      json = JSON.parse(v)
      onChange(json)
      updateSuccess(true)
    } catch (e) {
      updateSuccess(false)
      updateError(true)
    }
  }

  return <div>
    {success && <div className="alert alert-success">
      Format Valide
    </div>}
    {error && <div className="alert alert-warning">
      Attention, format invalide.
    </div>}
    {/* <textarea value={value} onChange={(e) => updateValue(e.target.value)}></textarea> */}
    {/* <input type="text" className="form-control" onChange={(e) => validateJSON(e.target.value)} value={value} /> */}
    <TextInput label="Importer JSON" errors={{}} value={value || ""} onChange={validateJSON} />
  </div>
}