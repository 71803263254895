import {gql} from "graphql-tag"

export default gql`

    fragment TargetFragment on Target {
      id
      clientId
      status
      channelList {
        id
      }
    }

`