import gql from "graphql-tag";

export default gql`

fragment FileFragment on File {
  id
  hotelId
  name
  extension
  url
}

`