import gql from "graphql-tag";
import RoomFragmentGql from "../fragment/RoomFragment.gql";

export default gql`

  ${RoomFragmentGql}

  query roomGet($id: String!) {
    room: roomGet(id: $id) {
      ...RoomFragment
    }
  }

`