import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import { SeasonalityInput } from "model"
import React, { useState } from "react"
import { SeasonalityEdit } from "../Edit/SeasonalityEdit"

type SeasonalityListProps = {
  hotelId: string,
  seasonalityList: SeasonalityInput[],
  onChange: (seasonalityList: SeasonalityInput[]) => void,
  errors: any,
}

export const SeasonalityList = ({ seasonalityList, errors, hotelId, onChange }: SeasonalityListProps) => {


  const [focusIndex, updateFocusIndex] = useState<number | null>(null)

  const hasFocus = focusIndex !== null

  return <div>
    <Modal display={hasFocus} onClose={() => updateFocusIndex(null)}>
      <SeasonalityEdit errors={errors && errors[focusIndex]} seasonality={seasonalityList?.find((s, idx) => idx === focusIndex)} onChange={(sea) => {
        onChange(seasonalityList?.map((s, idx) => idx === focusIndex ? sea : s))
      }} />
    </Modal>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {seasonalityList?.map((seasonality, index) => {

          const isOnError = errors && errors[index]

          return <tr key={`seasonality_${seasonality?.id}_${index}`} className={isOnError ? "bg-danger text-white" : ""}>
            <td>{seasonality?.name}</td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusIndex(index)}>
                <IconEdit />
              </button>
              <button className="btn btn-sm btn-danger ml-1" onClick={() => {
                onChange(seasonalityList.filter((el, idx) => index !== idx))
              }}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <button className="btn btn-dark btn-sm" onClick={() => {
      onChange([
        ...(seasonalityList || []),
        { name: "", }
      ])
      updateFocusIndex(seasonalityList?.length)
    }}>Ajouter un saison</button>

  </div >
}