
import gql from "graphql-tag";
import PaymentPublicFragment from "../fragment/PaymentPublicFragment";

export default gql`

  ${PaymentPublicFragment}

  query paymentGetByPublicIdAndPublicSalt($id: String!, $salt: String!) {
    payment: paymentGetByPublicIdAndPublicSalt(id: $id, salt: $salt) {
      ...PaymentPublicFragment
    }
  }
`