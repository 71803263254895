import { TerminalInput } from "model"
import React from "react"
import { TerminalForm } from "./TerminalForm"
import { IconTrash } from "@zipou/front_tools"

type TerminalFormListProps = {
  terminalList: TerminalInput[],
  errors: any,
  onChange: (terminalList: TerminalInput[]) => void,
}

export const TerminalFormList = ({ terminalList, errors, onChange }: TerminalFormListProps) => {


  return <div className="input-group">
    <span className="input-group-text">Terminaux</span>
    <div className="form-control" style={{ height: "100%" }}>
      {terminalList?.map((terminal, index) => {
        return <div className="input-group">
          <div className="form-control" style={{ height: "100%" }}>
            <TerminalForm terminal={terminal} errors={errors && errors[index]} onChange={(terminalInput) => {
              onChange(terminalList?.map((t, i) => i === index ? terminalInput : t))
            }}
            />
          </div>
          <span className="input-group-text" onClick={() => onChange(terminalList?.filter((_, i) => i !== index))}>
            <IconTrash />
          </span>
        </div>

      })}
      <div>
        <button className="btn btn-sm btn-dark" onClick={() => {
          onChange([{}, ...(terminalList || [])])
        }}>Ajouter</button>
      </div>
    </div>
  </div>

}