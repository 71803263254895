import gql from "graphql-tag";
import HotsoftConfigFragmentGql from "../HotsoftConfig/fragment/HotsoftConfigFragment.gql";

export default gql`

${HotsoftConfigFragmentGql}

fragment PmsConfigFragment on PmsConfig {
  type
  hotsoftConfig {
    ...HotsoftConfigFragment
  }
}


`