import gql from "graphql-tag";
import ChargeFragmentGql from "../fragment/ChargeFragment.gql";

export default gql`

${ChargeFragmentGql}

  mutation chargeAdd($tokenId: String!, $input: ChargeInput!) {
    charge: chargeAdd(tokenId: $tokenId, input: $input) {
      ...ChargeFragment
    }
  }

`