import { useState } from "react"
import { FileForm } from "../Form/FileForm"
import { FileInput, File } from "model"
import React from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"
import fileEditGql from "graphql/APP/File/mutation/fileEdit.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import fileGetGql from "graphql/APP/File/query/fileGet.gql"
import { IconLoading } from "@zipou/front_tools"

type FileEditProps = {
  id: string,
  onDone?: (file: File) => void
}

export const FileEdit = (props: FileEditProps) => {


  const [errors, updateErrors] = useState<any>()
  const [fileInput, updateFileInput] = useState<FileInput>()

  const { data } = useQuery<{ file: File }>(fileGetGql, {
    variables: {
      id: props.id
    },
    onCompleted: (response) => {
      const { name } = response.file
      updateFileInput({
        ...fileInput,
        name,
        hotelId: response?.file?.hotelId,
      })
    }
  })

  const file = data?.file

  const [mutate, { loading }] = useMutation<{ file: File }>(fileEditGql)

  const onSubmit = () => {
    mutate({
      variables: {
        id: file?.id,
        input: fileInput
      }
    })
      .then((response) => {
        const file = response.data.file
        props?.onDone(file)
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })

  }

  const disabled = !(fileInput?.name && fileInput?.base64Buffer) || loading

  return <div>
    <FileForm errors={errors} file={fileInput} onChange={updateFileInput} />
    <div>
      <button disabled={disabled} className="btn btn-sm btn-dark" onClick={() => onSubmit()}>
        {loading && <span className="mr-1">
          <IconLoading />
        </span>}
        Enregistrer
      </button>
    </div>
  </div>

}