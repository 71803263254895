import { EmailStatusEnum } from "model"
import React from "react"

type CustomerEmailStatusDisplayProps = {
  status: EmailStatusEnum,
}

export const CustomerEmailStatusDisplay = ({ status }: CustomerEmailStatusDisplayProps) => {

  switch (status) {
    case EmailStatusEnum.VALID:
      return <span className="badge badge-success">{status}</span>

    case EmailStatusEnum.UNKNOWN:
    case null:
      return <span className="badge badge-warning">INCONNU</span>

    default:
      return <span className="badge badge-danger">{status}</span>
  }
}