import {useQuery} from "@apollo/react-hooks"
import {URL_PLANNING_VIEW} from "constant/url"
import planningListGql from "graphql/PLANNING/Planning/query/planningList.gql"
import React, {useState} from "react"
import {useHistory} from "react-router-dom"
import {Planning} from "model"
import {PlanningEdit} from "../Edit/PlanningEdit"
import {PlanningAdd} from "../Add/PlanningAdd"
import { Modal } from "@zipou/front_tools"

export const PlanningList = (props: any) => {

  const [focusEdit, updateFocusEdit] = useState<Planning>(null);
  const [focusAdd, updateFocusAdd] = useState<boolean>(false);
  const history = useHistory();

  const {data} = useQuery(planningListGql)

  const planningList: Planning[] = data?.planningList || [];
  const hasResults = planningList?.length > 0

  return <div>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <PlanningEdit id={focusEdit?.id} onDone={() => updateFocusEdit(null)} />
    </Modal>
    <Modal display={!!focusAdd} onClose={() => updateFocusAdd(null)}>
      <PlanningAdd onDone={() => updateFocusAdd(null)} />
    </Modal>
    {!hasResults && <div className="alert alert-warning">Rien à afficher</div>}
    {hasResults && <div>
      <table className="table table-bordered">
        <thead className="bg-dark text-white">
          <tr>
            <th className=" text-white">Nom</th>
            <th className=" text-white">Actions</th>
          </tr>
        </thead>
        <tbody>
          {planningList?.map((planning) => {
            return <tr key={`planning_${planning?.id}`}>
              <td>{planning?.name}</td>
              <td>
                <button className="btn btn-dark btn-sm ml-2" onClick={() => history.push(URL_PLANNING_VIEW?.replace(':id', planning?.id))}>
                  <span className="icon-play" />
                </button>
                <button className="btn btn-dark btn-sm ml-2"  onClick={() => updateFocusEdit(planning)}>
                  <span className="icon-edit" />
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    }
    <div>
      <button className="btn btn-dark btn-sm" onClick={() => updateFocusAdd(true)}>
        Ajouter
      </button>
    </div>
  </div>
}