import { IconEdit, IconTrash, Modal, PriceFormat } from "@zipou/front_tools"
import { OptionInput } from "model"
import React, { useState } from "react"
import { OptionEdit } from "../Edit/OptionEdit"

type OptionListProps = {
  hotelId: string,
  optionList: OptionInput[]
  onChange: (optionList: OptionInput[]) => void,
  errors: any,
}


export const OptionList = ({ optionList, hotelId, errors, onChange }: OptionListProps) => {

  const [focusIndex, updateFocusIndex] = useState<number>(null)
  const hasContent = optionList?.length > 0
  const hasIndex = focusIndex !== null

  return <div>
    <Modal display={hasIndex} onClose={() => updateFocusIndex(null)}>
      <OptionEdit errors={errors && errors[focusIndex]} hotelId={hotelId} option={optionList?.find((el, idx) => idx === focusIndex)} onChange={(opt) => {
        onChange(optionList?.map((option, idx) => idx === focusIndex ? opt : option))
      }} />
    </Modal>
    <table className="table table-stripped table-bordered">
      <thead className="bg-dark text-white">
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Montant TTC</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {optionList?.map((option, index) => {

          const isOnError = errors && errors[index]

          return <tr key={`option_${option?.id}_${index}`} className={isOnError ? "bg-danger text-white" : ""}>
            <td>{option?.labelFr}</td>
            <td><PriceFormat value={option?.amountIncVat} /></td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusIndex(index)}>
                <IconEdit />
              </button>
              <button className="btn btn-sm btn-danger ml-1" onClick={() => {
                onChange(optionList?.filter((opt, idx) => idx !== index))
              }}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>

    <button className="btn btn-sm btn-dark" onClick={() => {
      onChange([
        ...(optionList || []),
        {
          pmsId: "",
          labelEn: "",
          labelFr: "",
          isBreakfast: false,
          isRestaurant: false,
          isRestaurant2: false,
          isSpa: false,
        },
      ])
      updateFocusIndex(optionList?.length)
    }}>Ajouter</button>
  </div>

}