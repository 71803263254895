import gql from "graphql-tag";
// import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}
  ${ContactListFragment}

  fragment PropositionListFragment on Proposition {
    id
    publicId
    status
    pushPms
    pushStatus
    sentStatus
    rowSelectedId
    creationDate
    status
    contactId
    nbRooms
    nbAdults
    nbChildren
    nbSpecial
    expirationDate
    Contact {
      ...ContactListFragment
    }
    Hotel {
      ...HotelDisplayFragment
    }

  }

`