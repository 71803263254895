import { useQuery } from "@apollo/react-hooks"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import segmentationEmailMemberListByContactIdGql from "graphql/CRM/Segmentation/EmailMember/query/segmentationEmailMemberListByContactId.gql"
import { SegmentationEmailMemberCursorResult } from "model"
import React from "react"

type SegmentationPreviewProps = {
  segmentationId: string,
}

export const SegmentationPreview = (props: SegmentationPreviewProps) => {

  const { data, refetch } = useQuery<{ list: SegmentationEmailMemberCursorResult }>(segmentationEmailMemberListByContactIdGql, {
    variables: {
      segmentationId: props?.segmentationId,
      cursor: {
        page: 1,
        limit: 50,
      }
    }
  })
  const membersList = data?.list?.nodes
  const hasMembers = membersList?.length > 0


  return <div>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {hasMembers && membersList?.map((member) => {
          return <tr>
            <td>{member?.CustomerEmail?.Customer?.lastName}</td>
            <td>{member?.CustomerEmail?.Customer?.firstName}</td>
            <td>{member?.CustomerEmail?.Customer?.mainEmail}</td>
          </tr>
        })}
      </tbody>
    </table>
    <Pagination cursor={data?.list?.cursor} onChange={(page: number) => {
      const { __typename, totalPages, ...cursor } = data?.list?.cursor
      refetch({
        segmentationId: props?.segmentationId,
        cursor: {
          ...cursor,
          page,
        }
      })
    }} />
  </div>
}