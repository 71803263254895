import gql from "graphql-tag";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";

export default gql`

  ${OptionFragmentGql}

fragment PropositionRowFragment on PropositionRow {
  id
  arrivalDate
  departureDate  
  paymentCondition
  totalToValid
  comment
  nbNights
  hasSameDates
  totalRow {
    total
    totalExtra
    totalDiscountExtra
    totalHosting
    totalDiscountHosting
    totalDiscount
    totalExtraWithoutDiscount
    totalHostingWithoutDiscount
  }
  roomList {
    Option {
      optionId
      quantity
      quantityType
      discount {
        percent
        forced
      }
      Option {
        ...OptionFragment
      }
    }
    optionList {
      optionPushed
      error
      optionId
      quantityType
      quantity
      discount {
        percent
        forced
      }
      Option {
        ...OptionFragment
      }
    }
    discount {
      percent
      forced
    }
    hasDelogement
    isUpgrade
    roomIndex
    sameConditions
    discountPushed
    depositPushed
    confirmedPush
    pmsId
    error
    itemList {
      date
      dateStamp
      roomId
      rateId
      pmsId
      error
      discountPushed
      confirmedPush
      Room {
        id
        roomTypeId
      }
      PriceRate {
        id
        valueIncTax
        valueExcTax
        PriceRateType {
          id
          labelFr
          labelEn
          isGiftBox
        }
      }
      rateCode
      priceRateList {
        id
        typeId
        code
        roomType
        valueIncTax
        valueExcTax
        PriceRateType {
          id
          code
          labelFr
          labelEn
          isGiftBox
          canBeSelectedInProposition
          isIncludedInConfirmationTotal
        }
      }
    }
  }
}
`