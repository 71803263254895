import { Contact } from "model";
import React from "react"

type ContactSourceDetailsProps = {
  contact: Contact,
}

export const ContactSourceDetails = ({contact}: ContactSourceDetailsProps) => {

  const customer = contact?.Customer

  return <div className="card">
    {contact?.Hotel?.name && <div className="card-header text-white" style={{ backgroundColor: `#${contact?.Hotel?.themeColor}` }}>
      {contact?.Hotel?.name}
    </div>}
    <table className="table">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{contact?.pmsId}</td>
        </tr>
        <tr>
          <th>Prénom</th>
          <td>{contact?.firstName}</td>
        </tr>
        <tr>
          <th>Nom</th>
          <td>{contact?.lastName}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{contact?.type}</td>
        </tr>
      </tbody>
    </table>
  </div>
}
