import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${PaymentFragment}

  mutation paymentCapture($id: String!, $amount: Float!) {
    payment: paymentCapture(id: $id, amount: $amount) {
      ...PaymentFragment
    }
  }

`