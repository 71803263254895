import { useQuery } from "@apollo/react-hooks"
import { TokenConfirm } from "components/front/BILLING/Token/Confirm/TokenConfirm"
import { HotelContainer } from "components/public/PMS/Hotel/HotelContainer"
import tokenGetByPublicIdAndPublicSalt from "graphql/BILLING/Token/query/tokenGetByPublicIdAndPublicSalt"
import { Token } from "model"
import React from "react"

export const TokenConfirmPublicIdAndSaltRoute = (props: any) => {

  const { params } = props?.match
  const { id, salt } = params

  const { data } = useQuery<{ token: Token }>(tokenGetByPublicIdAndPublicSalt, {
    variables: {
      id,
      salt,
    },
    pollInterval: 2000,
  })

  const token = data?.token

  return <div>
    {token && <HotelContainer hotel={token?.Hotel}>
      <div className="">
        <div className="card-body">
          <TokenConfirm token={token} />
        </div>
      </div>
    </HotelContainer>}
  </div>
}