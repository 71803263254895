import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

${HotelDisplayFragment}


fragment NewsletterRegistrationFragment on NewsletterRegistration {
  id
  email
  creationDate
  Hotel {
    ...HotelDisplayFragment
  }
}

`