import { useQuery } from "@apollo/react-hooks"
import { IconEdit, Modal } from "@zipou/front_tools"
import corpListGql from "graphql/APP/Corp/query/corpList.gql"
import { Corp } from "model"
import React, { useState } from "react"
import { CorpEdit } from "../Edit/CorpEdit"
import { CorpAdd } from "../Add/CorpAdd"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"



export const CorpList = () => {

  const [focusAdd, updateFocusAdd] = useState<boolean>(false)
  const [focusEdit, updateFocusEdit] = useState<Corp | null>()
  const { data } = useQuery<{ list: Corp[] }>(corpListGql)

  const corpList = data?.list

  return <div>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <CorpEdit corpId={focusEdit?.id} onDone={() => updateFocusEdit(null)} />
    </Modal>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}>
      <CorpAdd onDone={() => updateFocusAdd(false)} />
    </Modal>
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Hotels</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {corpList?.map((corp, index) => {
          return <tr key={`corp_${corp?.id}_${index}`}>
            <td>{corp?.name}</td>
            <td>
              {corp?.Hotel?.map(hotel => <span className="ml-2"><HotelBadge hotel={hotel} /></span>)}
            </td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusEdit(corp)}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <button className="btn btn-dark btn-sm ml-1" onClick={() => updateFocusAdd(true)} >
      Ajouter
    </button>
  </div>
}