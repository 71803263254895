import {useMutation} from "@apollo/react-hooks"
import templateAdd from "graphql/APP/Template/mutation/templateAdd"
import templateList from "graphql/APP/Template/query/templateList"
import {formatErrorResponseForJoi} from "helpers/Apollo"
import React, {useState} from "react"
import {TemplateForm} from "../Form/TemplateForm"

type TemplateAddProps = {
  templateId?: string,
  hotelId: string,
  onDone: () => void,
}

export const TemplateAdd = (props: TemplateAddProps) => {

  const [template, updateTemplate] = useState<any>({name: ""})
  const [errors, updateErrors] = useState<any>(null)

  const [templateAddMutation] = useMutation(templateAdd)

  const onSave = () => {
    templateAddMutation({
      variables: {
        input: template || {},
        hotelId: props?.hotelId,
      },
      refetchQueries: [{
        query: templateList,
      }]
    })
      .then(() => {
        if (props?.onDone) {
          props?.onDone()
        }
      })
      .catch(e => updateErrors(formatErrorResponseForJoi(e)))
  }


  return <div>
    <div className="card">
      <div className="card-body">
        <TemplateForm
          errors={errors}
          template={template}
          onChange={(data: any) => {
            updateTemplate(data)
          }}
        />
      </div>
      <div className="card-footer">
        <button className="btn btn-sm btn-dark" onClick={() => onSave()}>Enregistrer</button>
      </div>
    </div>
  </div>
}