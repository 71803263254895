import { useState } from "react"
import { FileForm } from "../Form/FileForm"
import { FileInput, File } from "model"
import React from "react"
import { useMutation } from "@apollo/react-hooks"
import fileAddGql from "graphql/APP/File/mutation/fileAdd.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { IconLoading } from "@zipou/front_tools"

type FileAddProps = {
  hotelId?: string,
  onDone?: (file: File) => void
}

export const FileAdd = (props: FileAddProps) => {

  const [errors, updateErrors] = useState<any>()
  const [fileInput, updateFileInput] = useState<FileInput>({
    hotelId: props?.hotelId,
  })

  const [mutate, { loading }] = useMutation<{ file: File }>(fileAddGql)

  const onSubmit = () => {
    mutate({
      variables: {
        input: fileInput
      }
    })
      .then((response) => {
        const file = response.data.file
        props?.onDone(file)
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })

  }

  const disabled = !(fileInput.name && fileInput.base64Buffer) || loading

  return <div>
    <FileForm errors={errors} file={fileInput} onChange={updateFileInput} />
    <div>
      <button disabled={disabled} className="btn btn-sm btn-dark" onClick={() => onSubmit()}>
        {loading && <span className="mr-1">
          <IconLoading />
        </span>}
        Ajouter
      </button>
    </div>
  </div>

}