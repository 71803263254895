import { IconWarningSign } from "@zipou/front_tools"
import { TextInput } from "components/common/Form/TextInput"
import { FileInput } from "model"
import React from "react"

type FileFormProps = {
  file: FileInput,
  errors: any,
  onChange: (input: FileInput) => void,
}

export const FileForm = (props: FileFormProps) => {

  const file = props?.file
  const errors = props?.errors

  const onChange = (fileInput: FileInput) => {
    props?.onChange(fileInput)
  }

  const onFileInputChange = (event: any) => {
    const reader = new FileReader();
    reader.onload = (upload) => {
      onChange({
        ...file,
        base64Buffer: upload.target.result.toString(),
      })
    }
    const fileUploaded = event.target.files[0];

    if (fileUploaded) {
      // const type = fileUploaded.type.split("/")[0];
      reader.readAsDataURL(fileUploaded);
    }
  }


  return <div>

    <div className="input-group">
      <TextInput value={file?.name} label="Nom du fichier" errors={errors} id="name" onChange={(_, v) => {
        onChange({
          ...file,
          name: v
        })
      }} />
    </div>
    <div className="input-group">
      <input type="file" className="form-control" onChange={onFileInputChange}></input>
      {(errors && errors?.base64Buffer) && <span className="input-group-text">
        <IconWarningSign />
      </span>}
    </div>
  </div>

}