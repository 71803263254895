import gql from "graphql-tag";

export default gql`

  fragment PostStayAnswerStatsFragment on PostStayAnswerStats {
    id
    questionId
    dimensionType
    dimensionValue
    choiceValue
    listValue
    avgValue
    nbValue
    minValue
    maxValue
    StatBefore {
        id
        questionId
        dimensionType
        dimensionValue
        choiceValue
        avgValue
        nbValue
        minValue
        maxValue
    }
  
  }

`