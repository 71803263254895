import { SelectInput, TextInput } from "@zipou/front_tools"
import { hotelContext } from "context/hotelContext"
import { HotelTokenInput, HotelTokenStatusEnum } from "model"
import React, { useContext } from "react"

type HotelTokenFormProps = {
  hotelToken: HotelTokenInput,
  errors: any,
  onChange: (hotelToken: HotelTokenInput) => void,
}

export const HotelTokenForm = ({ hotelToken, errors, onChange }: HotelTokenFormProps) => {

  const hotel = useContext(hotelContext)
  const tokenValue = hotel?.HotelToken?.find(el => el?.id === hotelToken?.id)
  const hasTokenValue = !!tokenValue

  return <>
    <TextInput errors={errors} value={hotelToken?.label} id="label" label="Nom" onChange={(v) => {
      onChange({
        ...hotelToken,
        label: v,
      })
    }} />
    <SelectInput errors={errors} id="status" label="Status" choiceList={Object.values(HotelTokenStatusEnum).map(el => ({ id: el, label: el }))} value={hotelToken?.status} onChange={v => {
      onChange({
        ...hotelToken,
        status: v as HotelTokenStatusEnum,
      })
    }} />
    {hasTokenValue && <div>
      <TextInput errors={errors} value={tokenValue?.id} disabled id="id" label="Id" onChange={(v) => { }} />
      <TextInput errors={errors} value={tokenValue?.token} disabled id="token" label="Token" onChange={(v) => { }} />
    </div>}

  </>

}
