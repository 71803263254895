import { userContext } from "context/User"
import { Hotel } from "model"
import React, { useContext, useEffect } from "react"

type HotelListSelectProps = {
  label: string,
  hotelId: string,
  disabled?: boolean,
  isError?: boolean,
  hotelFilter?: (hotel: Hotel) => boolean,
  onChange: (hotelId: string) => void,
}

export const HotelListSelect = ({ label, hotelId, disabled, isError, hotelFilter, onChange }: HotelListSelectProps) => {

  const user = useContext(userContext)
  const hotelList = user?.Hotel || []


  const hotelListFilterd = hotelFilter ? hotelList?.filter(hotelFilter) : hotelList

  useEffect(() => {

    if (!hotelId && hotelListFilterd?.length === 1) {

      onChange(hotelListFilterd[0]?.id)
    }
  }, [hotelListFilterd, hotelId])


  return <div className="input-group">
    <span className="input-group-text">{label}</span>
    <select className="form-control" value={hotelId || ""} disabled={disabled} onChange={(e: any) => {
      onChange(e.target.value)
    }}>
      <option value="" disabled>Choisissez un Hotel</option>
      {hotelListFilterd?.map((hotel: any) => <option key={`option_hotel_id_${hotel?.id}`} value={hotel?.id}>{hotel?.name}</option>)}
    </select>
    {isError && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
  </div>
}