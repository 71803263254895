import { useMutation, useQuery } from "@apollo/react-hooks"
import React, { useState } from "react"
import { UserForm } from "../Form/UserForm"
import { User, UserInput } from "model"
import userGet from "graphql/APP/User/query/userGet"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import userListGql from "graphql/APP/User/query/userList.gql"
import userUpdate from "graphql/APP/User/mutation/userUpdate"


type UserEditProps = {
  id: string,
  onDone?: () => void,
}

export const UserEdit = ({ id, onDone }: UserEditProps) => {

  const [user, updateUser] = useState<UserInput>()
  const [errors, updateErrors] = useState<any>()
  const [formUpdated, updateFormUpdated] = useState(false)


  const { data } = useQuery<{ user: User }>(userGet, {
    variables: {
      id
    },
    skip: !id,
    onCompleted: ({ user }) => {
      updateUser({
        email: user.email,
        name: user.name,
        corpId: user.corpId,
        PhoneAgentConfig: user?.PhoneAgentConfig,
        phoneSipConfig: user?.PhoneSipConfig,
        roles: user.roles,
        hotels: user.hotels,
        configId: user.configId,
        notificationListToMail: user?.notificationListToMail,
      })
    }
  })


  const [mutate] = useMutation(userUpdate, {
    refetchQueries: [{
      query: userListGql,
    }]
  })

  const onChange = (user: UserInput) => {
    updateUser(user)
    updateFormUpdated(true)
  }

  const onReset = () => {
    updateUser(data?.user)
  }

  const onSave = () => {
    mutate({
      variables: {
        input: user,
        id,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }



  return <div>
    <UserForm user={user} onChange={onChange} errors={errors} />
    {formUpdated && <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => onSave()}>Enregister</button>
      <button className="btn btn-danger btn-sm ml-1" onClick={() => onReset()}>Annuler</button>
    </div>}

  </div>




}