import gql from "graphql-tag";
import CorpFragmentGql from "../fragment/CorpFragment.gql";

export default gql`

  ${CorpFragmentGql}

  query corpGet($id: String!) {
    corp: corpGet(id: $id) {
      ...CorpFragment
    }
  }

`