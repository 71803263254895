import React from "react"

type DateFormatProps = {
  value: Date,
  isFrench?: boolean;
}
export const TimeFormat = (props: DateFormatProps) => {

  const isFrench = !(props?.isFrench === false)

  return <span>{isFrench ? props?.value?.toLocaleTimeString('fr-FR', {
    hour: "2-digit",
    minute: "2-digit",

  }) : props?.value?.toLocaleTimeString('en-US')}</span>
}