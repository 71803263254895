import gql from "graphql-tag";
import ReservationGroupLinkConfirmationFragment from "../../GroupLink/fragment/ReservationGroupLinkConfirmationFragment";

export default gql`

  ${ReservationGroupLinkConfirmationFragment}

  fragment ReservationGroupConfirmationFragment on ReservationGroup {
    id
    wholeDates
    ReservationGroupLink {
      ...ReservationGroupLinkConfirmationFragment
    }
  }

`