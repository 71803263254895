import {Customer} from "model"
import React from "react"

export type CustomerDisplayProps = {
  customer: Customer
}


export const CustomerDisplay = (props: CustomerDisplayProps) => {

  const customer = props?.customer

  return <span>
    {customer?.firstName || ""} {customer?.lastName || ""}
  </span>
}