import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton"
import { URL_COM_CONTACT_LIST_EDIT } from "constant/url"
import segmentationDelete from "graphql/CRM/Segmentation/mutation/segmentationDelete"
import segmentationList from "graphql/CRM/Segmentation/query/segmentationList"
import segmentationUpdateGql from "graphql/CRM/Segmentation/subscription/segmentationUpdate.gql"
import { Segmentation, SegmentationSyncStatus } from "model"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { SegmentationPreview } from "../Preview/SegmentationPreview"
import { SegmentationStatus } from "../Status/SegmentationStatus"
import { SegmentationExport } from "../Export/SegmentationExport"
import { IconRewind, IconSearch, IconStop, Modal } from "@zipou/front_tools"
import segmentationStop from "graphql/CRM/Segmentation/mutation/segmentationStop"
import segmentationSync from "graphql/CRM/Segmentation/mutation/segmentationSync"

export const SegmentationList = () => {

  const [mutateDelete] = useMutation(segmentationDelete)
  const [mutateStop] = useMutation(segmentationStop)
  const [mutateSync] = useMutation(segmentationSync)
  const [previewDisplay, updatePreviewDisplay] = useState(null)

  useSubscription(segmentationUpdateGql)

  const { data } = useQuery(segmentationList)

  const segmentation = data?.list;
  const hasList = data?.list && data?.list?.length > 0

  const onSegmentationStop = (id: string) => {
    mutateStop({
      variables: {
        id
      }
    })
  }
  const onSegmentationSync = (id: string) => {
    mutateSync({
      variables: {
        id
      }
    })
  }


  return <div>
    <Modal display={previewDisplay} onClose={() => updatePreviewDisplay(false)}>
      <SegmentationPreview segmentationId={previewDisplay} />
    </Modal>
    <h1>Segmentations</h1>
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="text-white bg-dark">
          <th className="text-white bg-dark">Nom</th>
          <th className="text-white bg-dark">Etat</th>
          <th className="text-white bg-dark">Dernière Synchro</th>
          <th className="text-white bg-dark">Actions</th>
        </tr>
      </thead>
      <tbody>
        {hasList && segmentation.map((segmentation: Segmentation, index: number) => {

          const isExportable = segmentation?.id && segmentation.status === SegmentationSyncStatus.SYNCED
          const isEditable = segmentation?.id && (
            segmentation.status === SegmentationSyncStatus.SYNCED ||
            segmentation.status === SegmentationSyncStatus.STOPPED
          )
          const isViewable = segmentation?.id && segmentation.status === SegmentationSyncStatus.SYNCED
          const isDeleteable = segmentation?.id && (
            segmentation.status === SegmentationSyncStatus.SYNCED ||
            segmentation.status === SegmentationSyncStatus.STOPPED
          )
          const isStoppable = segmentation?.id && segmentation.status === SegmentationSyncStatus.SYNCING
          const isSyncable = segmentation?.id && (
            segmentation.status === SegmentationSyncStatus.STOPPED ||
            segmentation.status === SegmentationSyncStatus.CREATED ||
            segmentation.status === SegmentationSyncStatus.SYNCED ||
            segmentation.status === SegmentationSyncStatus.ERROR ||
            segmentation.status === SegmentationSyncStatus.TODO
          )

          return <tr key={`segmentation_${segmentation?.id}`}>
            <td>{segmentation?.name}</td>
            <td><SegmentationStatus segmentation={segmentation} /></td>
            <td>{segmentation?.lastSyncDate && <DateTimeFormat value={new Date(segmentation?.lastSyncDate)} showUTC={false} />}</td>
            <td>
              {isEditable && <Link to={URL_COM_CONTACT_LIST_EDIT.replace(':id', segmentation?.id)}>
                <button className="btn btn-warning btn-sm">
                  <span className="icon-edit"></span>
                </button>
              </Link>}
              {isViewable && <button className="btn btn-dark btn-sm" style={{ marginLeft: 5 }} onClick={() => updatePreviewDisplay(segmentation?.id)}>
                <IconSearch />
              </button>}
              {isExportable && <SegmentationExport segmentationId={segmentation?.id} />}
              {isStoppable && <button className="btn btn-sm btn-danger ml-1" onClick={() => onSegmentationStop(segmentation?.id)}>
                <IconStop />
              </button>}
              {isSyncable && <button className="btn btn-sm btn-secondary ml-3" onClick={() => onSegmentationSync(segmentation?.id)}>
                <IconRewind />
              </button>}
              {isDeleteable && <TemporisationButton seconds={5} onClick={() => mutateDelete({
                variables: {
                  id: segmentation?.id
                },
                refetchQueries: [{
                  query: segmentationList,
                }]
              })}>
                {(onClick, spinnerComponent) => <button className="btn btn-sm btn-danger ml-1" onClick={onClick}>
                  {spinnerComponent}
                  <span className="icon-trash"></span>
                </button>}
              </TemporisationButton>}


            </td>
          </tr>
        })}
        {!hasList && <tr>
          <td colSpan={4}>
            <div className="alert alert-secondary">Aucune segmentation enregistrée</div>
          </td>
        </tr>}

      </tbody>
    </table>

  </div>
}