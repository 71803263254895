import gql from "graphql-tag";
import PostStayFragment from "../fragment/PostStayFragment";

export default gql`

${PostStayFragment}

query postStayGet($id: String!) {
  postStay: postStayGet(id: $id) {
    ...PostStayFragment
  }
}


`