import React from "react"

import {UserList} from "components/front/APP/User/List/UserList"

import './style.scss'

export const UserListRoute = () => {


  return <div className="user-list-route-container container">
    <UserList />
  </div>
}