import gql from "graphql-tag";
import ServiceFragment from "graphql/PMS/Service/fragment/ServiceFragment";

export default gql`

  ${ServiceFragment}

  fragment EventPublicFragment on Event {
    id
    eventType
    label
    utcDate
    status
    sourceType
    sourceId
    source
    serviceId
    Service {
      ...ServiceFragment
    }
  }

`