import moment from "moment"
import { useQuery } from "@apollo/react-hooks"
import postStayAnswerStatsGetAvailableWeeksGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableWeeks.gql"
import postStayAnswerStatsGetAvailableYearsGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableYears.gql"
import React, { useEffect } from "react"
import { IconArrowLeft, IconArrowRIght } from "@zipou/front_tools"

type StatsFilterWeeklyProps = {
  hotelId: string,
  dimensionValue: string,
  onChange: (dimensionValue: string) => void,
}

export const StatsFilterWeekly = ({ hotelId, dimensionValue, onChange }: StatsFilterWeeklyProps) => {

  const [year, weekIndexed] = (dimensionValue || "2024-10").split("-")

  // const year = dimensionDate?.getFullYear()
  // const week = moment(dimensionDate)?.week()
  // const weekIndexed = week


  const yearsQuery = useQuery<{ postStayAnswerStatsGetAvailableYears: string[] }>(postStayAnswerStatsGetAvailableYearsGql, {
    variables: {
      hotelId,
    }
  })

  const weeksQuery = useQuery<{ postStayAnswerStatsGetAvailableWeeks: string[] }>(postStayAnswerStatsGetAvailableWeeksGql, {
    variables: {
      hotelId,
      year: Number(year),
    },
  })

  const weekList = weeksQuery?.data?.postStayAnswerStatsGetAvailableWeeks
  const yearList = yearsQuery?.data?.postStayAnswerStatsGetAvailableYears

  const canGoNext = dimensionValue && weekList?.indexOf(dimensionValue) < weekList?.length - 1
  const canGoPrevious = dimensionValue && weekList?.indexOf(dimensionValue) > 0


  useEffect(() => {
    if (dimensionValue === "" && weekList?.length > 0) {
      console.log(`CHANGING for ${weekList[weekList?.length - 1]}`)
      onChange(weekList[weekList?.length - 1])
    }
  }, [dimensionValue, weekList])


  const goNext = () => {
    const index = weekList?.indexOf(dimensionValue)
    const newWeek = weekList[index + 1]
    onChange(newWeek)
  }

  const goPrevious = () => {
    const index = weekList?.indexOf(dimensionValue)
    const newWeek = weekList[index - 1]
    onChange(newWeek)
  }

  return <><div className='input-group'>
    {/* {canGoPrevious && <span className="input-group-text" onClick={() => {
      goPrevious()
    }}>
      <IconArrowLeft />
    </span>} */}
    <span className="input-group-text">Choisissez une semaine</span>
    <select className="form-control" placeholder="Semaine" value={dimensionValue || ""} onChange={(v) => {
      const week = v.target.value
      onChange(`${week}`)
    }}>
      <option value="" disabled>Semaine</option>
      {weekList?.map(weekNumber => {
        return <option key={`week_${weekNumber}`} value={weekNumber}>Semaine {weekNumber} </option>
      })}
    </select>
    {/* <select className="form-control" placeholder="Année" value={year} onChange={(v) => {
      onChange(`${v.target.value}-${weekIndexed}`)
    }}>
      <option value="" disabled>Année</option>
      {yearList?.map((year, index) => {
        return <option key={`year_${year}_${index}`} value={`${year}`}>{year}</option>
      })}
    </select> */}
    {/* {canGoNext && <span className="input-group-text" style={{ height: "100%" }} onClick={() => {
      goNext()
    }}>
      <IconArrowRIght />
    </span>} */}
  </div>
    <div className="d-flex flew-row justify-content-center">
      {canGoPrevious && <button className="btn btn-warning btn-sm" onClick={() => goPrevious()}>{"<- Semaine Précédente"}</button>}
      {canGoNext && <button className="btn btn-warning btn-sm ml-1" onClick={() => goNext()}>{"Semaine Suivante ->"}</button>}
    </div>
  </>
}