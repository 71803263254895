import gql from "graphql-tag";

export default gql`

  fragment NotificationFragment on Notification {
    id
    status
    type
    source
    sourceId
    date
  }

`