import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import { User } from "model"
import React, { useState } from "react"
import { UserAdd } from "../Add/UserAdd"
import userListGql from "graphql/APP/User/query/userList.gql"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { UserEdit } from "../Edit/UserEdit"
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton"
import userDeleteGql from "graphql/APP/User/mutation/userDelete.gql"

// type UserListProps = {
// }

export const UserList = () => {

  const { data} = useQuery<{ userList: User[] }>(userListGql)
  const [mutate] = useMutation(userDeleteGql)


  const userList = data?.userList
  const [focusAdd, updateFocusAdd] = useState(false)
  const [focusEdit, updateFocusEdit] = useState<User | null>(null)

  const onDelete = (user: User) => {
    mutate({
      variables: {
        id: user?.id
      },
      refetchQueries: [{
        query: userListGql
      }]
    })
      .then(() => {
        console.log("OK")
      })
      .catch((e: any) => {
        console.log("e", e.message)
      })
  }



  return <div className="user-list-container">
    <Modal display={focusAdd} onClose={() => updateFocusAdd(null)}>
      <UserAdd onDone={() => updateFocusAdd(false)} />
    </Modal>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <UserEdit id={focusEdit?.id} onDone={() => updateFocusEdit(null)} />
    </Modal>
    <h1>Liste des utilisateurs</h1>
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Email</th>
          <th className="bg-dark text-white">Corp</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {userList?.map((user, index) => {
          return <tr key={`user_${user?.id}_${index}`}>
            <td>{user?.name}</td>
            <td>{user?.email}</td>
            <td>{user?.Corp?.name}</td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusEdit(user)}>
                <IconEdit />
              </button>
              <TemporisationButton seconds={3} onClick={() => onDelete(user)}>
                {(onClick, spinner) => {
                  return <button className="btn btn-sm btn-danger ml-1" onClick={onClick} style={{ whiteSpace: "nowrap" }}>
                    {spinner} <IconTrash />
                  </button>
                }}
              </TemporisationButton>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <button className="btn btn-dark btn-sm" onClick={() => updateFocusAdd(true)}>Ajouter</button>
  </div>
}