import gql from "graphql-tag";
import ReservationGroupEditFragment from "../fragment/ReservationGroupEditFragment";

export default gql`

  ${ReservationGroupEditFragment}

  mutation reservationGroupUpdate($id: String!, $input: ReservationGroupEditInput!) {
    reservationGroup: reservationGroupUpdate(id: $id, input: $input) {
      ...ReservationGroupEditFragment
    }
  }

`