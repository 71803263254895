import gql from "graphql-tag";
import ChannelTargetFragmentGql from "../../ChannelTarget/fragment/ChannelTargetFragment.gql";
import MessageFragmentGql from "../../Message/fragment/MessageFragment.gql";

export default gql`

  ${ChannelTargetFragmentGql}
  ${MessageFragmentGql}

  fragment ChannelMessageFragment on Channel {
    id
    status
    options
    messageList {
      ...MessageFragment
    }
    ChannelTarget {
      ...ChannelTargetFragment
    }
  }

`
/*



*/