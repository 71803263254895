import gql from "graphql-tag";
import TokenFragment from "../fragment/TokenFragment";

export default gql`

  ${TokenFragment}

  mutation tokenAdd($input: TokenInput!) {
    token: tokenAdd(input: $input) {
      ...TokenFragment
    }
  }

`