import React from "react"
import {isFieldOnError} from "helpers/Apollo"

type DurationInputProps = {
  id?: string
  label: string,
  valueInMinutes: number,
  disabled?: boolean;
  errors: any,
  defaultValue?: string,
  defaultValueActive?: boolean,
  onChange: (event: any, v: string) => void,
}

export const DurationInput = (props: DurationInputProps) => {

  return <div className="input-group">
    <span className="input-group-text">{props?.label}</span>
    <select className="form-control" value={props?.valueInMinutes || ""} disabled={props?.disabled || false} onChange={(e) => props?.onChange(e, e.target.value)}>
      {!props?.defaultValue && <option value="" disabled={!props?.defaultValueActive}>Choisissez</option>}
      {props?.defaultValue && <option value="" disabled={!props?.defaultValueActive}>{props.defaultValue}</option>}
      <option value="30">30m</option>
      <option value="45">45m</option>
      <option value="60">1H</option>
      <option value="75">1H15</option>
      <option value="90">1H30</option>
      <option value="105">1H45</option>
      <option value="120">2H</option>
      <option value="135">2H15</option>
      <option value="150">2H30</option>
      <option value="165">2H45</option>
      <option value="180">3H</option>
      <option value="195">3H15</option>
      <option value="210">3H30</option>
      <option value="225">3H45</option>
      <option value="240">4H</option>
    </select>
    {isFieldOnError(props?.errors, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
  </div>

}