import gql from "graphql-tag";
import PhoneConfigFragmentGql from "graphql/PHONE/Config/fragment/PhoneConfigFragment.gql";

export default gql`

  ${PhoneConfigFragmentGql}

  fragment HotelConfirmationFragment on Hotel {
    id
    name
    themeColor
    logoUrl
    displayRoomCategoryInDetails
    cgvUrlFrFileId
    cgvUrlEnFileId
    PhoneConfig {
      ...PhoneConfigFragment
    }
  }
`