import gql from "graphql-tag";
import PlanningFragmentGql from "../fragment/PlanningFragment.gql";

export default gql`

  ${PlanningFragmentGql}

  query planningGet($id: String!) {
    planning: planningGet(id: $id) {
      ...PlanningFragment
    }
  }

`