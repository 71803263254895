import gql from "graphql-tag";
import PropositionFragment from "../fragment/PropositionFragment";

export default gql`

  ${PropositionFragment}

  query propositionGet($id: String!) {
    result: propositionGet(id: $id) {
      warning
      proposition {
        ...PropositionFragment
      }
    }
  }
`