import {PaymentEdit} from "components/front/BILLING/Payment/Edit/PaymentEdit"
import React from "react"

export const PaymentEditRoute = (props: any) => {

  const {match} = props
  const {params} = match
  const {paymentId} = params

  return <div>
    <h1>Paiement</h1>
    <PaymentEdit paymentId={paymentId} />
  </div>
}