import { SelectInput } from "components/common/Form/SelectInput"
import { TextInput } from "components/common/Form/TextInput"
import { TemplateSelectField } from "components/front/APP/Template/Field/TemplateSelectField"
import { BillingConfigInput, BillingConfigTypeEnum } from "model"
import React from "react"
import { TerminalFormList } from "../../Terminal/Form/TerminalFormList"

type BillingConfigFormProps = {
  hotelId: string,
  billingConfig?: BillingConfigInput,
  errors?: any,
  onChange?: (data: BillingConfigInput) => void,
}

export const BillingConfigForm = ({ billingConfig, hotelId, errors, onChange }: BillingConfigFormProps) => {

  // const onChange = (data: any) => {
  //   const { __typename, id, ...billingConfig } = data;
  //   props?.onChange({
  //     ...billingConfig
  //   })
  // }

  const renderSubForm = () => {
    switch (billingConfig?.type) {

      case BillingConfigTypeEnum.STRIPE: {
        return <div className="input-group">
          <span className="input-group-text">STRIPE</span>
          <div className="form-control" style={{ height: "100%" }}>
            <TextInput value={billingConfig?.stripeConfig?.secretKey || ""} id="secretKey" label="secretKey" errors={{}} onChange={(_, v) => {
              onChange({
                ...billingConfig,
                stripeConfig: {
                  ...(billingConfig?.stripeConfig || {}),
                  secretKey: v,
                }
              })
            }}
            />
            <TextInput value={billingConfig?.stripeConfig?.publishableKey || ""} id="publishableKey" label="publishableKey" errors={{}} onChange={(_, v) => {
              onChange({
                ...billingConfig,
                stripeConfig: {
                  ...(billingConfig?.stripeConfig || {}),
                  publishableKey: v,
                }
              })
            }}
            />
            <TextInput value={billingConfig?.stripeConfig?.webHookKey || ""} id="webHookKey" label="webHookKey" errors={{}} onChange={(_, v) => {
              onChange({
                ...billingConfig,
                stripeConfig: {
                  ...(billingConfig?.stripeConfig || {}),
                  webHookKey: v,
                }
              })
            }}
            />
          </div>
        </div>
      }

      // case BillingConfigTypeEnum.PAYTWEAK: {
      //   return <div className="input-group">
      //     <span className="input-group-text">PAYTWEAK</span>
      //     <div className="form-control" style={{ height: "100%" }}>
      //       <TextInput value={billingConfig?.options?.token || ""} id="token" label="token" errors={{}} onChange={(_, v) => {
      //         onChange({
      //           ...billingConfig,
      //           options: {
      //             ...(billingConfig?.options || {}),
      //             token: v,
      //           }
      //         })
      //       }} />
      //       <TextInput value={billingConfig?.options?.publicKey || ""} id="publicKey" label="publicKey" errors={{}} onChange={(_, v) => {
      //         onChange({
      //           ...billingConfig,
      //           options: {
      //             ...(billingConfig?.options || {}),
      //             publicKey: v,
      //           }
      //         })
      //       }} />
      //     </div>
      //   </div>
      // }

      default: {
        return <div> </div>
      }
    }
  }

  return <div>
    <TemplateSelectField hotelId={hotelId} value={billingConfig?.templateId} errors={errors} label="Template" id="templateId" onChange={(v) => {
      onChange({
        ...billingConfig,
        templateId: v,
      })
    }} />

    <SelectInput value={billingConfig?.type || ""} label="Type" id="type" disabled={false} errors={{}} choiceList={[{ id: "STRIPE", label: "STRIPE" }]} onChange={(_, v) => {
      onChange({
        ...billingConfig,
        type: v as BillingConfigTypeEnum,
      })
    }} />
    {renderSubForm()}

    <TerminalFormList errors={errors} terminalList={billingConfig?.terminalList} onChange={(terminalList) => {
      onChange({
        ...billingConfig,
        terminalList,
      })
    }} />
  </div>

}