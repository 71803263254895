import gql from "graphql-tag";

export default gql`

fragment ReceiptInfoFragment on ReceiptInfo {
  last4
  expYear
  expMonth
  brand
  country
  used3DSecure
}
`