import gql from "graphql-tag";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";

export default gql`

${ContactFragment}

fragment CustomerEmailFragment on CustomerEmail {
    id
    email
    domain
    status
    sourceType
    sourceId
    Contact {
      ...ContactFragment
    }
  }

`