import gql from "graphql-tag";
import CustomerCTIFragment from "../fragment/CustomerCTIFragment";

export default gql`

${CustomerCTIFragment}

query customerGetByPhoneNumber($phoneNumber: String!) {
  customer: customerGetByPhoneNumber(phoneNumber: $phoneNumber) {
    ...CustomerCTIFragment
  }
}
`