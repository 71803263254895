import gql from "graphql-tag";

export default gql`
  fragment ContactSearchIndivFragment on Contact {
    id
    pmsId
    firstName
    lastName
    email
    updateDate
    forceIsFrench
  }
`