import gql from "graphql-tag";
import ConfirmationAnswerFragment from "../fragment/ConfirmationAnswerFragment";

export default gql`
  ${ConfirmationAnswerFragment}

  mutation confirmationAnswerSave($confirmationId: String!, $questionId: String!, $datas: JSONObject!) {
    answer: confirmationAnswerSave(
      confirmationId: $confirmationId
      questionId: $questionId
      datas: $datas
    ) {
      ...ConfirmationAnswerFragment
    }
  }

`