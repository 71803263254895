import {useMutation} from '@apollo/react-hooks'
import React from 'react'
import contactAddGql from "graphql/PMS/Contact/mutation/contactAdd.gql"

import {useState} from "react"
import {ContactForm} from "../Form/ContactForm"
import {formatErrorResponseForJoi} from 'helpers/Apollo'

type ContactAddProps = {
  hotelId: string,
  onDone: (data: any) => void,
}

export const ContactAdd = (props: ContactAddProps) => {

  const [contact, updateContact] = useState({})
  const [errors, updateErrors] = useState({})

  const [mutate] = useMutation(contactAddGql)

  const onSubmit = (e: any) => {
    e.preventDefault()
    mutate({
      variables: {
        hotelId: props?.hotelId,
        input: {
          ...contact,
        }
      }
    })
      .then(response => {
        const contact = response?.data?.contact
        updateErrors({})
        props?.onDone(contact)
      })
      .catch(e => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div>
    <ContactForm contact={contact} onChange={updateContact} hotelId={props?.hotelId} errors={errors} />
    <div className='actions'>
      <button className='btn btn-dark' type="button" onClick={onSubmit}>Enregister</button>
    </div>
  </div>
}