import gql from "graphql-tag";
import PostStayGroupItemFragment from "../Item/PostStayGroupItemFragment";

export default gql`

  ${PostStayGroupItemFragment}

  fragment PostStayGroupFragment on PostStayGroup {
    id
    priority
    labelFr
    descFr
    labelEn
    descEn
    PostStayGroupItem {
      ...PostStayGroupItemFragment
    }
  }

`