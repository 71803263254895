import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

${PaymentFragment}
mutation paymentSend($id: String!) {
  payment: paymentSend(id: $id) {
    ...PaymentFragment
  }
}

`