import gql from "graphql-tag";
import ReceiptInfoFragmentGql from "graphql/BILLING/ReceiptInfo/fragment/ReceiptInfoFragment.gql";
import HotelBillingFragment from "graphql/PMS/Hotel/fragment/HotelBillingFragment";

export default gql`

  ${HotelBillingFragment}
  ${ReceiptInfoFragmentGql}

  fragment PaymentPublicFragment on Payment {
    id
    status
    hotelId
    publicId
    publicSalt
    contactId
    mobilePhoneNumber
    amount
    isError
    lastError
    stripeClientSecret
    captureStatus
    captureType
    provider
    captureDate
    hasValidEmail
    sendStatus
    receiptInfo {
      ...ReceiptInfoFragment
    }
    PaymentOptions {
      ... on PaymentPaytweakOptions {
        url
      }
      ... on PaymentStripeOptions {
        stripeClientSecret
        stripeId
        paymentId
      }
    }
    Hotel {
      ...HotelBillingFragment
    }

  }

`