import React from "react"
import {Widget} from "components/common/Widget/Widget"
import {useQuery} from "@apollo/react-hooks"
import reservationCount from "graphql/PMS/Reservation/query/reservationCount"

export const ReservationCountWidget = () => {

  const {data} = useQuery(reservationCount, {
    // pollInterval: 5000,
  })

  return <Widget type="number" value={data?.count} label="Reservations " />
}