import gql from "graphql-tag";
import ReservationGroupEditFragment from "../fragment/ReservationGroupEditFragment";

export default gql`

${ReservationGroupEditFragment}

  query reservationGroupGet($id: String!) {
    reservationGroup: reservationGroupGet(id: $id) {
      ...ReservationGroupEditFragment
    }
  }

`