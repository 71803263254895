import gql from "graphql-tag";
import PreStayPublicFragment from "../fragment/PreStayPublicFragment";

export default gql`

  ${PreStayPublicFragment}

  query preStayGetByIdAndPublicId($id: String!, $publicId: String!) {
    preStay: preStayGetByIdAndPublicId(id: $id, publicId: $publicId) {
      ...PreStayPublicFragment
    }
  }

`