import gql from "graphql-tag";
import ReservationWidgetFragment from "../fragment/ReservationWidgetFragment";

export default gql`

  ${ReservationWidgetFragment}

  query reservationGet($id: String!) {
    reservation: reservationGet(id: $id) {
      ...ReservationWidgetFragment
    }
  }

`