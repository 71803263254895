import {TextInput} from 'components/common/Form/TextInput'
import { PhoneAgentConfigInput } from 'model'
import React from 'react'

type PhoneAgentConfigFormProps = {
  phoneAgentConfig: PhoneAgentConfigInput,
  errors?: any,
  onChange: (data: PhoneAgentConfigInput) => void,
}

export const PhoneAgentConfigForm = (props: PhoneAgentConfigFormProps) => {


  const phoneAgentConfig: PhoneAgentConfigInput = props?.phoneAgentConfig

  const onChange = (d: any) => {
    props?.onChange(d)
  }

  return <div>
    <TextInput label="phoneConfigId" id={"phoneConfigId"} errors={props?.errors} value={phoneAgentConfig?.phoneConfigId} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        phoneConfigId: v,
      })
    }} />
    <TextInput label="token" id={"token"} errors={props?.errors} value={phoneAgentConfig?.token} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        token: v,
      })
    }} />
    <TextInput label="accountName" id={"accountName"} errors={props?.errors} value={phoneAgentConfig?.accountName} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        accountName: v,
      })
    }} />
    <TextInput label="callChannel" id={"callChannel"} errors={props?.errors} value={phoneAgentConfig?.callChannel} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        callChannel: v,
      })
    }} />
  </div>
}
