import gql from "graphql-tag";
import PriceRateTypeFragmentGql from "graphql/PMS/PriceRate/Type/fragment/PriceRateTypeFragment.gql";

export default gql`

  ${PriceRateTypeFragmentGql}

  fragment ReservationRateFragment on ReservationRate {
    id
    amountIncVat
    startDate
    PriceRateType {
      ...PriceRateTypeFragment
    }
  }
`