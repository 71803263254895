
import { useQuery } from "@apollo/react-hooks";
import priceRateCancelConditionItemValueListGql from "graphql/PMS/PriceRate/CancelCondition/query/priceRateCancelConditionItemValueList.gql";
import { PriceRateCancelConditionInput, PriceRateCancelConditionType } from "model";
import React from "react"

import "./style.scss"
import { IconWarningSign, NumberInput, SelectInput, TextField, TextInput } from "@zipou/front_tools";

type PriceRateCancelConditionFormProps = {
  priceRateCancelCondition: PriceRateCancelConditionInput,
  errors: any,
  onChange: (data: PriceRateCancelConditionInput) => void,
}


export const PriceRateCancelConditionForm = ({ priceRateCancelCondition, errors, onChange }: PriceRateCancelConditionFormProps) => {

  const { data } = useQuery(priceRateCancelConditionItemValueListGql)

  const valueList = data?.list.map((value: any) => ({ id: value, label: value }))

  const isTypeFunction = priceRateCancelCondition?.type === PriceRateCancelConditionType.TYPE_FUNCTION
  const isTypeClassic = priceRateCancelCondition?.type === PriceRateCancelConditionType.TYPE_CLASSIC

  return <div className="cancel-condition-container">
    <div>
      <TextInput id="label" errors={errors} value={priceRateCancelCondition?.label || ""} label="Nom" onChange={(e: any) => {
        onChange({
          ...priceRateCancelCondition,
          label: e.target.value,
        })
      }} />

      <SelectInput label="Type" id="type" errors={errors} value={priceRateCancelCondition?.type} choiceList={Object.keys(PriceRateCancelConditionType).map(el => ({ id: el, label: el }))} onChange={(e: any) => {
        onChange({
          ...priceRateCancelCondition,
          type: e.target.value,
        })
      }} />
      {isTypeFunction && <div>
        <TextField isError={errors?.functionText} label="Fonction" value={priceRateCancelCondition?.functionText || ""} onChange={v => {
          onChange({
            ...priceRateCancelCondition,
            functionText: v,
          })
        }} />
      </div>}
      {isTypeClassic && <div>
        <SelectInput id="defaultValue" choiceList={valueList} errors={errors} value={priceRateCancelCondition?.defaultValue || ""} label="Valeur par Defaut" onChange={(e: any) => {
          onChange({
            ...priceRateCancelCondition,
            defaultValue: e.target.value,
          })
        }} />
        <div className="input-group">
          <span className="input-group-text">Conditions</span>
          <div className="form-control" style={{ height: "100%" }}>
            {priceRateCancelCondition?.conditionList?.map((condition: any, index: number) => {

              // const isOnError = errors && errors?.conditionList && errors?.conditionList[index]
              const fieldError = errors && errors?.conditionList && errors?.conditionList[index]

              return <div key={`condition_${index}`} className="" style={{ height: "100%" }}>
                <div className="input-group">
                  <div className="form-control" style={{ height: "100%" }}>
                    <NumberInput id="before" value={condition.before} label="before" errors={fieldError} onChange={(_, v) => {
                      onChange({
                        ...priceRateCancelCondition,
                        conditionList: priceRateCancelCondition?.conditionList?.map((cond: any, idx: number) => idx === index ? {
                          ...cond,
                          before: v,
                        } : cond)
                      })
                    }} />
                    <SelectInput choiceList={valueList} value={condition.value} label="value" errors={fieldError} onChange={(event: any) => {
                      onChange({
                        ...priceRateCancelCondition,
                        conditionList: priceRateCancelCondition?.conditionList?.map((cond: any, idx: number) => idx === index ? {
                          ...cond,
                          value: event.target.value,
                        } : cond)
                      })
                    }} />
                  </div>
                  <span className="input-group-text" onClick={() => {
                    onChange({
                      ...priceRateCancelCondition,
                      conditionList: priceRateCancelCondition?.conditionList?.filter((el: any, idx: number) => idx !== index)
                    })
                  }}>
                    <span className="icon-trash" />
                  </span>
                  {fieldError && <span className="input-group-text">
                    <IconWarningSign />
                  </span>}

                </div>
              </div>
            })}
            <button className="btn btn-dark btn-sm" onClick={() => {
              onChange({
                ...priceRateCancelCondition,
                conditionList: [
                  ...(priceRateCancelCondition?.conditionList || []),
                  {
                    before: null,
                    value: null,
                  }
                ]
              })
            }}>Ajouter une condition</button>
          </div>
          {errors && errors.conditionList && <span className="input-group-text">
            <IconWarningSign />
          </span>}
        </div>
      </div>}
    </div>
  </div>
}