import { PropositionSentStatusEnum } from "model"
import React from "react"


type PropositionSentStatusProps = {
  value: PropositionSentStatusEnum
}

export const PropositionSentStatus = ({ value }: PropositionSentStatusProps) => {

  switch (value) {
    case PropositionSentStatusEnum.STATUS_NOT_SENT:
      return <span className="badge badge-warning">BROUILLON</span>

    case PropositionSentStatusEnum.STATUS_SENT:
      return <span className="badge badge-warning">ENVOYEE</span>

    case PropositionSentStatusEnum.STATUS_SENT:
      return <span className="badge badge-primary">ENVOYEE</span>

    case PropositionSentStatusEnum.STATUS_READ:
      return <span className="badge badge-success">LU</span>

    case PropositionSentStatusEnum.STATUS_DELIVERED:
      return <span className="badge badge-success">DELIVREE</span>

    default:
      return <span className="badge badge-info">{value}</span>
  }

}