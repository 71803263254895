import gql from "graphql-tag";
import CustomerDetailsFragment from "../fragment/CustomerDetailsFragment";

export default gql`

${CustomerDetailsFragment}

query customerGet($id: String!) {
  customer: customerGet(id: $id) {
    ...CustomerDetailsFragment
  }
}
`