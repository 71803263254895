import gql from "graphql-tag";
import ConfirmationFragment from "../../fragment/ConfirmationFragment";

export default gql`

  ${ConfirmationFragment}

  mutation confirmationAnswerClear($confirmationId: String!) {
    confirmation: confirmationAnswerClear(confirmationId: $confirmationId) {
      ...ConfirmationFragment
    }
  }

`