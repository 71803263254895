import gql from "graphql-tag";
import UserFragment from "../fragment/UserFragment";

export default gql`

  ${UserFragment}

  query userListByUser {
    list: userListByUser {
      ...UserFragment
    }
  }
`