import gql from "graphql-tag";
import CustomerAddressFragmentGql from "../fragment/CustomerAddressFragment.gql";

export default gql`

${CustomerAddressFragmentGql}

query customerAddressGet($id: String!) {
  customerAddress: customerAddressGet(id: $id) {
    ...CustomerAddressFragment
  }
}


`