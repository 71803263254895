import gql from "graphql-tag";

export default gql`
  fragment UserConfigFragment on UserConfig {
    id
    name
    datas {
      title
      logoUrl
      themeMainColor
      fontFamily
    }
  }

`