import gql from "graphql-tag";

export default gql`

  fragment TerminalFragment on Terminal {
    id
    name
    type
    location
    status
    StripeOptions {
      id
      stripeTerminalId
    }
  }

`