import gql from "graphql-tag";
import HotelEditFragment from "../fragment/HotelEditFragment";

export default gql`

${HotelEditFragment}

mutation hotelAdd($input: HotelInput!) {
  hotel: hotelAdd(input: $input) {
    ...HotelEditFragment
  }
}

`