import React from 'react'
import {EventStatusEnum} from "model"
import {useTranslation} from 'react-i18next'

export type EventStatusProps = {
  status: EventStatusEnum | string,
}

export const EventStatus = (props: EventStatusProps) => {

  const status = props?.status
  const {t} = useTranslation('translation', {keyPrefix: 'prestay'});

  switch (status) {
    case EventStatusEnum.EVENT_STATUS_PENDING: {
      return <span className='badge badge-info'>{t(status)}</span>
    }

    case EventStatusEnum.EVENT_STATUS_CONFIRMED: {
      return <span className='badge badge-success'>{t(status)}</span>
    }

    case EventStatusEnum.EVENT_STATUS_CANCELLED: {
      return <span className='badge badge-warning'>{t(status)}</span>
    }

    case EventStatusEnum.EVENT_STATUS_DELETED: {
      return <span className='badge badge-danger'>{t(status)}</span>
    }

    default: {
      return <span className="badge badge-dark">{t(status)}</span>
    }
  }

}