import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${PaymentFragment}

 subscription PaymentUpdate($id: String!) {
   payment: paymentUpdate(id: $id) {
     ...PaymentFragment
   }
 }
`