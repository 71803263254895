import gql from "graphql-tag";
import StripeConfigFragmentGql from "graphql/BILLING/Stripe/Config/fragment/StripeConfigFragment.gql";
import TerminalFragmentGql from "graphql/BILLING/Terminal/fragment/TerminalFragment.gql";

export default gql`

${StripeConfigFragmentGql}
${TerminalFragmentGql}

fragment BillingConfigAdminFragment on BillingConfig {
  id
  type
  templateId
  StripeConfig {
    ...StripeConfigFragment
  }
  Terminal {
    ...TerminalFragment
  }

}
`