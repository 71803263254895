import gql from "graphql-tag";
import CustomerListFragment from "../../fragment/CustomerListFragment";

export default gql`


${CustomerListFragment}

fragment CustomerEmailListFragment on CustomerEmail {
    id
    Customer {
      ...CustomerListFragment
    }
  }

`