import React from "react"

type DateFormatProps = {
  value: Date,
  showUTC?: boolean,
  UTCOffset?: boolean,
  options?: any
}



export const DateFormat = (props: DateFormatProps) => {

  // const valueUTC = new Date(props?.value?.getTime())
  const valueUTC = new Date(props?.value?.getTime() + (props?.UTCOffset ? (1 * 1000 * 3600 * 24 ) : 0))

  return <span>{valueUTC?.toLocaleDateString('fr-FR', {
    ...props?.options,
    timeZone: props?.showUTC ? "UTC": "Europe/Paris",
  })}</span>
}