import { TokenTypeEnum } from "model"
import React from "react";

type TokenTypeProps = {
  type: TokenTypeEnum;
}

export const TokenType = ({ type }: TokenTypeProps) => {

  switch (type) {
    case TokenTypeEnum.TYPE_LINK: {
      return <span className="badge badge-sm badge-info">Par Lien de Paiment</span>
    }

    case TokenTypeEnum.TYPE_TERMINAL: {
      return <span className="badge badge-sm badge-info">Par TPE</span>
    }

    default: {
      return <span className="badge badge-sm badge-warning">{type}</span>

    }
  }
}