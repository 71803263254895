import gql from "graphql-tag";
import FilePublicFragment from "graphql/APP/File/fragment/FilePublicFragment";
import BillingConfigFragment from "graphql/BILLING/Config/fragment/BillingConfigFragment";
import PhoneConfigFragmentGql from "graphql/PHONE/Config/fragment/PhoneConfigFragment.gql";
import PropositionConfigFragmentGql from "graphql/PMS/Proposition/Config/fragment/PropositionConfigFragment.gql";
import GoogleConfigFragmentGql from "graphql/PMS/Social/Google/fragment/GoogleConfigFragment.gql";
import TripAdvisorConfigGql from "graphql/PMS/Social/TripAdvisor/fragment/TripAdvisorConfig.gql";

export default gql`

  ${BillingConfigFragment}
  ${FilePublicFragment}
  ${PhoneConfigFragmentGql}
  ${GoogleConfigFragmentGql}
  ${TripAdvisorConfigGql}

  fragment HotelPublicFragment on Hotel {
    id
    name
    logoUrl
    logoMonoUrl
    cgvUrlEn
    cgvUrlFr
    cgvUrlFrFileId
    cgvUrlEnFileId
    PropositionConfig {
      id
      illustrationUrl
    }
    PhoneConfig {
      ...PhoneConfigFragment
    }
    CGVFrFile {
      ...FilePublicFragment
    }
    CGVEnFile {
      ...FilePublicFragment
    }
    address
    phoneNumber
    backgroundUrl
    checkInHour
    checkOutHour
    themeColor
    backgroundThemeColor
    displayRoomPictureDisclosure
    displayRoomCategoryInDetails
    BillingConfig {
      ...BillingConfigFragment
    }
    phoneConfigId
    GoogleConfig {
      ...GoogleConfigFragment
    }
    TripAdvisorConfig {
      ...TripAdvisorConfigFragment
    }
  }
`