import { useQuery } from "@apollo/react-hooks"
import roomGetGql from "graphql/PMS/Room/query/roomGet.gql"
import React from "react"

type RoomDetailsProps = {
    roomId: string,
}

export const RoomDetails = (props: RoomDetailsProps) => {

    const { data } = useQuery(roomGetGql, {
        variables: {
            id: props?.roomId
        },
        skip: !props?.roomId
    })

    const room = data?.room

    return <span>
        {room?.number} - {room?.RoomType?.type}
    </span>

}