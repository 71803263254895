import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import configListGql from "graphql/APP/User/Config/query/userConfigList.gql"
import { UserConfigAdd } from "../Add/UserConfigAdd"
import { UserConfigEdit } from "../Edit/UserConfigEdit"
import { UserConfig } from "model"
import { IconEdit, Modal } from "@zipou/front_tools"


type UserConfigListProps = {

}

export const UserConfigList = (props: UserConfigListProps) => {

  const { data } = useQuery(configListGql)
  const [focusAdd, updateFocusAdd] = useState<any>()
  const [focusUpdate, updateFocusUpdate] = useState<any>()

  const configList: UserConfig[] = data?.list

  return <div>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}  >
      <UserConfigAdd onDone={() => updateFocusAdd(false)} />
    </Modal>
    <Modal display={focusUpdate} onClose={() => updateFocusUpdate(false)}  >
      <UserConfigEdit id={focusUpdate} onDone={() => updateFocusUpdate(false)} />
    </Modal>

    <table className="table table-bordered table-stripped ">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Title</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {configList?.map(config => {
          return <tr key={`config_${config?.id}`}>
            <td>{config.name}</td>
            <td>
              <button className="btn btn-dark btn-sm" onClick={() => updateFocusUpdate(config?.id)}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>

    <div>
      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>

  </div>

}
