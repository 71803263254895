import React from "react"
import { TextInput } from "@zipou/front_tools"
import { NetworkConfig, NetworkConfigInput } from "model"

type NetworkConfigProps = {
  networkConfig: NetworkConfigInput | NetworkConfig,
  onChange: (networkConfig: NetworkConfigInput) => void,
  errors: any,
}

export const NetworkConfigForm = ({ networkConfig, onChange, errors }: NetworkConfigProps) => {


  return <div>
    <TextInput value={networkConfig?.hostingConfigId} errors={errors} label="hostingConfigId" onChange={hostingConfigId => {
      onChange({
        ...networkConfig,
        hostingConfigId,
      })
    }} />
    <TextInput value={networkConfig?.token} errors={errors} label="token" onChange={token => {
      onChange({
        ...networkConfig,
        token,
      })
    }} />
  </div>
}
