import moment from "moment"
import { useQuery } from "@apollo/react-hooks"
import postStayAnswerStatsGetAvailableWeeksGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableWeeks.gql"
import postStayAnswerStatsGetAvailableYearsGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableYears.gql"
import React, { useEffect } from "react"
import postStayAnswerStatsGetAvailableMonthsGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableMonths.gql"
import { IconArrowLeft, IconArrowRIght } from "@zipou/front_tools"

type StatsFilterMonthlyProps = {
  hotelId: string,
  dimensionValue: string,
  onChange: (dimensionValue: string) => void,
}

export const StatsFilterMonthly = ({ hotelId, dimensionValue, onChange }: StatsFilterMonthlyProps) => {

  const [year, monthIndexed] = (dimensionValue || "2024-10").split("-")


  // const year = dimensionDate?.getFullYear()
  // const month = moment(dimensionDate)?.month()
  // const monthIndexed = month

  // console.log('monthIndexed', monthIndexed)

  const yearsQuery = useQuery<{ postStayAnswerStatsGetAvailableYears: string[] }>(postStayAnswerStatsGetAvailableYearsGql, {
    variables: {
      hotelId,
    }
  })

  const monthsQuery = useQuery<{ postStayAnswerStatsGetAvailableMonths: string[] }>(postStayAnswerStatsGetAvailableMonthsGql, {
    variables: {
      hotelId,
      year: Number(year),
    },
  })


  const monthList = monthsQuery?.data?.postStayAnswerStatsGetAvailableMonths
  const yearList = yearsQuery?.data?.postStayAnswerStatsGetAvailableYears

  const canGoNext = dimensionValue && monthList?.indexOf(dimensionValue) < monthList?.length - 1
  const canGoPrevious = dimensionValue && monthList?.indexOf(dimensionValue) > 0



  const goNext = () => {
    const index = monthList?.indexOf(dimensionValue)
    const newMonth = monthList[index + 1]
    onChange(newMonth)
  }

  const goPrevious = () => {
    const index = monthList?.indexOf(dimensionValue)
    const newMonth = monthList[index - 1]
    onChange(newMonth)
  }

  useEffect(() => {
    if (dimensionValue === "" && monthList?.length > 0) {
      console.log(`CHANGING for ${monthList[monthList?.length - 1]}`)
      onChange(monthList[monthList?.length - 1])
    }
  }, [dimensionValue, monthList])



  return <>
    <div className='input-group'>
      {/* {canGoPrevious && <span className="input-group-text" onClick={() => {
      const index = monthList?.indexOf(dimensionValue)
      const newMonth = monthList[index - 1]
      onChange(newMonth)
    }}>
      <IconArrowLeft />
    </span>} */}
      <span className="input-group-text">Choisissez un mois</span>
      <select className="form-control" placeholder="Mois" value={dimensionValue || ""} onChange={(v) => {
        const value = v.target.value
        onChange(`${v.target.value}`)
      }}>
        <option value="" disabled>Mois</option>
        {monthList?.map(monthNumber => {
          return <option key={`month_${monthNumber}`} value={`${monthNumber}`}>{monthNumber}</option>
        })}
      </select>
      {/* <select className="form-control" placeholder="Année" value={year} onChange={(v) => {
      onChange(`${v.target.value}-${monthIndexed}`)
    }}>
      <option value="" disabled>Année</option>
      {yearList?.map((year, index) => {
        return <option key={`year_${year}_${index}`} value={`${year}`}>{year}</option>
      })}
    </select> */}
      {/* {canGoNext && <span className="input-group-text" style={{ height: "100%" }} onClick={() => {
      const index = monthList?.indexOf(dimensionValue)
      const newMonth = monthList[index + 1]
      onChange(newMonth)
    }}>
      <IconArrowRIght />
    </span>} */}
    </div>
    <div className="d-flex flew-row justify-content-center">
      {canGoPrevious && <button className="btn btn-warning btn-sm" onClick={() => goPrevious()}>{"<- Mois Précédent"}</button>}
      {canGoNext && <button className="btn btn-warning btn-sm ml-1" onClick={() => goNext()}>{"Mois Suivant ->"}</button>}
    </div>
  </>
}