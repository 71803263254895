import gql from "graphql-tag";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";

export default gql`

  ${ContactFragment}
  
  fragment CustomerPhoneFragment on CustomerPhone {
    id
    phone
    countryCode
    sourceType
    sourceId
    Contact {
      ...ContactFragment
    }
  }
 
`