import { PaymentCaptureTypeEnum, User, UserRoleEnum } from "model"
import React from "react"
import { SelectInput } from "@zipou/front_tools";

type PaymentTypeInputProps = {
  user: User,
  id?: string
  value: PaymentCaptureTypeEnum | string,
  disabled?: boolean;
  errors: any,
  onChange: (v: string) => void,
}


export const PaymentTypeInput = ({ user, value, onChange, errors, disabled }: PaymentTypeInputProps) => {


  // const hasRoleToken = user?.roles?.includes(UserRoleEnum.ROLE_BILLING_TOKEN)
  const hasRolePreAuth = user?.roles?.includes(UserRoleEnum.ROLE_BILLING_PRE_AUTH)
  const hasRolePayment = user?.roles?.includes(UserRoleEnum.ROLE_BILLING_PAYMENT)
  const choiceList = [
    ...(hasRolePayment ? [{ label: "Paiement", id: PaymentCaptureTypeEnum.CAPTURE_TYPE_FULL }] : []),
    ...(hasRolePreAuth ? [{ label: "Pré Autorisation", id: PaymentCaptureTypeEnum.CAPTURE_TYPE_PRE_AUTH }] : []),
    // ...(hasRoleToken ? [{ label: "Empreinte Bancaire", id: PaymentCaptureTypeEnum.CAPTURE_TYPE_TOKEN }] : []),
  ]

  return <div>
    <SelectInput disabled={disabled} errors={errors} value={value} id='captureType' label="Type de Paiement" choiceList={choiceList} onChange={(d) => {
      onChange(d)
    }} />

  </div>


}