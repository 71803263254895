import { PlanningWeeklyInput } from "model"
import React from "react"

type PlanningWeeklyFormProps = {
  planningWeekly: PlanningWeeklyInput,
  errors: any,
  onChange: (planningWeekly: PlanningWeeklyInput) => void,
}

export const PlanningWeeklyForm = ({ planningWeekly, onChange }: PlanningWeeklyFormProps) => {

  const serial = new Date().getTime()

  return <>
    <div className="input-group">
      <span className="input-group-text">Planning Hebdo</span>
      <div className="form-control d-flex flex-row" style={{ height: "100%" }}>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`monday_${serial}`}>Lundi</label>
          <input id={`monday_${serial}`} type="checkbox" checked={planningWeekly?.monday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              monday: isChecked
            })
          }} />
        </div>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`tuesday${serial}`}>Mardi</label>
          <input id={`tuesday${serial}`} type="checkbox" checked={planningWeekly?.tuesday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              tuesday: isChecked
            })
          }} />
        </div>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`wednesday${serial}`}>Mercredi</label>
          <input id={`wednesday${serial}`} type="checkbox" checked={planningWeekly?.wednesday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              wednesday: isChecked
            })
          }} />
        </div>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`thursday${serial}`}>Jeudi</label>
          <input id={`thursday${serial}`} type="checkbox" checked={planningWeekly?.thursday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              thursday: isChecked
            })
          }} />
        </div>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`friday${serial}`}>Vendredi</label>
          <input id={`friday${serial}`} type="checkbox" checked={planningWeekly?.friday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              friday: isChecked
            })
          }} />
        </div>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`saturday${serial}`}>Samedi</label>
          <input id={`saturday${serial}`} type="checkbox" checked={planningWeekly?.saturday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              saturday: isChecked
            })
          }} />
        </div>
        <div className="p-1 d-flex flex-row justify-content-center align-items-center">
          <label htmlFor={`sunday${serial}`}>Dimanche</label>
          <input id={`sunday${serial}`} type="checkbox" checked={planningWeekly?.sunday} onChange={(e) => {
            const isChecked = e.target.checked
            onChange({
              ...planningWeekly,
              sunday: isChecked
            })
          }} />
        </div>

      </div>
    </div>
  </>


}