import React, { useContext, useState } from "react"
import { useHistory } from 'react-router-dom';
import { useQuery, useSubscription, useMutation } from "@apollo/react-hooks"

import paymentGetGql from "graphql/BILLING/Payment/query/paymentGet.gql"
import paymentUpdateGql from "graphql/BILLING/Payment/subscription/paymentUpdate.gql"
import paymentAddGql from 'graphql/BILLING/Payment/mutation/paymentAdd.gql';

import { PriceInput } from "components/common/Form/PriceInput"
import { ContactSearchField } from "components/front/PMS/Contact/Field/ContactSearchField"
import { HotelListSelect } from "components/front/PMS/Hotel/List/HotelListSelect"

import { URL_BACKOFFICE_PAYMENT_EDIT } from 'constant/url';

import "./style.scss"
import { PaymentStatus } from "../Status/PaymentStatus";
import { formatErrorResponseForJoi, isFieldOnError } from "helpers/Apollo";
import { ClickAndCopy } from "components/common/Form/ClickAndCopy";
import { PaymentCaptureStatus } from "../Capture/Status/PaymentCaptureStatus";
import { PaymentCaptureConfirm } from "../Capture/Confirm/PaymentCaptureConfirm";
import { userContext } from "context/User";
import { Payment, PaymentCaptureStatusEnum, PaymentCaptureTypeEnum, PaymentSendStatusEnum, PaymentStatusEnum, User, UserRoleEnum } from "model";
import { PaymentRefundConfirm } from "../Refund/Confirm/PaymentRefundConfirm";
import { TextInput } from "components/common/Form/TextInput";
import { getRootUrl } from "helpers/Utils";
import { PaymentTypeInput } from "../Type/Form/PaymentTypeInput";
import { PaymentSend } from "../Send/PaymentSend";
import { PaymentSendStatus } from "../Send/Status/PaymentSendStatus";
import { IconLoading, Modal } from "@zipou/front_tools";
import { PaymentReceiptDisplay } from "../Receipt/PaymentReceiptDisplay";
// import {PaymentCaptureStatus} from "../Capture/Status/CaptureStatus";

type PaymentEditProps = {
  paymentId?: string,
  onDone?: () => void,
}

export const PaymentEdit = ({ paymentId, onDone }: PaymentEditProps) => {

  const history = useHistory()

  const user = useContext<User>(userContext)

  const [error, updateError] = useState<any>([])
  const [payment, updatePayment] = useState<Partial<Payment>>({})
  const [loading, updateLoading] = useState<boolean>(false)
  const [displaySend, updateDisplaySend] = useState<boolean>(false)
  const [displayCapture, updateDisplayCapture] = useState<boolean>(false)
  const [displayRefund, updateDisplayRefund] = useState<boolean>(false)
  // const [contact, updateContact] = useState<any>({})

  useQuery<{ payment: Payment }>(paymentGetGql, {
    variables: {
      id: paymentId,
    },
    skip: !paymentId,
    onCompleted: (data) => {
      updatePayment(data?.payment)
    }
  })

  useSubscription<{ payment: Payment }>(paymentUpdateGql, {
    variables: {
      id: paymentId,
    },
    skip: !paymentId,
    onSubscriptionData: ({ subscriptionData }) => {
      // console.log("DATA", subscriptionData)
      updatePayment(subscriptionData?.data?.payment)
    }
  })

  const [mutate] = useMutation(paymentAddGql)

  const onSubmit = async (e: any) => {
    e.preventDefault();
    updateLoading(true);
    return mutate({
      variables: {
        input: {
          hotelId: payment?.hotelId,
          contactId: payment?.contactId,
          mobilePhoneNumber: payment?.mobilePhoneNumber,
          amount: Number(payment?.amount),
          email: payment?.email,
          captureType: payment.captureType,
          notes: payment?.notes,
        }
      }
    })
      .then((response: any) => {
        updateLoading(false)
        const paymentId = response?.data?.payment?.id
        onDone ? onDone() : history.push(URL_BACKOFFICE_PAYMENT_EDIT?.replace(":paymentId", paymentId))
      })
      .catch((e: any) => {
        updateError(formatErrorResponseForJoi(e))
        updateLoading(false)
      })
  }


  const isDisabled = !!paymentId
  const hasTemplate = payment?.Hotel?.BillingConfig?.templateId
  const isPreAuth = payment?.captureType === "CAPTURE_TYPE_PRE_AUTH"
  const isCapturable = isPreAuth && payment?.captureStatus === "CAPTURE_STATUS_NOT_CAPTURED" && payment.status === "STATUS_DONE"
  const hasRoleRefund = user?.roles?.includes(UserRoleEnum.ROLE_BILLING_REFUND)
  const isRefundable = hasRoleRefund && ((!isPreAuth && payment?.status === PaymentStatusEnum.STATUS_DONE) || (isPreAuth && payment.captureStatus === PaymentCaptureStatusEnum.CAPTURE_STATUS_CAPTURED && payment.status === PaymentStatusEnum.STATUS_DONE))
  const hasUrl = !!payment?.publicUrl
  const isSendable = (hasUrl && hasTemplate) && user?.roles?.includes(UserRoleEnum.ROLE_BILLING_EMAIL) &&
    payment.hasValidEmail &&
    payment.sendStatus !== PaymentSendStatusEnum.SEND_STATUS_SENT &&
    payment.sendStatus !== PaymentSendStatusEnum.SEND_STATUS_DELIVERED &&
    payment.sendStatus !== PaymentSendStatusEnum.SEND_STATUS_READ &&
    (payment.status === PaymentStatusEnum.STATUS_INITIAL || payment.status === PaymentStatusEnum.STATUS_PENDING_CUSTOMER)
  const isReSendable = (hasUrl && hasTemplate) && user?.roles?.includes(UserRoleEnum.ROLE_BILLING_EMAIL) && payment.hasValidEmail && payment.sendStatus === PaymentSendStatusEnum.SEND_STATUS_SENT
  const hasError = error?.length > 0

  const hasReceipt = !!payment?.receiptInfo

  const hasSendStatus = payment.sendStatus && payment.sendStatus !== PaymentSendStatusEnum.SEND_STATUS_IDLE

  const hasRolePMS = user?.roles?.includes(UserRoleEnum.ROLE_PMS)

  const publicUrl = `${getRootUrl().slice(0, -1)}${payment?.publicPath}`


  return <div className="payment-container">
    {hasError && <div className="alert alert-danger"><h1>Oups :(</h1>Il y'a une erreur...</div>}
    <Modal display={displayCapture} onClose={() => updateDisplayCapture(false)}>
      <PaymentCaptureConfirm payment={payment} onClose={() => updateDisplayCapture(false)} />
    </Modal>
    <Modal display={displaySend} onClose={() => updateDisplaySend(false)}>
      <PaymentSend paymentId={payment?.id} onDone={() => { updateDisplaySend(false) }} />
      {/* <PaymentCaptureConfirm payment={payment} onClose={() => updateDisplayCapture(false)} /> */}
    </Modal>
    <Modal display={displayRefund} onClose={() => updateDisplayRefund(false)}>
      <PaymentRefundConfirm payment={payment} onClose={() => updateDisplayRefund(false)} />
    </Modal>
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              Infos
            </div>
            <div className="card-body">
              {payment?.isError && <div className="alert alert-danger">
                Une erreur est survenue pour ce paiement: <b>{payment?.lastError}</b>
              </div>}
              <HotelListSelect
                hotelFilter={(hotel) => !!hotel?.BillingConfig?.id}
                isError={isFieldOnError(error, "hotelId")}
                disabled={isDisabled}
                label='Hotel'
                hotelId={payment?.hotelId}
                onChange={(hotelId: string) => {
                  updatePayment({
                    ...payment,
                    hotelId
                  })
                }}

              />
              {hasRolePMS && <ContactSearchField isError={isFieldOnError(error, "contactId")} disabled={isDisabled} label='Contact PMS' hotelId={payment?.hotelId} value={payment?.contactId || ""} onChange={(contactId: string, contact: any) => {
                updatePayment({
                  ...payment,
                  contactId,
                  email: contact?.email || "",
                  mobilePhoneNumber: (contact?.phoneNumberValid?.length > 0) ? contact?.phoneNumberValid[0] : "",
                })
                // updateContact(contact)
              }}
              />}

              <PaymentTypeInput disabled={isDisabled} errors={error} value={payment?.captureType || ""} id='captureType' user={user} onChange={(v: string) => {
                updatePayment({
                  ...payment,
                  captureType: v as PaymentCaptureTypeEnum,
                })
              }} />

              {/* <SelectInput disabled={isDisabled} errors={error} value={payment?.captureType} id='captureType' label="Type de Paiement" choiceList={[{ label: "Pré Autorisation", id: "CAPTURE_TYPE_PRE_AUTH" }, { label: "Paiement", id: "CAPTURE_TYPE_FULL" }]} onChange={(e: any) => {
                updatePayment({
                  ...payment,
                  captureType: e.target.value,
                })
              }} /> */}
              <div className="input-group">
                <span className="input-group-text">Infos</span>
                <div className="form-control" style={{ height: "100%" }}>
                  <TextInput id="mobilePhoneNumber" errors={error} value={payment?.mobilePhoneNumber} label="N° Mobile" disabled={isDisabled} onChange={(e, v) => {
                    updatePayment({
                      ...payment,
                      mobilePhoneNumber: v,
                    })
                  }} />
                  <TextInput id="email" errors={error} value={payment?.email} label="Email" disabled={isDisabled} onChange={(e, v) => {
                    updatePayment({
                      ...payment,
                      email: v,
                    })
                  }} />
                  <TextInput id="notes" errors={error} value={payment?.notes} label="Notes" disabled={isDisabled} onChange={(e, v) => {
                    updatePayment({
                      ...payment,
                      notes: v,
                    })
                  }} />
                </div>
              </div>
              <PriceInput isError={isFieldOnError(error, "amount")} disabled={isDisabled} label="Montant" value={payment?.amount} onChange={(value: number) => {
                updatePayment({
                  ...payment,
                  amount: value
                })
              }}
              />
              {hasUrl && <div className="input-group" style={{ height: "100%" }}>
                <span className="input-group-text">Lien de Paiement</span>
                <div className="form-control" style={{ height: "100%" }}>

                  <div className="input-group">
                    <span className="input-group-text">Lien Français</span>
                    <span className="form-control">{publicUrl}</span>
                    <ClickAndCopy value={publicUrl} />
                  </div>

                  <div className="input-group">
                    <span className="input-group-text">Lien Anglais</span>
                    <span className="form-control">{publicUrl}?lang=en</span>
                    <ClickAndCopy value={`${publicUrl}?lang=en`} />
                  </div>

                </div>
                {/* <span className="input-group-text" onClick={onClickCopy}>Copier</span> */}
              </div>}
              {hasUrl && <div className="input-group">
                <span className="input-group-text">Status Client</span>
                <span className="form-control">
                  <PaymentStatus status={payment?.status} />
                </span>
              </div>}
              {hasSendStatus && <div className="input-group">
                <span className="input-group-text">Status Envoi</span>
                <span className="form-control">
                  <PaymentSendStatus status={payment?.sendStatus as PaymentSendStatusEnum} />
                </span>
              </div>}
              {(isPreAuth && hasUrl) && <div className="input-group">
                <span className="input-group-text">Status Pre Auto</span>
                <span className="form-control">
                  <PaymentCaptureStatus captureStatus={payment?.captureStatus} amount={payment?.captureValue} />
                </span>
              </div>}

              {hasReceipt && <div className="input-group">
                <span className="input-group-text">Reçu</span>
                <span className="form-control" style={{ height: "100%" }}>
                  <PaymentReceiptDisplay publicId={payment?.publicId} salt={payment?.publicSalt} />
                </span>
              </div>}

            </div>
            <div className="card-footer">
              <button className="btn btn-sm btn-dark" type="submit" disabled={isDisabled || loading}>
                {loading && <IconLoading />} Valider
              </button>
              {isCapturable && <button className="btn btn-sm btn-warning" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplayCapture(true)}>
                {loading && <IconLoading />} Forcer le paiement
              </button>}
              {isRefundable && <button className="btn btn-sm btn-warning" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplayRefund(true)}>
                {loading && <IconLoading />} Rembourser le paiement
              </button>}
              {isSendable && <button className="btn btn-sm btn-primary" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplaySend(true)}>
                {loading && <IconLoading />} Envoyer par Mail
              </button>}
              {isReSendable && <button className="btn btn-sm btn-warning" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplaySend(true)}>
                {loading && <IconLoading />} Renvoyer par Mail
              </button>}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

}

