import { useQuery } from "@apollo/react-hooks"
import { AutoCompleteInput } from "components/common/Form/AutoCompleteInput"
import customerGet from "graphql/CRM/Customer/query/customerGet"
import customerSearchByTextGql from "graphql/CRM/Customer/query/customerSearchByText.gql"
import { Customer } from "model"
import React, { useState } from "react"

type CustomerSearchFieldProps = {
  label: string,
  // hotelId: string,
  value?: string,
  disabled?: boolean,
  placeholder?: string,
  isError?: boolean,
  isWarning?: boolean,
  onChange: (value: string, object: any) => void
}

export const CustomerSearchField = (props: CustomerSearchFieldProps) => {

  // const [customer, updateCustomer] = useState(null)
  const [value, updateValue] = useState(null)
  const { refetch } = useQuery<{ list: Customer[] }>(customerSearchByTextGql, {
    skip: true,
  })

  useQuery(customerGet, {
    variables: {
      id: props?.value,
    },
    skip: !props?.value,
    onCompleted: (response: any) => {
      updateValue(response?.customer)
    }
  })

  // //Detect that hotelId has changed
  // useEffect(() => {
  //   if (value && props?.hotelId) {
  //     onValueChange(null)
  //   }
  // }, [props?.hotelId])

  const onValueChange = (value: any) => {
    updateValue(value)
    props?.onChange(value?.id, value)
  }

  const onSearch = async (textValue: string) => {
    const response = await refetch({
      text: textValue,
      // hotelId: props?.hotelId,
    })
    return response?.data?.list || []
  }

  return <div>
    <AutoCompleteInput
      placeholder={"Chercher un Client"}
      disabled={props?.disabled}
      onSearch={onSearch}
      label={props?.label}
      onChange={onValueChange}
      value={value}
      isError={props?.isError}
      isWarning={props?.isWarning}
      displayValue={(value: Customer) => `${value?.firstName || ""} ${value?.lastName || ""} ${value?.mainEmail || ""}`}
      displayResult={(value: Customer) => `${value?.firstName || ""} ${value?.lastName || ""}  ${value?.mainEmail || ""}`}
      renderNoResult={() => <li className="list-group-item list-group-item-danger">Pas de resultats </li>}
    />
  </div>
}
