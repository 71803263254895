import React, { useEffect } from "react"
import { ReservationCTIDetails } from "components/front/PMS/Reservation/Widget/ReservationCTIDetails";
import { CustomerCTIWidget } from "../../CRM/Customer/Widget/CustomerCTIWidget";
import { PropositionCTIDetails } from "../../PMS/Proposition/Widget/PropositionCTIDetails";
import { PhoneAgentWrapper } from "@zipou/phone_front";
import { useQuery } from "@apollo/react-hooks";
import phoneAgentConfigGetGql from "graphql/PHONE/Agent/Config/query/phoneAgentConfigGet.gql";
import { PhoneAgentConfig } from "model";

import "./style.scss"


type CTIAgentProps = {
}

export const CTIAgent = (props: CTIAgentProps) => {

  const { data } = useQuery<{ phoneAgentConfig: PhoneAgentConfig }>(phoneAgentConfigGetGql)

  const phoneAgentConfig = data?.phoneAgentConfig

  const CTIAgent = phoneAgentConfig?.accountName
  const hasCTIAgent = !!CTIAgent

  useEffect(() => {
    console.log("CTIAgent is MOUNTED", CTIAgent)

  }, [CTIAgent])

  return <div className="cti-container">
    {hasCTIAgent && <div>
      <PhoneAgentWrapper
        targetDevice={CTIAgent}
        phoneConfigId={phoneAgentConfig?.phoneConfigId}
        token={phoneAgentConfig?.token}
        moduleConfig={{
          url: "https://www.box4b.fr",
          wsUrl: "wss://www.box4b.fr/subscriptions",
        }}
      >
        {(callerName: string, callerNumber: string, vars: any, isRinging: boolean) => {
          const { reservationId, propositionId } = vars || {}

          // console.log("callerName", callerName)
          // console.log("callerNumber", callerNumber)
          // console.log("vars", vars)
          // console.log("isRinging", isRinging)

          if (reservationId) {
            return <div>
              <NotificationHelper caller={callerNumber} isRinging={isRinging} />
              <ReservationCTIDetails reservationId={reservationId} isRinging={isRinging} />
            </div>
          }

          if (propositionId) {

            return <div>
              <NotificationHelper caller={callerNumber} isRinging={isRinging} />
              <PropositionCTIDetails propositionId={propositionId} isRinging={isRinging} />
            </div>
          }

          return <div>
            <NotificationHelper caller={callerNumber} isRinging={isRinging} />
            <CustomerCTIWidget phoneNumber={callerNumber} isRinging={isRinging} />
          </div>
        }}
      </PhoneAgentWrapper>
    </div>}
  </div>

}

export type NotificationHelperProps = {
  caller: string,
  isRinging: boolean,
}

export const NotificationHelper = ({ caller, isRinging }: NotificationHelperProps) => {

  useEffect(() => {
    if (window?.Notification) {
      if (Notification.permission !== 'granted') {
        if (Notification.permission !== 'denied') {
          Notification.requestPermission()
            .then(() => {
              console.log("Notification active")
            })
        } else {
          console.log(`Notification has been denied`)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (isRinging && window?.Notification) {
      const notif = new window.Notification("Appel", {
        body: `${caller || ""}`,
      })
      notif.onclick = () => {
        window.focus();
      }
    } else {
    }
    //eslint-disable-next-line
  }, [isRinging])


  return <div />
}