import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import hotelListQuery from "graphql/PMS/Hotel/query/hotelList"
// import {HotelEdit} from "../Edit/HotelEdit"
import { Link } from "react-router-dom"
import { URL_BACKOFFICE_HOTEL_EDIT } from "constant/url"
import { QueryWrapper } from "components/QueryWrapper"
import { HotelBadge } from "../Badge/HotelBadge"
import { Modal } from "@zipou/front_tools"
import { HotelAdd } from "../Add/HotelAdd"

export const HotelList = () => {

  const { data, refetch } = useQuery(hotelListQuery)
  const hotelList = data?.list

  const [focusAdd, updateFocusAdd] = useState(false)

  return <div>
    {focusAdd && <Modal display onClose={() => updateFocusAdd(false)}>
      <HotelAdd onDone={() => {
        refetch();
        updateFocusAdd(false);
      }} />
    </Modal>}
    {hotelList?.map((hotel: any) => <li key={`hotel_id_${hotel?.id}`} className="list-group-item">
      <Link to={URL_BACKOFFICE_HOTEL_EDIT.replace(":hotelId", hotel?.id)}>
        <HotelBadge hotel={hotel} />
      </Link>
    </li>)}
    <div className="mt-2">
      <button className="btn btn-dark btn-sm" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div>

}