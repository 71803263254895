import gql from "graphql-tag";
import PostStayGroupFragment from "../../Group/fragment/PostStayGroupFragment";
import PostStayGroupStatsFragment from "../../Group/fragment/PostStayGroupStatsFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${PostStayGroupStatsFragment}
  ${HotelDisplayFragment}

  fragment PostStayConfigStatsFragment on PostStayConfig {
    id
    hotelId
    Hotel {
      ...HotelDisplayFragment
    }
    postStayActive
    demoMode
    templateId
    nbDaysAfterDeparture
    PostStayGroup {
      ...PostStayGroupStatsFragment
    }
  }

`