import { IconTrash, SelectInput, TextInput } from "@zipou/front_tools"
import { JoiError } from "helpers/Utils"
import { PostStayGroupItemInput, PostStayGroupItemTypeEnum } from "model"
import React from "react"
import { PostStayQuestionForm } from "../../../Question/Form/PostStayQuestionForm"



type PostStayGroupItemFormProps = {
  postStayGroupItem: PostStayGroupItemInput,
  onChange: (postStayGroupItem: PostStayGroupItemInput) => void,
  errors: any,
}

export const PostStayGroupItemForm = ({ onChange, postStayGroupItem, errors }: PostStayGroupItemFormProps) => {

  const isQuestion = postStayGroupItem?.type === PostStayGroupItemTypeEnum.TYPE_QUESTION
  const isSeparator = postStayGroupItem?.type === PostStayGroupItemTypeEnum.TYPE_SEPARATOR

  const renderQuestion = () => {
    const question = postStayGroupItem?.Question
    return <div className='input-group'>
      <div className="form-control" style={{ height: "100%" }}>
        <PostStayQuestionForm errors={errors && errors?.PostStayGroupItem} postStayQuestion={question} onChange={(postStayQuestion) => {
          onChange({
            ...postStayGroupItem,
            Question: postStayQuestion
          })

        }} />
      </div>

    </div>
  }

  const renderSeparator = () => {
    return <div>
      <h3 className="mt-3">Séparateur</h3>

      <div className='input-group'>
        <div className="form-control" style={{ height: "100%" }}>
          <TextInput id="labelFr" value={postStayGroupItem?.Separator?.labelFr} label="Label FR" errors={{}} onChange={(v) => {
            onChange({
              ...postStayGroupItem,
              Separator: {
                ...postStayGroupItem.Separator,
                labelFr: v
              }
            })
          }} />
          <TextInput id="labelEn" value={postStayGroupItem?.Separator?.labelEn} label="Label EN" errors={{}} onChange={(v) => {
            onChange({
              ...postStayGroupItem,
              Separator: {
                ...postStayGroupItem.Separator,
                labelEn: v
              }
            })
          }} />
        </div>
      </div>
    </div>

  }

  return <div>
    {isQuestion && renderQuestion()}
    {isSeparator && renderSeparator()}
  </div>

}