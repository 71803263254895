import gql from "graphql-tag";
import FileEncryptedFragment from "../fragment/FileEncryptedFragment";

export default gql`

${FileEncryptedFragment}

mutation fileEncryptedAdd($input: FileEncryptedInput!) {
  fileEncrypted: fileEncryptedAdd(input: $input) {
    ...FileEncryptedFragment
  }
}
 
`