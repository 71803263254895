import gql from "graphql-tag";
import PostStayConfigStatsFragment from "../fragment/PostStayConfigStatsFragment";

export default gql`

  ${PostStayConfigStatsFragment}

  query postStayConfigGet($hotelId: String!) {
    postStayConfig: postStayConfigGet(hotelId: $hotelId) {
      ...PostStayConfigStatsFragment
    }
  }

`