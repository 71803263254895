import gql from "graphql-tag";
import TargetFragmentGql from "../fragment/TargetFragment.gql";

export default gql`

  ${TargetFragmentGql}

  subscription targetUpdate($id: String!, $clientId: String!) {
    target: targetUpdate(id: $id, clientId: $clientId) {
      ...TargetFragment
    }
  }

`