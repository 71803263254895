import gql from "graphql-tag";

export default gql`

fragment TemplateFragment on Template {
  id
  name
  type
  contentFrHTML
  contentFrJSON
  contentEnHTML
  contentEnJSON
}

`