import gql from "graphql-tag";
import ChargeFragmentGql from "../fragment/ChargeFragment.gql";

export default gql`

  ${ChargeFragmentGql}

  subscription chargeUpdate($id: String!) {
    charge: chargeUpdate(id: $id) {
      ...ChargeFragment
    }
  }
 
`