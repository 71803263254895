import gql from "graphql-tag";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";

export default gql`

  ${ContactListFragment}

fragment ReservationConfirmationFragment on Reservation {
  id
  pmsId
  arrivalDate
  ReservationContact {
    ...ContactListFragment
  }
  ReservationRoom {
    id
    Room {
      id
      RoomType {
        id
      }
    }
  }
}

`