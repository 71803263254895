import gql from "graphql-tag";
import TerminalFragmentGql from "../fragment/TerminalFragment.gql";

export default gql`

  ${TerminalFragmentGql}

  subscription terminalUpdate($id: String!) {
    terminal: terminalUpdate(id: $id) {
      ...TerminalFragment
    }
  }

`