import gql from "graphql-tag";


export default gql`

  fragment CustomerNameFragment on Customer {
    id
    mainEmail
    firstName
    lastName
    origin
  }

`