import React from "react"
import {cookieGet, cookieSave} from "helpers/Cookies"
import {useMutation, useQuery, useSubscription} from "@apollo/react-hooks"
import targetRegisterGql from "graphql/PHONE/CHAT/Target/mutation/targetRegister.gql"
import targetGetGql from "graphql/PHONE/CHAT/Target/query/targetGet.gql"
import {ChannelContainer} from "./Channel/Container/ChannelContainer"
import {ChatControl} from "./Control/ChatControl"
import targetUpdateGql from "graphql/PHONE/CHAT/Target/mutation/targetUpdate.gql"
import targetUpdateSubGql from "graphql/PHONE/CHAT/Target/subscription/targetUpdate.gql"
import targetPingGql from "graphql/PHONE/CHAT/Target/query/targetPing.gql"
// import {userContext} from "context/User"


export enum TargetTypeEnum {
  TARGET_CUSTOMER = "TARGET_CUSTOMER",
  TARGET_AGENT = "TARGET_AGENT",
}

type ChatProps = {
  targetType: TargetTypeEnum,
  hotelIds?: string[],
  extraVars?: any,
  onStart?: () => void,
  onInit?: (targetId: string) => void,
}


export const Chat = (props: ChatProps) => {

  const COOKIE_CHAT_KEY = "h8_chat_auth"
  const clientPayload = cookieGet(COOKIE_CHAT_KEY)

  const [targetRegisterMutate] = useMutation(targetRegisterGql)
  const [targetUpdateMutate] = useMutation(targetUpdateGql)

  const {data, refetch} = useQuery(targetGetGql, {
    variables: {
      id: clientPayload?.id || "",
      clientId: clientPayload?.clientId || "",
    },
    onCompleted: (response) => {

      const target = response?.target
      if (!target) {
        console.log("NO TARGET FOUND, creating one")
        targetRegisterMutate({
          variables: {
            input: {
              type: props?.targetType,
              hotelIds: props?.hotelIds,
            }
          }
        })
          .then((response) => {
            const {id, clientId} = response.data?.target;
            props?.onInit && props?.onInit(clientId)
            cookieSave(COOKIE_CHAT_KEY, JSON.stringify({id, clientId}))
            // updateClientAuth({id, clientId})
            refetch()
          })
      } else {
        props?.onInit && props?.onInit(target?.clientId)
      }
    }
  })

  const onTargetUpdate = (target: any) => {
    const {__typename, id, clientId, channelList, ...rest} = target
    targetUpdateMutate({
      variables: {
        id: target?.id,
        clientId: target?.clientId,
        input: {
          ...rest,
        }
      }
    })
    .then(() => console.log("OK"))
    .catch((e: any) => console.log("ERRoR", e.message))
  }


  const target = data?.target

  useSubscription(targetUpdateSubGql, {
    variables: {
      id: target?.id,
      clientId: target?.clientId,
    },
    skip: !(target?.id && target?.clientId)
  })


  useQuery(targetPingGql, {
    variables: {
      id: target?.id,
      clientId: target?.clientId,
    },
    pollInterval: 2000,
    skip: !(target?.id && target?.clientId),
  })

  const isAgent = props?.targetType === TargetTypeEnum.TARGET_AGENT

  return <div>
    {/* <button className="btn btn-dark" onClick={() => props?.onStart(target?.clientId)}>Cliquer ici</button> */}
    {isAgent && <ChatControl onTargetChange={onTargetUpdate} target={target} />}
    <div className="chat-container" style={{display: "flex", flexDirection: "row", alignItems: "flex-end", position: "fixed", left: 50, bottom: 70, zIndex: 2000}}>
      {target?.channelList?.map((channel: any, index: number) => {
        return <ChannelContainer channelId={channel?.id} target={target} key={`chat_${index}`} isAgent={isAgent} />
      })}
    </div>
  </div>

}

