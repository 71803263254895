import gql from "graphql-tag";

export default gql`
fragment PriceRateCancelConditionFragment on PriceRateCancelCondition {
  id
  label
  type
  functionText
  hotelId
  defaultValue
  conditionList {
    before
    value
  }
}
`