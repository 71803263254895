import { useState } from "react"
import { NewsForm } from "../Form/NewsForm"
import { NewsInput } from "model"
import React from "react"
import { useMutation } from "@apollo/react-hooks"
import newsAddGql from "graphql/APP/News/mutation/newsAdd.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"

type NewsAddProps = {
  onDone: () => void,
}

export const NewsAdd = ({ onDone }: NewsAddProps) => {

  const [news, updateNews] = useState<NewsInput>({})
  const [errors, updateErrors] = useState<any>(null)

  const [mutate] = useMutation(newsAddGql)

  const onReset = () => {
    onDone && onDone()
  }

  const onSave = () => {
    mutate({
      variables: {
        input: news,
      }
    })
      .then(() => onDone && onDone())
      .catch((e) => updateErrors(formatErrorResponseForJoi(e)))
  }

  return <div>

    {news && <NewsForm news={news} errors={errors} onChange={(n) => {
      updateNews(n)
    }} />}

    <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-danger btn-sm ml-1" onClick={() => onReset()}>Annuler</button>
    </div>
  </div>
}