import gql from "graphql-tag";
import CheckinFragmentGql from "../fragment/CheckinFragment.gql";

export default gql`

  ${CheckinFragmentGql}

  mutation checkinUpdate($id: String!, $input: CheckinInput!) {
    checkin: checkinUpdate(id: $id, input: $input) {
      ...CheckinFragment
    }
  }

`