import React, {useContext} from "react"

import {alertContext} from "context/Alert/alert";

import "./style.scss";

export const Alert = () => {

  const {message, type, isVisible} = useContext(alertContext)

  const className = `alert-item ${type} ${(isVisible) ? "" : "alert-hidden"}`
  const classNameBanner = isVisible ? "alert-banner" : "alert-banner hidden"

  return <div className={classNameBanner}>
    <div className={className}>
      <div className="content">
        {message}
      </div>
    </div>
  </div>

}