import gql from "graphql-tag";
import RoomFragmentGql from "graphql/PMS/Room/fragment/RoomFragment.gql";

export default gql`

  ${RoomFragmentGql}

  query propositionGetAvailibilities($input: PropositionAvailibilityInput!) {
    availibility: propositionGetAvailibilities(input: $input) {
      room {
        ...RoomFragment
      }
      availibilityList {
        roomNumber
        date
        isAvailable
      }
    }
  }

`
