import gql from "graphql-tag";
import ChannelTargetFragmentGql from "../fragment/ChannelTargetFragment.gql";

export default gql`

  ${ChannelTargetFragmentGql}

  mutation channelTargetLeave($id: String!, $clientId: String!, $channelId: String!) {
    channelTarget: channelTargetLeave(id: $id, clientId: $clientId, channelId: $channelId) {
      ...ChannelTargetFragment
    }
  }
`