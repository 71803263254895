import { useMutation } from "@apollo/react-hooks"
import confirmationAddEventGql from "graphql/PMS/Confirmation/mutation/confirmationAddEvent.gql"
import confirmationListEventGql from "graphql/PMS/Confirmation/query/confirmationListEvent.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import { Event, EventInput } from "model"
import React, { useState } from "react"
import { EventForm } from "../Form/EventForm"

type EventAddByConfirmationProps = {
  confirmationId: string,
  hotelId: string,
  serviceId: string,
  date?: Date,
  onDone?: (event: Event) => void,
}

export const EventAddByConfirmation = (props: EventAddByConfirmationProps) => {

  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(confirmationAddEventGql, {
    refetchQueries: [{
      query: confirmationListEventGql,
      variables: {
        id: props?.confirmationId,
      }
    }]
  })

  const [event, updateEvent] = useState<Partial<EventInput>>({
    utcDate: props?.date || null,
    serviceId: props?.serviceId,
    source: {
      nbPeople: 2,
    },
  })

  const onSave = () => {
    mutate({
      variables: {
        id: props?.confirmationId,
        event,
      }
    })
      .then((response) => {
        props?.onDone(response?.data?.event)
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }


  return <div>
    {/* <h1>Ajouter une réservation</h1> */}
    <EventForm hotelId={props?.hotelId} event={event} errors={errors} serviceId={props?.serviceId} onChange={event => {
      updateEvent(event)
    }} />
    <div className="mt-4">
      <button className="btn btn-sm btn-primary" onClick={() => onSave()}>Enregistrer</button>
    </div>
  </div>
}