import gql from "graphql-tag";
import CustomerPhoneFragmentGql from "../fragment/CustomerPhoneFragment.gql";

export default gql`

${CustomerPhoneFragmentGql}

query customerPhoneGet($id: String!) {
  customerPhone: customerPhoneGet(id: $id) {
    ...CustomerPhoneFragment
  }
}


`