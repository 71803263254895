import gql from "graphql-tag";
import ConfirmationFragment from "../fragment/ConfirmationFragment";

export default gql`

  ${ConfirmationFragment}

  mutation confirmationSendManualById($id: String!) {
    confirmationSendManualById(id: $id) {
      ...ConfirmationFragment
    }
  }
`