import React from "react"
import { TokenDisplay } from "components/front/BILLING/Token/Display/TokenDisplay"

export const TokenDisplayRoute = (props: any) => {
  const { match } = props
  const { params } = match
  const { id } = params

  return <div>
    <h1>Empreinte bancaire</h1>
    <TokenDisplay tokenId={id} />
  </div>
}