import {useQuery} from "@apollo/react-hooks"
import React, {useState} from "react"
import {ReservationFullDetails} from "../Details/ReservationFullDetails"
import {ReservationStatusEnum} from "model"
import reservationGetButton from "graphql/PMS/Reservation/query/reservationGetButton"
import { Modal } from "@zipou/front_tools"

type ReservationButtonProps = {
  reservationId: string,
  pmsId?: string,
  status?: ReservationStatusEnum | string,
}

export const ReservationButton = (props: ReservationButtonProps) => {

  const {data, loading} = useQuery(reservationGetButton, {
    variables: {
      id: props?.reservationId,
    },
    skip: !props?.reservationId,
  })

  const reservation = data?.reservation
  const [focus, updateFocus] = useState<boolean>(false)

  // const status = props?.status || reservation?.status
  // const buttonClass =  status ? (status === ReservationStatusEnum.CONFIRMED || status === ReservationStatusEnum.CONFIRMED) ? `btn-success` : `btn-danger` : `btn-dark`
  const buttonClass = `btn-dark`

  return <span>
    <Modal display={focus} onClose={() => updateFocus(false)}>
      <ReservationFullDetails reservationId={props?.reservationId} />
    </Modal>
    <button className={`btn btn-sm ${buttonClass}`} onClick={() => updateFocus(true)}>
      {loading && <span className="icon-loading"></span>} {reservation?.pmsId || props?.pmsId}
    </button>
  </span>

}