import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import EventFragmentGql from "../fragment/EventFragment.gql";

export default gql`

  ${EventFragmentGql}
  ${CursorFragment}

  query eventList($input: EventListInput!, $cursor: CursorInput!) {
    list: eventList(input: $input, cursor: $cursor){
      nodes {
        ...EventFragment
      }
      cursor {
        ...CursorFragment
      }
      input
    }
  }
`