import gql from "graphql-tag";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";


export default gql`

${OptionFragmentGql}

    fragment ReservationPricePackageFragment on ReservationPricePackage {
        id
        ammountExcVat
        ammountIncVat
        quantity
        Option {
            ...OptionFragment
        }

    }

`