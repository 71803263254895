import gql from "graphql-tag";

export default gql`

  query contactCountryList {
    list: contactCountryList {
      name
      codeShort
      codeLong
      phonePrefix
    }
  }

`