import gql from "graphql-tag";

export default gql`

  fragment PhoneConfigFragment on PhoneConfig {
    id
    phoneConfigId
    token
    clic2CallSequenceId
  }

`