import React from "react"

type DateTimeInputProps = {
  value: any,
  label: string,
  isError?: boolean,
  isWarning?: boolean,
  onChange: (value: any) => void
}

export const DateTimeInput = (props: DateTimeInputProps) => {

  const value = props?.value?.replace(/:00\.000Z/g, "");

  return <div className="input-group">
    <span className="input-group-text">{props?.label}</span>
    <input type="datetime-local" className="form-control" placeholder={props?.label} value={value || ""} onChange={(e: any) => props?.onChange(`${e.target.value}:00.000Z`)} />
    {props?.isError && <span className="input-group-text"><span className="icon-warning-sign" /></span>}
    {props?.isWarning && <span className="input-group-text"><span className="icon-warning" /></span>}
  </div>
}