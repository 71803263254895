import { TextInput } from "@zipou/front_tools"
import { DeviceInput } from "model"
import React from "react"

type DeviceFormProps = {
  device: DeviceInput,
  errors?: any,
  onChange: (input: DeviceInput) => void,
}

export const DeviceForm = ({ device, onChange, errors }: DeviceFormProps) => {

  return <div>
    <TextInput label="Nom" id='name' errors={errors} value={device?.name} onChange={(v) => {
      onChange({
        ...device,
        name: v
      })
    }} />
  </div>

}