import { PreStayArrivalTransfertInput } from "model";
import React from "react"
import { useTranslation } from "react-i18next";


type TransfertFormProps = {
  themeColor: string,
  transfertData: PreStayArrivalTransfertInput,
  onChange: (transfertData: PreStayArrivalTransfertInput) => void,
}

export const TransfertForm = (props: TransfertFormProps) => {

  const { t, } = useTranslation('translation', { keyPrefix: 'prestay' });

  const transfertData = props?.transfertData
  const themeColor = props?.themeColor

  const onChange = (transfertData: PreStayArrivalTransfertInput) => {
    props?.onChange(transfertData)
  }

  const isPlane = transfertData?.isPlane
  const isTrain = transfertData?.isTrain


  return <div>
    <h1>{t("transfert_title_modal")}</h1>
    {/* <h2></h2> */}
    <div className="p-3">
      <div className="row pt-3 d-flex justify-content-start align-items-center">
        <div className="d-flex flex-column col-12 col-md-8 justify-content-start">
          <span className="text-left pb-1">{t("arrival.transfertData_isTrain")}</span>
        </div>
        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Non
        </div>
        <div className="d-flex flex-column col-3 col-md-2">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="arrival.transfertData.isTrain" checked={transfertData?.isTrain === true ? true : false} onChange={(e) => {
              onChange({
                ...transfertData,
                isTrain: e.target.checked ? true : false
              })
            }} />
            <label className="custom-control-label" style={{ "--theme-color": themeColor } as React.CSSProperties} htmlFor="arrival.transfertData.isTrain"></label>
          </div>
        </div>
        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Oui
        </div>
      </div>
      {isTrain && <div className="row m-3 pt-2 pb-4" style={{border: `${themeColor} 1px solid`}}>
        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_trainNumber")}</span>
          <input type="text" className="form-control" value={transfertData.trainNumber || ""} placeholder={t("arrival.transfertData_trainNumber")} onChange={(e) => {
            onChange({
              ...transfertData,
              trainNumber: e.target.value,
            })
          }} />
        </div>
        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_trainStation")}</span>
          <input type="text" className="form-control" value={transfertData.trainStation || ""} placeholder={t("arrival.transfertData_trainStation")} onChange={(e) => {
            onChange({
              ...transfertData,
              trainStation: e.target.value,
            })
          }} />
        </div>
        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_arrivalDate")}</span>
          <input type="text" className="form-control" value={transfertData.arrivalDate || ""} placeholder={t("arrival.transfertData_arrivalDate")} onChange={(e) => {
            onChange({
              ...transfertData,
              arrivalDate: e.target.value,
            })
          }} />
        </div>

      </div>}

      <div className="row pt-3 d-flex justify-content-start align-items-center">
        <div className="d-flex flex-column col-12 col-md-8 justify-content-start">
          <span className="text-left pb-1">{t("arrival.transfertData_isPlane")}</span>
        </div>
        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Non
        </div>
        <div className="d-flex flex-column col-3 col-md-2">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="arrival.transfertData_isPlane" checked={transfertData?.isPlane === true ? true : false} onChange={(e) => {
              onChange({
                ...transfertData,
                isPlane: e.target.checked ? true : false
              })
            }} />
            <label className="custom-control-label" style={{ "--theme-color": themeColor } as React.CSSProperties} htmlFor="arrival.transfertData_isPlane"></label>
          </div>
        </div>
        <div className="d-flex flex-column col-2 col-md-1 pt-2 text-uppercase ">
          Oui
        </div>
      </div>


      {(isPlane) && <div className="row m-3 pt-2 pb-4" style={{border: `${themeColor} 1px solid`}}>
        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_airport")}</span>
          <input type="text" className="form-control" value={transfertData.airport || ""} placeholder={t("arrival.transfertData_airport")} onChange={(e) => {
            onChange({
              ...transfertData,
              airport: e.target.value,
            })
          }} />
        </div>

        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_flightNumber")}</span>
          <input type="text" className="form-control" value={transfertData.flightNumber || ""} placeholder={t("arrival.transfertData_flightNumber")} onChange={(e) => {
            onChange({
              ...transfertData,
              flightNumber: e.target.value,
            })
          }} />
        </div>

        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_terminal")}</span>
          <input type="text" className="form-control" value={transfertData.terminal || ""} placeholder={t("arrival.transfertData_airport")} onChange={(e) => {
            onChange({
              ...transfertData,
              terminal: e.target.value,
            })
          }} />
        </div>


        <div className="d-flex flex-column col-12 col-md-6 pt-3">
          <span className="text-left pl-2 pb-1 text-uppercase">{t("arrival.transfertData_arrivalDate")}</span>
          <input type="text" className="form-control" value={transfertData.arrivalDate || ""} placeholder={t("arrival.transfertData_arrivalDate")} onChange={(e) => {
            onChange({
              ...transfertData,
              arrivalDate: e.target.value,
            })
          }} />
        </div>

      </div>}




    </div>

  </div>

}