import React from "react"
import { PostStayStatusEnum } from "model"

export type PostStayStatusProps = {
  status: PostStayStatusEnum
}

export const PostStayStatus = ({ status }: PostStayStatusProps) => {


  switch (status) {
    case PostStayStatusEnum.POST_STAY_STATUS_INITIAL: {
      return <span className="badge badge-sm badge-primary">INITIAL</span>
    }

    // case PostStayStatusEnum.POST_STAY_STATUS_EXCLUDED: {
    //   return <span className="badge badge-sm badge-secondary">EXCLUS</span>
    // }

    case PostStayStatusEnum.POST_STAY_STATUS_ERROR: {
      return <span className="badge badge-sm badge-danger">ERREUR</span>
    }

    case PostStayStatusEnum.POST_STAY_STATUS_DELETED: {
      return <span className="badge badge-sm badge-danger">SUPPRIME</span>
    }

    case PostStayStatusEnum.POST_STAY_STATUS_READ: {
      return <span className="badge badge-sm badge-success">LU</span>
    }

    case PostStayStatusEnum.POST_STAY_STATUS_DELIVERED: {
      return <span className="badge badge-sm badge-success">DELIVRE</span>
    }

    case PostStayStatusEnum.POST_STAY_STATUS_SENT: {
      return <span className="badge badge-sm badge-primary">ENVOYE</span>
    }

    case PostStayStatusEnum.POST_STAY_STATUS_TO_SEND: {
      return <span className="badge badge-sm badge-dark">A ENVOYER</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{status}</span>
    }

  }


}