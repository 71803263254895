import gql from "graphql-tag";

export default gql`
  fragment OptionFragment on Option {
    id
    labelFr
    labelEn
    descFr
    descEn
    pmsId
    amountIncVat
    amountExcVat
    isBreakfast
    isRestaurant
    isRestaurant2
    isSpa
    canBeDisplayedInConfirmation
    canBeSelectedInProposition
    canBeSelectedInPreStay
    isPerNight
    isPerChildren
    isPerAdult
    isPerSpecial
    seasonalityExclude
    fileId
    File {
      id
      url
    }
  }

`