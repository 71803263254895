import gql from "graphql-tag"
import LoginTokenFragment from "../fragment/LoginTokenFragment"

export default gql`

  ${LoginTokenFragment}

  mutation userLoginByToken($id: ID!, $token: String!){
    userLoginByToken(input: {
      id: $id
      token: $token
    }) {
      ...LoginTokenFragment
    }
  }

`