import { TextInput } from "@zipou/front_tools"
import { VideoConfigInput } from "model"
import React from "react"

type VideoConfigFormProps = {
  videoConfig: VideoConfigInput,
  errors: any,
  onChange: (videoConfig: VideoConfigInput) => void,
}

export const VideoConfigForm = ({ videoConfig, errors, onChange }: VideoConfigFormProps) => {


  return <div>
    <TextInput id="token" value={videoConfig?.token || ""} errors={errors} label="Token" onChange={(v) => {
      onChange({
        ...videoConfig,
        token: v,
      })
    }} />

    <TextInput id="videoConfigId" value={videoConfig?.videoConfigId || ""} errors={errors} label="Identifiant" onChange={(v) => {
      onChange({
        ...videoConfig,
        videoConfigId: v,
      })
    }} />

  </div>
}