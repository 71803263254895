import React from "react"

type SaveButtonProps = {
  loading: boolean,
  label: string,
  className?: string,
  onClick: (e: any) => void,
}

export const SaveButton = (props: SaveButtonProps) => {

  const {loading, label = "Enregister", onClick, className = ""} = props;

  return <button disabled={loading} onClick={onClick} className={className}>
    {loading && <span className="icon-spinner"> </span>}
    {label}
  </button>
}