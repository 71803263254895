import { useQuery } from "@apollo/react-hooks"
import { BooleanInput } from "components/common/Form/BooleanInput"
import { TextInput } from "components/common/Form/TextInput"
import { isFieldOnError } from "helpers/Apollo"
import { PropositionRowInput, PropositionRowPaymentConditionEnum, PropositionRowTotal } from "model"
import React, { useState } from "react"
import { ItemPriceSelect } from "../../Item/ItemPriceSelect"
import { DiscountInput } from "../Discount/DiscountInput"
import { PropositionRowOptionSelect } from "../Option/PropositionRowOptionSelect"
import propositionComputeTotalGql from "graphql/PMS/Proposition/Row/fragment/query/propositionComputeTotal.gql"

export type PropositionRowFormProps = {
  row: PropositionRowInput,
  isSelected: boolean,
  nbChildren: number,
  nbAdults: number,
  nbSpecial: number,
  errors: any,
  warnings: any,
  hotelId: string,
  onChange: (data: PropositionRowInput) => void,
}


export const PropositionRowForm = (props: PropositionRowFormProps) => {

  const [targetPriceRateTypeIdMap, updateTargetPriceRateTypeIdMap] = useState<any>({})
  const [targetPriceRateTypeId, updateTargetPriceRateTypeId] = useState<string>(null)

  const { data } = useQuery<{ propositionRowTotal: PropositionRowTotal }>(propositionComputeTotalGql, {
    variables: {
      input: props?.row,
    },
    skip: !props?.row,
  })


  const propositionRowTotal = data?.propositionRowTotal
  const row = props?.row
  const roomList = row?.roomList
  const paymentCondition = row?.paymentCondition
  const errors = props?.errors
  const warnings = props?.warnings

  const onChange = (row: PropositionRowInput) => {
    props?.onChange(row)
  }

  const hasExtraDiscount = propositionRowTotal?.totalDiscountExtra > 0
  const hasHostingDiscount = propositionRowTotal?.totalDiscountHosting > 0

  const isSelected = props?.isSelected

  const countPreviousItems = (itemGroupIndex: number) => {
    let startIndex = 0;
    for (let i = 0; i < itemGroupIndex; i++) {
      startIndex = startIndex + roomList[i]?.itemList?.length
    }
    return startIndex
  }


  return <div>

    {isSelected && <div className="alert alert-success">Proposition sélectionnée par le client</div>}

    <div className="">
      {roomList.map((rowRoom, itemGroupIndex: number) => {

        const hasDelogement = rowRoom?.itemList.reduce((acc, item, index) => {
          if (index === 0) return acc;
          const previousItem = rowRoom?.itemList[index - 1]
          return acc ? acc : previousItem.roomId !== item?.roomId
        }, false)


        const { itemList, optionList } = rowRoom

        const nbNights = itemList?.length

        const canDelete = roomList?.length > 1

        const startIndex = countPreviousItems(itemGroupIndex)

        return <div key={`chambre_${itemGroupIndex}`} className="input-group">
          <span className="input-group-text bg-dark text-white">Chambre {itemGroupIndex + 1}</span>
          <div className="form-control" style={{ height: "100%" }}>

            <div className="input-group">
              <span className="input-group-text ">Tarifs</span>
              <div className="form-control" style={{ height: "100%" }}>

                {hasDelogement && <div className="alert alert-warning">Cette sélection inclut un délogement</div>}

                {itemList?.map((item, itemIndex: number) => {

                  const targetPriceRateTypeIdIndex = (itemIndex + 1) + (startIndex)

                  return <div key={`list_${itemGroupIndex}_${itemIndex}`} className="">
                    <ItemPriceSelect
                      hotelId={props?.hotelId}
                      nbAdults={props?.nbAdults}
                      nbChildren={props?.nbChildren}
                      nbSpecial={props?.nbSpecial}
                      date={new Date(item?.dateStamp)}
                      item={item}
                      targetTypeId={targetPriceRateTypeIdMap && targetPriceRateTypeIdMap[targetPriceRateTypeIdIndex]}
                      isError={isFieldOnError(errors?.roomList && errors?.roomList[itemGroupIndex] && errors?.roomList[itemGroupIndex].itemList && errors?.roomList[itemGroupIndex].itemList[itemIndex], "rateId")}
                      isWarning={isFieldOnError(warnings?.roomList && warnings?.roomList[itemGroupIndex] && warnings?.roomList[itemGroupIndex].itemList && warnings?.roomList[itemGroupIndex].itemList[itemIndex], "rateId")}
                      onChange={(value: string, priceRate) => {
                        // console.log(`changing for ${new Date(item?.dateStamp)} with value ${value}, priceRate `, priceRate)
                        if (targetPriceRateTypeIdMap && !targetPriceRateTypeIdMap[targetPriceRateTypeIdIndex + 1]) {
                          updateTargetPriceRateTypeIdMap({
                            ...targetPriceRateTypeIdMap,
                            [targetPriceRateTypeIdIndex + 1]: priceRate?.PriceRateType?.id
                          })
                        }
                        onChange({
                          ...row,
                          roomList: roomList.map((rowRoom, roomListIndex: number) => {
                            return (itemGroupIndex === roomListIndex) ? {
                              ...rowRoom,
                              itemList: rowRoom?.itemList?.map((item, iIndex: number) => {
                                return (itemIndex === iIndex) ? {
                                  ...item,
                                  rateId: value,
                                } : {
                                  ...item,
                                }
                              })
                            } : rowRoom
                          })
                        })
                      }}
                      canDelete={itemList?.length > 1}
                      onDelete={() => {
                        onChange({
                          ...row,
                          roomList: roomList.map((rowRoom, roomListIndex: number) => {
                            return (itemGroupIndex === roomListIndex) ? {
                              ...rowRoom,
                              itemList: rowRoom?.itemList?.filter((item, iIndex: number) => iIndex !== itemIndex)
                            } : rowRoom
                          })
                        })
                      }}
                    />

                  </div>
                })}


              </div>
            </div>

            <DiscountInput discount={rowRoom?.discount} label={`Réduction globale chambre`} onChange={(discount) => {
              onChange({
                ...row,
                roomList: roomList.map((itemGroup, roomListIndex: number) => {
                  return (itemGroupIndex === roomListIndex) ? {
                    ...itemGroup,
                    discount,
                  } : itemGroup
                })
              })
            }} />

            <BooleanInput id="isUpgrade" warning={warnings} label={"Afficher comme surclassement"} errors={errors} value={rowRoom?.isUpgrade} onChange={value => {
              onChange({
                ...row,
                roomList: roomList.map((itemGroup, roomListIndex: number) => {
                  return (itemGroupIndex === roomListIndex) ? {
                    ...itemGroup,
                    isUpgrade: value,
                  } : itemGroup
                })
              })
            }} />

            <PropositionRowOptionSelect
              optionSelected={optionList}
              hotelId={props?.hotelId}
              nbChildren={props?.nbChildren}
              nbAdults={props?.nbAdults}
              nbSpecial={props?.nbSpecial}
              nbNights={nbNights}
              onChange={(optionSelected) => {
                onChange({
                  ...row,
                  roomList: roomList.map((itemGroup, roomListIndex: number) => {
                    return (itemGroupIndex === roomListIndex) ? {
                      ...itemGroup,
                      optionList: optionSelected,
                    } : itemGroup
                  })
                })
              }}
            />
          </div>
          {canDelete && <span className="input-group-text bg-dark text-white">
            <button className="btn btn-sm btn-dark" onClick={() => onChange({
              ...row,
              roomList: roomList.filter((_, roomListIndex: number) => {
                return roomListIndex !== itemGroupIndex
              })
            })
            }>
              <span className="icon-trash"></span>
            </button>
          </span>}
        </div>
      })}

    </div>

    <div className="input-group">
      <span className="input-group-text bg-dark text-white">Tarif</span>
      <div className="form-control" style={{ height: "100%" }}>

        <div className="input-group">
          <span className="input-group-text">Condition de Paiement</span>
          <select className="form-control" value={paymentCondition || ""} onChange={(e) => {
            onChange({
              ...row,
              paymentCondition: e.target.value as PropositionRowPaymentConditionEnum,
            })
          }}>
            <option value="" disabled>Choisissez une valeur</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_0}>Aucun Paiement</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_10}>10% du montant total</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_15}>15% du montant total</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_20}>20% du montant total</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_30}>30% du montant total</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_50}>50% du montant total</option>
            <option value={PropositionRowPaymentConditionEnum.CONDITION_100}>Totalité du montant</option>
          </select>
          {isFieldOnError(errors, "paymentCondition") && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
          {isFieldOnError(warnings, "paymentCondition") && <span className="input-group-text"><span className="icon-warning"></span></span>}
        </div>

        <div className="input-group">
          <span className="input-group-text">Total</span>
          <div className="form-control" style={{ height: "100%" }}>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Hébergement</th>
                  <td>{hasHostingDiscount ? <span><span style={{ textDecoration: "line-through" }}>{propositionRowTotal?.totalHostingWithoutDiscount} €</span> - {propositionRowTotal?.totalHosting} €</span> : <span>{propositionRowTotal?.totalHosting} €</span>}</td>
                </tr>
                <tr>
                  <th>Extra </th>
                  <td>{hasExtraDiscount ? <span><span style={{ textDecoration: "line-through" }}>{propositionRowTotal?.totalExtraWithoutDiscount} €</span> - {propositionRowTotal?.totalExtra} €</span> : <span>{propositionRowTotal?.totalExtra} €</span>}</td>
                </tr>
                <tr>
                  <th>Général </th>
                  <td>{(propositionRowTotal?.totalExtra + propositionRowTotal?.totalHosting) || 0} €</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text bg-dark text-white">Paramètres</span>
      <div className="form-control" style={{ height: "100%" }}>

        <TextInput label={"Commentaire"} value={row?.comment} id="comment" onChange={(_, v) => {
          onChange({
            ...row,
            comment: v,
          })
        }} rightLabel={<span className="badge badge-warning">Visible par le client</span>} />
      </div>
    </div>

  </div>

}