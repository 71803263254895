import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import ReservationGroupListFragment from "../fragment/ReservationGroupListFragment";

export default gql`

${ReservationGroupListFragment}
${CursorFragment}

  query reservationGroupList($input: ReservationGroupListInput!, $cursor: CursorInput!) {
    list: reservationGroupList(input: $input, cursor: $cursor) {
      nodes {
        ...ReservationGroupListFragment
      }
      cursor {
        ...CursorFragment
      }
      input
    }
  }

`