import gql from "graphql-tag";

export default gql`

fragment StripeConfigFragment on StripeConfig {
  publishableKey
  secretKey
  webHookKey
}

`