import { PropositionDisplay } from "components/front/PMS/Proposition/Display/PropositionDisplay"
import React from "react";

export const PropositionDisplayRoute = (props: any) => {

  const { match } = props;
  const { params } = match || {}
  const id = params?.id

  return <div>
    {id && <PropositionDisplay id={id} />}
  </div>
}