import {PriceFormat} from "components/common/Format/PriceFormat"
import React from "react"

type PaymentCaptureStatusProps = {
  captureStatus: string,
  amount: number,
}

export const PaymentCaptureStatus = (props: PaymentCaptureStatusProps) => {
  switch (props?.captureStatus) {

    case "CAPTURE_STATUS_CAPTURED": {
      return <span>
        <span className="badge badge-info">Prélevé</span>
        {props?.amount && <span className="badge badge-dark" style={{marginLeft: 10}}>
          <PriceFormat value={props?.amount} />
        </span>}
      </span>
    }

    case "CAPTURE_STATUS_NOT_CAPTURED": {
      return <span className="badge badge-warning">Non Prélevé</span>
    }

    default: {
      return <span className="badge badge-info">{props?.captureStatus}</span>
    }
  }
}