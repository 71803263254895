import { useMutation, useQuery } from "@apollo/react-hooks"
import templateUpdate from "graphql/APP/Template/mutation/templateUpdate"
import templateGet from "graphql/APP/Template/query/templateGet"
import templateList from "graphql/APP/Template/query/templateList"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import React, { useState } from "react"
import { TemplateForm } from "../Form/TemplateForm"
import { Template, TemplateInput } from "model"

type TemplateEditProps = {
  templateId: string,
  hotelId: string,
  onDone?: () => void,
}

export const TemplateEdit = ({ templateId, hotelId, onDone }: TemplateEditProps) => {

  const [template, updateTemplate] = useState<TemplateInput>(null)
  const [errors, updateErrors] = useState<any>(null)
  // const [message, updateMessage] = useState<any>(null)

  useQuery<{ template: Template }>(templateGet, {
    skip: !templateId,
    variables: {
      id: templateId,
    },
    onCompleted: (data: any) => {
      updateTemplate({
        name: data?.template?.name,
        type: data?.template?.type,
        contentFrHTML: data?.template?.contentFrHTML,
        contentFrJSON: data?.template?.contentFrJSON,
        contentEnHTML: data?.template?.contentEnHTML,
        contentEnJSON: data?.template?.contentEnJSON,
      })
    }
  })

  const [templateUpdateMutation] = useMutation(templateUpdate)

  const onSave = () => {
    templateUpdateMutation({
      variables: {
        id: templateId,
        hotelId,
        input: template,
      },
      refetchQueries: [{
        query: templateList,
      }]
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div>
    {/* {message && <div className="alert alert-success">
      OK :)
    </div>} */}

    {errors && <div className="alert alert-danger">
      Erreur dans le template :(
    </div>}

    <div className="card">
      <div className="card-body">
        {template && <TemplateForm
          errors={errors}
          template={template}
          onChange={(data: any) => { console.log("UPDATED", data); updateTemplate(data) }}
        />}
      </div>
      <div className="card-footer">
        <button className="btn btn-sm btn-dark" onClick={() => onSave()}>Enregistrer</button>
      </div>
    </div>
  </div>
}