import React, { useState } from "react"
import { ConfirmationDataForm } from "../Form/ConfirmationDataForm"
import { useMutation, useQuery } from "@apollo/react-hooks"
import confirmationGet from "graphql/PMS/Confirmation/query/confirmationGet"
import { Confirmation, ConfirmationDataInput } from "model"
import confirmationSetDataGql from "graphql/PMS/Confirmation/mutation/confirmationSetData.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"

type ConfirmationDataEditProps = {
  confirmationId: string,
  onDone: () => void,
}

export const ConfirmationDataEdit = (props: ConfirmationDataEditProps) => {

  const [confirmationData, updateConfirmationData] = useState<ConfirmationDataInput>()
  const [errors, updateErrors] = useState<any>()

  const [mutate, { loading }] = useMutation(confirmationSetDataGql)

  const { data } = useQuery<{ confirmation: Confirmation }>(confirmationGet, {
    variables: {
      id: props?.confirmationId,
    },
    skip: !props?.confirmationId,
    onCompleted: (response) => {
      const { __typename, ...confirmationData } = response?.confirmation?.confirmationData || {}
      updateConfirmationData(confirmationData)
    }
  })

  const onSave = () => {
    mutate({
      variables: {
        id: props?.confirmationId,
        input: confirmationData,
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const confirmation = data?.confirmation
  const confirmationConfig = confirmation?.ConfirmationConfig

  return <>
    {/* <h1>Données de la confirmation</h1> */}
    <ConfirmationDataForm
      confirmation={confirmation}
      errors={errors}
      confirmationDataTypeActive={confirmationConfig?.dataTypeActive || []}
      confirmationData={confirmationData} onChange={(input) => {
        updateConfirmationData(input)
      }} />
    <div>
      <button disabled={loading} className="btn btn-sm btn-dark" onClick={() => onSave()}>
        {loading && <span className="icon-loading mr-2"></span>}
        Enregistrer
      </button>
    </div>
  </>
}