import gql from "graphql-tag";

export default gql`
  fragment CustomerSearchFragment on Customer {
    id
    #phoneNumbers
    mainEmail
    firstName
    lastName
  }

`