import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Clic2CallModule } from "@zipou/phone_front"

import "./style.scss"
import { useQuery } from "@apollo/react-hooks"
import phoneConfigGetByHotelIdGql from "graphql/PHONE/Config/query/phoneConfigGetByHotelId.gql"
import { PhoneConfig } from "model"

type ContactProps = {
  hotelId: string,
  phoneNumber?: string,
  countryCode?: string,
  extraVars?: any,
}

export const Contact = (props: ContactProps) => {

  const { t } = useTranslation('translation', { keyPrefix: 'phone' });
  const [isOpen, updateIsOpen] = useState<boolean>(false)

  const [countryCode, updateContryCode] = useState<string>(props?.countryCode || "FR")
  const [number, updateNumber] = useState<string>(props?.phoneNumber || "")

  const [loading, updateLoading] = useState<boolean>(false)
  const [error, updateError] = useState<string>("")
  const [message, updateMessage] = useState<string>("")

  const { data } = useQuery<{ phoneConfig: PhoneConfig }>(phoneConfigGetByHotelIdGql, {
    variables: {
      hotelId: props?.hotelId,
    }
  })
  const phoneConfig = data?.phoneConfig

  useEffect(() => {
    if (number !== props?.phoneNumber && props?.countryCode !== countryCode) {
      updateContryCode(props?.countryCode)
      updateNumber(props?.phoneNumber)
    }
    //eslint-disable-next-line
  }, [props?.countryCode, props?.phoneNumber])

  const onChange = (e: any) => {
    updateNumber(e.target.value)
    updateError("")
  }

  const numberFormated = number && number

  return <Clic2CallModule
    phoneConfigId={phoneConfig?.phoneConfigId}
    token={phoneConfig?.token}
    sequenceId={phoneConfig?.clic2CallSequenceId}
    moduleConfig={{
      url: "https://www.box4b.fr",
      wsUrl: "wss://www.box4b.fr/subscriptions",
    }}>
    {(call, countryCodeList) => {

      const onSubmit = (e: any) => {
        if (loading) return;

        e.preventDefault();
        updateLoading(true);
        call(number, countryCode)
          .then((result) => {
            if (result) {
              updateError("")
              updateMessage(t("Appel en cours"))
              setTimeout(() => {
                updateLoading(false)
                updateMessage("")
                updateIsOpen(false)
              }, 5000)
            } else {
              updateLoading(false)
              updateError(`Erreur de format`)
            }
          })
          .catch(() => {
            updateError(`Erreur.`)
            updateLoading(false)
          })
      }

      return <div className="clic2call-container">
        {isOpen && <div className="clic2call-position">
          <span className="clic2call-close">
            <button onClick={() => {
              updateIsOpen(!isOpen)
            }}><span className="icon-close" /></button>
          </span>
          <div className="clic2call-content">
            <h2 style={{ fontFamily: "RidleyGrotesk" }}>{t("Une question ?")}</h2>
            <form onSubmit={onSubmit}>
              <h4>{t("etablissement vous rappelle immédiatement")}</h4>
              {message && <div className="alert alert-success">{message}</div>}
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="input-group">
                <select className="form-control" value={countryCode} onChange={(e: any) => updateContryCode(e?.target.value)}>
                  {countryCodeList.map((countryCode) => {
                    return <option key={countryCode?.code} value={countryCode?.code}>{countryCode?.name}</option>
                  })}
                </select>
              </div>
              <div className="input-group">
                <input className="form-control" value={numberFormated} type="text" placeholder={t("Votre numéro")} onChange={onChange} />
              </div>
              <div className="input-group">
                <button disabled={loading} className="btn btn-dark" type="submit">{t("Cliquez ici pour lancer la communication")}</button>
              </div>
            </form>
          </div>
        </div>}
        <div className="clic2call-handle">
          <span className="handle-label">
            <button onClick={() => updateIsOpen(!isOpen)} >{t("Une question ?")}</button>
          </span>
        </div>
      </div>
    }}

  </Clic2CallModule>
}