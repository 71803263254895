import React from "react"
import {Widget} from "components/common/Widget/Widget"
import {useQuery} from "@apollo/react-hooks"
import customerCount from "graphql/CRM/Customer/query/customerCount";



export const CustomerCountWidget = () => {

  const {data} = useQuery(customerCount)

  return <Widget type="number" value={data?.count} label="Customers" />
}