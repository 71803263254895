import gql from "graphql-tag";
import TemplateFragment from "../fragment/TemplateFragment";

export default gql`

  ${TemplateFragment}

  query templateGet($id: String!) {
    template: templateGet(id: $id) {
      ...TemplateFragment
    }
  }

`