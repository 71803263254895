import { useQuery } from "@apollo/react-hooks";
import { DateTimeFormat, PriceFormat } from "@zipou/front_tools";
import paymentGetByPublicIdAndPublicSalt from "graphql/BILLING/Payment/query/paymentGetByPublicIdAndPublicSalt";
import { Payment } from "model";
import React from "react"
import { useTranslation } from "react-i18next";

type PaymentReceiptDisplayProps = {
  publicId: string
  salt: string
}

export const PaymentReceiptDisplay = ({ publicId, salt }: PaymentReceiptDisplayProps) => {

  const { t } = useTranslation('translation', { keyPrefix: 'billing' });

  const { data } = useQuery<{ payment: Payment }>(paymentGetByPublicIdAndPublicSalt, {
    variables: {
      id: publicId,
      salt,
    }
  })

  const payment = data?.payment

  return <div>
    <table className="table table-stripped table-bordered">
      <tbody>
        <tr>
          <th>{t("id")}</th>
          <td>
            {payment?.publicId}
          </td>
        </tr>
        <tr>
          <th>{t("date")}</th>
          <td>
            <DateTimeFormat value={new Date(payment?.captureDate)} />
          </td>
        </tr>
        <tr>
          <th>{t("card_amount")}</th>
          <td>
            <PriceFormat value={payment?.amount} />
          </td>
        </tr>
        <tr>
          <th>{t("card_brand")}</th>
          <td>
            {payment?.receiptInfo?.brand}
          </td>
        </tr>
        <tr>
          <th>{t("card_last4")}</th>
          <td>
            XXXX-XXXX-XXXX-{payment?.receiptInfo?.last4}
          </td>
        </tr>
        {payment?.receiptInfo?.country && <tr>
          <th>{t("country")}</th>
          <td>
            {payment?.receiptInfo?.country}
          </td>
        </tr>}
        {payment?.receiptInfo?.used3DSecure && <tr>
          <th>{t("used3DSecure")}</th>
          <td>
            {payment?.receiptInfo?.used3DSecure === "authenticated" ? <span className="badge badge-sm badge-success">{"AUTHENTIFIE"}</span> : <span className="badge badge-sm badge-dark">{payment?.receiptInfo?.used3DSecure}</span>}
          </td>
        </tr>}
      </tbody>
    </table>
  </div>
}