import gql from "graphql-tag";
import CustomerEmailFragment from "../fragment/CustomerEmailFragment";

export default gql`

  ${CustomerEmailFragment}

  query customerEmailGet($id: String!) {
    customerEmail: customerEmailGet(id: $id) {
      ...CustomerEmailFragment
    }
  }

`