import { useSubscription } from "@apollo/react-hooks"
import { StripeContainer } from "components/front/BILLING/Stripe/Container/StripeContainer"
import paymentUpdateGql from "graphql/BILLING/Payment/subscription/paymentUpdate.gql"
import { BillingConfigTypeEnum, Payment } from "model"
import React from "react"
import { PaymentConfirmPaytweak } from "./Paytweak/PaymentConfirmPaytweak"
import { PaymentConfirmStripe } from "./Stripe/PaymentConfirmStripe"

export type PaymentConfirmProps = {
  payment: Payment,
  onCancel?: () => void,
}

export const PaymentConfirm = ({ payment, onCancel }: PaymentConfirmProps) => {

  useSubscription(paymentUpdateGql, {
    variables: {
      id: payment?.id,
    },
    skip: !payment?.id,

  })

  switch (payment?.provider) {
    case BillingConfigTypeEnum.STRIPE: {

      return <StripeContainer stripePk={payment?.Hotel?.BillingConfig?.StripeConfig?.publishableKey}>
        <PaymentConfirmStripe payment={payment} />
      </StripeContainer>

    }

    case BillingConfigTypeEnum.PAYTWEAK: {
      return <PaymentConfirmPaytweak payment={payment} newWindow={true} />
    }

    default: {
      return <h1>DEFAULT</h1>
    }
  }


}