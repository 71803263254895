import { v4 as uuid } from "uuid"
import React, { useState } from "react"
import { PostStayGroupInput, PostStayGroupItemInput, PostStayGroupItemTypeEnum, PostStayGroupSeparatorInput, PostStayQuestionInput } from "model"
// import { TextInput } from "components/common/Form/TextInput"
import { PostStayQuestionForm } from "../../Question/Form/PostStayQuestionForm"
import { IconArrow, IconArrowDown, IconArrowUp, IconBars, IconCheck, IconComment, IconDatabase, IconEdit, IconEllipsis, IconHome, IconMore, IconNotch, IconQuestion, IconRandom, IconTrash, Modal, TextInput } from "@zipou/front_tools"
import { PostStayGroupItemForm } from "../Item/Form"
import Dropdown from 'react-bootstrap/Dropdown'

type PostStayGroupFormProps = {
  postStayGroup: PostStayGroupInput,
  errors: any,
  onChange: (postStayGroup: PostStayGroupInput) => void,
}


export const PostStayGroupForm = ({ postStayGroup, errors, onChange }: PostStayGroupFormProps) => {


  const [focusItem, updateFocusItem] = useState<PostStayGroupItemInput>()
  const [focusSeparator, updateFocusSeparator] = useState<PostStayGroupSeparatorInput>()

  // const questionList = postStayGroup?.questionList
  const itemList = postStayGroup?.PostStayGroupItem

  const onMoveItem = (index: number, up: boolean) => {
    //Interverti juste les deux elements dans l'array et update le state
    let newArray = [...itemList]
    const elementToMove = itemList[index]
    const backup = itemList[index - (1 * (up ? 1 : -1))]

    newArray[index - (1 * (up ? 1 : -1))] = elementToMove
    newArray[index] = backup

    onChange({
      ...postStayGroup,
      PostStayGroupItem: newArray,
    })

  }


  return <div>
    <div className="input-group">
      <span className="input-group-text">Label</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput id={"labelFr"} value={postStayGroup?.labelFr} errors={errors} label={"labelFr"} onChange={(v) => {
          onChange({
            ...postStayGroup,
            labelFr: v,
          })
        }} />
        <TextInput id={"labelEn"} value={postStayGroup?.labelEn} errors={errors} label={"labelEn"} onChange={(v) => {
          onChange({
            ...postStayGroup,
            labelEn: v,
          })
        }} />
      </div>
    </div>

    <div className="input-group">
      <span className="input-group-text">Description</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput id={"descFr"} value={postStayGroup?.descFr} errors={errors} label={"descFr"} onChange={(v) => {
          onChange({
            ...postStayGroup,
            descFr: v,
          })
        }} />
        <TextInput id={"descEn"} value={postStayGroup?.descEn} errors={errors} label={"descEn"} onChange={(v) => {
          onChange({
            ...postStayGroup,
            descEn: v,
          })
        }} />
      </div>
    </div>

    <h3>Liste des questions</h3>

    <Modal display={!!focusItem} onClose={() => updateFocusItem(undefined)}>
      <PostStayGroupItemForm errors={{}} postStayGroupItem={postStayGroup?.PostStayGroupItem?.find(el => focusItem?.id === el?.id)} onChange={(e) => {
        onChange({
          ...postStayGroup,
          PostStayGroupItem: postStayGroup?.PostStayGroupItem?.map((item, idx) => item?.id === e.id ? e : item)
        })
      }} />

    </Modal>

    <table className="table table-striped table-bordered">
      <tbody>
        {itemList?.map((item, itemIndex) => {

          const isQuestion = item?.type === PostStayGroupItemTypeEnum.TYPE_QUESTION
          const canMoveUp = itemIndex > 0
          const canMoveDown = itemIndex < itemList?.length - 1

          return <tr key={`item_${item?.id}_${itemIndex}`} >
            <td>#{itemIndex + 1}</td>
            <td>{item?.type === PostStayGroupItemTypeEnum.TYPE_QUESTION ? <span className="badge badge-sm badge-info">QUESTION</span> : <span className="badge badge-sm badge-primary">SEPARATEUR</span>}</td>
            <td>{isQuestion ? item?.Question?.labelFr : item?.Separator?.labelFr}</td>
            <td>
              <div className="d-flex">
                <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusItem(item)}>
                  <IconEdit />
                </button>
                {canMoveUp && <button className='btn btn-dark btn-sm ml-1' onClick={() => onMoveItem(itemIndex, true)}>
                  <IconArrowUp />
                </button>}
                {canMoveDown && <button className='btn btn-dark btn-sm ml-1' onClick={() => onMoveItem(itemIndex, false)}>
                  <IconArrowDown />
                </button>}

                <Dropdown className="ml-4">
                  <Dropdown.Toggle size="sm" variant="info" id="dropdown-basic">
                    Ajouter
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => {
                      onChange({
                        ...postStayGroup,
                        PostStayGroupItem: [
                          ...(postStayGroup?.PostStayGroupItem.slice(0, itemIndex + 1) || []),
                          {
                            id: uuid(),
                            type: PostStayGroupItemTypeEnum.TYPE_SEPARATOR,
                            Question: {
                              id: uuid(),
                            }
                          },
                          ...(postStayGroup?.PostStayGroupItem.slice(itemIndex + 1) || []),
                        ]
                      })
                    }}>Séparateur en dessous</Dropdown.Item>
                    <Dropdown.Item onSelect={() => {
                      onChange({
                        ...postStayGroup,
                        PostStayGroupItem: [
                          ...(postStayGroup?.PostStayGroupItem.slice(0, itemIndex + 1) || []),
                          {
                            id: uuid(),
                            type: PostStayGroupItemTypeEnum.TYPE_QUESTION,
                            Question: {
                              id: uuid(),
                            }
                          },
                          ...(postStayGroup?.PostStayGroupItem.slice(itemIndex + 1) || []),
                        ]
                      })
                    }}>Question en dessous</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <button className='btn btn-sm btn-danger ml-4' onClick={() => {
                  onChange({
                    ...postStayGroup,
                    PostStayGroupItem: postStayGroup?.PostStayGroupItem.filter((_, idx) => {
                      return idx !== itemIndex
                    })
                  })
                }}>
                  <IconTrash />
                </button>
              </div>
            </td>

          </tr>

        })}
      </tbody>
    </table>

    <div className="mt-3">
      <button className="btn btn-sm btn-dark ml-2" onClick={(v) => {
        onChange({
          ...postStayGroup,
          PostStayGroupItem: [
            ...(postStayGroup?.PostStayGroupItem || []),
            {
              id: uuid(),
              type: PostStayGroupItemTypeEnum.TYPE_QUESTION,
              Question: {
                id: uuid(),
              }
            },
          ]
        })
      }} >Ajouter une question
      </button>
      <button className="btn btn-sm btn-dark ml-2" onClick={(v) => {
        onChange({
          ...postStayGroup,
          PostStayGroupItem: [
            ...(postStayGroup?.PostStayGroupItem || []),
            {
              id: uuid(),
              type: PostStayGroupItemTypeEnum.TYPE_SEPARATOR,
            },
          ]
        })
      }} >Ajouter un séparateur
      </button>
    </div>

  </div >



}