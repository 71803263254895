import gql from "graphql-tag";
import RoomFragmentGql from "graphql/PMS/Room/fragment/RoomFragment.gql";

export default gql`

  ${RoomFragmentGql}

  fragment ReservationRoomFragment on ReservationRoom {
    id
    Room {
      ...RoomFragment
    }
  }

`