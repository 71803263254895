import {PriceFormatFunction} from "components/common/Format/PriceFormat";
import React from "react";
import {useTranslation} from "react-i18next";

type ConfirmationAnswerDisplayProps = {
  answerList: any[],
  isFrench: boolean,
}

export const ConfirmationAnswerDisplay = (props: ConfirmationAnswerDisplayProps) => {
  const {t} = useTranslation('translation', {keyPrefix: 'confirmation'});

  const answerList = props?.answerList || []

  const getVarData = (varId: string) => {
    const answer = answerList.find((el: any) => el.varId === varId)
    return answer?.datas?.value
  }

  const answerListFiltered = [...answerList]?.sort((el1: any, el2: any) => (el1.varId === "lvcArrhesAmount" || el1.varId === "lmuArrhesAmount" || el1.varId === "flauArrhesAmount") ? -1 : 1)

  const formatDate = (date: Date): string => {
    return date?.toLocaleString("FR-fr", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: 'UTC',
    })
  }


  return <table className="table table-stripped">
    <tbody>
      {answerListFiltered?.map((answer: any) => {
        switch (answer?.varId) {

          case "flauHasParking": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("flauHasParking")}</span>
              </td>
            </tr> : <></>
          }

          case "lvcHasRestau": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lvcHasRestau", {nbPeople: getVarData("lvcRestauNbPeople"), dateString: formatDate(new Date(getVarData("lvcRestauDateTime")))})}</span>              </td>
            </tr> : <></>
          }

          case "lmuHasRestau": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lmuHasRestau", {nbPeople: getVarData("lmuRestauNbPeople"), dateString: formatDate(new Date(getVarData("lmuRestauDateTime")))})}</span>              </td>
            </tr> : <></>
          }

          case "lvcHasJoio": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lvcHasJoio", {nbPeople: getVarData("lvcJoioNbPeople"), dateString: formatDate(new Date(getVarData("lvcJoioDateTime")))})}</span>
              </td>
            </tr> : <></>
          }

          case "lvcHasSpa": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lvcHasSpa", {nbPeople: getVarData("lvcSpaNbPeople"), dateString: formatDate(new Date(getVarData("lvcSpaDateTime")))})}</span>
              </td>
            </tr> : <></>
          }

          case "lmuHasSpa": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lmuHasSpa", {nbPeople: getVarData("lmuSpaNbPeople"), dateString: formatDate(new Date(getVarData("lmuSpaDateTime")))})}</span>
              </td>
            </tr> : <></>
          }

          case "dhlHasSpa": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("dhlHasSpa", {nbPeople: getVarData("dhlSpaNbPeople"), dateString: formatDate(new Date(getVarData("dhlSpaDateTime")))})}</span>
              </td>
            </tr> : <></>
          }

          case "lvcHasParking": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lvcHasParking")}</span>
              </td>
            </tr> : <></>
          }

          case "lmuHasParking": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("lmuHasParking")}</span>
              </td>
            </tr> : <></>
          }

          case "lvcArrhesAmount": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span><b>{t("lvcArrhesAmount", {amount: PriceFormatFunction(value)})}</b></span>
              </td>
            </tr> : <></>
          }

          case "flauArrhesAmount": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span><b>{t("flauArrhesAmount", {amount: PriceFormatFunction(value)})}</b></span>
              </td>
            </tr> : <></>
          }

          case "lmuArrhesAmount": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span><b>{t("lmuArrhesAmount", {amount: PriceFormatFunction(value)})}</b></span>
              </td>
            </tr> : <></>
          }

          case "cbsArrhesAmount": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span><b>{t("cbsArrhesAmount", {amount: PriceFormatFunction(value)})}</b></span>
              </td>
            </tr> : <></>
          }

          case "dhlArrhesAmount": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span><b>{t("cbsArrhesAmount", {amount: PriceFormatFunction(value)})}</b></span>
              </td>
            </tr> : <></>
          }

          case "flauComment": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{value}</span>
              </td>
            </tr> : <></>
          }

          case "dhlComment": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{value}</span>
              </td>
            </tr> : <></>
          }

          case "lmuComment": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{value}</span>
              </td>
            </tr> : <></>
          }

          case "lvcComment": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{value}</span>
              </td>
            </tr> : <></>
          }

          case "cbsComment": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{value}</span>
              </td>
            </tr> : <></>
          }

          case "dhlHasGastro": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("dhlHasGastro", {nbPeople: getVarData("dhlGastroNbPeople"), dateString: formatDate(new Date(getVarData("dhlGastroDateTime")))})}</span>              </td>
            </tr> : <></>
          }

          case "dhlHasBistro": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("dhlHasBistro", {nbPeople: getVarData("dhlBistroNbPeople"), dateString: formatDate(new Date(getVarData("dhlBistroDateTime")))})}</span>              </td>
            </tr> : <></>
          }

          case "dhlHasCours": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("dhlHasCours", {nbPeople: getVarData("dhlCoursNbPeople"), dateString: formatDate(new Date(getVarData("dhlCoursDateTime")))})}</span>              </td>
            </tr> : <></>
          }

          case "cbsHasRestau": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("cbsHasRestau", {nbPeople: getVarData("cbsRestauNBPeople"), dateString: formatDate(new Date(getVarData("cbsRestauDateTime")))})}</span>              </td>
            </tr> : <></>
          }

          case "cbsHasJacuzzi": {
            const {value} = answer?.datas
            return value ? <tr>
              <td>
                <span>{t("cbsHasJacuzzi", {nbPeople: getVarData("cbsJacuzziNBPeople"), dateString: formatDate(new Date(getVarData("cbsJacuzziDateTime")))})}</span>              </td>
            </tr> : <></>
          }


          default: {
            return <></>
          }
        }
      })}
    </tbody>

  </table>

}