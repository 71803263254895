import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import ConfirmationListFragment from "../fragment/ConfirmationListFragment";

export default gql`

  ${CursorFragment}
  ${ConfirmationListFragment}

  query confirmationList($cursor: CursorInput!, $input: ConfirmationListInput!) {
    list: confirmationList(cursor: $cursor, input: $input) {
      input
      cursor {
        ...CursorFragment
      }
      nodes {
        ...ConfirmationListFragment
      }
    }
  }
`