import gql from "graphql-tag";
import NewsletterRegistrationFragmentGql from "graphql/CRM/NewsletterRegistration/fragment/NewsletterRegistrationFragment.gql";

export default gql`

${NewsletterRegistrationFragmentGql}

fragment CustomerNewsletterRegistrationFragment on CustomerNewsletterRegistration {
  id
  NewsletterRegistration {
    ...NewsletterRegistrationFragment
  }
}

`