import gql from "graphql-tag";

export default gql`

  fragment ChargeFragment on Charge {
    id
    amount
    status
    creationDate
    refundAmount
  }

`