import gql from "graphql-tag";
import ReservationFragment from "../fragment/ReservationFragment";

export default gql`

  ${ReservationFragment}

  query reservationGet($id: String!) {
    reservation: reservationGet(id: $id) {
      ...ReservationFragment
    }
  }

`