import { NetworkModuleDisplay } from "components/front/NETWORK/Module/NetworkModuleDisplay"
import React, {  } from "react"


export const NetworkRoute = () => {


  return <div>
    <NetworkModuleDisplay />
  </div>

}