import gql from "graphql-tag"
import PlanningFragment from "../fragment/PlanningFragment.gql"

export default gql`

  ${PlanningFragment}

  query planningList {
    planningList: planningList {
      ...PlanningFragment
    }
  }

`