import gql from "graphql-tag";
import TargetFragmentGql from "../fragment/TargetFragment.gql";

export default gql`

  ${TargetFragmentGql}

  mutation targetUpdate($id: String!, $clientId: String!, $input: TargetUpdateInput!) {
    target: targetUpdate(id: $id, clientId: $clientId, input: $input) {
      ...TargetFragment
    }
  }

`