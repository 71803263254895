import { useMutation, useQuery } from "@apollo/react-hooks"
import { DateFormat, IconEdit, IconTrash, Modal, Pagination } from "@zipou/front_tools"
import newsListGql from "graphql/APP/News/query/newsList.gql"
import { CursorInput, News, NewsListResult } from "model"
import React, { useState } from "react"
import { NewsAdd } from "../Add/NewsAdd"
import { NewsEdit } from "../Edit/NewsEdit"
import newsDeleteGql from "graphql/APP/News/mutation/newsDelete.gql"

// type NewsListProps = {

// }

export const NewsList = () => {

  const [focusAdd, updateFocusAdd] = useState(false)
  const [focusEdit, updateFocusEdit] = useState<News>(null)

  const [cursor, updateCursor] = useState<CursorInput>({
    limit: 10,
    page: 1,
  })

  const [deleteMutation] = useMutation(newsDeleteGql)

  const { data, refetch } = useQuery<{ list: NewsListResult }>(newsListGql, {
    variables: {
      cursor
    }
  })

  const onDelete = (id: string,) => {
    deleteMutation({
      variables: {
        id,
      }
    })
      .then(() => {
        refetch()
      })
  }

  const newsList = data?.list?.nodes
  // const cursor = data?.list?.cursor

  return <div>
    {focusAdd && <Modal size="xl" display title="Ajouter une news" onClose={() => updateFocusAdd(false)}>
      <NewsAdd onDone={() => updateFocusAdd(false)} />
    </Modal>}

    {!!focusEdit && <Modal size="xl" display title="Editer une news" onClose={() => updateFocusEdit(null)}>
      <NewsEdit id={focusEdit?.id} onDone={() => updateFocusEdit(null)} />
    </Modal>}
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="text-white bg-dark">
          <td className="text-white bg-dark">Titre</td>
          <td className="text-white bg-dark">Date</td>
          <td className="text-white bg-dark">Action</td>
        </tr>
      </thead>
      <tbody>
        {newsList?.map(news => {
          return <tr>
            <td>{news?.title}</td>
            <td><DateFormat value={new Date(news?.date)} /></td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusEdit(news)}>
                <IconEdit />
              </button>

              <button className="btn btn-sm btn-danger ml-1" onClick={() => onDelete(news?.id)}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
    <div>
      <Pagination cursor={cursor} onChange={(page) => updateCursor({ ...cursor, page, })} />
    </div>
  </div>

}