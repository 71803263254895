import {useMutation, useQuery} from '@apollo/react-hooks'
import {Pagination} from 'components/common/Navigation/Pagination/Pagination'
import {HotelBadge} from 'components/front/PMS/Hotel/Badge/HotelBadge'
import {userContext} from 'context/User'
import reservationGroupListGql from 'graphql/PMS/Reservation/Group/query/reservationGroupList.gql'
import {ReservationGroup} from 'model'
import React, {useContext, useState} from 'react'
import {ReservationButton} from '../../Button/ReservationButton'
import {ReservationGroupEdit} from '../Edit/ReservationGroupEdit'
import {ContactEditButton} from 'components/front/PMS/Contact/Widget/ContactEditButton'
import {ReservationGroupAdd} from '../Add/ReservationGroupAdd'
import reservationGroupDelete from 'graphql/PMS/Reservation/Group/mutation/reservationGroupDelete'
import {TemporisationButton} from 'components/common/Navigation/Button/TemporisationButton'
import {TextInput} from 'components/common/Form/TextInput'
import { Modal } from '@zipou/front_tools'

type ReservationGroupListProps = {

}

export const ReservationGroupList = (props: ReservationGroupListProps) => {

  const user = useContext(userContext)

  const [focusEdit, updateFocusEdit] = useState<ReservationGroup>(null)
  const [focusAdd, updateFocusAdd] = useState<boolean>(null)
  const [focusPmsId, updateFocusPmsId] = useState<boolean>(null)

  const [pmsIdFilter, updatePmsIdFilter] = useState<any>(null)

  const [mutate] = useMutation(reservationGroupDelete)

  const {data, refetch} = useQuery(reservationGroupListGql, {
    variables: {
      input: {
        hotelIds: user?.hotels,
        ...(pmsIdFilter ? {reservationPmsId: pmsIdFilter} : {})
      },
      cursor: {
        page: 1,
        limit: 10,
      }
    }
  })

  const onDelete = (groupId: string) => {

    mutate({
      variables: {
        id: groupId,
      }
    })
      .then(() => {
        return refetch({
          cursor: {
            page: data?.list?.cursor?.page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list?.input,
        })
      })

  }

  const reservationGroupList: ReservationGroup[] = data?.list?.nodes
  const hasElements = reservationGroupList?.length > 0
  const hasMultiHotels = data?.list?.input?.hotelIds?.length > 1

  return <div>
    <h1>Liste des Groupes</h1>
    <Modal display={!!focusPmsId} onClose={() => updateFocusPmsId(null)}>
      <TextInput label="Numero de Résa" errors={null} value={pmsIdFilter} rightLabel={<span className='icon-trash' style={{cursor: "pointer"}} onClick={() => {updatePmsIdFilter(null); updateFocusPmsId(null)}}></span>} onChange={(e, v) => {
        updatePmsIdFilter(v)
      }} />
    </Modal>
    <Modal display={!!focusAdd} onClose={() => updateFocusAdd(null)} title="Ajouter un groupe">
      <ReservationGroupAdd onDone={() => {
        updateFocusAdd(null)
        refetch({
          cursor: {
            page: data?.list?.cursor?.page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list?.input,
        })
      }} />
    </Modal>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)} title="Modifier le groupe">
      <ReservationGroupEdit id={focusEdit?.id} onDone={() => {
        updateFocusEdit(null)
        refetch({
          cursor: {
            page: data?.list?.cursor?.page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list?.input,
        })
      }} />
    </Modal>
    <table className='table table-bordered table-stripped'>
      <thead>
        <tr className='bg-dark text-white'>
          {hasMultiHotels && <th className='bg-dark text-white'>
            <button className="btn btn-sm btn-secondary">Hotel</button>
          </th>}
          <th className='bg-dark text-white'>
            Contact
          </th>
          <th className='bg-dark text-white'>
            <button className="btn btn-sm btn-secondary" onClick={() => updateFocusPmsId(true)}>Reservations</button>
          </th>
          <th className='bg-dark text-white'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {!hasElements && <tr>
          <td colSpan={hasMultiHotels ? 4 : 3}>
            <div className='alert alert-warning'>Rien à afficher</div>
          </td>
        </tr>}
        {hasElements && reservationGroupList?.map(group => {
          return <tr key={`group_id_${group?.id}`}>
            {hasMultiHotels && <td><HotelBadge hotel={group?.Hotel} /></td>}
            <td><ContactEditButton contact={group?.Contact} /></td>
            <td>
              {group?.ReservationGroupLink?.map(resaGroupLink => {
                return <span className="ml-2" key={`resa_${resaGroupLink?.id}`}>
                  <ReservationButton reservationId={resaGroupLink?.reservationId} />
                </span>
              })}
            </td>
            <td>
              <div className='d-flex flex-row'>
                <button className="btn-warning btn btn-sm" onClick={() => updateFocusEdit(group)}>
                  <span className="icon-edit"></span>
                </button>
                <TemporisationButton seconds={5} onClick={() => onDelete(group?.id)}>
                  {(onClick, spinnerComponent) => {
                    return <button className="ml-2 btn-danger btn btn-sm" onClick={onClick}>
                      {spinnerComponent}
                      <span className="icon-trash"></span>
                    </button>
                  }}
                </TemporisationButton>
              </div>
            </td>

          </tr>
        })}
      </tbody>
    </table>
    <div>
      <Pagination cursor={data?.list?.cursor} onChange={page => {
        refetch({
          cursor: {
            limit: data?.list?.cursor?.limit,
            page,
          },
          input: data?.list?.input,
        })
      }} />
    </div>
    <div className=''>
      <button className='btn btn-sm btn-dark' onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div>

}