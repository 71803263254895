import gql from "graphql-tag";

export default gql`

  fragment PriceRateFragment on PriceRate {
    id
    code
    valueIncTax
    creationDate
    PriceRateType {
      id
      isGiftBox
      canBeSelectedInProposition
    }
  }

`