import {DateFormat} from "components/common/Format/DateFormat"
import {PriceFormat} from "components/common/Format/PriceFormat"
import React from "react"
import {useTranslation} from "react-i18next"
import {PropositionRowTotal} from "../../Proposition/Row/Display/Total/PropositionRowTotal"
export type PriceRateFocusProps = {
  propositionRow: any,
}

export const PriceRateFocus = (props: PriceRateFocusProps) => {

  const {t, i18n} = useTranslation('translation', {keyPrefix: 'price_rate'});
  const isFrench = i18n.language === "fr";


  const {itemGroupList} = props?.propositionRow || []
  return <div className="">
    <div className="card-deck">
      {false && itemGroupList?.map((itemGroup: any, index: number) => {
        return <div className="card">
          <div className="card-header bg-dark text-white">
            {t("Chambre {id}", {id: index + 1})}
          </div>
          <div className="card-body">
            <table className="table table-bordered table-stripped">
              <thead>
                <tr>
                  <th className="bg-dark text-white">{t("Date")}</th>
                  <th className="bg-dark text-white">{t("Code Tarif")}</th>
                  <th className="bg-dark text-white">{t("Montant")}</th>
                </tr>
              </thead>
              <tbody>
                {itemGroup?.itemList?.map((item: any) => {
                  const key = `${new Date(item?.date).getTime()}_${item?.roomId}`
                  const isIncludedInConfirmationTotal = item?.PriceRate?.PriceRateType?.isIncludedInConfirmationTotal

                  return <tr key={key}>
                    <td><DateFormat value={new Date(item?.date)} /> </td>
                    <td>{isFrench ? item?.PriceRate?.PriceRateType?.labelFr : item?.PriceRate?.PriceRateType?.labelEn}</td>
                    <td>{isIncludedInConfirmationTotal ? <PriceFormat value={item?.PriceRate?.valueIncTax * 100} withDecimal={true} currency="€" /> : "--"}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      })}
    </div>
    <div style={{paddingTop: 20}}>
      <PropositionRowTotal row={props?.propositionRow} showDetails={true} />
    </div>
  </div>
}