import gql from "graphql-tag";
import PaymentFragment from "../fragment/PaymentFragment";

export default gql`

  ${PaymentFragment}

  query paymentGet($id: String!) {
    payment: paymentGet(id: $id) {
      ...PaymentFragment
    }
  }
`