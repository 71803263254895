import { DateTimeFormat } from "@zipou/front_tools"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import { NewsletterRegistration } from "model"
import React from "react"

type NewsletterRegistrationListProps = {
  newsletterRegistrationList: NewsletterRegistration[]
}


export const NewsletterRegistrationList = ({ newsletterRegistrationList }: NewsletterRegistrationListProps) => {
  return <div>
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Email</td>
          <td className="bg-dark text-white">Date</td>
          <td className="bg-dark text-white">Hotel</td>
        </tr>
      </thead>
      <tbody>
        {newsletterRegistrationList?.map(newsletterRegistration => {
          return <tr key={`newsletterRegistration_${newsletterRegistration?.id}`}>
            <td>{newsletterRegistration.email}</td>
            <td>
              <DateTimeFormat value={new Date(newsletterRegistration.creationDate)} />
            </td>
            <td>
              <HotelBadge hotel={newsletterRegistration?.Hotel} />
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}