import gql from "graphql-tag";
import OptionFragmentGql from "../fragment/OptionFragment.gql";

export default gql`

  ${OptionFragmentGql}

  query optionGet($id: String!) {
    option: optionGet(id: $id) {
      ...OptionFragment
    }
  }

`