import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";
import ReservationGroupLinkEditFragment from "../../GroupLink/fragment/ReservationGroupLinkEditFragment";

export default gql`

${HotelDisplayFragment}
${ReservationGroupLinkEditFragment}

fragment ReservationGroupEditFragment on ReservationGroup {
  id
  hotelId
  contactId
  arrivalDate
  departureDate
  Hotel {
    ...HotelDisplayFragment
  }
  ReservationGroupLink {
    ...ReservationGroupLinkEditFragment
  }
}

`