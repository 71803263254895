import gql from "graphql-tag";
import CustomerSearchFragment from "graphql/CRM/Customer/fragment/CustomerSearchFragment";
import ContactSearchFragment from "graphql/PMS/Contact/fragment/ContactSearchFragment";
import ReservationSearchFragment from "graphql/PMS/Reservation/fragment/ReservationSearchFragment";

export default gql`

  ${CustomerSearchFragment}
  ${ContactSearchFragment}
  ${ReservationSearchFragment}

  query Search($text: String!) {
    search(text: $text) {
      ... on Customer {
        ...CustomerSearchFragment
      }
      ... on Contact {
        ...ContactSearchFragment
      }
      ... on Reservation {
        ...ReservationSearchFragment
      }
    }
  }
`