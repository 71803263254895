import gql from "graphql-tag";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";

export default gql`

${HotelPublicFragment}

fragment DeviceFragment on Device {
  id
  name
  status
  usage
  usageData
  Hotel {
    ...HotelPublicFragment
  }
}


`