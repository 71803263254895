import gql from "graphql-tag";
import CustomerListFragment from "graphql/CRM/Customer/fragment/CustomerListFragment";
import SegmentationListFragment from "../../fragment/SegmentationListFragment";

export default gql`

  ${CustomerListFragment}
  ${SegmentationListFragment}

  fragment SegmentationMemberFragment on SegmentationMember {
    id
    Customer {
      ...CustomerListFragment
    }
    Segmentation {
      ...SegmentationListFragment
    }
  }

`