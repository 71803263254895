import gql from "graphql-tag";
import PriceRateFragmentGql from "../fragment/PriceRateFragment.gql";

export default gql`

  ${PriceRateFragmentGql}

  query priceGet($id: String!) {
    priceRate: priceRateGet(id: $id) {
      ...PriceRateFragment
    }
  }
`
