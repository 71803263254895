import gql from "graphql-tag";
import PriceRateCancelConditionFragmentGql from "../fragment/PriceRateCancelConditionFragment.gql";

export default gql`

  ${PriceRateCancelConditionFragmentGql}

  query priceRateCancelConditionList($hotelId: String!) {
    list: priceRateCancelConditionList(hotelId: $hotelId) {
      ...PriceRateCancelConditionFragment
    }
  }

`