import gql from "graphql-tag";
import PlanningWeeklyFragment from "graphql/PLANNING/Planning/Weekly/fragment/PlanningWeeklyFragment";

export default gql`

  ${PlanningWeeklyFragment}

  fragment ServiceEditFragment on Service {
    id
    name
    type
    labelFr
    descFr
    labelEn
    descEn
    canBeSelectedInPreStay
    canBeSelectedInConfirmation
    type
    providerType
    pictoUrl
    managerUserId
    planningWeekly {
      ...PlanningWeeklyFragment
    }

  }

`