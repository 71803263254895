import gql from "graphql-tag";
import ServiceFragment from "../fragment/ServiceFragment";

export default gql`

${ServiceFragment}

query serviceListByHotelId($hotelId: String!) {
  list: serviceListByHotelId(hotelId: $hotelId) {
    ...ServiceFragment
  }
}

`