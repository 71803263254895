import gql from "graphql-tag";
import PropositionFragment from "../fragment/PropositionFragment";

export default gql`
  ${PropositionFragment}

  mutation propositionAddOrUpdate($input: PropositionInput!) {
    result: propositionAddOrUpdate(input: $input) {
      proposition {
        ...PropositionFragment
      }
      warning
    }
  }

`