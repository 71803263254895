import { useQuery } from "@apollo/react-hooks"
import { AutoCompleteInput } from "components/common/Form/AutoCompleteInput"
import { DateFormat } from "components/common/Format/DateFormat"
import contactGetGql from "graphql/PMS/Contact/query/contactGet.gql"
import reservationSearchByPmsId from "graphql/PMS/Reservation/query/reservationSearchByPmsId"
import { Reservation, ReservationStatusEnum } from "model"
import React, { useEffect, useState } from "react"
import { ReservationStatus } from "../Status/ReservationStatus"

type ReservationSearchInputProps = {
  label: string,
  hotelId: string,
  value?: string,
  disabled?: boolean,
  placeholder?: string,
  isError?: boolean,
  isWarning?: boolean,
  onChange: (value: string, object: any) => void
}

export const ReservationSearchInput = (props: ReservationSearchInputProps) => {

  const [value, updateValue] = useState<Reservation>(null)
  const { refetch } = useQuery(reservationSearchByPmsId, {
    skip: true,
  })

  useQuery(contactGetGql, {
    variables: {
      id: props?.value,
    },
    skip: !props?.value,
    onCompleted: (response: any) => {
      updateValue(response?.contact)
    }
  })

  //Detect that hotelId has changed
  useEffect(() => {
    if (value && props?.hotelId) {
      onValueChange(null)
    }
  }, [props?.hotelId])

  const onValueChange = (value: any) => {
    updateValue(value)
    props?.onChange(value?.id, value)
  }

  const onSearch = async (textValue: string) => {
    const response = await refetch({
      pmsId: textValue,
      hotelId: props?.hotelId,
    })
    return response?.data?.list || []
  }


  return <div>
    <AutoCompleteInput
      placeholder={"Chercher une reservation via son numéro"}
      disabled={!props?.hotelId || props?.disabled}
      onSearch={onSearch}
      label={props?.label}
      onChange={onValueChange}
      value={value}
      isError={props?.isError}
      isWarning={props?.isWarning}
      displayValue={(value) => <span>{value?.pmsId || ""} - <DateFormat value={new Date(value?.arrivalDate)} /> - <DateFormat value={new Date(value?.departureDate)} /> - <ReservationStatus value={value?.status as ReservationStatusEnum} /></span>}
      displayResult={(value) => <span>{value?.pmsId || ""} - <DateFormat value={new Date(value?.arrivalDate)} /> - <DateFormat value={new Date(value?.departureDate)} /></span>}
      renderNoResult={() => <li className="list-group-item list-group-item-danger">Pas de resultats</li>}
    />
  </div>
}
