import React from "react"
import { PostStay, PostStaySendStatusEnum } from "model"
import { Tooltip } from "components/front/APP/Navigation/Tooltip"
import { IconInfo } from "@zipou/front_tools"

export type PostStaySendStatusProps = {
  postStay: PostStay
}

export const PostStaySendStatus = ({ postStay }: PostStaySendStatusProps) => {


  switch (postStay?.sendStatus) {
    case PostStaySendStatusEnum.POST_STAY_SEND_STATUS_HOLD: {
      return postStay?.reason ? <Tooltip label={postStay?.reason} top={-150}>
        <span className="badge badge-sm badge-warning">NON VALIDE</span>
      </Tooltip>
        : <span className="badge badge-sm badge-warning">NON VALIDE</span>

    }

    case PostStaySendStatusEnum.POST_STAY_SEND_STATUS_TODO: {
      return <span className="badge badge-sm badge-success">VALIDE</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{postStay?.sendStatus}</span>
    }

  }


}