import gql from "graphql-tag";
import UserConfigFragmentGql from "../fragment/UserConfigFragment.gql";

export default gql`

  ${UserConfigFragmentGql}

  mutation userConfigUpdate($id: String!, $input: UserConfigInput!) {
    config: userConfigUpdate(id: $id ,input: $input) {
      ...UserConfigFragment
    }
  }

`