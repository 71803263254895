import gql from "graphql-tag";
import EventServiceFragment from "graphql/PLANNING/Event/fragment/EventServiceFragment";

export default gql`
  ${EventServiceFragment}

  query preStayListEvent($id: String!, $publicId: String!) {
    list: preStayListEvent(id: $id, publicId: $publicId) {
      ...EventServiceFragment
    }
  }
`