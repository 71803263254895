
import React from 'react'

type StarInputProps = {
  value: number,
  label?: string,
  fontSize?: number,
  leftAlign?: boolean,
  onChange: (value: number) => void,
}

export const StarInput = ({ value, label, fontSize, leftAlign, onChange }: StarInputProps) => {


  const hasLabel = !!label
  const fontSizeDefault = fontSize || 36
  const showColors = true

 
  return <div className={leftAlign ? "d-flex flex-column" : "d-flex flex-column justify-content-center"}>
    {hasLabel && <div className="d-flex justify-content-center">
      <h3>{label}</h3>
    </div>}
    <div className={leftAlign ? "d-flex " : "d-flex justify-content-center"}>
      <span style={{ fontSize: fontSizeDefault }} className={value >= 1 ? "icon-star-full" : "icon-star-shallow"} onClick={() => onChange(1)}></span>
      <span style={{ fontSize: fontSizeDefault }} className={value >= 2 ? "icon-star-full" : "icon-star-shallow"} onClick={() => onChange(2)}></span>
      <span style={{ fontSize: fontSizeDefault, color: showColors ? "" : "" }} className={value >= 3 ? "icon-star-full" : "icon-star-shallow"} onClick={() => onChange(3)}></span>
      <span style={{ fontSize: fontSizeDefault }} className={value >= 4 ? "icon-star-full" : "icon-star-shallow"} onClick={() => onChange(4)}></span>
      <span style={{ fontSize: fontSizeDefault }} className={value >= 5 ? "icon-star-full" : "icon-star-shallow"} onClick={() => onChange(5)}></span>
    </div>
  </div>

}