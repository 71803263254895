import { DateInput, TextInput } from "@zipou/front_tools"
import { NewsInput } from "model"
import React from "react"
import { UnlayerForm } from "../../Template/Form/UnlayerForm"

type NewsFormProps = {
  news: NewsInput,
  errors: any,
  onChange: (news: NewsInput) => void,
}


export const NewsForm = ({ news, onChange, errors }: NewsFormProps) => {

  // console.log("news", news)

  return <div>

    <TextInput label="Titre" errors={errors} value={news?.title} onChange={(v) => onChange({ ...news, title: v })} />

    <DateInput label="Date" value={news?.date} onChange={(v, d) => onChange({
      ...news,
      date: d
    })} />

    <UnlayerForm errors={errors} contentJSON={news?.contentJSON} onChange={(html, json) => {
      console.log('newsinside', news)

      onChange({
        ...news,
        contentHTML: html,
        contentJSON: json,

      })
    }} />



  </div>

}