// import {TemplateAdd} from "components/front/COM/Template/Add/TemplateAdd";
import React from "react"

type TemplateAddRouteProps = {

}

export const TemplateAddRoute = (props: TemplateAddRouteProps) => {

  return <div>
    <h1>Ajouter un template</h1>
    {/* <TemplateAdd /> */}
  </div>

}