import gql from "graphql-tag";
import TerminalFragmentGql from "../fragment/TerminalFragment.gql";

export default gql`

  ${TerminalFragmentGql}

  mutation terminalCancelAction($terminalId: String!) {
    terminal: terminalCancelAction(terminalId: $terminalId) {
      ...TerminalFragment
    }
  }

`