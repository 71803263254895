import gql from "graphql-tag";
import PreStayFragment from "../fragment/PreStayFragment";

export default gql`

  ${PreStayFragment}

  query preStayGet($id: String!) {
    preStay: preStayGet(id: $id) {
      ...PreStayFragment
    }
  }

`