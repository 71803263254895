import gql from "graphql-tag";

export default gql`
  
fragment NewsListFragment on News {
  id
  title
  date
  level
  contentHTML
}

`