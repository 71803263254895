import gql from "graphql-tag";

export default gql`

fragment ReservationConfirmationListFragment on Reservation {
  id
  pmsId
  arrivalDate
  status
}

`