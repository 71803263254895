import { useQuery } from "@apollo/react-hooks"
import { BooleanInput, SelectInput, TextField, TextInput } from "@zipou/front_tools"
import { PictureUploader } from "components/common/Form/PictureUploader/PictureUploader"
import { PlanningWeeklyForm } from "components/front/PLANNING/Planning/Weekly/Form/PlanningWeeklyForm"
import userListByUserGql from "graphql/APP/User/query/userListByUser"
import { isFieldOnError } from "helpers/Apollo"
import { ServiceInput, ServiceProviderTypeEnum, ServiceTypeEnum, User } from "model"
import React from "react"

export type ServiceFormProps = {
  service: ServiceInput,
  errors: any,
  onChange: (datas: ServiceInput) => void,
}

export const ServiceForm = ({ service, errors, onChange }: ServiceFormProps) => {

  const { data } = useQuery<{ list: User[] }>(userListByUserGql)
  const userList = data?.list

  return <div>
    <TextInput errors={errors} id={"name"} value={service?.name} label={"Nom"} onChange={(v) => {
      onChange({
        ...service,
        name: v
      })
    }} rightContent={<span className="badge badge-warning">Visible par les clients</span>} />

    <TextInput id={"labelFr"} value={service?.labelFr} label={"Libellé Français"} errors={errors} onChange={(v) => {
      onChange({
        ...service,
        labelFr: v
      })
    }} rightContent={<span className="badge badge-warning">Visible par les clients</span>} />

    <TextField isError={errors && errors?.descFr} value={service?.descFr} label={"Description Français"} onChange={(v) => {
      onChange({
        ...service,
        descFr: v
      })
    }} />

    <TextInput id={"labelEn"} value={service?.labelEn} label={"Libellé Anglais"} errors={errors} onChange={(v) => {
      onChange({
        ...service,
        labelEn: v
      })
    }} rightContent={<span className="badge badge-warning">Visible par les clients</span>} />

    <TextField isError={errors && errors?.descEn} value={service?.descEn} label={"Description Anglais"} onChange={(v) => {
      onChange({
        ...service,
        descEn: v
      })
    }} />


    {service?.pictoUrl && <div className="input-group">
      <img className="img-reponsive" src={service?.pictoUrl} width={100} alt="picto" />
    </div>}

    <div className="input-group">
      <span className="input-group-text">Image</span>
      <div className="form-control" style={{ height: "100%" }}>
        <PictureUploader onDone={(url) => {
          onChange({
            ...service,
            pictoUrl: url,
          })
        }} />

      </div>
      {isFieldOnError(errors, "pictoUrl") && <span className="input-group-text">
        <span className="icon-warning-sign"></span>
      </span>}
    </div>

    <div className="input-group">
      <span className="input-group-text">Type</span>
      <select className="form-control" value={service?.type || ""} onChange={e => {
        onChange({
          ...service,
          type: e.target?.value as ServiceTypeEnum,
        })
      }}>
        <option value="" disabled>Choisissez un type</option>
        {Object.keys(ServiceTypeEnum).map(key => {
          return <option key={`service_${key}`} value={key}>{key}</option>
        })}
      </select>
      {isFieldOnError(errors, "type") && <span className="input-group-text">
        <span className="icon-warning-sign"></span>
      </span>}
    </div>

    <BooleanInput id={"canBeSelectedInPreStay"} value={service?.canBeSelectedInPreStay} label={"Selectionnable dans les Pré Stay ?"} errors={errors} onChange={(v) => {
      onChange({
        ...service,
        canBeSelectedInPreStay: v
      })
    }} />

    <BooleanInput id={"canBeSelectedInConfirmation"} value={service?.canBeSelectedInConfirmation} label={"Demander dans les Confirmations ?"} errors={errors} onChange={(v) => {
      onChange({
        ...service,
        canBeSelectedInConfirmation: v
      })
    }} />

    <SelectInput id={"managerUserId"} label="Manager" errors={errors} choiceList={userList?.map((user: User) => ({ id: user?.id, label: user?.email }))} value={service?.managerUserId} onChange={(v) => {
      onChange({
        ...service,
        managerUserId: v
      })
    }} />

    <SelectInput defaultLabel="Choisissez un type" id={"providerType"} label="Type de dispo" errors={errors} choiceList={Object.keys(ServiceProviderTypeEnum)?.map((type: string) => ({ id: type, label: type }))} value={service?.providerType || ""} onChange={(v) => {
      onChange({
        ...service,
        providerType: v as ServiceProviderTypeEnum,
      })
    }} />

    <PlanningWeeklyForm errors={errors} planningWeekly={service?.planningWeekly} onChange={(pw) => {
      onChange({
        ...service,
        planningWeekly: pw,
      })
    }} />


  </div>
}