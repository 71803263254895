import gql from "graphql-tag";
import ReservationPublicFragment from "../../fragment/ReservationPublicFragment";

export default gql`

  ${ReservationPublicFragment}

  fragment ReservationGroupLinkPublicFragment on ReservationGroupLink {
    id
    groupId
    reservationId
    Reservation {
      ...ReservationPublicFragment
    }
  }

`