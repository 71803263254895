import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import { RoomTypeInput } from "model"
import React, { useState } from "react"
import { RoomTypeEdit } from "../Edit/RoomTypeEdit"

type RoomTypeListProps = {
  hotelId: string,
  roomTypeList: RoomTypeInput[],
  onChange: (roomTypeList: RoomTypeInput[]) => void,
  errors: any,
}

export const RoomTypeList = ({ roomTypeList, hotelId, errors, onChange }: RoomTypeListProps) => {

  const [focusIndex, updateFocusIndex] = useState<number | null>(null)

  const hasFocus = focusIndex !== null
  const hasContent = roomTypeList?.length > 0

  return <div>
    <Modal size="xl" display={hasFocus} onClose={() => updateFocusIndex(null)}>
      <RoomTypeEdit roomType={roomTypeList?.find((rt, idx) => idx === focusIndex)} onChange={(rtu) => onChange(roomTypeList?.map((rt, idx) => idx === focusIndex ? rtu : rt))} errors={errors && errors[focusIndex]} hotelId={hotelId} />
    </Modal>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Code</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {!hasContent && <tr>
          <td colSpan={3}>
            <div className="alert alert-warning">
              Vide
            </div>
          </td>
        </tr>}
        {roomTypeList?.map((roomType, index) => {

          const isOnError = errors && errors[index]

          return <tr key={`roomType_${roomType?.id}_${index}`} className={isOnError ? "bg-danger text-white" : ""}>
            <td>{roomType?.labelFr}</td>
            <td>{roomType?.type}</td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusIndex(index)}>
                <IconEdit />
              </button>
              <button className="btn btn-sm btn-danger ml-1" onClick={() => onChange(roomTypeList?.filter((rt, idx) => idx !== index))}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>

    <button className="btn btn-sm btn-dark" onClick={() => {
      onChange([
        ...(roomTypeList || []),
        {}
      ])
      updateFocusIndex(roomTypeList.length)
    }}>Ajouter</button>

  </div >


}