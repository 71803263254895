import { DateFormat } from "components/common/Format/DateFormat"
import { PropositionRowInput, PropositionRowRoomInput } from "model"
import React from "react"

type PropositionRowSelectProps = {
    rowWidth: number,
    row: PropositionRowInput,
    nbRooms: number,
    onAdd: (row: PropositionRowInput) => void
}

export const PropositionRowSelect = (props: PropositionRowSelectProps) => {

    const row = props?.row
    const selectionIsComplete = row?.roomList?.reduce((acc, room) => {
        return acc ? room?.itemList?.reduce((acc, item) => {
            return acc ? !!item.roomId : false
        }, true) : false
    }, true)

    return <div className="input-group">
        <span className="input-group-text bg-dark text-white category-label">Sélection</span>
        <div className="form-control" style={{ height: '100%'}}>
            {row?.roomList?.map((room: PropositionRowRoomInput, roomIndex: number) => {
                return <div className="row" key={`row_${roomIndex}`} style={{ alignItems: "center", minHeight: 40, flexWrap: "nowrap", paddingRight: 30 }}>
                    <div className="choice" style={{ width: props?.rowWidth, textAlign: "center" }}>Ch {roomIndex + 1}</div>
                    {room?.itemList.map((rowRoomNightInput, idx: number) => {

                        const isDateInSelection = !!rowRoomNightInput.roomId

                        return <div style={{ minHeight: 30, display: "flex", justifyContent: "center", alignItems: "center", width: props?.rowWidth }} key={`col_${rowRoomNightInput?.dateStamp}`} className={isDateInSelection ? "text-white border border-dark bg-success p-0 " : "p-0 text-white border border-dark bg-warning"}>
                            <DateFormat value={new Date(rowRoomNightInput?.dateStamp)} options={{ day: "2-digit", month: "2-digit" }} />
                        </div>
                    })}
                </div>
            })}
        </div>
        {selectionIsComplete && <span className="input-group-text">
            <button className="btn btn-primary" onClick={() => props?.onAdd(row)}>Ajouter</button>
        </span>}

    </div>

}