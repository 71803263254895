import {isFieldOnError} from "helpers/Apollo"
import React from "react"

type PlanningRowProps = {
  row: any,
  errors: any,
  onChange: (row: any) => void
}

export const PlanningRow = (props: PlanningRowProps) => {

  const {label, hourStart, hourEnd} = props?.row || {}


  return <div>
    <div className="input-group">
      <span className="input-group-text">Label</span>
      <input type="text" value={label} className="form-control" onChange={(e: any) => {
        props?.onChange({
          ...props?.row,
          label: e.target.value,
        })
      }} />
      {isFieldOnError(props?.errors, "label") && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
    </div>
    <div className="input-group">
      <span className="input-group-text">Hour Start</span>
      <input type="text" value={hourStart} className="form-control" onChange={(e: any) => {
        props?.onChange({
          ...props?.row,
          hourStart: e.target.value,
        })
      }} />
      {isFieldOnError(props?.errors, "hourStart") && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
    </div>
    <div className="input-group">
      <span className="input-group-text">Hour End</span>
      <input type="number" min={0} max="24" value={hourEnd} className="form-control" onChange={(e: any) => {
        props?.onChange({
          ...props?.row,
          hourEnd: e.target.value,
        })
      }} />
      {isFieldOnError(props?.errors, "hourEnd") && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
    </div>
  </div>

}