import { useQuery } from "@apollo/react-hooks"
import { PaymentConfirm } from "components/front/BILLING/Payment/Confirm/PaymentConfirm"
import { HotelContainer } from "components/public/PMS/Hotel/HotelContainer"
import paymentGetById from "graphql/BILLING/Payment/query/paymentGetById"
import React from "react"

export const PaymentConfirmIdRoute = (props: any) => {

  const { params } = props?.match
  const { id } = params

  const { data } = useQuery(paymentGetById, {
    variables: {
      id,
    },
    pollInterval: 2000,
  })

  const payment = data?.payment

  return <div>
    {payment && <HotelContainer hotel={payment?.Hotel}>
      <div className="">
        <div className="card-body">
          <PaymentConfirm payment={payment} />
        </div>
      </div>
    </HotelContainer>}
  </div>
}