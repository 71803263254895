import gql from "graphql-tag";
import TerminalFragmentGql from "../fragment/TerminalFragment.gql";

export default gql`

  ${TerminalFragmentGql}

  mutation terminalProcessToken($tokenId: String!, $terminalId: String!) {
    terminal: terminalProcessToken(tokenId: $tokenId, terminalId: $terminalId) {
      ...TerminalFragment
    }
  }

`