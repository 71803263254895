import { IconEdit, IconTrash, IconWarning, IconWarningSign, Modal } from "@zipou/front_tools"
import { ServiceInput } from "model"
import React, { useState } from "react"
import { ServiceEdit } from "../Edit/ServiceEdit"

type ServiceListProps = {
  hotelId: string,
  serviceList: ServiceInput[],
  onChange: (serviceList: ServiceInput[]) => void,
  errors: any,
}

export const ServiceList = ({ serviceList, hotelId, errors, onChange }: ServiceListProps) => {

  const [focusIndex, updateFocusIndex] = useState<number | null>(null)

  const hasIndex = focusIndex !== null
  const hasContent = serviceList?.length > 0

  return <div>
    <Modal display={hasIndex} onClose={() => updateFocusIndex(null)}>
      <ServiceEdit hotelId={hotelId} errors={errors && errors[focusIndex]} service={serviceList?.find((s, idx) => focusIndex === idx)} onChange={(service: ServiceInput) => {
        onChange(serviceList?.map((srv, idx) => idx === focusIndex ? service : srv))
      }} />
    </Modal>
    <table className="table table-bordered table-stripped">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {!hasContent && <tr>
          <td colSpan={2}>
            <div className="alert alert-warning">Vide</div>
          </td>
        </tr>}
        {serviceList?.map((service, index) => {


          const isLineOnError = errors && !!errors[index]

          return <tr key={`service_${service?.id}_${index}`} className={isLineOnError ? "" : ""}>
            <td>
              <div className="d-flex">
                {isLineOnError && <IconWarningSign />}
                <span className="ml-1">{service?.name}</span>
              </div>
            </td>
            <td>
              <button className="btn btn-warning btn-sm ml-1" onClick={() => {
                updateFocusIndex(index)
              }}>
                <IconEdit />
              </button>
              <button className="btn btn-danger btn-sm ml-1" onClick={() => onChange(serviceList?.filter((s, idx) => idx !== index))}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <button className="btn btn-sm btn-dark" onClick={() => {
      onChange([
        ...(serviceList || []),
        {},
      ])
      updateFocusIndex(serviceList?.length)
    }
    }>Ajouter</button>
  </div >

}