import { CustomerOriginEnum } from "model"
import React from "react"

type CustomerOriginProps = {
  origin: CustomerOriginEnum | string,
}

export const CustomerOrigin = ({ origin }: CustomerOriginProps) => {

  switch (origin) {
    case CustomerOriginEnum.ORIGIN_IMPORT: {
      return <span className="badge badge-sm badge-secondary">Importation</span>
    }

    case CustomerOriginEnum.ORIGIN_OTHER: {
      return <span className="badge badge-sm badge-secondary">Autre</span>
    }

    case CustomerOriginEnum.ORIGIN_PMS_CONTACT: {
      return <span className="badge badge-sm badge-primary">PMS</span>
    }

    case CustomerOriginEnum.ORIGIN_SHOP: {
      return <span className="badge badge-sm badge-primary">BOUTIQUE SITE WEB</span>
    }

    case CustomerOriginEnum.ORIGIN_NEWSLETTER_REGISTRATION: {
      return <span className="badge badge-primary">WIDGET NEWSLETTER</span>
    }
  }
}