import gql from "graphql-tag";

export default gql`

fragment SegmentationListFragment on Segmentation {
  id
  name
  color
  picto
  status
  lastError
  lastSyncDate
  progress
  displayCustomerDashboard
}
`