import { PropositionPushStatusEnum } from "model";
import React from "react";

type PropositionPushStatusDisplayProps = {
  status: PropositionPushStatusEnum
}

export const PropositionPushStatusDisplay = ({ status }: PropositionPushStatusDisplayProps) => {

  switch (status) {

    case PropositionPushStatusEnum.PUSH_CONFIRMED_DONE: {
      return <span className="badge badge-sm badge-success">Statut Confirmé OK</span>
    }

    case PropositionPushStatusEnum.PUSH_DEPOSIT_DONE: {
      return <span className="badge badge-sm badge-success">AHRRES OK</span>
    }

    case PropositionPushStatusEnum.PUSH_DISCOUNT_DONE: {
      return <span className="badge badge-sm badge-success">REDUCTION OK</span>
    }

    case PropositionPushStatusEnum.PUSH_ERROR: {
      return <span className="badge badge-sm badge-danger">ERREUR PUSH</span>
    }

    case PropositionPushStatusEnum.PUSH_DONE: {
      return <span className="badge badge-sm badge-success">PUSH COMPLET</span>
    }

    case PropositionPushStatusEnum.PUSH_OPTIONS_DONE: {
      return <span className="badge badge-sm badge-success">OPTIONS OK </span>
    }

    case PropositionPushStatusEnum.PUSH_RESERVATION_DONE: {
      return <span className="badge badge-sm badge-success">RESERVATION OK </span>
    }

    case PropositionPushStatusEnum.PUSH_NONE:
    default: {
      return <span className="badge badge-sm badge-dark">PUSH A FAIRE</span>
    }
  }

}