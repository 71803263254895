import gql from "graphql-tag";
import TokenPublicFragment from "../fragment/TokenPublicFragment";

export default gql`

  ${TokenPublicFragment}

  mutation tokenConfirm($id: String!, $options: JSONObject) {
    token: tokenConfirm(id: $id, options: $options) {
      ...TokenPublicFragment
    }
  }

`