import gql from "graphql-tag";

export default gql`

  fragment ServiceFragment on Service {
    id
    name
    type
    pictoUrl
    labelFr
    labelEn

  }

`