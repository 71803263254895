import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks"
import messagePushGql from "graphql/PHONE/CHAT/Message/mutation/messagePush.gql"
import messageNewGql from "graphql/PHONE/CHAT/Message/subscription/messageNew.gql"
import React, { useState } from "react"
import { MessageDisplay } from "../../Message/Display/MessageDisplay"

import { animateScroll } from "react-scroll";
import channelTargetLeaveGql from "graphql/PHONE/CHAT/ChannelTarget/mutation/channelTargetLeave.gql"
import channelGetGql from "graphql/PHONE/CHAT/Channel/query/channelGet.gql"
import channelUpdateGql from "graphql/PHONE/CHAT/Channel/subscription/channelUpdate.gql"
import channelTargetUpdateGql from "graphql/PHONE/CHAT/ChannelTarget/mutation/channelTargetUpdate.gql"
import { PropositionDetailsModal } from "components/front/PMS/Proposition/Widget/PropositionDetailsModal"
import { ReservationDetailsModal } from "components/front/PMS/Reservation/Widget/ReservationDetailsModal"
import { IconClose, IconEllipsis } from "@zipou/front_tools"

type ChannelContainerProps = {
  channelId: string,
  target: any,
  isAgent?: boolean,
}

export const ChannelContainer = (props: ChannelContainerProps) => {

  const [text, updateText] = useState<string>(null)
  const [channel, updateChannel] = useState<any>(null)
  const [isWriting, updateIsWriting] = useState<any>(false)
  const [mutateMessagePush] = useMutation(messagePushGql)
  const [mutateChannelTargetLeave] = useMutation(channelTargetLeaveGql)
  const [mutateChannelTargetUpdate] = useMutation(channelTargetUpdateGql)

  useQuery(channelGetGql, {
    variables: {
      channelId: props?.channelId,
      id: props?.target?.id,
      clientId: props?.target?.clientId,
    },
    onCompleted: (data) => {
      updateChannel(data.channel)
      scrollToBottom()
    }
  })

  const target = props?.target

  const channelTarget = channel?.ChannelTarget
  const channelTargetOther = channelTarget?.filter((el: any) => el.targetId !== props?.target?.id)
  const isOtherWriting = channelTargetOther?.reduce((acc: boolean, current: any) => {
    if (acc) return acc
    if (current?.isWriting) return true
    return false
  }, false)

  // const [messageList, updateMessageList] = useState<any[]>([])

  useSubscription(channelUpdateGql, {
    variables: {
      channelId: props?.channelId,
      id: props?.target?.id,
      clientId: props?.target?.clientId,
    },
    skip: !channel?.id,
    onSubscriptionData: (response) => {
      updateChannel({
        ...response.subscriptionData?.data?.channel,
        messageList: channel.messageList,
      })
    }
  })

  useSubscription(messageNewGql, {
    onSubscriptionData: (response) => {
      pushContent(response.subscriptionData.data.message)
    },
    variables: {
      channelId: props?.channelId,
    },
    skip: !props?.channelId,
  })

  const updateWriting = (isWriting: boolean) => {
    mutateChannelTargetUpdate({
      variables: {
        id: target.id,
        clientId: target.clientId,
        channelId: props?.channelId,
        input: {
          isWriting,
        }
      }
    })
      .then(() => console.log("UPDATE OK"))
      .catch((e: any) => console.log("ERROR", e.message))
  }

  // mutateChannelTargetUpdate


  const onFieldUpdate = (e: any) => {
    updateText(e.target.value)

    if (isWriting) {
      clearInterval(isWriting)
      updateIsWriting(
        setTimeout(() => {
          updateWriting(false)
          updateIsWriting(false)
        }, 3000)
      )
    } else {
      updateWriting(true)
      updateIsWriting(
        setTimeout(() => {
          updateWriting(false)
          updateIsWriting(false)
        }, 3000)
      )


    }

  }

  const pushContent = (message: any) => {

    updateChannel({
      ...channel,
      messageList: [
        ...(channel?.messageList || []),
        message,
      ]
    })

    // updateMessageList([
    //   ...messageList,
    //   message,
    // ])
    scrollToBottom()
  }

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: `scroller_${props?.channelId}`,
      duration: 0,
    });
  }

  const onLeave = () => {
    mutateChannelTargetLeave({
      variables: {
        id: target.id,
        clientId: target.clientId,
        channelId: props?.channelId,
      }
    })
      .then(() => console.log("OK"))
      .catch((e: any) => console.log(`error`, e.message))
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    mutateMessagePush({
      variables: {
        id: target.id,
        clientId: target.clientId,
        input: {
          channelId: props?.channelId,
          content: [{
            type: "TYPE_TEXT",
            value: text,
            options: {}
          }]
        }
      }
    })
    updateText("")
  }

  const isChannelOpen = channel?.status === "STATUS_OPEN"
  const options = channel?.options
  const propositionId = options?.propositionId
  const reservationId = options?.reservationId
  const messageList = channel?.messageList

  const HEIGHT = 260 // VS 240


  // return <div style={{position: "relative", height: 300, width: 300, backgroundColor: "#FFFFFF", border: "4px #00356b solid", borderRadius: 5, overflow: "hidden", marginRight: 10}}>
  return <div className="card border-dark" style={{ position: "relative", minHeight: 300, minWidth: 300, backgroundColor: "#FFFFFF", overflow: "hidden", marginRight: 10 }}>
    <div className="card-header text-white" style={{ backgroundColor: "#343a40", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 0 }}>
      <span>Chat</span>
      <button onClick={() => onLeave()}>
        <IconClose />
      </button>
    </div>
    <div className="card-body" style={{ padding: 0 }}>
      <div className="" style={{ height: HEIGHT }}>
        {!isChannelOpen && <div style={{ position: "absolute", zIndex: 3000, top: 150, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", width: "100%", backgroundColor: "#888", padding: 5 }}>
          <span>Ce chat est désormais terminé</span>
        </div>}
        {(isOtherWriting) && <div style={{ position: "absolute", zIndex: 3000, top: HEIGHT + 20, display: "flex", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "left", width: "100%", padding: "5px 15px" }}>
          <IconEllipsis style={{ fontSize: 24, color: "black" }} />
        </div>}
        <div className="" style={{ position: "absolute", top: 55, width: "100%", padding: 5, overflow: "scroll" }} id={`scroller_${props?.channelId}`}>
          <div className="scroller" style={{ height: HEIGHT - 40 }}>
            {messageList?.map(((data: any) => {
              // const message = data?.message
              return <MessageDisplay message={data} clientAuth={target} />
            }))}
          </div>
        </div>
      </div>
    </div>
    <div className="card-footer">
      <form onSubmit={onSubmit}>
        <input type="text" disabled={!isChannelOpen} value={text} className="form-control" placeholder="Message" style={{ height: 40, fontSize: 14 }} onChange={onFieldUpdate} />
      </form>
    </div>
    {(props?.isAgent) && <div>
      {propositionId && <div className="card-footer">
        <PropositionDetailsModal propositionId={propositionId} />
      </div>}
      {reservationId && <div className="card-footer">
        <ReservationDetailsModal reservationId={reservationId} />
      </div>}
    </div>}
  </div>
}