import gql from "graphql-tag";
import HotelFragment from "../fragment/HotelFragment";

export default gql`

  ${HotelFragment}

  query hotelListByCorpId($corpId: String!) {
    list: hotelListByCorpId(corpId: $corpId) {
      ...HotelFragment
    }
  }
`