import React from "react"

type DurationFormatProps = {
  valueInMinutes: number,
}

export const DurationFormat = ({ valueInMinutes }: DurationFormatProps) => {

  const valueToDisplay = valueInMinutes < 60 ? `${valueInMinutes} min` :
    valueInMinutes % 60 === 0 ? `${valueInMinutes / 60} H` :
      <>{Math.floor(valueInMinutes / 60)}H{valueInMinutes - (Math.floor(valueInMinutes / 60) * 60)} </>

  return <span>{valueToDisplay}</span>

}