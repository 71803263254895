import React from "react"
import { TokenEdit } from "components/front/BILLING/Token/Edit/TokenEdit"
import { TokenAdd } from "components/front/BILLING/Token/Add/TokenAdd"
import { URL_BACKOFFICE_BILLING_TOKEN_DISPLAY } from "constant/url"
import { useHistory } from "react-router-dom"

export const TokenAddRoute = () => {

  const history = useHistory()

  return <div>
    <h1>Ajouter une empreinte</h1>
    <TokenAdd onDone={token => history.push(URL_BACKOFFICE_BILLING_TOKEN_DISPLAY.replace(":id", token.id))} />
  </div>
}