import {isFieldOnError} from "helpers/Apollo"
import React from "react"


type TextInputProps = {
  id?: string,
  label?: string,
  rightLabel?: any,
  disabled?: boolean,
  value: string,
  onChange: (data: any, v: string) => void
  errors?: any,
  maxLength?: number,
}

export const TextInput = (props: TextInputProps) => {

  return <div className="input-group">
  {props?.label && <span className="input-group-text">{props?.label}</span>}
  <input type="text" disabled={props?.disabled} maxLength={props?.maxLength} className='form-control' placeholder={props?.label} value={props?.value || ""} onChange={(e: any) => props?.onChange(e, e.target.value)} />
  {props?.rightLabel && <span className='input-group-text'>{props?.rightLabel}</span>}
  {isFieldOnError(props?.errors, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
</div>

}