import { useQuery, useSubscription } from "@apollo/react-hooks"
import { PostStayAnswer } from "components/front/PMS/Stay/Post/Answer/PostStayAnswer"
import { HotelContainer } from "components/public/PMS/Hotel/HotelContainer"
import deviceGetByIdAndTokenGql from "graphql/APP/Device/query/deviceGetByIdAndToken.gql"
import deviceUsageChangeGql from "graphql/APP/Device/subscription/deviceUsageChange.gql"
import { Device, DeviceUsageEnum } from "model"
import React from "react"


type DeviceDisplayProps = {
  id: string,
  token: string,
}


export const DeviceDisplay = ({ id, token }: DeviceDisplayProps) => {

  useSubscription(deviceUsageChangeGql, {
    variables: {
      id,
      token,
    },
    skip: !(id && token)
  })


  const { data } = useQuery<{ device: Device }>(deviceGetByIdAndTokenGql, {
    variables: {
      id,
      token,
    },
    skip: !(id && token)
  })

  const device = data?.device

  switch (device?.usage) {
    case DeviceUsageEnum.DEVICE_USAGE_IDLE: {
      return <div>
        <HotelContainer hotel={device?.Hotel}>
          <div></div>
        </HotelContainer>
      </div>
    }
    case DeviceUsageEnum.DEVICE_USAGE_POST_STAY: {
      return <div>
        <HotelContainer hotel={device?.Hotel}>
          <PostStayAnswer
            id={device?.usageData?.id}
            publicId={device?.usageData?.publicId}
          // postStay={postStay}
          // reservation={reservation} 
          // hotel={hotel}
          />
        </HotelContainer>
      </div>
    }
  }

  return <div>
    <h1>Device {device?.name}</h1>
  </div>
}