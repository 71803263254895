import {isFieldOnError} from "helpers/Apollo"
import React from "react"


type BooleanInputProps = {
  id?: string,
  label: string,
  value: boolean,
  errors: any,
  warning?: any,
  allowEmpty?: boolean,
  onChange: (result: boolean) => void,
}

export const BooleanInput = (props: BooleanInputProps) => {

  return <div className="input-group">
  <span className="input-group-text">{props?.label}</span>
  <select className="form-control" value={(props?.value !== null && props?.value !== undefined )  ? `${props?.value ? "1" : "0"}` : ""} onChange={(e: any ) => props?.onChange(e.target.value === "1")}>
    <option value="" disabled={!props?.allowEmpty}>Choisissez</option>
    <option value="1">Oui</option>
    <option value="0">Non</option>
  </select>
  {isFieldOnError(props?.errors, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
  {isFieldOnError(props?.warning, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning" /></span>}
</div>

}