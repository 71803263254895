import gql from "graphql-tag";
import ContactFragment from "../fragment/ContactFragment";

export default gql`

  ${ContactFragment}

  mutation contactAdd($hotelId: String!, $input: ContactInput!) {
    contact: contactAdd(hotelId: $hotelId, input: $input) {
      ...ContactFragment
    }
  }

`