import { SelectInput, TextInput } from "@zipou/front_tools"
import { TerminalInput, TerminalTypeEnum } from "model"
import React from "react"

type TerminalFormProps = {
  terminal: TerminalInput,
  onChange: (terminal: TerminalInput) => void,
  errors: any,
}

export const TerminalForm = ({ terminal, onChange, errors }: TerminalFormProps) => {

  const isTypeStripe = terminal?.type === TerminalTypeEnum.TYPE_STRIPE

  
  return <div>

    <TextInput id="name" value={terminal?.name} errors={errors} label="Nom" onChange={(v) => {
      onChange({
        ...terminal,
        name: v,
      })
    }} />

    <TextInput id="location" value={terminal?.location} errors={errors} label="Localisation" onChange={(v) => {
      onChange({
        ...terminal,
        location: v,
      })
    }} />

    <SelectInput id="type" label="Type" choiceList={[{ id: TerminalTypeEnum.TYPE_STRIPE, label: "STRIPE" }]} value={terminal?.type} errors={errors} onChange={(v) => {
      onChange({
        ...terminal,
        type: v as TerminalTypeEnum,
      })
    }} />

    {isTypeStripe && <div className="input-group">
      <span className="input-group-text">Stripe Options</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput label="Stripe Terminal Id" errors={errors} id="stripeTerminalId" value={terminal?.stripeOptions?.stripeTerminalId} onChange={(stripeTerminalId) => {
          onChange({
            ...terminal,
            stripeOptions: {
              ...terminal?.stripeOptions,
              stripeTerminalId,
            }
          })
        }} />
      </div>
    </div>
    }
  </div>


}