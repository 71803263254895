import { useQuery } from "@apollo/react-hooks"
import fileGetGql from "graphql/APP/File/query/fileGet.gql"
import { File } from "model"
import React, { useState } from "react"
import { FileAdd } from "../Add/FileAdd"
import { Modal } from "@zipou/front_tools"

type FileInputProps = {
  id: string,
  label: string,
  onAdd: (file: File) => void,
}

export const FileInput = (props: FileInputProps) => {

  const [focusAdd, updateFocusAdd] = useState(false)
  // const [focusEdit, updateFocusEdit] = useState(false)

  const { data } = useQuery<{ file: File }>(fileGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
  })

  const file = data?.file

  return <div>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)} >
      <FileAdd onDone={(file: File) => {
        updateFocusAdd(false)
        props?.onAdd(file)
      }} />
    </Modal>
    <div className="input-group">
      <span className="input-group-text" style={{height: "100%"}}>{props?.label}</span>
      <input type="text" className="form-control" disabled value={file?.name || ""} />
      <span className="input-group-text">
        <button className="badge badge-sm badge-info" onClick={() => updateFocusAdd(true)}>
          Modifier
        </button>
      </span>
    </div>
  </div>

}