import gql from "graphql-tag";
import PostStayQuestionEditFragment from "../../Question/fragment/PostStayQuestionEditFragment";

export default gql`

${PostStayQuestionEditFragment}

fragment PostStayGroupItemFragment on PostStayGroupItem {
  id
  type
  Question {
    ...PostStayQuestionEditFragment
  }
  Separator {
    id
    labelEn
    labelFr
  }
}

`