import React from "react"
import { PostStayStatus } from "./PostStayStatus"
import { PostStayStatusEnum } from "model"

export type PostStayStatusFilterProps = {
  value: string[],
  onChange: (datas: PostStayStatusEnum[]) => void
}


export const PostStayStatusFilter = (props: PostStayStatusFilterProps) => {

  const statusList = Object.keys(PostStayStatusEnum)

  const { value, onChange } = props;

  return <div className="card">
    <div className="card-body">
      <button className="btn btn-sm btn-warning" onClick={() => onChange([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => onChange(statusList as PostStayStatusEnum[])} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group" >
        {statusList?.map((item) => {
          return <li className="list-group-item d-flex justify-content-start align-items-center" >
            <input type="checkbox" id={item} checked={!!value?.includes(item)} onChange={(e: any) => {
              const newValue = e.target.checked ? [...(value || []), item] : value?.filter((el: string) => el !== item)
              onChange(newValue as PostStayStatusEnum[])
            }} />
            <label htmlFor={item} style={{ padding: "0 10px", margin: 0 }}>
              <PostStayStatus status={item as PostStayStatusEnum} />
            </label>
          </li>

        })}
      </ul>
    </div>
  </div>
}