import React from "react"
import { EventSourceTypeEnum } from "model"

type EventSourceTypeDisplayProps = {
  sourceType: EventSourceTypeEnum
}

export const EventSourceTypeDisplay = (props: EventSourceTypeDisplayProps) => {

  switch (props?.sourceType) {
    case EventSourceTypeEnum.PLANNING_EVENT_SOURCE_CONFIRMATION: {
      return <span className="badge badge-info">Confirmation</span>
    }

    case EventSourceTypeEnum.PLANNING_EVENT_SOURCE_PRESTAY: {
      return <span className="badge badge-warning">Pré Stay</span>
    }

    case EventSourceTypeEnum.PLANNING_EVENT_SOURCE_RESERVATION: {
      return <span className="badge badge-info">Reservation</span>
    }

    case EventSourceTypeEnum.PLANNING_EVENT_SOURCE_RESERVATION_GROUP: {
      return <span className="badge badge-info">Reservation Group</span>
    }

    default:
      return <span />
  }

}