import gql from "graphql-tag";
import PostStayAnswerStatsFragmentGql from "../../Answer/Stats/fragment/PostStayAnswerStatsFragment.gql";

export default gql`

${PostStayAnswerStatsFragmentGql}

fragment PostStayQuestionStatsFragment on PostStayQuestion {
  id
  type
  name
  choiceList
  conditionKey
  conditionValue
  labelFr
  descFr
  labelEn
  descEn
  PostStayAnswerStats {
    ...PostStayAnswerStatsFragment
  }
}
`