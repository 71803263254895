import { gql, useQuery } from "@apollo/react-hooks"
import React from "react"
import { PropositionStatus } from "./PropositionStatus"
import { PropositionStatusEnum } from "model"

export type PropositionStatusFilterProps = {
  value: string[],
  onChange: (datas: String[]) => void
}


export const PropositionStatusFilter = (props: PropositionStatusFilterProps) => {


  const { value, onChange } = props;

  const propositionStatusList = Object.values(PropositionStatusEnum)


  return <div className="card">
    <div className="card-body">
      <ul className="list-group" >
        {propositionStatusList?.map((item) => {
          return <li className="list-group-item d-flex justify-content-start align-items-center" >
            <input type="checkbox" id={item} checked={!!value?.includes(item)} onChange={(e: any) => {
              const newValue = e.target.checked ? [...(value || []), item] : value?.filter((el: string) => el !== item)
              onChange(newValue)
            }} />
            <label htmlFor={item} style={{ padding: "0 10px", margin: 0 }}>
              <PropositionStatus value={item} />
            </label>
          </li>

        })}
      </ul>
    </div>
  </div>
}