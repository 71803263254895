import gql from "graphql-tag";

export default gql`

fragment PlanningWeeklyFragment on PlanningWeekly {
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
  sunday
}
`