import { useQuery } from "@apollo/react-hooks"
import { ContactSourceDetails } from "components/front/PMS/Contact/Details/ContactSourceDetails"
import customerEmailGetGql from "graphql/CRM/Customer/Email/query/customerEmailGet.gql"
import { CustomerEmail, EmailStatusEnum } from "model"
import React from "react"
import { CustomerEmailStatusDisplay } from "../Status/Display/CustomerEmailStatusDisplay"

type CustomerEmailDetailsProps = {
  id: string
}

export const CustomerEmailDetails = ({ id }: CustomerEmailDetailsProps) => {

  const { data } = useQuery<{ customerEmail: CustomerEmail }>(customerEmailGetGql, {
    variables: {
      id,
    },
    skip: !id,
  })

  const customerEmail = data?.customerEmail
  const hasContact = customerEmail?.Contact

  return <div>
    <table className="table table-bordered table stripped">
      <tbody>
        <tr>
          <th>Email</th>
          <td>{customerEmail?.email}</td>
        </tr>
        <tr>
          <th>Domain</th>
          <td>{customerEmail?.domain}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td><CustomerEmailStatusDisplay status={customerEmail?.status as EmailStatusEnum} /></td>
        </tr>
        {hasContact && <tr>
          <th>Source</th>
          <td>
            <ContactSourceDetails contact={customerEmail?.Contact} />
          </td>
        </tr>}
      </tbody>
    </table>
  </div>
}