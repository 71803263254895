import React, {useState} from "react"
import {useMutation, useQuery} from "@apollo/react-hooks"

import {SelectInput} from "components/common/Form/SelectInput"
import contactEditGql from "graphql/PMS/Contact/mutation/contactEdit.gql"
import contactGetGql from "graphql/PMS/Contact/query/contactGet.gql"
import {ContactDetails} from "../Details/ContactDetails"


type ContactEditProps = {
  contactId: string,
  onDone?: () => void,
}

export const ContactEdit = (props: ContactEditProps) => {

  const [contact, updateContact] = useState<any>(null)

  const {data} = useQuery(contactGetGql, {
    variables: {
      id: props?.contactId,
    },
    onCompleted: (data) => {
      updateContact(data.contact)
    }
  })

  const [hasChanged, updateHasChanged] = useState<any>(false)

  const [mutate] = useMutation(contactEditGql)

  const onChange = (value: any) => {
    updateContact(value)
    updateHasChanged(true)
  }

  const onCancel = () => {
    updateHasChanged(false)
    updateContact(data?.contact)
    props?.onDone && props?.onDone()
  }

  const onSave = () => {

    const {forceIsFrench} = contact

    mutate({
      variables: {
        id: props?.contactId,
        input: {
          forceIsFrench,
        }
      }
    })
    .then(() => {
      props?.onDone && props?.onDone()
    })
  }

  return <div>
    <div>
      <ContactDetails contact={contact} />
    </div>
    <div>
    <SelectInput defaultValueActive value={contact?.forceIsFrench === false ? "0" : contact?.forceIsFrench === true ? "1" : "" } disabled={false} defaultValue={"Non"} choiceList={[{id: "1", label: "Forcé en Français"}, {id: "0", label: "Forcé en Anglais"},]} errors={{}} label="Forcer la Langue" onChange={(_,v) => {
      onChange({
        ...contact,
        forceIsFrench: v === "" ? null : v === "0" ? false : true,
      })
    }} />
    </div>
    {hasChanged && <div>
      <button className="btn btn-dark" onClick={onSave} style={{marginLeft: 5}}>Enregistrer</button>
      <button className="btn btn-danger" onClick={onCancel} style={{marginLeft: 5}}>Annuler</button>
    </div>}
  </div>

}