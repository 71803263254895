import {gql, useQuery} from "@apollo/react-hooks"
import React from "react"
import {PaymentStatus} from "./PaymentStatus"

export type PaymentStatusFilterProps = {
  value: string[],
  onChange: (datas: String[]) => void
}


export const PaymentStatusFilter = (props: PaymentStatusFilterProps) => {

  const {data} = useQuery(gql`query paymentStatusList { list: paymentStatusList }`)
  const {value, onChange} = props;

  return <div className="card">
    <div className="card-body">
      <ul className="list-group" >
        {data?.list?.map((item: string) => {
          return <li className="list-group-item d-flex justify-content-start align-items-center" >
            <input type="checkbox" id={item} checked={!!value?.includes(item)} onChange={(e: any) => {
              const newValue = e.target.checked ? [...(value || []), item] : value?.filter((el: string) => el !== item)
              onChange(newValue)
            }} />
            <label htmlFor={item} style={{padding: "0 10px", margin: 0}}>
              <PaymentStatus status={item} />
            </label>
          </li>

        })}
      </ul>
    </div>
  </div>
}