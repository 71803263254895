
import React from "react"
import reservationSyncById from "graphql/PMS/Reservation/mutation/reservationSyncById";
import { useMutation } from "@apollo/react-hooks";

type ReservationSyncButtonProps = {
  reservationId: string,
}

export const ReservationSyncButton = (props: ReservationSyncButtonProps) => {

  const [mutate, { loading }] = useMutation(reservationSyncById)

  const onSync = (reservationId: string) => {
    mutate({
      variables: {
        reservationId,
      }
    })
  }

  return <button className="btn btn-warning btn-sm" disabled={loading} onClick={() => onSync(props?.reservationId)}>
    {loading && <span className="icon-loading"> </span>} Re synchroniser avec le PMS
  </button>

}