import { IconCheck } from "@zipou/front_tools"
import React, { useState } from "react"


type ClickAndCopyProps = {
  value: string
}
export const ClickAndCopy = (props: ClickAndCopyProps) => {

  const [inClipboard, setInClipboard] = useState(false)

  const onClickCopy = (event: any) => {
    setInClipboard(true)
    navigator.clipboard.writeText(props?.value)
  }

  return <span className="input-group-text" onClick={onClickCopy} style={{ cursor: "pointer" }}>
    {inClipboard && <div style={{ display: "flex" }}><IconCheck style={{ color: "#AAA", marginRight: 5 }} />Copié !</div>}
    {!inClipboard && <span>Copier</span>}
  </span>
}