import gql from "graphql-tag";
import ContactPublicFragment from "graphql/PMS/Contact/fragment/ContactPublicFragment";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import ReservationRateFragment from "../Rate/fragment/ReservationRateFragment";

export default gql`

  ${HotelPublicFragment}
  ${ContactPublicFragment}
  ${ReservationRateFragment}

  fragment ReservationPublicFragment on Reservation {
    id
    status
    pmsId
    departureDate
    arrivalDate
    nbNight
    npPeople
    nbSpecial
    nbAdults
    nbChildren
    totalIncTax
    DefaultRate {
      ...ReservationRateFragment
    }
    ReservationRate {
      ...ReservationRateFragment
    }
    ReservationPricePackage {
      id
      quantity
      ammountIncVat
      Option {
        id
        canBeDisplayedInConfirmation
        labelFr
        labelEn
      }
    }
    Hotel {
      ...HotelPublicFragment
    }
    ReservationRoom {
      id
      roomId
      Room {
        id
        RoomType {
          id
          labelEn
          labelFr
          descImgUrls
        }
      }
    }
    ReservationGuest {
      id
      Contact {
        ...ContactPublicFragment
      }
    }
    Contact {
      ...ContactPublicFragment
    }
  }

`