import React from "react"

type PaymentCaptureTypeProps = {
  type: string,
}

export const PaymentCaptureType = (props: PaymentCaptureTypeProps) => {
  switch (props?.type) {

    case "CAPTURE_TYPE_PRE_AUTH": {
      return <span className="badge badge-info">Pré Autorisation</span>
    }

    case "CAPTURE_TYPE_FULL": {
      return <span className="badge badge-secondary">Paiement</span>
    }

    default: {
      return <span className="badge badge-info">{props?.type}</span>
    }
  }
}