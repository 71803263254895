import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import ContactDedupListFragment from "../fragment/ContactDedupListFragment";

export default gql`

  ${ContactDedupListFragment}
  ${CursorFragment}

  query contactDedupList($input: ContactDedupListInput!, $cursor: CursorInput!){
    list: contactDedupList(input: $input, cursor: $cursor) {
      cursor {
        ...CursorFragment
      }
      nodes {
        ...ContactDedupListFragment
      }
    }
  }

`