import React from "react"
import { PostStayResponseStatusEnum } from "model"
import { PostStayResponseStatus } from "./PostStayResponseStatus"

export type PostStayResponseStatusFilterProps = {
  value: string[],
  onChange: (datas: PostStayResponseStatusEnum[]) => void
}


export const PostStayResponseStatusFilter = (props: PostStayResponseStatusFilterProps) => {

  const statusList = Object.keys(PostStayResponseStatusEnum)

  const { value, onChange } = props;

  return <div className="card">
    <div className="card-body">
      <button className="btn btn-sm btn-warning" onClick={() => onChange([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => onChange(statusList as PostStayResponseStatusEnum[])} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group" >
        {statusList?.map((item) => {
          return <li className="list-group-item d-flex justify-content-start align-items-center" >
            <input type="checkbox" id={item} checked={!!value?.includes(item)} onChange={(e: any) => {
              const newValue = e.target.checked ? [...(value || []), item] : value?.filter((el: string) => el !== item)
              onChange(newValue as PostStayResponseStatusEnum[])
            }} />
            <label htmlFor={item} style={{ padding: "0 10px", margin: 0 }}>
              <PostStayResponseStatus status={item as PostStayResponseStatusEnum} />
            </label>
          </li>

        })}
      </ul>
    </div>
  </div>
}