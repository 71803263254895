import gql from "graphql-tag";
import TemplateFragment from "../fragment/TemplateFragment";

export default gql`

  ${TemplateFragment}

  mutation templateAdd($hotelId: String!, $input: TemplateInput!) {
    template: templateAdd(input: $input, hotelId: $hotelId) {
      ...TemplateFragment
    }
  }
`