import gql from "graphql-tag";
import NewsListFragmentGql from "../fragment/NewsListFragment.gql";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";

export default gql`

  ${NewsListFragmentGql}
  ${CursorFragment}

  query newsList($cursor: CursorInput!) {
    list: newsList(cursor: $cursor) {
      cursor {
        ...CursorFragment
      }
      nodes {
        ...NewsListFragment

      }
    }
  }

`