import gql from "graphql-tag";
import PropositionFragment from "../fragment/PropositionFragment";

export default gql`
  ${PropositionFragment}

  mutation propositionSend($id: String!) {
    proposition: propositionSend(id: $id) {
      ...PropositionFragment
    }
  }

`