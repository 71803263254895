import gql from "graphql-tag";
import UserConfigFragmentGql from "../fragment/UserConfigFragment.gql";

export default gql`

  ${UserConfigFragmentGql}

  query userConfigGet($id: String!) {
    config: userConfigGet(id: $id) {
      ...UserConfigFragment
    }
  }

`