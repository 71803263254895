import gql from "graphql-tag";

export default gql`

  fragment CursorFragment on Cursor {
    page
    totalPages
    limit
  }

`