import React from "react"
import {isFieldOnError} from "helpers/Apollo"

type SelectInputProps = {
  id?: string
  label: string,
  value: string,
  disabled?: boolean;
  errors: any,
  defaultValue?: string,
  defaultValueActive?: boolean,
  onChange: (event: any, v: string) => void,
  choiceList: {label: string, id: string}[]
}

export const SelectInput = (props: SelectInputProps) => {

  return <div className="input-group">
    <span className="input-group-text">{props?.label}</span>
    <select className="form-control" value={props?.value || ""} disabled={props?.disabled || false} onChange={(e) => props?.onChange(e, e.target.value)}>
      {!props?.defaultValue && <option value="" disabled={!props?.defaultValueActive}>Choisissez</option>}
      {props?.defaultValue && <option value="" disabled={!props?.defaultValueActive}>{props.defaultValue}</option>}
      {props.choiceList?.map(choice => {
        return <option key={`option_${props?.label}_${choice.id}`} value={choice.id}>{choice.label}</option>
      })}
    </select>
    {isFieldOnError(props?.errors, props?.id || props?.label) && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
  </div>

}