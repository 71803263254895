import { Contact, ContactTypeEnum } from "model";
import React from "react"

type ContactDisplayProps = {
  contact: Contact,
  showAdmin?: boolean,
}

export const ContactDisplay = ({ contact, showAdmin }: ContactDisplayProps) => {

  const isFrench = contact?.isFrench
  const isGuest = contact?.type === ContactTypeEnum.GUEST
  const isForceIsFrench = contact?.forceIsFrench

  const isAdmin = showAdmin

  const renderCorp = (contact: any) => {
    return <span>{contact?.companyName || contact?.lastName}</span>
  }

  const renderGuest = (contact: any) => {
    return <span style={{ whiteSpace: "nowrap" }}>
      {contact?.firstName || ""} {contact?.lastName?.toUpperCase() || ""}
      {isAdmin && (!isFrench ? <span className="icon-globe" style={{ marginLeft: 5, fontSize: 13, color: "white" }} /> : <span />)}
      {isAdmin && ((isForceIsFrench === true || isForceIsFrench === false) ? <span className="icon-flag" style={{ marginLeft: 5, fontSize: 13 }} /> : <span />)}
    </span>
  }

  return contact ? <span style={{ display: "flex" }}>
    {isGuest ? renderGuest(contact) : renderCorp(contact)}
  </span> : <div />

}
