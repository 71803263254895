import { useQuery } from "@apollo/react-hooks"
import { TextInput } from "@zipou/front_tools"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import hotelListGql from "graphql/PMS/Hotel/query/hotelList"
import { CorpInput, Hotel } from "model"
import React from "react"

type CorpFormProps = {
  corp: CorpInput,
  onChange: (corp: CorpInput) => void,
  errors: any,
}

export const CorpForm = ({ corp, onChange, errors }: CorpFormProps) => {

  const { data } = useQuery<{ list: Hotel[] }>(hotelListGql)

  const hotelList = data?.list

  return <div>
    <TextInput label="Nom" id="name" value={corp?.name || ""} errors={errors} onChange={(v) => {
      onChange({
        ...corp,
        name: v
      })
    }} />

    <div className="input-group">
      <span className="input-group-text">Hotel</span>
      <div className="form-control" style={{ height: "100%" }}>
        <div className="d-flex flex-row flex-wrap">
          {hotelList?.map((hotel, index) => {

            const key = `hotel_${hotel?.id}_${index}`

            return <span key={key} className="mt-2 ml-2 mr-2 d-flex" style={{ whiteSpace: "nowrap" }}>
              <input type="checkbox" checked={corp?.hotelIdList?.includes(hotel?.id)} id={key} onChange={(e: any) => {
                const checked = e.target.checked
                onChange({
                  ...corp,
                  hotelIdList: checked ? [...(corp?.hotelIdList ? corp?.hotelIdList : []), hotel?.id] : corp?.hotelIdList?.filter((el: string) => el !== hotel?.id)
                })
              }} />
              <label htmlFor={key} style={{marginBottom: 0}}>
                <HotelBadge hotel={hotel} />
              </label>
            </span>

          })}
        </div>
      </div>
    </div>

  </div>
}