import { NewsWidget } from "components/front/APP/News/Widget/NewsWidget"
import { UserProfileDisplay } from "components/front/APP/User/Profile/UserProfileDisplay"
import { CustomerCountWidget } from "components/front/CRM/Customer/Widget/CustomerCountWidget"
// import {CTIAgent} from "components/front/CTI/CTIAgent"
import { HotelStatusWidget } from "components/front/PMS/Hotel/Widget/HotelStatusWidget"
import { ReservationCountWidget } from "components/front/PMS/Reservation/Widget/ReservationCountWidget"
import { userContext } from "context/User"
import React, { useContext } from "react"

export const MainRoute = () => {
  const user = useContext(userContext)


  // const isAdmin = user?.roles.includes("ROLE_ADMIN")
  const isPMS = user?.roles.includes("ROLE_PMS")
  const isCRM = user?.roles.includes("ROLE_CRM")

  return <div className="container">
    <h1>Welcome <span className="capitalize">{user?.name}</span> ;)</h1>
    <div className="row">
    </div>
    {isCRM && <div className="row">
      <div className="col-6 col-sm-3">
        <CustomerCountWidget />
      </div>
      <div className="col-6 col-sm-3">
        <ReservationCountWidget />
      </div>
    </div>}
    {isPMS && <div className="row" style={{ paddingTop: 15 }}>
      <div className="col-6 col-sm-6">
        <HotelStatusWidget />
      </div>
      {/* <div className="col-9 col-sm-8">
        <FetcherStatus />
      </div> */}
    </div>}
    <div className="row" style={{ paddingTop: 15 }}>
      <div className="col-12">
        <NewsWidget />
      </div>
    </div>
    {/* {isAdmin && <div className="row" style={{ paddingTop: 15 }}>
      <div className="col-12 col-sm-6">
        <ReservationLastCreatedWidget />
      </div>
      <div className="col-12 col-sm-6">
        <ReservationLastUpdatedWidget />
      </div>
    </div>}
    {isAdmin && <div className="row" style={{ paddingTop: 15 }}>
      <div className="col-12">
        <ContactLastUpdatedWidget />
      </div>
    </div>} */}
    {/* <div style={{paddingTop: 30}}>
      <p>Ceci est la page d'accueil où viendront s'ajouter des widgets d'informations sur la base CRM</p>
    </div> */}
    <div className="mt-2">
      <UserProfileDisplay />
    </div>
  </div>
}