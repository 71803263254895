import React from "react"

export type PriceFormatProps = {
  value: number,
  currency?: string,
  withDecimal?: boolean,
}

export const PriceFormatFunction = (value: number, currency = '€', withDecimal = true) => `${value && (value / 100).toFixed(withDecimal ? 2 : 0)} ${currency}`


export const PriceFormat = (props: PriceFormatProps) => {
  const { value, currency = '€', withDecimal = true } = props
  return <span>{PriceFormatFunction(value, currency, withDecimal)}</span>
}

// return{`${value && (value / 100).toFixed(withDecimal ? 2 : 0)} ${currency}`}</span>
