import {ContactDedupStatusEnum} from "model"
import React from "react"

type ContactDedupStatusProps = {
  status: ContactDedupStatusEnum | string
}


export const ContactDedupStatus = (props: ContactDedupStatusProps) => {
  switch(props?.status) {
    case ContactDedupStatusEnum.STATUS_DONE: {
      return <span className="badge badge-success">DONE</span>
    }

    case ContactDedupStatusEnum.STATUS_TODO: {
      return <span className="badge badge-info">A FAIRE</span>
    }
    default: {
      return <span className="badge badge-info">{props?.status}</span>
    }
  }
}
