import gql from "graphql-tag";
import SegmentationListFragment from "../../fragment/SegmentationListFragment";
import CustomerEmailListFragment from "graphql/CRM/Customer/Email/fragment/CustomerEmailListFragment";

export default gql`

  ${CustomerEmailListFragment}
  ${SegmentationListFragment}

  fragment SegmentationEmailMemberFragment on SegmentationEmailMember {
    id
    CustomerEmail {
      ...CustomerEmailListFragment
    }
    Segmentation {
      ...SegmentationListFragment
    }
  }

`