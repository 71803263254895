import gql from "graphql-tag";
import SegmentationFragment from "../fragment/SegmentationFragment";

export default gql`

${SegmentationFragment}

mutation segmentationSync($id: String!) {
  segmentation: segmentationSync(id: $id) {
    ...SegmentationFragment
  }
}

`