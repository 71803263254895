import gql from "graphql-tag";
import CursorFragment from "graphql/APP/Cursor/fragment/CursorFragment";
import PostStayListFragmentGql from "../fragment/PostStayListFragment.gql";

export default gql`

  ${PostStayListFragmentGql}
  ${CursorFragment}

  query postStayListByCursorAndInput($input: PostStayListInput!, $cursor: CursorInput!) {
    list: postStayListByCursorAndInput(input: $input, cursor: $cursor) {
      nodes {
        ...PostStayListFragment
      }
      input,
      cursor {
        ...CursorFragment
      }
    }
  }

`