import gql from "graphql-tag";
import MessageFragmentGql from "../fragment/MessageFragment.gql";

export default gql`

  ${MessageFragmentGql}

  subscription messageNew($channelId: String!) {
    message: messageNew(channelId: $channelId) {
      ...MessageFragment
    }
  }
`