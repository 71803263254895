import { DeviceDisplay } from "components/front/APP/Device/Display"
import React from "react"


export const DeviceDisplayRoute = (props: any) => {

  const { id, token } = props?.match?.params

  return <div>
    <DeviceDisplay id={id} token={token} />
  </div>
}