import React from "react"

import {useQuery} from "@apollo/react-hooks"
import roomTypeGetGql from "graphql/PMS/Room/Type/query/roomTypeGet.gql"

type RoomTypeViewProps = {
  id: string,
  isFrench: boolean,
}

export const RoomTypeView = (props: RoomTypeViewProps) => {


  const {data} = useQuery(roomTypeGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
  })

  // const label = props?.isFrench ? data?.roomType?.labelFr : data?.roomType?.labelEn
  const label =  data?.roomType?.type

  return <span>{label}</span>
}