import gql from "graphql-tag";
import FileFragment from "../fragment/FileFragment";

export default gql`

${FileFragment}

mutation fileEdit($id: String!, $input: FileInput!) {
  file: fileEdit(id: $id, input: $input) {
    ...FileFragment
  }
}
 
`