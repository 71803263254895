import React from "react"
import { useState } from "react"

type TooltipProps = {
  label: string,
  children: any,
  top?: number,
  left?: number,
}

export const Tooltip = (props: TooltipProps) => {

  const [show, updateShow] = useState(false)

  const top = props?.top || -35
  const left = props?.left || -80

  return <span>
    <div style={{ position: "absolute", display: show ? "block" : "none" }}>
      <div className="tooltip bs-tooltip-top" role="tooltip" style={{ opacity: show ? 1 : 0, width: 180, top, left }}>
        <div className="tooltip-arrow"></div>
        <div className="tooltip-inner">
          {props?.label}
        </div>
      </div>
    </div>
    <span style={{ border: '' }} onMouseOver={() => updateShow(true)} onMouseOut={() => updateShow(false)}>
      {props?.children}
    </span>
  </span>

}